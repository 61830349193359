import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import Editor from "../../components/Editor";
import OutputSheet from "../../store/models/OutputSheet";
import CSVSheet from "../Worksheet/CSVSheet";
import { CsvFile } from "../Workspace/types";

type Props = {
  isActive: boolean;
  file: CsvFile;
  onActive: (sheetId: string) => void;
};

const Header = styled.div`
  font-size: 0.75rem;

  label {
    font-weight: bold;
    margin-right: 0.625rem;
    text-transform: uppercase;
    user-select: none;
  }

  span {
    color: #000000;
    margin-right: 1.875rem;

    &.country {
      display: inline;
    }
  }
`;

const SheetWithEditorContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CommentEditor = styled(Editor)`
  .editor {
    min-height: 26.55rem;
  }
`;

const SheetWithEditor = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: calc(100% * 3 / 5);

  ${Header} {
    padding: 1.25rem;
  }
`;

const CommentWithEditor = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 1.25rem;
  padding-left: 0;
  width: calc(100% * 2 / 5);

  ${Header} {
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
  }
`;

const CSVSheetWithEditor: React.FC<Props> = ({ isActive, file, onActive }) => {
  const methods = useForm();
  const sheet = file.sheet as OutputSheet;

  const handlePreSave = useCallback(async () => {
    // Update notes in output sheet before saving.
    await methods.handleSubmit((data) => {
      if (data.notes !== sheet.notes) {
        sheet.setValue("notes", data.notes);
      }
    })();
  }, [methods, sheet]);

  return (
    <FormProvider {...methods}>
      <SheetWithEditorContainer>
        <SheetWithEditor>
          <Header>
            <label>Viscosity</label>
            <span>{sheet.viscosity || "–"}</span>
            <label>Spec</label>
            <span>{sheet.specs}</span>
            <label>Countries</label>
            <span className="country">{sheet.countryNames.join(", ")}</span>
          </Header>
          <CSVSheet
            collapsible={false}
            isActive={isActive}
            onActive={onActive}
            onPreSave={handlePreSave}
            file={file}
            multiple
          />
        </SheetWithEditor>
        <CommentWithEditor>
          <Header>
            <label>Comments</label>
          </Header>
          <CommentEditor name="notes" defaultValue={sheet.notes} size="sm" />
        </CommentWithEditor>
      </SheetWithEditorContainer>
    </FormProvider>
  );
};

export default observer(CSVSheetWithEditor);
