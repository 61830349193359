import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { Alert, Button, Form, Image, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import bpLogo from "../../assets/images/bp-logo-green.svg";
import loginVector from "../../assets/images/login-vectors.png";
import FormField from "../../components/FormField";
import { LOGIN_REDIRECT_URL } from "../../constants";
import { Page } from "../Page";
import styles from "./styles.module.scss";

interface LoginData {
  email: string;
}

interface LoginLocationState {
  authError?: {
    error: string;
    errorDescription?: string;
  };
}

type APIValidationErrors<T> = Partial<{ [key in keyof T]: string[] }> & {
  nonFieldErrors?: string[];
} & { [key: string]: string[] };

type LoginProps = {};

export const Login: React.FC<LoginProps> = () => {
  const location = useLocation<LoginLocationState>();
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<APIValidationErrors<LoginData>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleSubmit = useCallback(() => {
    setErrors({});
    setIsLoading(true);

    if (!email) {
      setErrors({
        email: ["This field may not be blank."],
      });
      setIsLoading(false);
    } else {
      window.location.assign(`${LOGIN_REDIRECT_URL}&login_hint=${email}`);
    }
  }, [email, setErrors, setIsLoading]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Page title="Sign in" hideNav>
      <div className={styles.container}>
        <div className={clsx(styles.authForm, styles.login)}>
          <Image src={bpLogo} className={styles.bpLogo} />

          <Form className={styles.form}>
            <h4>Sign in</h4>
            {errors.detail && (
              <Alert className={styles.error} variant="danger">
                {errors.detail}
              </Alert>
            )}

            <Form.Group className={styles.formGroup}>
              <FormField
                containerClassName={styles.formInputContainer}
                error={errors.email}
              >
                <div className={styles.floatLabel}>
                  <Form.Control
                    required
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.currentTarget.value);
                      setIsActive(e.currentTarget.value ? true : false);
                    }}
                    onKeyPress={handleKeyPress}
                    className={styles.formInput}
                  />
                  <Form.Label
                    htmlFor="email"
                    className={isActive ? styles.active : ""}
                  ></Form.Label>
                </div>
              </FormField>
            </Form.Group>

            <Button
              className={styles.submitBtn}
              variant="primary"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading && <Spinner animation="border" size="sm" />}
              Continue
            </Button>
          </Form>
          {location.state && location.state.authError && (
            <div className={styles.errorMessage}>
              <p>
                {String(location.state.authError.error)}
                <br />
                {location.state.authError.errorDescription &&
                  String(location.state.authError.errorDescription)}
              </p>
            </div>
          )}
          <Image src={loginVector} className={styles.loginVector} />
        </div>
      </div>
    </Page>
  );
};

export default observer(Login);
