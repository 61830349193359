/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Model,
  _async,
  _await,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
} from "mobx-keystone";
import api from "../api";
import { APIRMPA, APIRMPAListSearchParams } from "../api/rmPriceAssumptions";
import RMPriceAssumption from "./models/RMPriceAssumption";

@model("collab/RMPriceAssumptionStore")
class RMPriceAssumptionStore extends Model({
  listItems: prop_mapObject(() => new Map<string, RMPriceAssumption>()),
}) {
  @modelAction
  saveListItem(item: APIRMPA) {
    const listItem = new RMPriceAssumption(item);
    this.listItems.set(
      `${item.rCode}-${item.productName}-${item.plantName}`,
      listItem
    );
    return listItem;
  }

  @modelAction
  getItemFromKey(key: string) {
    const item = this.listItems.get(key);
    return item === undefined ? null : item;
  }

  @modelFlow
  list = _async(function* (
    this: RMPriceAssumptionStore,
    page?: number,
    limit?: number,
    searchParams?: APIRMPAListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.rmPriceAssumptions.list(page, limit, searchParams));

    this.reset();
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelAction
  findRMPriceAssumption(rCode: string, description: string, plantName: string) {
    const items = Array.from(this.listItems.values());

    let item = items.find(
      (r) =>
        r.rCode === rCode &&
        r.productName === description &&
        r.plantName === plantName
    );
    if (item !== undefined) return item;

    item = items.find((r) => r.rCode === rCode && r.plantName === plantName);
    if (item !== undefined) return item;

    return items.find(
      (r) => r.productName === description && r.plantName === plantName
    );
  }

  @modelAction
  reset() {
    this.listItems.clear();
  }
}

export default RMPriceAssumptionStore;
