import {
  APIRMFormulationAdditivePercentage,
  APIRMFormulationBaseOilPercentage,
} from "api/rmFormulations";
import { Model, model, prop } from "mobx-keystone";

@model("collab/RMFormulation")
class RMFormulation extends Model({
  id: prop<identifier>(),
  project: prop<identifier>(),
  specs: prop<string>(""),
  code: prop<string>(""),
  plant: prop<identifier | null>(null),
  plantName: prop<string>(""),
  plantType: prop<"master" | "plant" | null>(null),
  formulationOption: prop<string>(""),
  plantOption: prop<string>(""),
  altScenario: prop<string>(""),
  fgVol: prop<number>(0),
  ordering: prop<number>(9999),
  baseOilPercentages: prop<APIRMFormulationBaseOilPercentage[]>(() => []),
  additivePercentages: prop<APIRMFormulationAdditivePercentage[]>(() => []),
}) {}

export default RMFormulation;
