import { FormatColorFill, InfoOutlined } from "@material-ui/icons";
import Button from "components/Button";
import { useCsvToGridDataAsync } from "components/DataGrid/hooks";
import { IRow } from "csv/types";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import { CsvFile } from "pages/Workspace/types";
import React, { useCallback } from "react";
import type { ButtonProps } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useStore } from "store";
import { Toast } from "utils";

type Props = ButtonProps & {
  file?: CsvFile;
};

const AutofillForexButton: React.FC<Props> = ({
  file,
  // TODO: Update when designs are finalized
  // Currently using clt-files styles
  variant = "clt-files",
  size = "thin",
  ...props
}) => {
  const project = useProject();
  const store = useStore();
  const currentUser = store.auth.current;

  const [columns, rows, csvLoaded, setRows] = useCsvToGridDataAsync({
    sheet: file?.sheet,
    editable: file?.editable ?? true,
    schema: file?.schema,
  });

  const hasEditPermission =
    currentUser &&
    (currentUser.isAdmin ||
      currentUser.isAnalyst ||
      currentUser.id === project?.creator ||
      project?.adminUsers.includes(currentUser.id));

  const update = useCallback(
    (rows: IRow[]) => {
      if (!file) return;

      const { updatable, sheet } = file;
      if (updatable) {
        const rowCopy = rows.map(({ id, ...rest }) => rest) as IRow[];

        // Include hidden columns, but not the "id".
        const headers = columns
          .filter(({ key }) => key !== "id")
          .map(({ key }) => key);

        sheet.setCsvString(rowCopy, headers);
      }
    },
    [columns, file]
  );

  const handleAutofillForexClick = useCallback(async () => {
    if (!project || !file) return;
    if (!project.forexDate) {
      Toast.danger("Please set the forex end rate date.");
      return;
    }

    project.setLoading();

    try {
      // Fetch forex data
      const { results } = await store.forex.list(undefined, undefined, {
        forex_date: project.forexDate.substring(0, 10),
      });

      if (!results.length) {
        Toast.danger("No data were found in the database.");
        return;
      }

      // Update grid data
      const newRows = cloneDeep(rows).map((row) => {
        const code = row["currency_code"];
        row["1usd="] =
          store.forex.getUsdExchangeRate(code as string) ?? row["1usd="];
        row["1euro="] =
          store.forex.getEurExchangeRate(code as string) ?? row["1euro="];
        return row;
      }) as IRow[];
      setRows(newRows);
      update(newRows);

      // Immediately save input sheets to display changes in frontend
      await store.defineSheets.saveAll(project.id);
      await store.defineSheets.list(project.id);

      Toast.success("Forex table filled.");
    } catch (e) {
      Toast.danger("Unable to fill forex table.");
      return;
    } finally {
      project.setNotLoading();
    }
  }, [file, project, rows, setRows, store.defineSheets, store.forex, update]);

  return (
    <>
      <Button
        variant={variant}
        size={size}
        onClick={handleAutofillForexClick}
        disabled={!hasEditPermission || project?.loading}
        {...props}
        style={{ marginRight: "-0.75rem" }}
      >
        <FormatColorFill /> Autofill Forex
      </Button>
      <OverlayTrigger
        placement="top"
        delay={{ show: 200, hide: 400 }}
        overlay={
          <Tooltip id={`forex-autofill-tooltip`}>
            Uses the latest forex data with respect to the Forex End Rate Date.
            Select and right-click on specific cells to autofill only those
            cells.
          </Tooltip>
        }
      >
        <InfoOutlined />
      </OverlayTrigger>
    </>
  );
};

export default observer(AutofillForexButton);
