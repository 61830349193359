import base, { APIList, APISearchParams, config } from "./base";

export interface APIForex {
  id: identifier;
  currencyCode: string;
  usdExchangeRate: number;
  eurExchangeRate: number;
  effectiveOn: Date;
}
export interface APIForexList extends APIList<APIForex> {}
export interface APIForexListSearchParams extends APISearchParams {
  forex_date: string;
  currency_codes?: string; // string[] ?
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIForexListSearchParams
) =>
  base.list<APIForexList, APIForexListSearchParams>(
    "forex/",
    page,
    limit,
    searchParams
  );
