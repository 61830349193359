import React from "react";
import { RootStore, StoreProvider } from "../store";
import Routes from "./Routes";
import ToastContainer from "../components/ToastContainer";
import "react-toastify/dist/ReactToastify.css";
interface Props {
  store: RootStore;
}

const Application: React.FC<Props> = ({ store }) => (
  <StoreProvider value={store}>
    <Routes />
    <ToastContainer />
  </StoreProvider>
);

export default Application;
