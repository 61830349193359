import base64js from "base64-js";
import base, { APIDetail, APIList, APISearchParams, config } from "./base";

export type APIDefineSheetFileType =
  | "forex"
  | "cost_sheet"
  | "rm_price_assumptions"
  | "rm_report_product"
  | "rm_report_add_and_oil";

export interface APIDefineSheet {
  id: identifier;
  project: identifier;
  cluster: identifier | null;
  clusterName?: string | null;
  fileType: APIDefineSheetFileType;
  file: string;
  isCltLocked: boolean;
}

export type APIDefineSheetInput = Partial<
  Omit<
    APIDefineSheet,
    "id" | "project" | "fileType" | "cluster" | "clusterName" | "isCltLocked"
  >
>;

export interface APIDefineSheetList extends APIList<APIDefineSheet> {}

export interface APIDefineSheetSearchParams extends APISearchParams {
  project?: number;
  file_type?: APIDefineSheetFileType;
  file_type__in?: APIDefineSheetFileType[];
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIDefineSheetSearchParams
) =>
  base.list<APIDefineSheetList, APIDefineSheetSearchParams>(
    "define-sheets/",
    page,
    limit,
    searchParams
  );

export interface APIDefineSheetDetail extends APIDetail<APIDefineSheet> {}

export const update = (sheetId: identifier, csvData: string) =>
  base.patch<APIDefineSheetDetail>(`define-sheets/${sheetId}/`, {
    json: {
      file: base64js.fromByteArray(Buffer.from(csvData)),
    },
  });
