import { computed } from "mobx";
import { ExtendedModel, model, prop } from "mobx-keystone";
import { APIDeliveryInfoInput } from "../../api/deliveryInfo";
import DirtyableWithId from "./DirtyableWithId";

@model("collab/DeliveryInfo")
class DeliveryInfo extends ExtendedModel(DirtyableWithId, {
  project: prop<identifier>(),
  cluster: prop<identifier>(),
  notes: prop<string>(),
  isCltLocked: prop<boolean>(),
}) {
  @computed
  get asAPI(): APIDeliveryInfoInput {
    return {
      project: this.project,
      cluster: this.cluster,
      notes: this.notes,
    };
  }
}

export default DeliveryInfo;
