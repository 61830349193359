import { CheckOutlined } from "@material-ui/icons";
import api from "api";
import { APIClusterInputSheetsStatustSearchParams } from "api/clusterInputSheetsStatus";
import clsx from "clsx";
import { formatDistanceToNow, parseISO } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useAsync } from "react-use";
import { useStore } from "store";
import Project from "store/models/Project";
import styled from "styled-components";
import Button from "../Button";
import { Header, SubHeader } from "../Header";
import ModalForm from "../ModalForm";
import Submit from "./Submit";

type Props = {
  project: Project;
};

const SubmitModalContainer = styled(ModalForm)`
  .modal-dialog {
    font-size: 0.875rem;
    line-height: 1.625rem;
    min-width: 34.75rem;
  }
`;

const HeaderRight = styled(Header)`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: capitalize;
`;

const SubmitModal: React.FC<Props> = ({ project }) => {
  const methods = useForm();
  const store = useStore();
  const [show, setShow] = useState(false);
  const currentUser = store.auth.current;

  const clusterStatusState = useAsync(async () => {
    const params: APIClusterInputSheetsStatustSearchParams = {
      project: project.id,
      cluster: currentUser?.analystCluster,
    };
    return api.clusterInputSheetsStatus.list(undefined, 100, params);
  }, [project, currentUser]);

  const clusterStatus = useMemo(() => {
    if (clusterStatusState?.value?.results) {
      return clusterStatusState?.value?.results[0];
    }
    return undefined;
  }, [clusterStatusState]);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleHide = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <FormProvider {...methods}>
      <Button onClick={handleShow}>
        <CheckOutlined /> Submit&hellip;
      </Button>
      <SubmitModalContainer centered show={show} onHide={handleHide}>
        <Modal.Header className={clsx("d-flex", "justify-content-between")}>
          <Modal.Title>
            <SubHeader>{currentUser?.analystClusterName}</SubHeader>
            <Header>Submit</Header>
          </Modal.Title>
          <Modal.Title className={clsx("text-right")}>
            <SubHeader>Version {project.versionNumber}</SubHeader>
            {project.latestIssueDate ? (
              <HeaderRight>
                Model Issued{" "}
                {formatDistanceToNow(parseISO(project.latestIssueDate))} ago
              </HeaderRight>
            ) : (
              <HeaderRight>Model not published</HeaderRight>
            )}
          </Modal.Title>
        </Modal.Header>
        <Submit
          project={project}
          clusterStatus={clusterStatus}
          onHide={handleHide}
        />
      </SubmitModalContainer>
    </FormProvider>
  );
};

export default observer(SubmitModal);
