import { Add, BorderColor, DeleteOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAsync, useAsyncFn } from "react-use";
import styled from "styled-components";
import api from "../../api";
import { APISearchParams } from "../../api/base";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import history from "../../services/history";
import { useStore } from "../../store";
import ProjectFolder from "../../store/models/ProjectFolders";
import { Toast } from "../../utils";
import { confirmDeleteFolder } from "../../utils/confirm";
import { Page } from "../Page";
import FolderForm from "./FolderForm";
import NewProject from "./NewProject";
import Projects from "./Projects";

const RootContainer = styled.div`
  margin: 1.875rem 3.75rem;
`;

const OptionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;

  margin: 1.875rem 0;
`;

interface FolderProps {}

const Folder: React.FC<FolderProps> = () => {
  const { slug } = useParams<{ slug: string }>();
  const store = useStore();
  const currentUser = store.auth.current;
  const urlPrefix = "/projects/";
  const [renameFolderModalShow, setRenameFolderModalShow] =
    useState<boolean>(false);
  const [newProjectModalShow, setNewProjectModalShow] =
    useState<boolean>(false);
  const folder = store.projectFolders.listItems.get(slug);

  const fetchProjectsList = async (params: APISearchParams) => {
    return store.projects.listFolder(slug, undefined, undefined, params);
  };

  const [{ loading: projectsLoading, value: projects }, fetchProjects] =
    useAsyncFn(async () => {
      const params: APISearchParams = {
        // limit: 0,
      };

      const { count } = await fetchProjectsList(params);
      return {
        count,
        results: Array.from(store.projects.listFolderItems.values()),
      };
    });

  useAsync(async () => {
    await store.projectFolders.detail(slug);
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const handleProjectSubmit = async () => {
    await fetchProjects();
  };

  const handleFolderDelete = async () => {
    if (folder) {
      if (await confirmDeleteFolder(folder.name)) {
        await api.projectFolders.destroy(folder.slug);
        history.push(urlPrefix);
        Toast.success("Folder Deleted");
      }
    }
  };

  return (
    <Page
      title={folder?.name}
      breadcrumb={[
        { title: "Projects Drive", subtitle: "Projects", link: "/projects" },
        {
          title: folder?.name || "",
          subtitle: `${folder?.isActive ? "Active" : "Archive"} Folder`,
        },
      ]}
    >
      <RootContainer>
        {folder?.isActive && (
          <Button
            variant="green"
            size="page"
            onClick={() => setNewProjectModalShow(true)}
          >
            <Add />
            New Project
          </Button>
        )}
        {currentUser?.isAdmin && (
          <OptionContent>
            <Button
              onClick={() => {
                setRenameFolderModalShow(true);
              }}
            >
              <BorderColor />
              Rename Folder
            </Button>
            <Button onClick={handleFolderDelete}>
              <DeleteOutlined />
              Delete Folder
            </Button>
          </OptionContent>
        )}
        {projectsLoading ? (
          <Loading />
        ) : (
          <Projects
            isFolder
            archived={!folder?.isActive}
            title="Projects"
            urlPrefix={urlPrefix}
            projects={projects?.results}
            projectCount={projects?.count}
            onProjectSubmit={handleProjectSubmit}
            onArchiveRestore={handleProjectSubmit}
          />
        )}
      </RootContainer>
      <FolderForm
        isInsideFolder
        instance={folder as ProjectFolder}
        archived={!folder?.isActive}
        isOpen={renameFolderModalShow}
        setModal={(visible: boolean) => setRenameFolderModalShow(visible)}
      />
      <NewProject
        slug={slug}
        isOpen={newProjectModalShow}
        setModal={setNewProjectModalShow}
      />
    </Page>
  );
};

export default observer(Folder);
