interface MapWithName {
  name: string;
}

function filterDisplayMapper<T extends MapWithName>(
  map: Map<identifier, T>,
  multiple?: boolean,
  format?: (mapped?: T) => string
) {
  const _format = format ? format : (mapped?: T) => mapped?.name || "";

  return (value: string) => {
    if (!value || value.length === 0) {
      return "";
    }

    if (multiple) {
      const values = value.split(",");

      return values
        .map((value) => _format(map.get(parseInt(value, 10))))
        .join(", ");
    }
    return _format(map.get(parseInt(value, 10)));
  };
}

export default filterDisplayMapper;
