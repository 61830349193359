import { Add } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { APISearchParams } from "../../api/base";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { useStore } from "../../store";
import { Page } from "../Page";
import NewProject from "./NewProject";
import Projects from "./Projects";

const RootContainer = styled.div`
  margin: 1.875rem 3.75rem;
`;

interface ProjectsDriveProps {}

const ProjectsDrive: React.FC<ProjectsDriveProps> = () => {
  const store = useStore();
  const urlPrefix = `/projects/`;
  const [newProjectModalShow, setNewProjectModalShow] =
    useState<boolean>(false);

  const fetchProjectFoldersList = async (params: APISearchParams) => {
    return store.projectFolders.listActive(1, -1, params);
  };

  const [
    { loading: projectFoldersLoading, value: projectFolders },
    fetchProjectFolders,
  ] = useAsyncFn(async () => {
    const params: APISearchParams = {
      is_active: true,
    };

    await fetchProjectFoldersList(params);
    return Array.from(store.projectFolders.listActiveItems.values());
  });

  const fetchProjectsList = async (params: APISearchParams) => {
    return store.projects.listActive(1, -1, params);
  };

  const [{ loading: projectsLoading, value: activeProject }, fetchProjects] =
    useAsyncFn(async () => {
      const params: APISearchParams = {
        is_active: true,
      };

      const { count } = await fetchProjectsList(params);
      return {
        count,
        results: Array.from(store.projects.listActiveItems.values()),
      };
    });

  const fetchArchivedProjectFoldersList = async (params: APISearchParams) => {
    return store.projectFolders.listArchived(1, -1, params);
  };

  const [
    { loading: archivedProjectFoldersLoading, value: archivedProjectFolders },
    fetchArchivedProjectFolders,
  ] = useAsyncFn(async () => {
    const params: APISearchParams = {
      is_active: false,
    };
    await fetchArchivedProjectFoldersList(params);
    return Array.from(store.projectFolders.listArchivedItems.values());
  });

  const fetchArchivedProjectsList = async (params: APISearchParams) => {
    return store.projects.listArchived(1, -1, params);
  };

  const [
    { loading: archivedProjectsLoading, value: archivedProjects },
    fetchArchivedProjects,
  ] = useAsyncFn(async () => {
    const params: APISearchParams = {
      is_active: false,
    };

    const { count } = await fetchArchivedProjectsList(params);
    return {
      count,
      results: Array.from(store.projects.listArchiveItems.values()),
    };
  });

  useEffect(() => {
    fetchProjectFolders();
    fetchProjects();
    fetchArchivedProjectFolders();
    fetchArchivedProjects();
  }, [
    fetchProjectFolders,
    fetchProjects,
    fetchArchivedProjectFolders,
    fetchArchivedProjects,
  ]);

  const handleFolderSubmit = async () => {
    await Promise.all([fetchProjectFolders(), fetchProjects()]);
  };

  const handleArchivedFolderSubmit = async () => {
    await Promise.all([fetchArchivedProjectFolders(), fetchArchivedProjects()]);
  };

  const handleProjectSubmit = async () => {
    await fetchProjects();
  };

  const handleArchivedProjectSubmit = async () => {
    await fetchArchivedProjects();
  };

  const handleArchiveRestore = async () => {
    await Promise.all([fetchProjects(), fetchArchivedProjects()]);
  };

  return (
    <Page
      title="Projects"
      breadcrumb={[
        {
          title: "Projects Drive",
          subtitle: "Projects",
          link: "/projects",
        },
      ]}
    >
      <RootContainer>
        <Button
          variant="green"
          size="page"
          onClick={() => setNewProjectModalShow(true)}
        >
          <Add />
          New Project
        </Button>

        {projectsLoading &&
        projectFoldersLoading &&
        archivedProjectFoldersLoading &&
        archivedProjectsLoading ? (
          <Loading />
        ) : (
          <>
            <Projects
              title="Active Projects"
              urlPrefix={urlPrefix}
              projects={activeProject?.results}
              folders={projectFolders}
              projectCount={activeProject?.count}
              onFolderSubmit={handleFolderSubmit}
              onProjectSubmit={handleProjectSubmit}
              onArchiveRestore={handleArchiveRestore}
            />
            <Projects
              archived
              title="Archived Projects"
              urlPrefix={urlPrefix}
              projects={archivedProjects?.results}
              projectCount={archivedProjects?.count}
              folders={archivedProjectFolders}
              onFolderSubmit={handleArchivedFolderSubmit}
              onProjectSubmit={handleArchivedProjectSubmit}
              onArchiveRestore={handleArchiveRestore}
              noFilter
            />
          </>
        )}

        <NewProject
          isOpen={newProjectModalShow}
          setModal={setNewProjectModalShow}
        />
      </RootContainer>
    </Page>
  );
};

export default observer(ProjectsDrive);
