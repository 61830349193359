import { Model, model, prop } from "mobx-keystone";

@model("collab/UnitOfMeasurement")
class UnitOfMeasurement extends Model({
  id: prop<identifier>(),
  name: prop<string>(),
  conversion: prop<number>(),
}) {}

export default UnitOfMeasurement;
