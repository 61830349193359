import { detach, Model, model, prop, rootRef } from "mobx-keystone";

export function makeCountryId(id: number) {
  return `collab/Country/${id}`;
}

@model("collab/Country")
class Country extends Model({
  id: prop<identifier>(),
  name: prop<string>(),
  cluster: prop<identifier>(),
  project: prop<identifier>(),
}) {
  getRefId() {
    return makeCountryId(this.id);
  }
}

export const countryRef = rootRef<Country>("collab/CountryRef", {
  onResolvedValueChange(ref, newPlant, oldPlant) {
    if (oldPlant && !newPlant) {
      detach(ref);
    }
  },
});

export default Country;
