import papaparse, { UnparseConfig } from "papaparse";
import trimNewlines from "trim-newlines";
import { CSVHeaderMapping } from "./mappings";

type CSVRow = Record<string, any>;

export const beautifyText = (text: string) => {
  const beautifiedText = text
    .replaceAll("_", " ")
    .replace(/([A-Z0-9])/g, " $1") // uppercase the first character
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
  return beautifiedText;
};

export const parseColumns = (csvData: string) => {
  const rawHeaderData = csvData.split("\n")[0];
  const columnHeaders = papaparse.parse<string[]>(rawHeaderData).data[0];
  return columnHeaders;
};

export const parseRows = <T extends CSVRow>(csvData: string) => {
  const rows = papaparse.parse<T>(trimNewlines.end(csvData), {
    header: true,
  }).data;

  return rows;
};

export const unparseRows = (
  rows: any[],
  headers?: string[],
  options?: UnparseConfig
) => {
  if (rows.length === 0 && headers) {
    return papaparse.unparse([headers], options);
  } else if (rows.length === 0) {
    return "";
  }
  return papaparse.unparse(rows, { columns: headers, ...options });
};

function formatColumn(column: string, headerMapping?: CSVHeaderMapping) {
  if (headerMapping && headerMapping[column]) {
    return headerMapping[column][0].replace("\n", " ").trim();
  }
  return column;
}

/**
 * Remaps the headers of given rows and columns arrays using the given mapping.
 *
 * Newlines in the mapping are changed to spaces.
 *
 * @param rows Rows of resulting CSV file
 * @params columns original columns
 * @param headerMapping the mapping from original to new headers
 * @returns CSV string with the headers remapped
 */
export const reparseMapHeadersFromRowColumn = <T extends CSVRow>(
  rows: T[],
  columns: string[],
  headerMapping?: CSVHeaderMapping,
  eu?: boolean
): [string, number, number] => {
  const mappedColumns = columns.map((column) =>
    formatColumn(column, headerMapping)
  );
  const mappedRows = rows.map((row) =>
    columns.reduce(
      (acc, column) => ({
        ...acc,
        [formatColumn(column, headerMapping)]: row[column],
      }),
      {}
    )
  );

  return [
    unparseRows(mappedRows, mappedColumns, eu ? { delimiter: ";" } : undefined),
    rows.length,
    columns.length,
  ];
};

/**
 * Remaps the headers of a given CSV string using the given mapping.
 *
 * Newlines in the mapping are changed to spaces.
 *
 * @param csvData CSV string with headers to remap
 * @param headerMapping the mapping from original to new headers
 * @returns CSV string with the headers remapped
 */
export const reparseMapHeadersFromCsvString = <T extends CSVRow>(
  csvData: string,
  headerMapping?: CSVHeaderMapping,
  eu?: boolean
) => {
  const columns = parseColumns(csvData);
  const rows = parseRows<T>(csvData);
  return reparseMapHeadersFromRowColumn(rows, columns, headerMapping, eu);
};

export function tsvToArray<K extends string | number, V>(tsv: string) {
  return papaparse.parse<Record<K, V>>(trimNewlines.end(tsv), {
    delimiter: "\t",
    header: false,
  }).data;
}

export function arrayToCsv<T extends object>(arr: T[]) {
  return papaparse.unparse(arr);
}

export function addCsvSepHeader(csvString: string) {
  // FIXME Removed SEP= header to not mislead users into thinking that
  // European locale support has been completely added.
  // Right now only separators are being handled; number formats need to be handled in the future.
  // return `SEP=,\n${csvString}`;

  // Remove this in favor of the commented-out code above
  // when we can properly format numbers.
  return csvString;
}
