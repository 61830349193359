import api from "api";
import {
  APICommentDetail,
  APICommentInput,
  APICommentSearchParams,
} from "api/comments";
import { computed } from "mobx";
import {
  Model,
  model,
  modelAction,
  modelFlow,
  prop,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import Comment from "./models/Comment";

export enum CommentFilter {
  all = "All",
  unresolved = "Unresolved",
}

@model("collab/CommentStore")
class CommentStore extends Model({
  filter: prop<CommentFilter>(CommentFilter.unresolved),
  listItems: prop_mapObject(() => new Map<identifier, Comment>()),

  commentsPanelShow: prop(false),
}) {
  @computed
  get ofSheet() {
    return (projectId: identifier, sheetType: string, sheetId: identifier) =>
      Array.from(this.listItems.values()).filter(
        (comment) =>
          comment.project === projectId &&
          comment.sheetType.includes(sheetType) &&
          comment.sheetId === sheetId
      );
  }

  @computed
  get ofSheetFiltered() {
    return (projectId: identifier, sheetType: string, sheetId: identifier) =>
      this.ofSheet(projectId, sheetType, sheetId).filter((comment) => {
        if (this.filter === CommentFilter.unresolved) {
          return !comment.isResolved;
        }
        return true;
      });
  }

  @computed
  get ofSheetGrouped() {
    return (projectId: identifier, sheetType: string, sheetId: identifier) => {
      const filteredComments = this.ofSheetFiltered(
        projectId,
        sheetType,
        sheetId
      );
      const grouping = filteredComments.reduce((acc, comment) => {
        const key = JSON.stringify([
          comment.sheetId,
          comment.rowIndex,
          comment.colName,
        ]);
        acc[key] = acc[key] || [];
        acc[key].push(comment);
        return acc;
      }, {} as { [k: string]: Comment[] });

      return Object.values(grouping);
    };
  }

  @computed
  get ofSheetIdentifiers() {
    return (
      projectId: identifier,
      sheetType: string,
      sheetId: identifier
    ): Record<string, boolean> => {
      // Do not show comment indicators for resolved comments.
      const filteredComments = this.ofSheet(
        projectId,
        sheetType,
        sheetId
      ).filter((comment) => !comment.isResolved);

      const results = filteredComments.reduce(
        (acc, { rowIndex, colName }) => ({
          ...acc,
          [`${colName}-${rowIndex}`]: true,
        }),
        {}
      );
      return results;
    };
  }

  @modelAction
  saveListItem(item: APICommentDetail) {
    const listItem = new Comment(item);
    const existing = this.listItems.get(item.id);

    if (!existing) {
      this.listItems.set(item.id, listItem);
      return listItem;
    }

    if (!existing.isDirty) {
      existing.update(item);
    }
    return existing;
  }

  @modelFlow
  list = _async(function* (
    this: CommentStore,
    projectId: identifier,
    sheetType: string,
    sheetId: identifier,
    page?: number,
    limit?: number,
    searchParams?: APICommentSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(
      api.comments.list(page, limit, {
        ...searchParams,
        project: projectId,
        sheet_type__model: sheetType,
        sheet_id: sheetId,
      })
    );

    [...this.ofSheet(projectId, sheetType, sheetId)].forEach((comment) => {
      if (comment.id > 0 && !comment.isDirty) {
        this.listItems.delete(comment.id);
      }
    });

    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  create = _async(function* (
    this: CommentStore,
    projectId: identifier,
    data: APICommentInput
  ) {
    const response = yield* _await(api.comments.create(projectId, data));
    return this.saveListItem(response);
  });

  @modelFlow
  update = _async(function* (
    this: CommentStore,
    commentId: identifier,
    data: APICommentInput
  ) {
    const response = yield* _await(api.comments.update(commentId, data));
    return this.saveListItem(response);
  });

  @modelFlow
  delete = _async(function* (this: CommentStore, commentId: identifier) {
    yield* _await(api.comments.del(commentId));
    this.listItems.delete(commentId);
  });

  @modelFlow
  resolve = _async(function* (this: CommentStore, commentId: identifier) {
    yield* _await(api.comments.resolve(commentId));
    const comment = this.listItems.get(commentId);
    if (comment) {
      yield* _await(
        this.list(comment.project, comment.sheetType, comment.sheetId)
      );
    }
  });

  @modelFlow
  unresolve = _async(function* (this: CommentStore, commentId: identifier) {
    yield* _await(api.comments.unresolve(commentId));
    const comment = this.listItems.get(commentId);
    if (comment) {
      yield* _await(
        this.list(comment.project, comment.sheetType, comment.sheetId)
      );
    }
  });

  @modelAction
  setFilter(filter: CommentFilter) {
    this.filter = filter;
  }

  @modelAction
  showCommentsPanel() {
    this.commentsPanelShow = true;
  }

  @modelAction
  hideCommentsPanel() {
    this.commentsPanelShow = false;
  }

  @modelAction
  toggleCommentsPanel() {
    this.commentsPanelShow = !this.commentsPanelShow;
  }
}

export default CommentStore;
