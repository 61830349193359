import { computed, observable } from "mobx";
import {
  Model,
  _async,
  _await,
  getRootStore,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
} from "mobx-keystone";
import { createViewModel } from "mobx-utils";
import { RootStore } from ".";
import api from "../api";
import {
  APIAdditive,
  APIBaseOil,
  APIFormulation,
  APIFormulationListSearchParams,
  APITreatRate,
} from "../api/formulations";
import {
  APIFormulationDatahub,
  APIFormulationDatahubListSearchParams,
} from "../api/formulationsDatahub";
import { arrayToCsv } from "../csv/utils";
import Additive from "./models/Additive";
import BaseOil from "./models/BaseOil";
import Formulation from "./models/Formulation";
import FormulationDatahub from "./models/FormulationDatahub";
import FormulationFilter from "./models/FormulationFilter";
import TreatRate from "./models/TreatRate";
import { forceNumberArray } from "./utils";

type FormulationHeadRow = (
  | string
  | number
  | boolean
  | identifier
  | identifier[]
)[];

@model("collab/FormulationStore")
class FormulationStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, Formulation>()),
  baseOilListItems: prop_mapObject(() => new Map<identifier, BaseOil>()),
  additiveListItems: prop_mapObject(() => new Map<identifier, Additive>()),
  treatRateListItems: prop_mapObject(() => new Map<identifier, TreatRate>()),
  filters: prop_mapObject(() => new Map<identifier, FormulationFilter>()),

  datahubListItems: prop_mapObject(
    () => new Map<identifier, FormulationDatahub>()
  ),
}) {
  @observable
  private _headCsv: string = "";

  @observable
  private _baseOilsCsv: string = "";

  @observable
  private _additivesCsv: string = "";

  @observable
  private _treatRatesCsv: string = "";

  @observable
  private _datahubCsv: string = "";

  @modelFlow
  init = _async(function* (this: FormulationStore) {
    // 9/30 -- Load base oils and additives as part of formulations.
    // yield* _await(this.listBaseOils());
    // yield* _await(this.listAdditives());
  });

  @modelAction
  saveListItem(item: APIFormulation) {
    const {
      baseOilPercentages,
      additivePercentages,
      treatRatePercentages,
      ...formulationData
    } = item;
    const listItem = new Formulation(formulationData);
    const existing = this.listItems.get(formulationData.id);

    if (!existing || !existing.isDirty) {
      if (!existing) {
        this.listItems.set(formulationData.id, listItem);
      } else if (!existing.isDirty) {
        existing.update(listItem);
      }

      listItem.clearPercentages();

      // Save base oils separately
      baseOilPercentages.forEach((baseOilPercentage) => {
        const { baseOil, value } = baseOilPercentage;
        const baseOilObj = this.saveBaseOilListItem(baseOil);
        listItem.setBaseOilPercentage(baseOilObj.id, value, false);
      });

      // Save additives separately
      additivePercentages.forEach((additivePercentage) => {
        const { additive, value } = additivePercentage;
        const additiveObj = this.saveAdditiveListItem(additive);
        listItem.setAdditivePercentage(additiveObj.id, value, false);
      });

      // Save unclassified treat rates separately
      treatRatePercentages.forEach((treatRatePercentage) => {
        const { treatRate, value } = treatRatePercentage;
        const additiveObj = this.saveTreatRateListItem(treatRate);
        listItem.setTreatRatePercentage(additiveObj.id, value, false);
      });

      return listItem;
    }

    return existing;
  }

  @modelAction
  saveBaseOilListItem(item: APIBaseOil) {
    const listItem = new BaseOil(item);
    const existing = this.baseOilListItems.get(item.id);

    if (!existing) {
      this.baseOilListItems.set(item.id, listItem);
      return listItem;
    } else if (!existing.isDirty) {
      existing.update(item);
    }
    return existing;
  }

  @modelAction
  saveAdditiveListItem(item: APIAdditive) {
    const listItem = new Additive(item);
    const existing = this.additiveListItems.get(item.id);

    if (!existing) {
      this.additiveListItems.set(item.id, listItem);
      return listItem;
    } else if (!existing.isDirty) {
      existing.update(item);
    }
    return existing;
  }

  @modelAction
  saveTreatRateListItem(item: APITreatRate) {
    const listItem = new TreatRate(item);
    const existing = this.treatRateListItems.get(item.id);

    if (!existing) {
      this.treatRateListItems.set(item.id, listItem);
      return listItem;
    } else if (!existing.isDirty) {
      existing.update(item);
    }
    return existing;
  }

  @modelAction
  saveDatahubListItem(item: APIFormulationDatahub) {
    const listItem = new FormulationDatahub(item);
    this.datahubListItems.set(item.id, listItem);
    return listItem;
  }

  @modelAction
  clearDatahubCache() {
    this.datahubListItems.clear();
  }

  @modelFlow
  list = _async(function* (
    this: FormulationStore,
    projectId: identifier,
    page?: number,
    limit?: number,
    searchParams?: APIFormulationListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(
      api.formulations.list(page, limit, {
        ...searchParams,
        project: projectId,
      })
    );

    // Clear list except those that have not been saved or are dirty.
    [...Array.from(this.listItems.values())].forEach((formulation) => {
      if (formulation.id > 0 && !formulation.isDirty) {
        this.listItems.delete(formulation.id);
      }
    });

    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listBaseOils = _async(function* (
    this: FormulationStore,
    page?: number,
    limit?: number,
    searchParams?: APIFormulationListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.formulations.baseOilList(page, limit, searchParams));

    // Clear list except those that have not been saved or are dirty.
    [...Array.from(this.baseOilListItems.values())].forEach((baseOil) => {
      if (baseOil.id > 0 && !baseOil.isDirty) {
        this.baseOilListItems.delete(baseOil.id);
      }
    });

    const results = resultsRaw.map((item) => this.saveBaseOilListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listAdditives = _async(function* (
    this: FormulationStore,
    page?: number,
    limit?: number,
    searchParams?: APIFormulationListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.formulations.additiveList(page, limit, searchParams));

    // Clear list except those that have not been saved or are dirty.
    [...Array.from(this.additiveListItems.values())].forEach((additive) => {
      if (additive.id > 0 && !additive.isDirty) {
        this.additiveListItems.delete(additive.id);
      }
    });

    const results = resultsRaw.map((item) => this.saveAdditiveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listTreatRates = _async(function* (
    this: FormulationStore,
    page?: number,
    limit?: number,
    searchParams?: APIFormulationListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(
      api.formulations.treatRateList(page, limit, searchParams)
    );

    // Clear list except those that have not been saved or are dirty.
    [...Array.from(this.treatRateListItems.values())].forEach((treatRate) => {
      if (treatRate.id > 0 && !treatRate.isDirty) {
        this.treatRateListItems.delete(treatRate.id);
      }
    });

    const results = resultsRaw.map((item) => this.saveTreatRateListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listDatahub = _async(function* (
    this: FormulationStore,
    page?: number,
    limit?: number,
    searchParams?: APIFormulationDatahubListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.formulationsDatahub.list(page, limit, searchParams));
    if (page === 1) {
      this.listItems.clear();
    }
    const results = resultsRaw.map((item) => this.saveDatahubListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelAction
  create(projectId: number, addToRightOfId?: number, formulationId?: number) {
    const formulations = this.ofProject(projectId);

    // Find position for new formulation.
    const addToRightOf = addToRightOfId
      ? this.listItems.get(addToRightOfId)
      : undefined;
    let ordering = addToRightOf
      ? addToRightOf.ordering + 1
      : formulations.length > 0
      ? formulations[formulations.length - 1].ordering + 1
      : 0;

    // Use a negative `id` to indicate that the formulation has never been saved.
    const id = Math.min(0, ...this.listItems.keys()) - 1;

    const formulation = formulationId && this.listItems.get(formulationId);

    let newData = {
      id,
      ordering,
      project: projectId,
      density: 850,
    };

    const listItem = new Formulation(newData);

    if (formulation) {
      const viewModel = createViewModel(listItem);
      viewModel.clusters = formulation.clusters;
      viewModel.code = formulation.code;
      viewModel.comments = formulation.comments;
      viewModel.countries = formulation.countries;
      viewModel.density = formulation.density;
      viewModel.isApproved = formulation.isApproved;
      viewModel.isCltLocked = formulation.isCltLocked;
      viewModel.notes = formulation.notes;
      viewModel.ordering = formulation.ordering;
      viewModel.packSizes = formulation.packSizes;
      viewModel.plant = formulation.plant;
      viewModel.plantSplitCode = formulation.plantSplitCode;
      viewModel.plantSplitPercent = formulation.plantSplitPercent;
      viewModel.plantType = formulation.plantType;
      viewModel.project = formulation.project;
      viewModel.repackagingPackSizes = formulation.repackagingPackSizes;
      viewModel.repackagingPlant = formulation.repackagingPlant;
      viewModel.sourcePlants = formulation.sourcePlants;
      viewModel.specs = formulation.specs;
      viewModel.viscosity = formulation.viscosity;
      viewModel.baseOilPercentages = formulation.baseOilPercentages;
      viewModel.additivePercentages = formulation.additivePercentages;
      viewModel.treatRatePercentages = formulation.treatRatePercentages;
      viewModel.submit();
    }

    // Update ordering of subsequent items before inserting the new item to the map.
    formulations.forEach((formulation) => {
      if (formulation.ordering >= ordering) {
        formulation.setValue("ordering", formulation.ordering + 1);
      }
    });

    this.listItems.set(listItem.id, listItem);
    return listItem;
  }

  @modelAction
  findOrCreateBaseOil(rcode: string) {
    // Find base oil if its rcode is already in the store
    return (
      Array.from(this.baseOilListItems.values()).find(
        (item) => item.rcode === rcode
      ) || this.createBaseOil()
    );
  }

  @modelAction
  createBaseOil() {
    // Use a negative `id` to indicate that the formulation has never been saved.
    const id = Math.min(0, ...this.baseOilListItems.keys()) - 1;

    const listItem = new BaseOil({ id });
    this.baseOilListItems.set(listItem.id, listItem);
    return listItem;
  }

  @modelAction
  createBaseOilWithRowData(
    data: Omit<APIBaseOil, "id">,
    formulations: { [key: string]: string }
  ) {
    // Use a negative `id` to indicate that the formulation has never been saved.
    const id = Math.min(0, ...this.baseOilListItems.keys()) - 1;

    const listItem = new BaseOil({ id, ...data });

    Object.entries(formulations).forEach(([key, percentage]) => {
      const formulation = this.listItems.get(Number(key));
      formulation?.setBaseOilPercentage(listItem.id, percentage);
    });

    this.baseOilListItems.set(listItem.id, listItem);
    return listItem;
  }

  @modelAction
  findOrCreateAdditive(rcode: string) {
    // Find additive if its rcode is already in the store
    return (
      Array.from(this.additiveListItems.values()).find(
        (item) => item.rcode === rcode
      ) || this.createAdditive()
    );
  }

  @modelAction
  createAdditive() {
    // Use a negative `id` to indicate that the formulation has never been saved.
    const id = Math.min(0, ...this.additiveListItems.keys()) - 1;

    const listItem = new Additive({ id });
    this.additiveListItems.set(listItem.id, listItem);
    return listItem;
  }

  @modelAction
  createAdditiveWithRowData(
    data: Omit<APIAdditive, "id">,
    formulations: { [key: string]: string }
  ) {
    // Use a negative `id` to indicate that the formulation has never been saved.
    const id = Math.min(0, ...this.additiveListItems.keys()) - 1;

    const listItem = new Additive({ id, ...data });

    Object.entries(formulations).forEach(([key, percentage]) => {
      const formulation = this.listItems.get(Number(key));
      formulation?.setAdditivePercentage(listItem.id, percentage);
    });

    this.additiveListItems.set(listItem.id, listItem);
    return listItem;
  }

  @modelAction
  findOrCreateTreatRate(rcode: string) {
    // Find treat rate if its rcode is already in the store
    return (
      Array.from(this.treatRateListItems.values()).find(
        (item) => item.rcode === rcode
      ) || this.createTreatRate()
    );
  }

  @modelAction
  createTreatRate() {
    // Use a negative `id` to indicate that the formulation has never been saved.
    const id = Math.min(0, ...this.treatRateListItems.keys()) - 1;

    const listItem = new TreatRate({ id });
    this.treatRateListItems.set(listItem.id, listItem);
    return listItem;
  }

  @modelAction
  reorder(
    projectId: identifier,
    sourceFormulationId: identifier,
    targetFormulationId: identifier
  ) {
    const formulations = [...this.ofProject(projectId)];

    const srcIdx = formulations.findIndex(
      (formulation) => formulation.id === sourceFormulationId
    );
    const tgtIdx = formulations.findIndex(
      (formulation) => formulation.id === targetFormulationId
    );

    if (srcIdx < 0 || tgtIdx < 0) {
      return;
    }

    formulations.splice(tgtIdx, 0, formulations.splice(srcIdx, 1)[0]);

    formulations.forEach((formulation, idx) => {
      // Do not unnecessarily mark formulations dirty if its
      // ordering wouldn't change.
      if (formulation.ordering !== idx) {
        formulation.setValue("ordering", idx);
      }
    });
  }

  @modelAction
  syncFormulations(projectId: identifier) {
    const allFormulations = this.ofProject(projectId);
    allFormulations.forEach((formulation) => {
      this.listItems.delete(formulation._prevId);
    });
    allFormulations.forEach((formulation) => {
      this.listItems.set(formulation.id, formulation);
    });
    allFormulations.forEach((formulation) => {
      formulation.updatePrevId();
    });
  }

  @modelAction
  syncBaseOilMap(projectId: identifier) {
    const allFormulations = this.ofProject(projectId);
    const allBaseOils = this.baseOilsOfProject(projectId);

    allFormulations.forEach((formulation) => {
      formulation.copyBaseOilPercentages();
    });

    allFormulations.forEach((formulation) => {
      allBaseOils.forEach((baseOil) => {
        formulation.setBaseOilPercentage(
          baseOil.id,
          formulation.baseOilPercentages2.get(baseOil._prevId) ?? "",
          false
        );
      });
    });

    allBaseOils.forEach((baseOil) => {
      if (baseOil._prevId !== baseOil.id) {
        this.baseOilListItems.delete(baseOil._prevId);
      }
    });
    allBaseOils.forEach((baseOil) => {
      if (baseOil._prevId !== baseOil.id) {
        this.baseOilListItems.set(baseOil.id, baseOil);
      }
    });

    allBaseOils.forEach((baseOil) => {
      baseOil.updatePrevId();
    });
  }

  @modelAction
  syncAdditiveMap(projectId: identifier) {
    const allFormulations = this.ofProject(projectId);
    const allAdditives = this.additivesOfProject(projectId);

    allFormulations.forEach((formulation) => {
      formulation.copyAdditivePercentages();
    });

    allFormulations.forEach((formulation) => {
      allAdditives.forEach((additive) => {
        formulation.setAdditivePercentage(
          additive.id,
          formulation.additivePercentages2.get(additive._prevId) ?? "",
          false
        );
      });
    });

    allAdditives.forEach((additive) => {
      if (additive._prevId !== additive.id) {
        this.additiveListItems.delete(additive._prevId);
      }
    });
    allAdditives.forEach((additive) => {
      if (additive._prevId !== additive.id) {
        this.additiveListItems.set(additive.id, additive);
      }
    });

    allAdditives.forEach((additive) => {
      additive.updatePrevId();
    });
  }

  @modelAction
  syncTreatRateMap(projectId: identifier) {
    const allFormulations = this.ofProject(projectId);
    const allTreatRates = this.treatRatesOfProject(projectId);

    allFormulations.forEach((formulation) => {
      formulation.copyTreatRatePercentages();
    });

    allFormulations.forEach((formulation) => {
      allTreatRates.forEach((treatRate) => {
        formulation.setTreatRatePercentage(
          treatRate.id,
          formulation.treatRatePercentages2.get(treatRate._prevId) ?? "",
          false
        );
      });
    });

    allTreatRates.forEach((treatRate) => {
      if (treatRate._prevId !== treatRate.id) {
        this.treatRateListItems.delete(treatRate._prevId);
      }
    });
    allTreatRates.forEach((treatRate) => {
      if (treatRate._prevId !== treatRate.id) {
        this.treatRateListItems.set(treatRate.id, treatRate);
      }
    });

    allTreatRates.forEach((treatRate) => {
      treatRate.updatePrevId();
    });
  }

  @modelFlow
  saveAll = _async(function* (this: FormulationStore, projectId: identifier) {
    // Save base oils and additives first!
    // Save formulations will also save percentages and we need to have the
    // ids of any new base oil or additive.
    yield* _await(
      Promise.all(this.baseOilsOfProject(projectId).map((item) => item.save()))
    );

    yield* _await(
      Promise.all(this.additivesOfProject(projectId).map((item) => item.save()))
    );

    yield* _await(
      Promise.all(
        this.treatRatesOfProject(projectId).map((item) => item.save())
      )
    );

    // Update base oil and additives maps with the updated IDs as the keys.
    this.syncBaseOilMap(projectId);
    this.syncAdditiveMap(projectId);
    this.syncTreatRateMap(projectId);

    // Save formulations one by one.
    yield* _await(
      Promise.all(this.ofProject(projectId).map((item) => item.save()))
    );

    // Update formulation map with the updated IDs as the keys.
    this.syncFormulations(projectId);
  });

  @modelFlow
  delete = _async(function* (
    this: FormulationStore,
    formulationId: identifier
  ) {
    if (formulationId > 0) {
      try {
        yield* _await(api.formulations.del(formulationId));
      } catch (e) {
        throw e;
      }
    }

    this.listItems.delete(formulationId);
  });

  @modelAction
  deleteBaseOil(projectId: identifier, baseOilId: identifier) {
    const formulations = this.ofProject(projectId);
    const baseOil = this.baseOilListItems.get(baseOilId);

    if (!baseOil) {
      return;
    }

    formulations.forEach((formulation) => {
      formulation.deleteBaseOilPercentage(baseOil.id);
    });

    if (baseOil.id < 0) {
      this.baseOilListItems.delete(baseOil.id);
    }
  }

  @modelAction
  deleteAdditive(projectId: identifier, additiveId: identifier) {
    const formulations = this.ofProject(projectId);
    const additive = this.additiveListItems.get(additiveId);

    if (!additive) {
      return;
    }

    formulations.forEach((formulation) => {
      formulation.deleteAdditivePercentage(additive.id);
    });

    if (additive.id < 0) {
      this.additiveListItems.delete(additive.id);
    }
  }

  @modelAction
  deleteTreatRate(projectId: identifier, treatRateId: identifier) {
    const formulations = this.ofProject(projectId);
    const treatRate = this.treatRateListItems.get(treatRateId);

    if (!treatRate) {
      return;
    }

    formulations.forEach((formulation) => {
      formulation.deleteTreatRatePercentage(treatRate.id);
    });

    if (treatRate.id < 0) {
      this.treatRateListItems.delete(treatRate.id);
    }
  }

  ofProject(projectId: identifier) {
    let formulations = Array.from(this.listItems.values()).filter(
      (item) => item.project === projectId
    );

    return formulations.sort(
      ({ id: idA, ordering: orderingA }, { id: idB, ordering: orderingB }) => {
        if (orderingA !== orderingB) {
          return orderingA - orderingB;
        }
        if (idA > 0 && idB > 0) {
          return idA - idB;
        }
        return idB - idA;
      }
    );
  }

  baseOilsOfProject(projectId: identifier) {
    return Array.from(this.baseOilListItems.values())
      .sort(({ id: a }, { id: b }) => {
        if (a > 0 && b > 0) {
          return a - b;
        }
        return b - a;
      })
      .filter(
        (baseOil) =>
          baseOil.id < 0 ||
          this.ofProject(projectId).find(
            (formulation) =>
              formulation.baseOilPercentages.has(baseOil.id) ||
              formulation.baseOilPercentages.has(baseOil._prevId)
          )
      );
  }

  additivesOfProject(projectId: identifier) {
    return Array.from(this.additiveListItems.values())
      .sort(({ id: a }, { id: b }) => {
        if (a > 0 && b > 0) {
          return a - b;
        }
        return b - a;
      })
      .filter(
        (additive) =>
          additive.id < 0 ||
          this.ofProject(projectId).find(
            (formulation) =>
              formulation.additivePercentages.has(additive.id) ||
              formulation.additivePercentages.has(additive._prevId)
          )
      );
  }

  treatRatesOfProject(projectId: identifier) {
    return Array.from(this.treatRateListItems.values())
      .sort(({ id: a }, { id: b }) => {
        if (a > 0 && b > 0) {
          return a - b;
        }
        return b - a;
      })
      .filter(
        (treatRate) =>
          treatRate.id < 0 ||
          this.ofProject(projectId).find(
            (formulation) =>
              formulation.treatRatePercentages.has(treatRate.id) ||
              formulation.treatRatePercentages.has(treatRate._prevId)
          )
      );
  }

  filterListItems(projectId: identifier, formulations: Formulation[]) {
    // Filter items
    // TODO Make this a separate function?
    const filter = this.filters.get(projectId);
    if (filter) {
      formulations = formulations.filter((item) => {
        if (item.id < 0) {
          return true;
        }

        let out = true;
        if (filter.selectedProductSpecsIds.length > 0) {
          out =
            out &&
            !!item.specs &&
            filter.selectedProductSpecsIds.includes(item.specs);
        }
        if (filter.selectedFormulationCodesIds.length > 0) {
          out =
            out &&
            !!item.code &&
            filter.selectedFormulationCodesIds.includes(item.code);
        }
        if (filter.selectedPlantIds.length > 0) {
          out =
            out &&
            !!item.plant &&
            filter.selectedPlantIds.indexOf(item.plant) >= 0;
        }
        if (filter.selectedClusterIds.length > 0) {
          if (Array.isArray(item.clusters)) {
            out =
              out &&
              item.clusters.filter(
                (x) => filter.selectedClusterIds.indexOf(x) >= 0
              ).length > 0;
          } else {
            out =
              out &&
              (item.clusters as string)
                .split(",")
                .filter(
                  (x) => filter.selectedClusterIds.indexOf(parseInt(x, 10)) >= 0
                ).length > 0;
          }
        }
        if (filter.selectedCountryIds.length > 0) {
          if (Array.isArray(item.countries)) {
            out =
              out &&
              item.countries.filter(
                (x) => filter.selectedCountryIds.indexOf(x) >= 0
              ).length > 0;
          } else {
            out =
              out &&
              (item.countries as string)
                .split(",")
                .filter(
                  (x) => filter.selectedCountryIds.indexOf(parseInt(x, 10)) >= 0
                ).length > 0;
          }
        }
        if (filter.selectedApprovedStates.length > 0) {
          out =
            out && filter.selectedApprovedStates.indexOf(item.isApproved) >= 0;
        }
        return out;
      });
    }

    return formulations;
  }

  @modelAction
  commitHeadCsv(projectId: identifier) {
    this._headCsv = this.getHeadCsv(projectId, false);
  }

  getHeadCsv(projectId: identifier, forExport: boolean = false) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return "";
    }

    const formulations = this.filterListItems(
      projectId,
      this.ofProject(projectId)
    );

    const headers: FormulationHeadRow = [
      "id",
      "rcode",
      "description",
      "crdStatus",
    ];
    const specs: FormulationHeadRow = ["specs", "Product Specs", "", ""];
    const viscosities: FormulationHeadRow = ["viscosity", "Viscosity", "", ""];
    const codes: FormulationHeadRow = ["code", "Formulation Code", "", ""];
    const notes: FormulationHeadRow = ["notes", "Technology Notes", "", ""];
    const plants: FormulationHeadRow = [
      "plant",
      "Plant Selected for Costing",
      "",
      "",
    ];
    const plantTypes: FormulationHeadRow = [
      "plantType",
      "Master vs Plant",
      "",
      "",
    ];
    const sourcePlants: FormulationHeadRow = [
      "sourcePlants",
      "Existing Formulation Source Plants",
      "",
      "",
    ];
    const clusters: FormulationHeadRow = [
      "clusters",
      "Cluster to be Used For",
      "",
      "",
    ];
    const countries: FormulationHeadRow = [
      "countries",
      "Countries to be Used For",
      "",
      "",
    ];
    const plantSplitCodes: FormulationHeadRow = [
      "plantSplitCode",
      "US Production Split?",
      "",
      "",
    ];
    const plantSplitPercents: FormulationHeadRow = [
      "plantSplitPercent",
      "US Production Split %",
      "",
      "",
    ];
    const packSizes: FormulationHeadRow = [
      "packSizes",
      "Pack Size to Offer to Customer",
      "",
      "",
    ];
    const repackagingPlants: FormulationHeadRow = [
      "repackagingPlant",
      "Repackaging Plant (if applicable)",
      "",
      "",
    ];
    const repackagingPackSizes: FormulationHeadRow = [
      "repackagingPackSizes",
      "Pack Size from Source if Repack Reqd",
      "",
      "",
    ];
    const density: FormulationHeadRow = [
      "density",
      "Formulation Density (g/L)",
      "",
      "",
    ];
    const comments: FormulationHeadRow = ["comments", "Comments", "", ""];
    const isApproved: FormulationHeadRow = [
      "isApproved",
      "Approved for Costing?",
      "",
      "",
    ];
    formulations.forEach((formulation) => {
      headers.push(formulation.id);
      isApproved.push(
        forExport
          ? formulation.isApproved
            ? "Y"
            : "N"
          : formulation.isApproved
      );
      specs.push(formulation.specs);
      viscosities.push(formulation.viscosity);
      codes.push(formulation.code);
      notes.push(formulation.notes);
      plants.push(
        forExport && formulation.plant
          ? rootStore.assets.plantListItems.get(formulation.plant)?.name || ""
          : formulation.plant || ""
      );
      ////
      plantTypes.push(formulation.plantType || "");
      sourcePlants.push(
        forExport && formulation.sourcePlants
          ? forceNumberArray(formulation.sourcePlants)
              .map(
                (plantId) => rootStore.assets.plantListItems.get(plantId)?.name
              )
              .filter((plantName) => !!plantName)
              .join(", ")
          : formulation.sourcePlants || ""
      );
      clusters.push(
        forExport && formulation.clusters
          ? forceNumberArray(formulation.clusters)
              .map(
                (clusterId) =>
                  rootStore.assets.clusterListItems.get(clusterId)?.name
              )
              .filter((clusterName) => !!clusterName)
              .join(", ")
          : formulation.clusters || ""
      );
      countries.push(
        forExport && formulation.countries
          ? forceNumberArray(formulation.countries)
              .map((id) => rootStore.assets.countryListItems.get(id)?.name)
              .filter((name) => !!name)
              .join(", ")
          : formulation.countries || ""
      );
      packSizes.push(
        forExport && formulation.packSizes
          ? forceNumberArray(formulation.packSizes)
              .map((id) => rootStore.assets.packSizeListItems.get(id)?.name)
              .filter((name) => !!name)
              .join(", ")
          : formulation.packSizes || ""
      );
      plantSplitCodes.push(formulation.plantSplitCode);
      plantSplitPercents.push(formulation.plantSplitPercent || "");
      repackagingPlants.push(
        forExport && formulation.repackagingPlant
          ? rootStore.assets.plantListItems.get(formulation.repackagingPlant)
              ?.name || ""
          : formulation.repackagingPlant || ""
      );
      repackagingPackSizes.push(
        forExport && formulation.repackagingPackSizes
          ? forceNumberArray(formulation.repackagingPackSizes)
              .map((id) => rootStore.assets.packSizeListItems.get(id)?.name)
              .filter((name) => !!name)
              .join(", ")
          : formulation.repackagingPackSizes || ""
      );
      density.push(formulation.density);
      comments.push(formulation.comments);
    });

    // If CSV is for export, replace the ID column with blanks.
    if (forExport) {
      headers[0] = "";
      isApproved[0] = "";
      specs[0] = "";
      viscosities[0] = "";
      codes[0] = "";
      notes[0] = "";
      plants[0] = "";
      plantTypes[0] = "";
      sourcePlants[0] = "";
      clusters[0] = "";
      countries[0] = "";
      packSizes[0] = "";
      plantSplitCodes[0] = "";
      plantSplitPercents[0] = "";
      repackagingPlants[0] = "";
      repackagingPackSizes[0] = "";
      density[0] = "";
      comments[0] = "";
    }

    const exportedRows = [
      headers,
      isApproved,
      specs,
      viscosities,
      codes,
      notes,
      plants,
      ////
      plantTypes,
      sourcePlants,
      clusters,
      countries,
      packSizes,
      plantSplitCodes,
      plantSplitPercents,
      repackagingPlants,
      repackagingPackSizes,
      density,
      comments,
    ];
    return arrayToCsv(exportedRows);
  }

  @computed
  get headAsCsv() {
    return this._headCsv;
  }

  @modelAction
  commitBaseOilsCsv(projectId: identifier) {
    this._baseOilsCsv = this.getBaseOilsCsv(projectId, false);
  }

  getBaseOilsCsv(projectId: identifier, forExport: boolean = false) {
    const formulations = this.filterListItems(
      projectId,
      this.ofProject(projectId)
    );

    const headers: string[] = [
      "id",
      "rcode",
      "description",
      "crdStatus",
    ].concat(formulations.map((formulation) => `${formulation.id}`));

    const rows: string[][] = [];
    this.baseOilsOfProject(projectId).forEach((baseOil) => {
      let inUse: boolean = false;
      const row: string[] = [
        forExport ? "" : `${baseOil.id}`,
        baseOil.rcode,
        baseOil.description,
        baseOil.crdStatus,
      ];
      formulations.forEach((formulation) => {
        const value = formulation.baseOilPercentages.get(baseOil.id);
        if (value) {
          row.push(value);
          inUse = true;
        } else {
          row.push("");
        }
      });

      // Hide based oils not used in any formulation of given project.
      if (baseOil.id < 0 || inUse) {
        rows.push(row);
      }
    });

    if (forExport && rows[0]) {
      rows[0][0] = "BASE OILs";
    }

    return arrayToCsv([headers, ...rows]);
  }

  @computed
  get baseOilsAsCsv() {
    return this._baseOilsCsv;
  }

  @modelAction
  commitAdditivesCsv(projectId: identifier) {
    this._additivesCsv = this.getAdditivesCsv(projectId, false);
  }

  getAdditivesCsv(projectId: identifier, forExport: boolean = false) {
    const formulations = this.filterListItems(
      projectId,
      this.ofProject(projectId)
    );

    const headers: string[] = [
      "id",
      "rcode",
      "description",
      "crdStatus",
    ].concat(formulations.map((formulation) => `${formulation.id}`));

    const rows: string[][] = [];
    this.additivesOfProject(projectId).forEach((additive) => {
      let inUse: boolean = false;
      const row: string[] = [
        forExport ? "" : `${additive.id}`,
        additive.rcode,
        additive.description,
        additive.crdStatus,
      ];
      formulations.forEach((formulation) => {
        const value = formulation.additivePercentages.get(additive.id);
        if (value) {
          row.push(value);
          inUse = true;
        } else {
          row.push("");
        }
      });

      // Hide additives not used in any formulation of given project.
      if (additive.id < 0 || inUse) {
        rows.push(row);
      }
    });

    if (forExport && rows[0]) {
      rows[0][0] = "ADDITIVES";
    }

    return arrayToCsv([headers, ...rows]);
  }

  @computed
  get additivesAsCsv() {
    return this._additivesCsv;
  }

  @modelAction
  commitTreatRatesCsv(projectId: identifier) {
    this._treatRatesCsv = this.getTreatRatesCsv(projectId, false);
  }

  getTreatRatesCsv(projectId: identifier, forExport: boolean = false) {
    const formulations = this.filterListItems(
      projectId,
      this.ofProject(projectId)
    );

    const headers: string[] = [
      "id",
      "rcode",
      "description",
      "crdStatus",
    ].concat(formulations.map((formulation) => `${formulation.id}`));

    const rows: string[][] = [];
    this.treatRatesOfProject(projectId).forEach((treatRate) => {
      let inUse: boolean = false;
      const row: string[] = [
        forExport ? "" : `${treatRate.id}`,
        treatRate.rcode,
        treatRate.description,
        treatRate.crdStatus,
      ];
      formulations.forEach((formulation) => {
        const value = formulation.treatRatePercentages.get(treatRate.id);
        if (value) {
          row.push(value);
          inUse = true;
        } else {
          row.push("");
        }
      });

      // Hide additives not used in any formulation of given project.
      if (treatRate.id < 0 || inUse) {
        rows.push(row);
      }
    });

    if (forExport && rows[0]) {
      rows[0][0] = "UNCLASSIFIED";
    }

    return arrayToCsv([headers, ...rows]);
  }

  @computed
  get treatRatesAsCsv() {
    return this._treatRatesCsv;
  }

  @modelAction
  commitDatahubCsv(projectId: identifier) {
    this._datahubCsv = this.getDatahubCsv(projectId);
  }

  getDatahubCsv(projectId: identifier) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return "";
    }

    const datahubFormulations = this.datahubListItems;

    const headers: FormulationHeadRow = [
      "id",
      "rcode",
      "description",
      "crdStatus",
    ];
    const toImport: FormulationHeadRow = ["toImport", "Import?", "", ""];
    const codes: FormulationHeadRow = ["code", "Formulation Code", "", ""];
    const masterCodes: FormulationHeadRow = [
      "masterCode",
      "Master Formulation Code",
      "",
      "",
    ];
    const plants: FormulationHeadRow = [
      "plant",
      "Plant Selected for Costing",
      "",
      "",
    ];
    const formulationStatus: FormulationHeadRow = [
      "formulationStatus",
      "Formulation Status",
      "",
      "",
    ];
    const treatRates: FormulationHeadRow = [
      "treatRates",
      "Treat Rates",
      "",
      "",
    ];
    datahubFormulations.forEach((data) => {
      headers.push(data.id);
      toImport.push(data.toImport);
      codes.push(data.plantFormulationCode);
      masterCodes.push(data.masterFormulationCode);
      plants.push(data.plantFormulationPlant || "");
      formulationStatus.push(data.formulationStatus || "");
      treatRates.push(JSON.stringify(data.treatRates) || "");
    });

    const exportedRows = [
      headers,
      toImport,
      codes,
      masterCodes,
      plants,
      formulationStatus,
      treatRates,
    ];
    return arrayToCsv(exportedRows);
  }

  @computed
  get datahubAsCsv() {
    return this._datahubCsv;
  }

  @modelAction
  createFilters(projectId: identifier) {
    if (!this.filters.has(projectId)) {
      this.filters.set(projectId, new FormulationFilter({}));
    }
  }

  productSpecsOfProject(projectId: identifier) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return [];
    }

    const uniqueSpecs = new Set<string>();
    this.ofProject(projectId).forEach((formulation) => {
      uniqueSpecs.add(formulation.specs);
    });
    return Array.from(uniqueSpecs)
      .sort()
      .map((spec) => ({
        label: spec,
        value: spec,
      }));
  }

  formulationCodesOfProject(projectId: identifier) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return [];
    }

    const uniqueCodes = new Set<string>();
    this.ofProject(projectId).forEach((formulation) => {
      uniqueCodes.add(formulation.code);
    });
    return Array.from(uniqueCodes)
      .sort()
      .map((code) => ({
        label: code,
        value: code,
      }));
  }

  plantsOfProject(projectId: identifier) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return [];
    }

    let projectPlantIds: Array<number> = [];
    this.ofProject(projectId).forEach((formulation) => {
      if (formulation.plant) {
        projectPlantIds.push(formulation.plant);
      }
      projectPlantIds = projectPlantIds.concat(formulation.sourcePlants);
      if (formulation.repackagingPlant) {
        projectPlantIds.push(formulation.repackagingPlant);
      }
    });
    return rootStore.assets.plantSelect.filter((plant) =>
      projectPlantIds.includes(plant.value)
    );
  }

  clustersOfProject(projectId: identifier) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return [];
    }

    const requirements = rootStore.requirements.ofProject(projectId);
    let projectClusterIds = requirements.map((e) =>
      Number(e.destinationCluster)
    );

    let formulationClusterIds: Array<number> = [];
    this.ofProject(projectId).forEach((formulation) => {
      formulationClusterIds = formulationClusterIds.concat(
        formulation.asAPI.clusters || []
      );
    });

    const countrySelect = this.countriesOfProject(projectId);
    countrySelect.forEach(({ value: countryId }) => {
      const country = rootStore.assets.countryListItems.get(countryId);
      if (country) {
        formulationClusterIds.push(country.cluster);
      }
    });

    projectClusterIds = projectClusterIds.concat(formulationClusterIds);

    return rootStore.assets.clusterSelect.filter((cluster) =>
      projectClusterIds.includes(cluster.value)
    );
  }

  countriesOfProject(projectId: identifier) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return [];
    }

    const requirements = rootStore.requirements.ofProject(projectId);
    let projectCountryIds = requirements.map((e) =>
      Number(e.destinationCountry)
    );

    let formulationCountryIds: Array<number> = [];
    this.ofProject(projectId).forEach((formulation) => {
      formulationCountryIds = formulationCountryIds.concat(
        formulation.asAPI.countries || []
      );
    });

    projectCountryIds = projectCountryIds.concat(formulationCountryIds);

    return rootStore.assets.countrySelect.filter((country) =>
      projectCountryIds.includes(country.value)
    );
  }

  @modelAction
  commitCsv(projectId: identifier) {
    this.commitHeadCsv(projectId);
    this.commitBaseOilsCsv(projectId);
    this.commitAdditivesCsv(projectId);
    this.commitTreatRatesCsv(projectId);
  }
}

export default FormulationStore;
