import base, { APIDetail, APIList, APISearchParams, config } from "./base";

export interface APICLTAssumptions {
  id: identifier;
  sheetsStatus: identifier;
  project: identifier;
  cluster: identifier;
  clusterName: string;
  notes: string;
  isCltLocked: boolean;
  isCltApproved: boolean;
  isCltSubmitted: boolean;
  isEditable: boolean;
}

export type APICLTAssumptionsInput = Partial<
  Omit<
    APICLTAssumptions,
    | "id"
    | "project"
    | "cluster"
    | "clusterName"
    | "isCltLocked"
    | "isCltApproved"
    | "isCltSubmitted"
    | "isEditable"
  >
>;

export interface APICLTAssumptionsList extends APIList<APICLTAssumptions> {}
export interface APICLTAssumptionsSearchParams extends APISearchParams {
  project?: number;
  cluster?: number;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APICLTAssumptionsSearchParams
) =>
  base.list<APICLTAssumptionsList, APICLTAssumptionsSearchParams>(
    "clt-assumptions/",
    page,
    limit,
    searchParams
  );

export interface APICLTAssumptionsDetail extends APIDetail<APICLTAssumptions> {}

export const update = (
  cltAssumptionsId: identifier,
  data: APICLTAssumptionsInput
) =>
  base.patch<APICLTAssumptionsDetail>(`clt-assumptions/${cltAssumptionsId}/`, {
    json: data,
  });

export type APICLTAssumptionsBulkUpdateInput = {
  instances: Partial<
    Omit<
      APICLTAssumptions,
      "sheetsStatus" | "project" | "cluster" | "clusterName" | "isCltLocked"
    >
  >[];
};

export type APICLTAssumptionsBulkUpdateDetail = {
  instances: APICLTAssumptionsDetail[];
};

export const bulkUpdate = (input: APICLTAssumptionsBulkUpdateInput) =>
  base.patch<APICLTAssumptionsBulkUpdateDetail>(
    `clt-assumptions/bulk-update/`,
    {
      json: input,
    }
  );
