import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import api from "../../api";
import { APIProjectDuplicateInput } from "../../api/projects";
import { useStore } from "../../store";
import Button from "../../components/Button";
import ProjectNameForm from "./ProjectNameForm";
import ProjectMinimal from "../../store/models/ProjectMinimal";
import { Toast } from "../../utils";
import history from "../../services/history";
import Duplicate from "../../icons/Duplicate";

type Props = {
  instance: ProjectMinimal;
  isOpen: boolean;
  setModal: (visible: boolean) => void;
};

const DuplicateProject: React.FC<Props> = ({ instance, isOpen, setModal }) => {
  const methods = useForm();
  const urlPrefix = "/projects/";
  const store = useStore();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: APIProjectDuplicateInput) => {
      setLoading(true);

      try {
        const { id } = await store.projects.duplicate(instance.id, data);
        history.push(`${urlPrefix}${id}/settings/details/`);
      } catch (errorResponse: any) {
        methods.reset();
        const { detail } = await api.handleError(errorResponse);
        if (errorResponse.description !== "Unauthorized") {
          if (detail) {
            Object.entries(detail!).forEach(([key, message]) => {
              methods.setError(key as any, {
                message: (message as string[])[0],
              });
            });
            return;
          }
        }
      } finally {
        setLoading(false);
      }

      setModal(false);
      Toast.success("Project Duplicated");
    },
    [instance, store, methods, setModal]
  );

  return (
    <FormProvider {...methods}>
      <ProjectNameForm
        disabled={loading}
        instance={instance}
        isOpen={isOpen}
        setModal={setModal}
        headerText="Duplicate Project"
        submitButton={
          <Button disabled={loading} type="submit">
            <Duplicate /> Duplicate
          </Button>
        }
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
};

export default observer(DuplicateProject);
