import { APIActivityListSearchParams } from "api/activities";
import Button from "components/Button";
import Loading from "components/Loading";
import { format, formatDistanceToNow, isSameDay, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { observer } from "mobx-react-lite";
import { lighten, rgba } from "polished";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useAsyncFn, useEffectOnce } from "react-use";
import { useStore } from "store";
import styled from "styled-components";
import CollapsibleSubActivity from "./CollapsibleSubActivity";

export type Props = {
  projectId: number;
};

const Container = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 2rem;
`;

const ActivityDiv = styled.div`
  padding-bottom: 2rem;
  border-bottom: 0.063rem solid #c8c8c8;

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const DateDiv = styled.div`
  color: #484848;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0.031rem;

  padding-bottom: 0.375rem;
  border-bottom: 0.063rem solid #c8c8c8;
  margin-bottom: 2rem;
`;

const MainActivity = styled.div`
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.031rem;
  margin-bottom: 0.375rem;
`;

const ActivityAvatar = styled(Image)`
  float: left;
  border: solid 0.125rem #000000;
  background-color: #c4c4c4;
  user-select: none;

  width: 1.75rem;
  height: 1.75rem;
`;

const ActivityText = styled.div`
  margin-bottom: 0.375rem;
`;

const SpanAgo = styled.span`
  font-size: 0.875rem;
  color: #bdbdbd;
  white-space: nowrap;
`;

const ActivityDetails = styled.div`
  margin-left: 0.875rem;
  padding-left: 1.531rem;
`;

const NotesDiv = styled.div`
  display: inline-flex;
  background-color: ${rgba(195, 218, 135, 0.3)};
  padding: 0.5rem;

  font-size: 0.875rem;
  line-height: 0.875rem;

  p {
    margin-bottom: 0;
  }
`;

const EmptyText = styled.div`
  color: ${lighten(0.5, "#000000")};
  text-align: center;
  user-select: none;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  margin: 5rem 0;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const RecentActivity: React.FC<Props> = ({ projectId }) => {
  const store = useStore();
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const params: APIActivityListSearchParams = {
    project: projectId,
  };

  const [{ loading, value: activities }, fetchActivities] = useAsyncFn(
    async (
      page: number,
      limit: number,
      params: APIActivityListSearchParams
    ) => {
      const { count } = await store.activities.list(page, limit, params);

      return {
        count,
        results: Array.from(store.activities.listItems.values()),
      };
    }
  );

  useEffectOnce(() => {
    fetchActivities(page, limit, params);
  });

  const ready = !loading && activities !== undefined;

  if (!ready && (!activities || activities!.results.length === 0)) {
    return <Loading />;
  }

  return (
    <Container>
      {activities!.results.length > 0 ? (
        <>
          {activities!.results.map((activity, index, arrayActivities) => {
            const {
              id,
              projectName,
              projectVersionNumber,
              created,
              userName,
              userPhotoSizes,
              activityType,
              clusterNames,
              notes,
              subactivityCount,
            } = activity;
            const createdDate = parseISO(created);
            const showDate =
              index === 0 ||
              !isSameDay(
                createdDate,
                parseISO(arrayActivities[index - 1].created)
              );
            const clusterNamesCombined = clusterNames.join(", ");
            return (
              <div key={id}>
                {showDate && (
                  <DateDiv key={index}>
                    {format(createdDate, "Pp", { locale: enGB })}
                  </DateDiv>
                )}

                <ActivityDiv>
                  <MainActivity>
                    <div>
                      <ActivityAvatar
                        roundedCircle
                        src={userPhotoSizes.medium}
                      />
                    </div>
                    <ActivityDetails>
                      <ActivityText>
                        <strong>{userName}</strong>{" "}
                        {activityType === "project_created" ? (
                          <>
                            created project <strong>{projectName}</strong>.
                          </>
                        ) : activityType === "clt_sheets_published" ? (
                          <>
                            published version number{" "}
                            <strong>{projectVersionNumber}</strong> for{" "}
                            <strong>{projectName}</strong>.
                          </>
                        ) : activityType === "clt_sheet_submitted" ? (
                          <>
                            submitted data for {clusterNamesCombined} for{" "}
                            <strong>{projectName}</strong>{" "}
                            <strong>{projectVersionNumber}</strong>.
                          </>
                        ) : activityType === "clt_sheet_unsubmitted" ? (
                          <>
                            unsubmitted data for {clusterNamesCombined} for{" "}
                            <strong>{projectName}</strong>{" "}
                            <strong>{projectVersionNumber}</strong>.
                          </>
                        ) : activityType === "clt_sheet_approved" ? (
                          <>
                            approved data for {clusterNamesCombined} for{" "}
                            <strong>{projectName}</strong>{" "}
                            <strong>{projectVersionNumber}</strong>.
                          </>
                        ) : activityType === "clt_sheet_rejected" ? (
                          <>
                            rejected data for {clusterNamesCombined} for{" "}
                            <strong>{projectName}</strong>{" "}
                            <strong>{projectVersionNumber}</strong>.
                          </>
                        ) : activityType === "global_pricing_published" ? (
                          <>
                            published to Global Pricing or Project Manager for
                            an internal costing evaluation for{" "}
                            <strong>{projectName}</strong>.
                          </>
                        ) : activityType ===
                          "rm_price_assumption_request_published" ? (
                          <>
                            published a raw material price request for{" "}
                            <strong>{projectName}</strong>.
                          </>
                        ) : activityType ===
                          "rm_price_assumption_request_submitted" ? (
                          <>
                            published a raw material price request for{" "}
                            <strong>{projectName}</strong>.
                          </>
                        ) : undefined}{" "}
                        <SpanAgo
                          title={format(createdDate, "Pp", { locale: enGB })}
                        >
                          {formatDistanceToNow(createdDate)} ago
                        </SpanAgo>
                      </ActivityText>
                      {(notes || subactivityCount > 0) && (
                        <>
                          {notes && (
                            <NotesDiv
                              dangerouslySetInnerHTML={{
                                __html: notes || "",
                              }}
                            />
                          )}
                          <CollapsibleSubActivity
                            activityId={id}
                            subactivityCount={subactivityCount}
                          />
                        </>
                      )}
                    </ActivityDetails>
                  </MainActivity>
                </ActivityDiv>
              </div>
            );
          })}
          {page < Math.ceil(activities!.count / limit) && (
            <ButtonContainer>
              <Button
                onClick={() => {
                  const nextPage = page + 1;
                  setPage(nextPage);
                  fetchActivities(nextPage, limit, params);
                }}
              >
                Load more
              </Button>
            </ButtonContainer>
          )}
        </>
      ) : (
        <EmptyText>No activities</EmptyText>
      )}
    </Container>
  );
};

export default observer(RecentActivity);
