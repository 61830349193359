import { CloudDownloadOutlined } from "@material-ui/icons";
import { APIOutputSheetFileType } from "api/outputSheets";
import Button from "components/Button";
import DataGrid, { FrozenDataGrid } from "components/DataGrid";
import { useCsvToGridDataAsync } from "components/DataGrid/hooks";
import { DataGridHandleWithData } from "components/DataGrid/types";
import GenerateOutputsButton from "components/GenerateOutputsButton";
import Loading from "components/Loading";
import Toolbar from "components/Toolbar";
import ToolbarStatus from "components/ToolbarStatus";
import ValidationDashboard from "components/ValidationDashboard";
import { CsvValidationErrorContext } from "components/ValidationDashboard/contexts";
import {
  usePopulateValidationSummary,
  useValidationSummary,
} from "components/ValidationDashboard/hooks";
import { IRow } from "csv/types";
import { observer } from "mobx-react-lite";
import FilterOptions from "pages/OutputSection/FilterOptions";
import { useDownload, useProject } from "pages/Workspace/hooks";
import { Worksheet } from "pages/Workspace/types";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Column } from "react-data-grid";
import { ScrollSync } from "react-scroll-sync";
import { useAsync, useAsyncFn, useMount } from "react-use";
import { useStore } from "store";
import OutputSheet from "store/models/OutputSheet";
import styled from "styled-components";
import { Toast } from "utils";
import {
  filterByFormulationCplFilter,
  filterEmptyCplRows,
  getUniqueFormulationCodes,
  getUniquePlants,
  getUniqueProductSpecs,
  getUniqueViscosities,
} from "utils/cpl";
import {
  schemaFormulationsCplAdditive,
  schemaFormulationsCplBaseOil,
  schemaFormulationsCplHead,
  schemaFormulationsCplTotals,
} from "./schema";

type Props = {};

const SheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  overflow: auto;
`;

const filterOptions = (
  columns: readonly Column<IRow>[],
  rows: readonly IRow[]
) => ({
  productSpec: {
    label: "Product Spec(s)",
    choices: getUniqueProductSpecs(columns, rows).map((choice) => ({
      label: choice || "(Blank)",
      value: choice,
    })),
  },
  viscosity: {
    label: "Viscosity",
    choices: getUniqueViscosities(columns, rows).map((choice) => ({
      label: choice || "(Blank)",
      value: choice,
    })),
  },
  formulationCode: {
    label: "Formulation Code",
    choices: getUniqueFormulationCodes(columns, rows).map((choice) => ({
      label: choice || "(Blank)",
      value: choice,
    })),
  },
  plant: {
    label: "Plant Selected for Costing",
    choices: getUniquePlants(columns, rows).map((choice) => ({
      label: choice || "(Blank)",
      value: choice,
    })),
  },
});

const FormulationsCpl: React.FC<Props> = () => {
  const store = useStore();
  const project = useProject();

  const refHead = useRef<DataGridHandleWithData>(null);
  const refBaseOils = useRef<DataGridHandleWithData>(null);
  const refAdditives = useRef<DataGridHandleWithData>(null);
  const refTotals = useRef<DataGridHandleWithData>(null);

  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, { label: string; value: string }[]>
  >({});

  const [state, init] = useAsyncFn(async () => {
    if (!project) {
      return;
    }
    const { results } = await store.outputSheets.list(
      project.id,
      undefined,
      undefined,
      {
        file_type__in: [
          "formulation_product",
          "formulation_base_oil",
          "formulation_additive",
          "formulation_totals",
        ],
      }
    );
    return results;
  }, [project, store.outputSheets]);

  useMount(() => {
    init();
  });

  const sheets = (state.value || []).reduce(
    (acc, curr) => ({
      ...acc,
      [curr.fileType]: curr,
    }),
    {}
  ) as { [k in APIOutputSheetFileType]: OutputSheet };

  const csvFiles =
    state.value?.map((sheet) => [
      {
        id: `formulationsCpl-${sheet.id}`,
        sheet,
        rowIdentifier: "id",
      },
    ]) || [];
  const worksheet: Worksheet = {
    kind: "worksheet",
    id: "formulationsCpl",
    type: "sheet-output",
    title: "Formulations (CPL)",
    fileType: "formulation_cpl",
    csvFiles,
    marginalCsvFiles: [],
  };

  const validationSummary = useValidationSummary(csvFiles);
  const [onPopulate, errors] = usePopulateValidationSummary(
    validationSummary.setters
  );

  const [columnsHead, rowsHead, loadedHead] = useCsvToGridDataAsync({
    sheet: sheets.formulation_product,
    editable: false,
    schema: schemaFormulationsCplHead,
  });
  const [columnsBaseOils, rowsBaseOils, loadedBaseOils] = useCsvToGridDataAsync(
    {
      sheet: sheets.formulation_base_oil,
      editable: false,
      schema: schemaFormulationsCplBaseOil,
    }
  );
  const [columnsAdditives, rowsAdditives, loadedAdditives] =
    useCsvToGridDataAsync({
      sheet: sheets.formulation_additive,
      editable: false,
      schema: schemaFormulationsCplAdditive,
    });
  const [columnsTotals, rowsTotals, loadedTotals] = useCsvToGridDataAsync({
    sheet: sheets.formulation_totals,
    editable: false,
    schema: schemaFormulationsCplTotals,
  });

  const productSpecFilter =
    selectedFilters["productSpec"]?.map((value) => value.value) || [];
  const viscosityFilter =
    selectedFilters["viscosity"]?.map((value) => value.value) || [];
  const formulationCodeFilter =
    selectedFilters["formulationCode"]?.map((value) => value.value) || [];
  const plantFilter =
    selectedFilters["plant"]?.map((value) => value.value) || [];

  const columnsHeadFiltered = filterByFormulationCplFilter(
    columnsHead,
    rowsHead,
    productSpecFilter,
    viscosityFilter,
    formulationCodeFilter,
    plantFilter,
    ["id", "col", "_"]
  );
  const columnsBaseOilsFiltered = filterByFormulationCplFilter(
    columnsBaseOils,
    rowsHead,
    productSpecFilter,
    viscosityFilter,
    formulationCodeFilter,
    plantFilter,
    ["id", "rcode", "rm_desc"]
  );
  const columnsAdditivesFiltered = filterByFormulationCplFilter(
    columnsAdditives,
    rowsHead,
    productSpecFilter,
    viscosityFilter,
    formulationCodeFilter,
    plantFilter,
    ["id", "rcode", "rm_desc"]
  );
  const columnsTotalsFiltered = filterByFormulationCplFilter(
    columnsTotals,
    rowsHead,
    productSpecFilter,
    viscosityFilter,
    formulationCodeFilter,
    plantFilter,
    ["id", "rcode", "rm_desc"]
  );
  const rowsBaseOilsFiltered = filterEmptyCplRows(
    rowsBaseOils,
    columnsBaseOilsFiltered
      .map((col) => col.key)
      .filter((key) => !["id", "rcode", "rm_desc"].includes(key))
  );
  const rowsAdditivesFiltered = filterEmptyCplRows(
    rowsAdditives,
    columnsAdditivesFiltered
      .map((col) => col.key)
      .filter((key) => !["id", "rcode", "rm_desc"].includes(key))
  );

  const filters = useMemo(() => {
    if (columnsHead === undefined || rowsHead === undefined) {
      return {};
    }
    return filterOptions(columnsHead, rowsHead);
  }, [columnsHead, rowsHead]);

  const handleFilterChange = useCallback(
    (key: string, values: { label: string; value: string }[]) => {
      setSelectedFilters((prev) => {
        const next = { ...prev };
        if (values.length > 0) {
          next[key] = values;
        } else {
          delete next[key];
        }
        return next;
      });
    },
    []
  );

  const handleFilterReset = useCallback(() => setSelectedFilters({}), []);

  const onDownload = useDownload();

  const handleDownloadClick = async () => {
    project?.setLoading("Preparing download...");
    await onDownload(worksheet, true);
    Toast.success("CSV file successfully loaded.");
    project?.setNotLoading();
  };

  const ready = loadedHead && loadedBaseOils && loadedAdditives && loadedTotals;

  useAsync(async () => {
    if (!ready) {
      return;
    }
    const { errorFile, id } = sheets.formulation_product;
    if (!errorFile) {
      return;
    }
    await onPopulate(`formulationsCpl-${id}`, rowsHead, columnsHead, errorFile);
  }, [ready, sheets.formulation_product, rowsHead, columnsHead]);

  if (!ready) {
    return <Loading full />;
  }

  return (
    <CsvValidationErrorContext.Provider value={validationSummary}>
      <Toolbar>
        {Object.keys(filters).length > 0 ? (
          <FilterOptions
            filterOptions={filters}
            selectedFilters={selectedFilters}
            onChange={handleFilterChange}
            onReset={handleFilterReset}
          />
        ) : null}
        <Button size="thin" onClick={handleDownloadClick}>
          <CloudDownloadOutlined /> Download CSV File
        </Button>
        <GenerateOutputsButton
          onClick={async () => {
            await init();
          }}
        />
        {project && <ToolbarStatus tab={worksheet} project={project} />}
        <ValidationDashboard validationChecks={[]} />
      </Toolbar>

      <ScrollSync vertical={false}>
        <SheetContainer className="multiple">
          <FrozenDataGrid
            ref={refHead}
            id="formulationCpl-head"
            collapsible
            isActive
            multiple
            maxRowsShown={4}
            title="Formulations"
            rows={rowsHead}
            columns={columnsHeadFiltered}
            onRowsChange={() => {}}
            errors={errors}
            filterable={false}
            rowIdentifier="id"
          />
          <DataGrid
            ref={refBaseOils}
            id="formulationCpl-baseOils"
            collapsible
            isActive
            multiple
            maxRowsShown="auto"
            title="Base Oils"
            rows={rowsBaseOilsFiltered}
            columns={columnsBaseOilsFiltered}
            onRowsChange={() => {}}
            filterable={false}
            rowIdentifier="id"
          />
          <DataGrid
            ref={refAdditives}
            id="formulationCpl-additives"
            collapsible
            isActive
            multiple
            maxRowsShown="auto"
            title="Additives"
            rows={rowsAdditivesFiltered}
            columns={columnsAdditivesFiltered}
            onRowsChange={() => {}}
            filterable={false}
            rowIdentifier="id"
          />
          <DataGrid
            ref={refTotals}
            id="formulationCpl-totals"
            collapsible={false}
            headerRowHeight={0}
            isActive
            multiple
            maxRowsShown="auto"
            rows={rowsTotals}
            columns={columnsTotalsFiltered}
            onRowsChange={() => {}}
            filterable={false}
            rowIdentifier="id"
          />
        </SheetContainer>
      </ScrollSync>
    </CsvValidationErrorContext.Provider>
  );
};

export default observer(FormulationsCpl);
