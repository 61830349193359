import { CloudUploadOutlined, Lock, RemoveCircle } from "@material-ui/icons";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { darken } from "polished";
import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
import { FormProvider, useForm } from "react-hook-form";
import { useAsync } from "react-use";
import styled from "styled-components";
import { APIProjectPublishInput } from "../../api/projects";
import { useStore } from "../../store";
import Project from "../../store/models/Project";
import { Toast } from "../../utils";
import Button from "../Button";
import Editor from "../Editor";
import Loading from "../Loading";

type Props = {
  project: Project;
  onHide: () => void;
};

type Inputs = {
  notes: string;
};

const ModalBodyHeader = styled.h4`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 0.875rem;
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;
`;

const ClusterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
`;

const ClusterButton = styled(Button)`
  &.btn.btn-primary {
    border-color: #c8c8c8;
    font-size: 0.75rem;
    text-transform: none;

    svg {
      color: #c8c8c8;
      float: right;
      height: 2.25rem;
      margin-left: 0.625rem;
      margin-right: 0;
      transition: color 0.15s ease-in-out;
    }

    &:hover {
      border-color: ${darken(0.2, "#c8c8c8")};

      svg {
        color: ${darken(0.2, "#c8c8c8")};
      }
    }
  }

  &.btn.btn-primary {
    &.selected,
    &.selected.published.changed {
      border-color: #9bbe41;

      svg {
        color: #9bbe41;
      }

      &:hover:not(:disabled) {
        border-color: ${darken(0.2, "#9bbe41")};

        svg {
          color: ${darken(0.2, "#9bbe41")};
        }
      }
    }

    &.published.changed {
      border-color: #484848;
      opacity: 1;

      svg {
        color: #484848;
      }

      &:hover:not(:disabled) {
        border-color: #000000;

        svg {
          border-color: #000000;
        }
      }
    }
  }
`;

const Publish: React.FC<Props> = ({ project, onHide }) => {
  const store = useStore();
  const methods = useForm<Inputs>();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClusters, setSelectedClusters] = useState<identifier[]>([]);

  const resetSelectedClusters = useCallback(() => {
    setSelectedClusters([]);
  }, []);

  useAsync(async () => {
    setIsLoading(true);
    await project.getPublishedClusters();
    resetSelectedClusters();
    setIsLoading(false);
  }, [project, project.clusters]);

  const handleSelectedClustersClear = useCallback(() => {
    resetSelectedClusters();
  }, [resetSelectedClusters]);

  const handleSelectClustersAll = useCallback(() => {
    setSelectedClusters(
      project.clusters
        .filter((cluster) => !project.lockedClusters.includes(cluster.id))
        .map(({ id }) => id)
    );
  }, [project.clusters, project.lockedClusters]);

  const handleClusterToggle = useCallback((id: identifier) => {
    setSelectedClusters((prev) => {
      const existing = prev.indexOf(id) >= 0;
      if (existing) {
        return prev.filter((pid) => pid !== id);
      }
      return [...prev, id];
    });
  }, []);

  const handleSave = useCallback(
    async (data: Pick<APIProjectPublishInput, "notes">) => {
      project.setLoading();

      // Ensure project is saved before publishing.
      try {
        await Promise.all([
          store.requirements.saveAll(project.id),
          store.formulations.saveAll(project.id),
        ]);
      } catch (e) {
        Toast.danger(
          "Not published. Please review your requirements and formulations, then try again."
        );
        return;
      } finally {
        project.setNotLoading();
      }

      try {
        await project.publish({
          ...data,
          clusterIds: selectedClusters,
        });

        const rmWorking = store.inputSheets
          .ofProject(project.id)
          .get("rm_working");
        if (rmWorking !== undefined) {
          await Promise.all(rmWorking.map((sheet) => sheet.save(true)));
        }
      } catch (e) {
        Toast.danger("Not published. Please review your inputs and try again.");
        return;
      } finally {
        project.setNotLoading();
      }

      try {
        await store.projects.detail(project.id);
      } catch (e) {}

      Toast.success("Collect sheets published.");
      resetSelectedClusters();
      onHide();
    },
    [
      onHide,
      project,
      resetSelectedClusters,
      selectedClusters,
      store.formulations,
      store.inputSheets,
      store.projects,
      store.requirements,
    ]
  );

  const clusters = Array.from(project.clusters).sort((a, b) =>
    a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSave)}>
        <Modal.Body>
          <ModalBodyHeader>
            Release for cluster{" "}
            <Button
              variant="borderless"
              size="thin"
              onClick={handleSelectedClustersClear}
            >
              Clear
            </Button>{" "}
            <Button size="thin" onClick={handleSelectClustersAll}>
              Select All
            </Button>
          </ModalBodyHeader>
          {isLoading ? (
            <Loading full />
          ) : (
            <ClusterList>
              {clusters.map((cluster) => (
                <ClusterButton
                  key={cluster.id}
                  className={clsx({
                    selected: selectedClusters.indexOf(cluster.id) >= 0,
                    published: cluster.published,
                    changed: cluster.changed,
                  })}
                  disabled={
                    project.loading ||
                    project.lockedClusters.includes(cluster.id)
                  }
                  onClick={() => handleClusterToggle(cluster.id)}
                  title={
                    cluster.changed
                      ? `${cluster.name} has changes`
                      : `${cluster.name} has no changes`
                  }
                >
                  {cluster.name} {cluster.published ? "" : "(unpublished)"}
                  {!project.lockedClusters.includes(cluster.id) &&
                  selectedClusters.indexOf(cluster.id) >= 0 ? (
                    <CheckCircleFill />
                  ) : null}
                  {!project.lockedClusters.includes(cluster.id) &&
                  selectedClusters.indexOf(cluster.id) < 0 ? (
                    <RemoveCircle />
                  ) : null}
                  {project.lockedClusters.includes(cluster.id) ? (
                    <Lock />
                  ) : null}
                </ClusterButton>
              ))}
            </ClusterList>
          )}

          <p>
            This will email all country analysts with links to the tabs where
            they are needed to input data.
          </p>

          <ModalBodyHeader>Additional notes</ModalBodyHeader>

          <Editor name="notes" size="sm" readOnly={project.loading} noToolbar />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="borderless" onClick={onHide}>
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              project.loading || isLoading || selectedClusters.length === 0
            }
          >
            <CloudUploadOutlined /> Publish
          </Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

export default observer(Publish);
