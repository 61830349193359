import { CommentOutlined } from "@material-ui/icons";
import HelpModal from "components/HelpModal";
import SubmitRMPAModal from "components/SubmitRMPAModal";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSaveEmit } from "../../pages/Workspace/hooks";
import { useStore } from "../../store";
import Project from "../../store/models/Project";
import { Toast } from "../../utils";
import ApproveModal from "../ApproveModal";
import Button from "../Button";
import PublishModal from "../PublishModal";
import RejectModal from "../RejectModal";
import SaveButton from "../SaveButton";
import SubmitModal from "../SubmitModal";

type Props = {
  urlPrefix: string;
  project: Project;
};

const NavbarButtons: React.FC<Props> = ({ urlPrefix, project }) => {
  const store = useStore();
  const location = useLocation();
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const { current: currentUser } = store.auth;

  const cltLocked =
    currentUser && project.lockedClusters.includes(currentUser.cluster);
  const cltOpen =
    currentUser && project.openClusters.includes(currentUser.cluster);
  const cltSubmitted =
    currentUser && project.submittedClusters.includes(currentUser.cluster);
  const cltApproved =
    currentUser && project.approvedClusters.includes(currentUser.cluster);
  const cltRejected =
    currentUser && project.rejectedClusters.includes(currentUser.cluster);

  const isDefineSection = location.pathname.endsWith(urlPrefix);
  const isCollectSection = location.pathname.startsWith(`${urlPrefix}/collect`);
  const isOutputSection = location.pathname.startsWith(`${urlPrefix}/analyze`);
  const isStatusSection = location.pathname.startsWith(`${urlPrefix}/status`);
  const isSettingsSection = location.pathname.startsWith(
    `${urlPrefix}/settings`
  );

  const isSubmitting = project.loading;

  const onSave = useSaveEmit(project);

  const handleUnsubmit = useCallback(async () => {
    try {
      await project.unsubmit();
    } catch (e) {
      Toast.danger("Unsubmit failed.");
      return;
    }
  }, [project]);

  const handleUnsubmitRMPA = useCallback(async () => {
    try {
      await project.unsubmitRMPA();
    } catch (e) {
      Toast.danger("Unsubmit failed.");
      return;
    }
  }, [project]);

  const handleSave = useCallback(async () => {
    try {
      await onSave();
      setIsSubmitSuccessful(true);
    } catch (e) {}
  }, [onSave]);

  const setNotSubmitSuccessful = () => {
    setIsSubmitSuccessful(false);
  };

  const handleCommentsClick = useCallback(() => {
    store.comments.toggleCommentsPanel();
  }, [store.comments]);

  if (!currentUser) {
    return null;
  }

  if (project.isLocked) {
    return null;
  }

  const isAdmin =
    currentUser.isAdmin || project.adminUsers.includes(currentUser.id);

  if (isDefineSection) {
    const showSave =
      isAdmin ||
      currentUser.isGlobalAnalyst ||
      (!cltLocked &&
        (currentUser.isClusterAnalyst ||
          project.opsInputUsers.includes(currentUser.id) ||
          project.rmPriceUsers.includes(currentUser.id)));
    const showPublish = isAdmin || currentUser.isGlobalAnalyst;
    const showRMPA =
      project.rmPriceUsers.includes(currentUser.id) &&
      project.activeTab === "priceAssumptions";

    return (
      <>
        <HelpModal project={project} />
        {showSave ? (
          <SaveButton
            project={project}
            isSubmitting={isSubmitting}
            isSubmitSuccessful={isSubmitSuccessful}
            setNotSubmitSuccessful={setNotSubmitSuccessful}
            onClick={handleSave}
          />
        ) : null}
        {showPublish ? <PublishModal project={project} /> : null}
        {showRMPA ? (
          project.rmpaSubmitted ? (
            <Button onClick={handleUnsubmitRMPA}>Unsubmit</Button>
          ) : (
            <SubmitRMPAModal project={project} />
          )
        ) : null}
        {project.showComments ? (
          <Button onClick={handleCommentsClick}>
            <CommentOutlined /> Comments
          </Button>
        ) : null}
      </>
    );
  } else if (isCollectSection) {
    const isAnalyst =
      currentUser.isAnalyst && currentUser.cluster === project.activeCluster;
    const isReviewer =
      currentUser.isReviewer && currentUser.cluster === project.activeCluster;
    const isClusterAnalystAndReviewer =
      currentUser.isClusterAnalystAndReviewer &&
      currentUser.cluster === project.activeCluster;

    const showSave =
      isAdmin ||
      currentUser.isGlobalAnalyst ||
      ((isClusterAnalystAndReviewer || isAnalyst) && (cltOpen || cltRejected));
    const showSubmit =
      (isClusterAnalystAndReviewer || isAnalyst) && (cltOpen || cltRejected);
    const showUnsubmit =
      (isClusterAnalystAndReviewer || isAnalyst) && cltSubmitted;
    const showApprove =
      (isClusterAnalystAndReviewer || isReviewer) && cltSubmitted;
    const showUnapprove = isClusterAnalystAndReviewer && cltApproved;
    const showReject =
      !isAnalyst && isReviewer && (cltSubmitted || cltApproved);

    return (
      <>
        <HelpModal project={project} />
        {showSave ? (
          <SaveButton
            project={project}
            isSubmitting={isSubmitting}
            isSubmitSuccessful={isSubmitSuccessful}
            setNotSubmitSuccessful={setNotSubmitSuccessful}
            onClick={handleSave}
          />
        ) : null}
        {showSubmit ? <SubmitModal project={project} /> : null}
        {showUnsubmit ? (
          <Button onClick={handleUnsubmit}>Unsubmit</Button>
        ) : null}
        {showApprove ? <ApproveModal project={project} /> : null}
        {showUnapprove ? (
          <Button onClick={handleUnsubmit}>Unapprove</Button>
        ) : null}
        {showReject ? <RejectModal project={project} /> : null}
        {project.showComments ? (
          <Button onClick={handleCommentsClick}>
            <CommentOutlined /> Comments
          </Button>
        ) : null}
      </>
    );
  } else if (isOutputSection || isStatusSection || isSettingsSection) {
    const showSave = isAdmin;
    return (
      <>
        <HelpModal project={project} />
        {showSave ? (
          <SaveButton
            project={project}
            isSubmitting={isSubmitting}
            isSubmitSuccessful={isSubmitSuccessful}
            setNotSubmitSuccessful={setNotSubmitSuccessful}
            onClick={handleSave}
          />
        ) : null}
        {project.showComments ? (
          <Button onClick={handleCommentsClick}>
            <CommentOutlined /> Comments
          </Button>
        ) : null}
      </>
    );
  }

  return null;
};

export default observer(NavbarButtons);
