import { computed } from "mobx";
import { Model, model, prop } from "mobx-keystone";
import { APICLTAssumptionsInput } from "../../api/cltAssumptions";

@model("collab/CLTAssumptions")
class CLTAssumptions extends Model({
  id: prop<identifier>(),
  project: prop<identifier>(),
  cluster: prop<identifier>(),
  clusterName: prop<string>(),
  sheetsStatus: prop<identifier>(),
  notes: prop<string>(),
  isCltLocked: prop<boolean>(),
  isCltApproved: prop<boolean>(),
  isCltSubmitted: prop<boolean>(),
  isEditable: prop<boolean>(),
}) {
  @computed
  get asAPI(): APICLTAssumptionsInput {
    return {
      notes: this.notes,
      sheetsStatus: this.sheetsStatus,
    };
  }
}

export default CLTAssumptions;
