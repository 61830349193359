import clsx from "clsx";
import { CSVFrozenHeaderMapping } from "csv/mappings";
import { getNumberFormatter } from "../../components/DataGrid/NumberFormatter";
import SelectCellFormatter from "../../components/DataGrid/SelectCellFormatter";
import { ColumnExtraOptions } from "../../components/DataGrid/types";
import {
  mappingCostSheet,
  mappingRawMaterialSummary,
} from "../../csv/mappings/output";
import { IRow } from "../../csv/types";
import { beautifyText } from "../../csv/utils";
import { scaledRem } from "../../utils";

function getNumberCell(
  sheetEditable: boolean,
  key: string,
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 4
): ColumnExtraOptions<IRow> {
  const NumberFormatter = getNumberFormatter(
    minimumFractionDigits,
    maximumFractionDigits
  );
  return {
    cellClass: "cell-readonly cell-numeric",
    formatter: NumberFormatter,
  };
}

const NumberFormatter = getNumberFormatter(3, 3);

export const schemaCostingSheet = (
  key: string,
  sheetEditable?: boolean,
  frozenIdentifiers?: CSVFrozenHeaderMapping
) => {
  const defaultCell: ColumnExtraOptions<IRow> = {
    cellClass(row) {
      if (row.plant_split_link !== "") {
        return "cell-readonly cell-link-child";
      }
      return "cell-readonly";
    },
    editable: false,
    name: mappingCostSheet[key] ? mappingCostSheet[key][0] : key,
    width: mappingCostSheet[key] ? mappingCostSheet[key][1] : 50,
  };
  const defaultCellSummary: ColumnExtraOptions<IRow> = {
    ...defaultCell,
    cellClass(row) {
      if (row.plant_split_link !== "") {
        return "cell-gray cell-link-child";
      }
      return "cell-gray";
    },
  };
  const inputCell: ColumnExtraOptions<IRow> = {
    ...defaultCell,
    cellClass(row) {
      if (row.plant_split_link !== "") {
        return "cell-input cell-link-child";
      }
      return "cell-input";
    },
    editable: sheetEditable ?? true,
  };
  const numberCell0: ColumnExtraOptions<IRow> = {
    ...defaultCell,
    ...getNumberCell(sheetEditable ?? true, key, 0, 0),
    cellClass(row) {
      return clsx((defaultCell.cellClass as Function)(row), "cell-numeric");
    },
  };
  const numberCell3: ColumnExtraOptions<IRow> = {
    ...defaultCell,
    ...getNumberCell(sheetEditable ?? true, key, 3, 3),
    cellClass(row) {
      return clsx((defaultCell.cellClass as Function)(row), "cell-numeric");
    },
  };
  const numberCell3Summary: ColumnExtraOptions<IRow> = {
    ...numberCell3,
    cellClass(row) {
      if (row.plant_split_link !== "") {
        return "cell-gray cell-numeric cell-link-child";
      }
      return "cell-gray cell-numeric";
    },
  };
  const checkboxCell: ColumnExtraOptions<IRow> = {
    ...defaultCell,
    frozen: true,
    maxWidth: scaledRem(100),
    editable: sheetEditable ?? true,
    cellClass: sheetEditable ?? true ? undefined : "cell-readonly",
  };
  const unfrozenCheckboxCell: ColumnExtraOptions<IRow> = {
    ...defaultCell,
    frozen: false,
    maxWidth: scaledRem(100),
    editable: sheetEditable ?? true,
    cellClass: sheetEditable ?? true ? undefined : "cell-readonly",
  };
  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: { name: "", frozen: true },
    requirement_id: {
      ...defaultCell,
      cellClass: "cell-given",
      frozen: !frozenIdentifiers?.requirement_id ? false : true,
    },
    selected: {
      ...(!frozenIdentifiers?.selected ? unfrozenCheckboxCell : checkboxCell),
      cellClass: "cell-given",
      formatter({ row, isCellSelected, onRowChange }) {
        if (
          row.not_selected === "Y" ||
          row.not_selected === "True" ||
          row.not_selected === "true" ||
          row.not_selected === "TRUE"
        ) {
          return null;
        }
        if (row.plant_split_link !== "") {
          return null;
        }
        const value =
          row[key] === true || row[key]?.toString().toLowerCase() === "true"
            ? true
            : false;
        return (
          <SelectCellFormatter
            tabIndex={-1}
            value={value}
            disabled={!sheetEditable}
            onChange={() => {
              const nextValue = !value;
              onRowChange({ ...row, [key]: nextValue ? "TRUE" : "" });
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    not_selected: {
      ...(!frozenIdentifiers?.not_selected
        ? unfrozenCheckboxCell
        : checkboxCell),
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-given cell-link-child";
        }
        return "cell-given";
      },
      formatter: ({ isCellSelected, row, onRowChange }) => {
        if (row.plant_split_link !== "") {
          return null;
        }
        const value =
          row[key] === true || row[key]?.toString().toLowerCase() === "true"
            ? true
            : false;
        return (
          <SelectCellFormatter
            tabIndex={-1}
            value={value}
            disabled={!sheetEditable}
            onChange={() => {
              const nextValue = !value;
              onRowChange({
                ...row,
                [key]: nextValue ? "TRUE" : "FALSE",
                selected: nextValue ? "" : row.selected || "",
              });
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    plant_split_percent: {
      ...defaultCell,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-given cell-link-child";
        }
        return "cell-given";
      },
      formatter: getNumberFormatter(0, 2, true),
    },
    matched: {
      ...defaultCell,
      frozen: !frozenIdentifiers?.matched ? false : true,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-given cell-link-child";
        }
        return "cell-given";
      },
      formatter: ({ row }) => (
        <>
          {row[key]?.toString().toLowerCase() === "true" ? "Matched" : "ERROR"}
        </>
      ),
    },
    volume_yr1: {
      ...numberCell0,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-given cell-numeric cell-link-child";
        }
        return "cell-given cell-numeric";
      },
    },
    volume_yr2: {
      ...numberCell0,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-given cell-numeric cell-link-child";
        }
        return "cell-given cell-numeric";
      },
    },
    volume_yr3: {
      ...numberCell0,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-given cell-numeric cell-link-child";
        }
        return "cell-given cell-numeric";
      },
    },
    volume_yr4: {
      ...numberCell0,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-given cell-numeric cell-link-child";
        }
        return "cell-given cell-numeric";
      },
    },
    volume_yr5: {
      ...numberCell0,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-given cell-numeric cell-link-child";
        }
        return "cell-given cell-numeric";
      },
    },
    bo: numberCell3,
    add: numberCell3,
    currency: defaultCell,
    formulation_cost: numberCell3,
    yield_loss: numberCell3,
    packaging_labelling_cost: numberCell3,
    specific_packaging_labelling_cost: numberCell3,
    decanting_cost: numberCell3,
    "3p_toll_blending_cost": numberCell3,
    bp_blending_fee: numberCell3,
    bp_filling_fee: numberCell3,
    depreciation: numberCell3,
    other_bp_manufacturing_cost: numberCell3,
    total_ex_works_cost: {
      ...numberCell3,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-yellow cell-numeric cell-link-child";
        }
        return "cell-yellow cell-numeric";
      },
    },
    group_purchase: numberCell3,
    "3p_purchase": numberCell3,
    br_icms_tax: numberCell3,
    my_sales_tax: numberCell3,
    manufacturing_mark_up: numberCell3,
    unit_cogs_source_country: {
      ...numberCell3,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-yellow cell-numeric cell-link-child";
        }
        return "cell-yellow cell-numeric";
      },
    },
    cpl_import_cost: numberCell3,
    percent_import_cost: numberCell3,
    th_import_sales_tax: numberCell3,
    cn_consumption_tax: numberCell3,
    "3p_primary_transport": numberCell3,
    internal_primary_transport: numberCell3,
    "3p_storage_handling_cost": numberCell3,
    internal_storage_handling_cost: numberCell3,
    logistics_specific_cost: numberCell3,
    in_country_storage_handling_mark_up: numberCell3,
    unit_cogs_source_country_import_primary_transport_storage_handling: {
      ...numberCell3,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-yellow cell-numeric cell-link-child";
        }
        return "cell-yellow cell-numeric";
      },
    },
    secondary_logistics: numberCell3,
    unit_cogs_source_country_import_primary_transport_storage_handling_secondary_logistics:
      {
        ...numberCell3,
        cellClass(row) {
          if (row.plant_split_link !== "") {
            return "cell-yellow cell-numeric cell-link-child";
          }
          return "cell-yellow cell-numeric";
        },
      },
    total_cost: numberCell0,
    cogs_efficiency_savings: numberCell3,
    comments: inputCell,
    unit_cogs_source_country_import_primary_transport_storage_handling_secondary_logistics_cogs_efficiency_savings:
      {
        ...numberCell3,
        cellClass(row) {
          if (row.plant_split_link !== "") {
            return "cell-yellow cell-numeric cell-link-child";
          }
          return "cell-yellow cell-numeric";
        },
      },
    summary_formulation_cost: numberCell3Summary,
    summary_packaging_cost: numberCell3Summary,
    summary_manufacturing: numberCell3Summary,
    summary_depreciation: numberCell3Summary,
    summary_fg_related_cost: numberCell3Summary,
    summary_primary_transport: numberCell3Summary,
    summary_storage_handling: numberCell3Summary,
    summary_other_gsc_costs: numberCell3Summary,
    summary_3p_purchase: numberCell3Summary,
    summary_taxes: numberCell3Summary,
    summary_future_efficiency_savings: numberCell3Summary,
    summary_gsc_markup: numberCell3Summary,
    summary_seclogs: numberCell3Summary,
    summary_marg_cogs_manufacturing: numberCell3Summary,
    summary_marg_cogs_storage_handling: numberCell3Summary,
    summary_check: defaultCellSummary,
  };
  return (
    options[key] || {
      ...defaultCell,
      cellClass(row) {
        if (row.plant_split_link !== "") {
          return "cell-given cell-link-child";
        }
        return "cell-given";
      },
    }
  );
};

export const schemaFormulationsCplHead = (key: string) => {
  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: {},
    col: {
      width: scaledRem(222),
      frozen: true,
      editable: false,
      resizable: false,
      cellClass: "cell-given",
      name: "",
      colSpan() {
        return 2;
      },
    },
    _: {
      frozen: true,
      editable: false,
      minWidth: 0,
      width: 0,
    },
  };
  return (
    options[key] || {
      name: "\xa0", // Insert non-breaking space so column select keeps working.
      width: scaledRem(123),
      cellClass: "cell-readonly",
      resizable: false,
      editable: false,
    }
  );
};

export const schemaFormulationsCplBaseOil = (key: string) => {
  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: {},
    "R Code": {
      key: "rcode",
      width: scaledRem(111),
      frozen: true,
      formatter: ({ row }) => <>{row[key]}</>,
      resizable: false,
      editable: false,
      name: "R Code",
      cellClass: "cell-given",
    },
    "RM Desc": {
      key: "rm_desc",
      width: scaledRem(111),
      frozen: true,
      formatter: ({ row }) => <>{row[key]}</>,
      resizable: false,
      editable: false,
      name: "RM Desc",
      cellClass: "cell-given",
    },
  };
  return (
    options[key] || {
      name: "\xa0", // Insert non-breaking space so column select keeps working.
      width: scaledRem(123),
      cellClass: "cell-readonly cell-numeric",
      resizable: false,
      editable: false,
      formatter: NumberFormatter,
    }
  );
};

export const schemaFormulationsCplAdditive = (key: string) => {
  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: {},
    "R Code": {
      key: "rcode",
      width: scaledRem(111),
      frozen: true,
      formatter: ({ row }) => <>{row[key]}</>,
      resizable: false,
      editable: false,
      name: "R Code",
      cellClass: "cell-given",
    },
    "RM Desc": {
      key: "rm_desc",
      width: scaledRem(111),
      frozen: true,
      formatter: ({ row }) => <>{row[key]}</>,
      resizable: false,
      editable: false,
      name: "RM Desc",
      cellClass: "cell-given",
    },
  };
  return (
    options[key] || {
      name: "\xa0", // Insert non-breaking space so column select keeps working.
      width: scaledRem(123),
      cellClass: "cell-readonly cell-numeric",
      resizable: false,
      editable: false,
      formatter: NumberFormatter,
    }
  );
};

export const schemaFormulationsCplTotals = (key: string) => {
  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: {},
    "R Code": {
      key: "rcode",
      width: scaledRem(111),
      frozen: true,
      resizable: false,
      editable: false,
      name: "R Code",
      cellClass: "cell-readonly",
      formatter: () => <>Totals</>,
      colSpan: () => 2,
    },
    "RM Desc": {
      key: "rm_desc",
      width: scaledRem(111),
      frozen: true,
      resizable: false,
      editable: false,
      name: "RM Desc",
      cellClass: "cell-readonly",
    },
  };
  return (
    options[key] || {
      name: "\xa0", // Insert non-breaking space so column select keeps working.
      width: scaledRem(123),
      cellClass: "cell-readonly cell-numeric",
      resizable: false,
      editable: false,
      formatter: NumberFormatter,
    }
  );
};

export const schemaRawMaterialSummary = (key: string) => {
  const givenCell: ColumnExtraOptions<IRow> = {
    cellClass: "cell-given",
    frozen: true,
    name: mappingRawMaterialSummary[key]
      ? mappingRawMaterialSummary[key][0]
      : key,
    width: mappingRawMaterialSummary[key]
      ? mappingRawMaterialSummary[key][1]
      : 100,
  };
  const numberCell: ColumnExtraOptions<IRow> = {
    cellClass: "cell-readonly cell-numeric",
    formatter: getNumberFormatter(0, 0),
    name: mappingRawMaterialSummary[key]
      ? mappingRawMaterialSummary[key][0]
      : key,
    width: mappingRawMaterialSummary[key]
      ? mappingRawMaterialSummary[key][1]
      : 100,
  };
  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: {},
    supplier: givenCell,
    r_code: givenCell,
    supplier_product_name: givenCell,
  };
  const landCostPerMtPlant = beautifyText(key.split("_").reverse()[0]);
  return (
    options[key] || {
      ...numberCell,
      name: `${landCostPerMtPlant}\nLand Cost/MT`,
    }
  );
};
