import React, { useContext } from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import { Row as RDGRow, RowRendererProps } from "react-data-grid";
import { IRow } from "../../csv/types";
import Cell from "./Cell";
import { DataGridContext } from "./contexts";

type Props = RowRendererProps<IRow>;

const Row: React.FC<Props> = (props) => {
  const { id } = useContext(DataGridContext);
  const { rowIdx } = props;

  return (
    <ContextMenuTrigger
      id={`grid-context-menu-${id}`}
      collect={() => ({ rowIdx })}
      key={rowIdx}
    >
      <RDGRow cellRenderer={Cell} {...props} />
    </ContextMenuTrigger>
  );
};

export default React.memo(Row);
