import base, { APIDetail, APIList, APISearchParams, config } from "./base";
import { APIProject, APIProjectMinimal } from "./types";

export interface TaskState {
  id: string;
  state: "PENDING" | "STARTED" | "SUCCESS" | "FAILURE";
}

export type APIProjectInput = Partial<
  Omit<
    APIProject,
    | "id"
    | "creator"
    | "creatorName"
    | "creatorPhotoSizes"
    | "created"
    | "folder"
    | "isActive"
    | "latestIssueDate"
    | "lockedClusters"
    | "openClusters"
    | "submittedClusters"
    | "approvedClusters"
    | "rejectedClusters"
    | "currentVersionId"
    | "versionNumber"
    | "currencyName"
    | "rmpaSubmitted"
    | "rmpaIssueDate"
    | "isCostSheetPendingUpdate"
  >
>;

export type APIProjectPublishInput = {
  clusterIds: identifier[];
  notes: string;
};

export interface APIProjectPublishResult extends APIProjectPublishInput {
  state: TaskState;
}

export interface APIProjectDetail extends APIDetail<APIProject> {}
export const detail = (projectId: number) =>
  base.get<APIProjectDetail>(`projects/${projectId}/`);

export interface APIProjectList extends APIList<APIProjectMinimal> {}
export interface APIProjectListSearchParams extends APISearchParams {}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIProjectListSearchParams
) =>
  base.list<APIProjectList, APIProjectListSearchParams>(
    "projects/",
    page,
    limit,
    searchParams
  );

export const listFolder = (
  slug: string,
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIProjectListSearchParams
) =>
  base.list<APIProjectList, APIProjectListSearchParams>(
    `projects/folder/${slug}/`,
    page,
    limit,
    searchParams
  );

export type APIProjectCreateInput = Pick<APIProjectInput, "name">;
export const create = (input: APIProjectCreateInput, slug?: string) => {
  const url = slug ? `projects/folder/${slug}/` : "projects/";
  return base.post<APIProjectDetail>(url, { json: input });
};

export const update = (projectId: identifier, input: APIProjectInput) =>
  base.patch<APIProjectDetail>(`projects/${projectId}/`, { json: input });

export type APIProjectDuplicateInput = Pick<APIProjectInput, "name">;
export const duplicate = (projectId: number, input: APIProjectDuplicateInput) =>
  base.post<APIProjectDetail>(`projects/${projectId}/duplicate/`, {
    json: input,
  });

export type APIProjectRenameInput = Pick<APIProjectInput, "name">;
export const rename = (projectId: identifier, input: APIProjectRenameInput) =>
  base.patch<APIProjectDetail>(`projects/${projectId}/`, { json: input });

export type APIProjectUpdateFolderInput = Pick<APIProject, "folder">;
export const updateFolder = (
  projectId: identifier,
  input: APIProjectUpdateFolderInput
) => base.patchEmpty(`projects/${projectId}/update-folder/`, { json: input });

export const lock = (projectId: identifier) =>
  base.patch<APIProjectDetail>(`projects/${projectId}/lock/`);

export const unlock = (projectId: identifier) =>
  base.patch<APIProjectDetail>(`projects/${projectId}/unlock/`);

export const archiveProject = (projectId: identifier) =>
  base.patchEmpty(`projects/${projectId}/archive/`);

export const restoreProject = (projectId: identifier) =>
  base.patchEmpty(`projects/${projectId}/restore/`);

export const pollState = (taskId: string) =>
  base.get<TaskState>("tasks/", {
    searchParams: {
      id: taskId,
    },
  });

export interface APIProjectPublishedCluster {
  id: identifier;
  name: string;
  published: boolean;
  changed: boolean;
}

export interface APIProjectPublishedClusterList
  extends APIList<APIProjectPublishedCluster> {}

export const publishedClusters = (projectId: identifier) =>
  base.list<APIProjectPublishedClusterList>(
    `projects/${projectId}/published-clusters/`
  );

export const generateCostSheet = (projectId: identifier) =>
  base.post<TaskState>(`projects/${projectId}/generate-cost-sheet/`);

export const generateOutputs = (projectId: identifier) =>
  base.post<TaskState>(`projects/${projectId}/generate-outputs/`);

export const publish = (projectId: identifier, data: APIProjectPublishInput) =>
  base.post<APIProjectPublishResult>(`projects/${projectId}/publish/`, {
    json: data,
  });

export const uploadRequirements = (
  projectId: identifier,
  data: { file: string }
) =>
  base.postEmpty(`projects/${projectId}/upload-requirements-csv/`, {
    json: data,
  });

export const uploadFormulations = (
  projectId: identifier,
  data: { file: string }
) =>
  base.postEmpty(`projects/${projectId}/upload-formulations-csv/`, {
    json: data,
  });

export const uploadPriceAssumptions = (
  projectId: identifier,
  data: { file: string }
) =>
  base.postEmpty(
    `projects/${projectId}/upload-raw-materials-price-assumptions-csv/`,
    {
      json: data,
    }
  );

export const uploadInputSheet = (
  projectId: identifier,
  data: { file: string; cluster?: identifier; fileType: string }
) =>
  base.postEmpty(`projects/${projectId}/upload-input-sheet-csv/`, {
    json: data,
  });

export type APIProjectSubmitInput = {
  notes: string;
};

export interface APIProjectSubmitResults extends APIProjectSubmitInput {
  project: APIProjectDetail;
}

export const submit = (projectId: identifier, data: APIProjectSubmitInput) =>
  base.post<APIProjectSubmitResults>(`projects/${projectId}/submit/`, {
    json: data,
  });

export const submitPriceRequirements = (
  projectId: identifier,
  data: APIProjectSubmitInput
) => base.postEmpty(`projects/${projectId}/rm-price-req/`, { json: data });

export const submitGlobalPricing = (
  projectId: identifier,
  data: APIProjectSubmitInput
) => base.postEmpty(`projects/${projectId}/global-pricing/`, { json: data });

export const submitRMPA = (
  projectId: identifier,
  data: APIProjectSubmitInput
) =>
  base.post<APIProjectSubmitResults>(`projects/${projectId}/submit-rmpa/`, {
    json: data,
  });

export type APIProjectApproveRejectInput = {
  reviewerNotes?: string;
};

export interface APIProjectApproveRejectResults
  extends APIProjectApproveRejectInput {
  project: APIProjectDetail;
}

export const approve = (
  projectId: identifier,
  data: APIProjectApproveRejectInput
) =>
  base.post<APIProjectApproveRejectResults>(`projects/${projectId}/approve/`, {
    json: data,
  });

export const reject = (
  projectId: identifier,
  data: APIProjectApproveRejectInput
) =>
  base.post<APIProjectApproveRejectResults>(`projects/${projectId}/reject/`, {
    json: data,
  });

export const unsubmit = (projectId: identifier) =>
  base.post<APIProjectDetail>(`projects/${projectId}/unsubmit/`);

export const unsubmitRMPA = (projectId: identifier) =>
  base.post<APIProjectDetail>(`projects/${projectId}/unsubmit-rmpa/`);
