import "./index.scss";

import ReactDOM from "react-dom";
import api from "./api";
import appConfig from "./config/appConfig";
import { createRootStore } from "./store";
import App from "./pages/App";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import { Quill as QuillBase } from "react-quill";

// window.Quill needs to exist to make the Quill image plugin work.
(window as any).Quill = QuillBase;

// Set the locale to en-GB to automatically fix date formatting to DD/MM/YYYY.
registerLocale("en-GB", enGB);
setDefaultLocale("en-GB");

// Set default values for `api.config`.
api.config.baseUrl = appConfig.apiBaseUrl! as string;
api.config.defaultLimit = appConfig.apiDefaultLimit as number;
api.config.timeout = appConfig.apiTimeout as number;

const store = createRootStore();

ReactDOM.render(<App store={store} />, document.getElementById("root"));
