import clsx from "clsx";
import { formatDistanceToNow, parseISO } from "date-fns";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { useCallback, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useStore } from "store";
import styled from "styled-components";
import { Header, SubHeader } from "../Header";
import ModalForm from "../ModalForm";
import SearchFormulationsForm from "./Form";
import SearchFormulationsResults from "./Results";

type SearchFormulationsModalProps = {
  show: boolean;
  onHide: () => void;
};

const SearchFormulationsModalContainer = styled(ModalForm)`
  .modal-dialog {
    font-size: 0.875rem;
    line-height: 1.625rem;

    ${({ $numResults }) =>
      $numResults
        ? `max-width: ${Math.min(80, 34.75 + ($numResults - 1) * 7.75)}rem;`
        : `min-width: 34.75rem;`}
  }
`;

const HeaderRight = styled(Header)`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: capitalize;
`;

const SearchFormulationsModal: React.FC<SearchFormulationsModalProps> = ({
  show,
  onHide,
}) => {
  const store = useStore();
  const project = useProject();

  const hasResults = useMemo(() => {
    return Boolean(store.formulations.datahubListItems.size);
  }, [store.formulations.datahubListItems.size]);

  const onFormCancel = useCallback(() => {
    // Remove cached results from search
    store.formulations.clearDatahubCache();
    onHide();
  }, [onHide, store.formulations]);

  const onResultsCancel = useCallback(() => {
    store.formulations.clearDatahubCache();
  }, [store.formulations]);

  return project ? (
    <SearchFormulationsModalContainer
      centered
      show={show}
      onHide={onHide}
      $numResults={store.formulations.datahubListItems.size}
    >
      <Modal.Header className={clsx("d-flex", "justify-content-between")}>
        <Modal.Title>
          <SubHeader>Search</SubHeader>
          <Header>Formulations</Header>
        </Modal.Title>
        <Modal.Title className={clsx("text-right")}>
          <SubHeader>Version {project.versionNumber}</SubHeader>
          {project.latestIssueDate ? (
            <HeaderRight>
              Model Issued{" "}
              {formatDistanceToNow(parseISO(project.latestIssueDate))} ago
            </HeaderRight>
          ) : (
            <HeaderRight>Model not published</HeaderRight>
          )}
        </Modal.Title>
      </Modal.Header>
      {hasResults ? (
        <SearchFormulationsResults
          onBack={onResultsCancel}
          onCancel={onFormCancel}
          onSubmit={onFormCancel}
        />
      ) : (
        <SearchFormulationsForm onCancel={onFormCancel} />
      )}
    </SearchFormulationsModalContainer>
  ) : null;
};

export default observer(SearchFormulationsModal);
