import React, { FC, useState } from "react";
import { Redirect } from "react-router-dom";
import { useAsync } from "react-use";
import Loading from "../../components/Loading";
import { useStore } from "../../store";

const Logout: FC = () => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(true);

  const state = useAsync(async () => {
    await store.auth.checkAuth();

    const { user } = store.auth;

    if (user) {
      await store.auth.logout();
      setIsLoading(false);
    }
  }, []);

  if (isLoading || state.loading) {
    return <Loading full />;
  }

  return <Redirect to="/" />;
};

export default Logout;
