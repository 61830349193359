import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "store";
import styled from "styled-components";
import { validateEmail } from "utils";

type Props = {
  message: string;
};

const Container = styled.div`
  border-left: 2px solid #c4c4c4;
  font-size: 0.825rem;
  font-weight: 600;
  hyphens: auto;
  margin-bottom: 0.5rem;
  margin-left: calc(0.875rem - 2px);
  padding: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;

  span {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const atMentionRegex = /@\[([\W\w@._+]+?)\]/g;

const AtMentionDiv: React.FC<Props> = ({ message }) => {
  const store = useStore();
  const tokens = message.split(atMentionRegex);

  return (
    <Container>
      {tokens.map((token) => (
        <React.Fragment key={token}>
          {validateEmail(token) ? (
            <span>@{store.users.getByEmail(token)?.email}</span>
          ) : (
            token
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default observer(AtMentionDiv);
