import Button from "components/Button";
import Folder from "icons/Folder";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAsync } from "react-use";
import styled from "styled-components";
import { APICLTAssumptionsSearchParams } from "../../api/cltAssumptions";
import Loading from "../../components/Loading";
import ProjectApprovedLabel from "../../components/ProjectApprovedLabel";
import ProjectLockedLabel from "../../components/ProjectLockedLabel";
import ProjectSubmittedLabel from "../../components/ProjectSubmittedLabel";
import Toolbar from "../../components/Toolbar";
import { useStore } from "../../store";
import { useProject } from "../Workspace/hooks";
import CLTAssumptionsForm from "./CLTAssumptionsForm";
import CLTAssumptionsGlobal from "./CLTAssumptionsGlobal";

interface Props {}

const CLTAssumptionsToolbar = styled(Toolbar)`
  flex-direction: row-reverse;
`;
const FilesButton = styled(Button)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  width: 6.25rem;
  right: 0;
`;

const CLTAssumptionsTab: React.FC<Props> = () => {
  const methods = useForm();
  const store = useStore();
  const project = useProject();
  const currentUser = store.auth.current;

  const [showFiles, setShowFiles] = useState(true);
  const handleFilesButtonClick = () => {
    setShowFiles((prev) => !prev);
  };
  const { loading } = useAsync(async () => {
    if (project) {
      const params: APICLTAssumptionsSearchParams = {
        project_id: project.id,
      };
      await store.cltAssumptions.list(1, -1, params);
    }
  }, [project, store]);

  if (!project || loading) {
    return <Loading full />;
  }

  const cltSubmitted =
    currentUser && project.submittedClusters.includes(currentUser.cluster);
  const cltApproved =
    currentUser && project.approvedClusters.includes(currentUser.cluster);

  const hasCltAssumptions = Boolean(
    Array.from(store.cltAssumptions.listItems.values()).length
  );

  return (
    <FormProvider {...methods}>
      <CLTAssumptionsToolbar>
        {currentUser?.isClusterUser && (
          <>
            {cltApproved && <ProjectApprovedLabel />}
            {cltSubmitted && <ProjectSubmittedLabel />}
          </>
        )}
        {project.isLocked && <ProjectLockedLabel />}
        <FilesButton
          variant="clt-files"
          onClick={handleFilesButtonClick}
          disabled={!hasCltAssumptions}
        >
          FILES
          <Folder />
        </FilesButton>
      </CLTAssumptionsToolbar>
      {currentUser &&
      (currentUser.isGlobalUserOrAdmin ||
        project.adminUsers.includes(currentUser.id)) ? (
        <CLTAssumptionsGlobal showFiles={showFiles} />
      ) : (
        currentUser?.isClusterUser && (
          <CLTAssumptionsForm showFiles={showFiles} />
        )
      )}
    </FormProvider>
  );
};

export default observer(CLTAssumptionsTab);
