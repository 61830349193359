import { CalendarToday } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import DatePicker from ".";
import { GetComponentProps } from "../../utils/types";
import Button from "../Button";

type InputProps = Pick<
  GetComponentProps<typeof DatePicker>,
  "errors" | "className"
> & {
  onCalendarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  value?: string;
  disabled?: boolean;
};

const CalendarButton = styled(Button)`
  &.btn {
    &:focus {
      outline: none;
    }
  }

  &.btn-calendar {
    background-color: #ffffff;
    border-color: #ced4da;
    border-right: 0;

    svg {
      margin-right: 0;
    }
  }
`;

const CalendarInput = React.forwardRef(
  (
    { className, errors, onCalendarOpen, value, disabled }: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <>
        <InputGroup
          className={clsx(className, { "is-invalid": !!errors }, { disabled })}
          role="button"
          onClick={() => {
            if (!disabled) onCalendarOpen((prev) => !prev);
          }}
          onKeyPress={() => {
            if (!disabled) onCalendarOpen((prev) => !prev);
          }}
          tabIndex={0}
        >
          <InputGroup.Prepend>
            <CalendarButton variant="calendar" tabIndex={-1}>
              <CalendarToday />
            </CalendarButton>
          </InputGroup.Prepend>
          <Form.Control disabled ref={ref} value={value} isInvalid={!!errors} />
        </InputGroup>
        {!!errors && (
          <Form.Control.Feedback type="invalid">
            {errors.type === "required"
              ? "This field may not be blank."
              : errors.message}
          </Form.Control.Feedback>
        )}
      </>
    );
  }
);

export default CalendarInput;
