import { SearchOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { useCallback, useState } from "react";
import { useStore } from "store";
import Button from "../Button";
import SearchFormulationsModal from "./Modal";

type Props = {};

const SearchFormulations: React.FC<Props> = () => {
  const project = useProject();
  const store = useStore();
  const currentUser = store.auth.current;

  const [show, setShow] = useState(false);

  const hasEditPermission =
    currentUser &&
    (currentUser.isAdmin ||
      currentUser.id === project?.creator ||
      project?.adminUsers.includes(currentUser.id) ||
      project?.opsInputUsers.includes(currentUser.id));

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const handleHide = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <>
      <Button
        variant="clt-files"
        size="thin"
        onClick={handleShow}
        disabled={!hasEditPermission}
      >
        <SearchOutlined />
        Search Formulations
      </Button>
      <SearchFormulationsModal show={show} onHide={handleHide} />
    </>
  );
};

export default observer(SearchFormulations);
