import { APITenderOutcomeInput } from "api/tenderOutcomes";
import { computed } from "mobx";
import { model, Model, prop } from "mobx-keystone";

@model("collab/TenderOutcome")
class TenderOutcome extends Model({
  id: prop<identifier>(),
  project: prop<identifier>(),
  projectName: prop<string>(),
  status: prop<string>(),
  notes: prop<string>(),
  date: prop<string>(),
}) {
  @computed
  get asAPI(): APITenderOutcomeInput {
    return {
      project: this.project,
    };
  }
}

export default TenderOutcome;
