import { Model, model, prop } from "mobx-keystone";

@model("collab/Currency")
class Currency extends Model({
  id: prop<identifier>(),
  name: prop<string>(),
  code: prop<string>(),
}) {}

export default Currency;
