import React, { useCallback, useMemo } from "react";
import { Form, Image } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Select, { components } from "react-select";
import styled, { CSSObject } from "styled-components";

const Avatar = styled(Image)`
  width: 20px;
  height: 20px;
  background-color: #c4c4c4;
`;

export const customStyles = {
  control: (base: CSSObject, state: any) => ({
    ...base,
    borderColor: state.isFocused ? "#74b468" : "#ced4da",
    boxShadow: state.isFocused ? "0 0 0 1px #74b468" : "none",
    fontSize: "0.875rem",
    lineHeight: "0.875rem",

    "&:hover": {
      borderColor: "#74b468",
      outline: "#74b464 auto 1px",
    },
  }),
  singleValue: (base: CSSObject) => ({
    ...base,
    height: "1rem",
  }),
  multiValue: (base: CSSObject) => ({
    ...base,
    backgroundColor: "#c3da87",
  }),
  multiValueLabel: (base: CSSObject) => ({
    ...base,
    fontFamily: "Roboto",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "0.875rem",
    letterSpacing: "0.5px",
    color: "#000000",
    backgroundColor: "#c3da87",
  }),
  multiValueRemove: (base: CSSObject) => ({
    ...base,
    color: "#000000",
    backgroundColor: "#c3da87",

    "&:hover": {
      color: "#484848",
      backgroundColor: "#c3da87",
    },
  }),
  menu: (base: CSSObject) => ({
    ...base,
    zIndex: 101,
  }),
  option: (base: CSSObject, state: any) => ({
    ...base,
    color: "#484848",
    fontFamily: "Roboto",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "0.875rem",
    letterSpacing: "0.5px",
    backgroundColor: state.isFocused ? "#c3da87" : "#ffffff",

    "&:hover": {
      color: "#484848",
      backgroundColor: "#c3da87",
    },
  }),
};

interface FormSelectOption {
  value: number | string | null;
  label: JSX.Element | string;
  avatar?: string;
}

interface Props {
  name: string;
  defaultValue?: number | number[] | string | null;
  options: FormSelectOption[];
  placeholder?: string;
  required?: boolean;
  noOptionsMessage?: string;
  disabled?: boolean;
  isMulti?: boolean;
  hideSelectedOptions?: boolean;
}

const Option = (props: any) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      {data.avatar !== undefined ? (
        <Avatar src={data.avatar} roundedCircle />
      ) : null}{" "}
      {data.label}
    </components.Option>
  );
};

const MultiValueLabel = (props: any) => {
  const { data } = props;
  return (
    <components.MultiValueLabel {...props}>
      {data.avatar !== undefined ? (
        <Avatar src={data.avatar} roundedCircle />
      ) : null}{" "}
      {data.label}
    </components.MultiValueLabel>
  );
};

const ReactSelect: React.FC<Props> = ({
  name,
  defaultValue,
  options,
  placeholder,
  required = false,
  noOptionsMessage,
  disabled,
  isMulti,
  hideSelectedOptions = true,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const fieldValue: number[] | number | string | null =
    watch(name) === undefined ? defaultValue : watch(name);

  const value = useMemo(() => {
    let v: FormSelectOption[] = [];
    if (fieldValue) {
      v = options.filter((option) => {
        if (isMulti && option.value && Array.isArray(fieldValue)) {
          return fieldValue.includes(option.value as number);
        } else {
          return fieldValue === option.value;
        }
      });
    }

    if (!isMulti) {
      return v[0];
    }
    return v;
  }, [fieldValue, isMulti, options]);

  const handleChange = useCallback(
    (selected) => {
      let value: number[] | number | null = null;
      if (selected) {
        if (isMulti) {
          const selectedValues = selected.map(
            (item: FormSelectOption) => item.value
          );
          value = selectedValues || [];
        } else {
          value = selected.value;
        }
      }
      setValue(name, value, { shouldDirty: true });
    },
    [name, isMulti, setValue]
  );

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          validate(value) {
            return !required || !!value || "This field is required.";
          },
        }}
        render={({ field: { name, ref, onBlur }, fieldState }) => (
          <Select
            name={name}
            ref={ref}
            onBlur={onBlur}
            hideSelectedOptions={hideSelectedOptions}
            isMulti={isMulti}
            className={fieldState.invalid ? "is-invalid" : undefined}
            styles={{
              ...customStyles,
              control: (provided: any, state) => ({
                ...customStyles.control(provided, state),
                borderColor: errors[name]
                  ? "#dc3545"
                  : customStyles.control(provided, state).borderColor,
              }),
            }}
            value={value}
            placeholder={placeholder || "Select Options"}
            noOptionsMessage={() => noOptionsMessage || "No Options"}
            isClearable={false}
            components={{
              IndicatorSeparator: () => null,
              Option,
              MultiValueLabel,
            }}
            options={options}
            onChange={handleChange}
            isDisabled={disabled}
          />
        )}
      />
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </>
  );
};

export default ReactSelect;
