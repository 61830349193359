import { Close } from "@material-ui/icons";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import { CustomSheet, Worksheet } from "pages/Workspace/types";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { useStore } from "store";
import { CommentFilter } from "store/CommentStore";
import InputSheet from "store/models/InputSheet";
import styled from "styled-components";
import { useComments } from "./hooks";
import ThreadBox from "./ThreadBox";
import { Thread } from "./types";

type Props = Pick<React.HTMLAttributes<HTMLDivElement>, "className"> & {
  onClose: () => void;
  tab: Worksheet | CustomSheet;
};

const Container = styled.div`
  display: flex;
  background-color: #fdfdfd;
  width: 18.75rem;
  flex-direction: column;

  .dropdown-item {
    font-size: 0.875rem;
    margin-right: 1rem;

    svg {
      height: 1.25rem;
      margin-right: 1rem;
      vertical-align: top;
    }
  }
`;

const FilterDropdown = styled(Dropdown)`
  .btn,
  .dropdown {
    font-size: 0.625rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .dropdown {
    font-size: 0.875rem;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0;
  padding: 1rem;
  border-bottom: 1px solid #d8d8d8;

  ${FilterDropdown} {
    float: left;
  }
`;

const CloseButton = styled(Button).attrs({ variant: "borderless" })`
  float: right;

  &.btn svg {
    margin-right: 0;
  }
`;

const CommentList = styled.div`
  padding: 1rem;
  flex: 1;
  overflow: auto;
`;

const Comments: React.FC<Props> = ({ className, onClose, tab }) => {
  const store = useStore();
  const project = useProject();
  const { showCreate, selection, visibleSheets } = useComments();

  // Consolidate comments from multiple related CSV sheets into grouped threads.
  let threads: Thread[] = [];
  if (project && tab.kind === "worksheet") {
    const csvFiles = [...tab.csvFiles, ...tab.marginalCsvFiles];
    csvFiles.forEach((fileSet) => {
      fileSet.forEach(({ sheet }) => {
        store.comments
          .ofSheetGrouped(project.id, sheet.sheetType, sheet.id)
          .forEach((comments) => {
            if (!visibleSheets.includes(`${sheet.sheetType}-${sheet.id}`)) {
              return;
            }
            if (
              sheet.sheetType === "inputsheet" &&
              (sheet as InputSheet).cluster !== project.activeCluster
            ) {
              return;
            }

            if (comments.length > 0) {
              threads.push({
                comments,
                selection: {
                  sheetType: sheet.sheetType,
                  sheetId: sheet.id,
                  rowIndex: comments[0].rowIndex,
                  colName: comments[0].colName,
                },
              });
            }
          });
      });
    });
  }

  const selectionThread = threads.find(
    (thread) =>
      selection &&
      thread.selection.sheetType === selection.sheetType &&
      thread.selection.sheetId === selection.sheetId &&
      thread.selection.colName === selection.colName &&
      thread.selection.rowIndex === selection.rowIndex
  );

  const handleFilterSelect = (eventKey: string) => {
    store.comments.setFilter(
      CommentFilter[eventKey as keyof typeof CommentFilter]
    );
  };

  return (
    <Container className={className}>
      <Head>
        <FilterDropdown onSelect={handleFilterSelect}>
          <Dropdown.Toggle variant="borderless" id="dropdown-basic">
            {store.comments.filter.valueOf()}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {Object.entries(CommentFilter).map(([key, value]) => (
              <Dropdown.Item key={key} eventKey={key}>
                {value}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </FilterDropdown>
        <CloseButton onClick={onClose}>
          <Close />
        </CloseButton>
      </Head>
      <CommentList>
        {showCreate && selection && !selectionThread && (
          <ThreadBox forNewComment thread={{ comments: [], selection }} />
        )}
        {threads.map((thread) => (
          <ThreadBox key={thread.comments[0].id} thread={thread} />
        ))}
      </CommentList>
    </Container>
  );
};

export default observer(Comments);
