import {
  APIUnitOfMeasurement,
  APIUnitOfMeasurementListSearchParams,
} from "api/unitsOfMeasurement";
import { computed } from "mobx";
import {
  Model,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import api from "../api";
import { APIClusterListSearchParams } from "../api/clusters";
import { APICountryListSearchParams } from "../api/countries";
import { APICurrencyListSearchParams } from "../api/currencies";
import { APIPackSize, APIPackSizeListSearchParams } from "../api/packSizes";
import { APIPlant, APIPlantListSearchParams } from "../api/plants";
import { APITerms, APITermsListSearchParams } from "../api/terms";
import { APICluster, APICountry, APICurrency } from "../api/types";
import Cluster from "./models/Cluster";
import Country from "./models/Country";
import Currency from "./models/Currency";
import PackSize from "./models/PackSize";
import Plant from "./models/Plant";
import Terms from "./models/Terms";
import UnitOfMeasurement from "./models/UnitOfMeasurement";

@model("collab/AssetStore")
class AssetStore extends Model({
  clusterListItems: prop_mapObject(() => new Map<identifier, Cluster>()),
  countryListItems: prop_mapObject(() => new Map<identifier, Country>()),
  currencyListItems: prop_mapObject(() => new Map<identifier, Currency>()),
  packSizeListItems: prop_mapObject(() => new Map<identifier, PackSize>()),
  plantListItems: prop_mapObject(() => new Map<identifier, Plant>()),
  termsListItems: prop_mapObject(() => new Map<identifier, Terms>()),
  unitListItems: prop_mapObject(() => new Map<identifier, UnitOfMeasurement>()),
}) {
  @modelAction
  saveClusterListItem(item: APICluster) {
    const listItem = new Cluster(item);
    this.clusterListItems.set(item.id, listItem);
  }

  @modelAction
  saveCountryListItem(item: APICountry) {
    const listItem = new Country(item);
    this.countryListItems.set(item.id, listItem);
  }

  @modelAction
  saveCurrencyListItem(item: APICurrency) {
    const listItem = new Currency(item);
    this.currencyListItems.set(item.id, listItem);
  }

  @modelAction
  savePackSizeListItem(item: APIPackSize) {
    const listItem = new PackSize(item);
    this.packSizeListItems.set(item.id, listItem);
    return listItem;
  }

  @modelAction
  savePlantListItem(item: APIPlant) {
    const listItem = new Plant(item);
    this.plantListItems.set(item.id, listItem);
    return listItem;
  }

  @modelAction
  saveTermsListItem(item: APITerms) {
    const listItem = new Terms(item);
    this.termsListItems.set(item.id, listItem);
    return listItem;
  }

  @modelAction
  saveUnitListItem(item: APIUnitOfMeasurement) {
    const listItem = new UnitOfMeasurement(item);
    this.unitListItems.set(item.id, listItem);
    return listItem;
  }

  @modelFlow
  init = _async(function* (this: AssetStore) {
    yield* _await(
      Promise.all([
        this.listClusters(),
        this.listCurrencies(),
        this.listPackSizes(),
        this.listPlants(),
        this.listTerms(),
        this.listUnitsOfMeasurement(),
      ])
    );
  });

  @modelFlow
  listClusters = _async(function* (
    this: AssetStore,
    page?: number,
    limit?: number,
    searchParams?: APIClusterListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.clusters.list(page, limit, searchParams));
    const results = resultsRaw.map((item) => this.saveClusterListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listCountries = _async(function* (
    this: AssetStore,
    page?: number,
    limit?: number,
    searchParams?: APICountryListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.countries.list(page, limit, searchParams));
    this.countryListItems.clear();
    const results = resultsRaw.map((item) => this.saveCountryListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listCurrencies = _async(function* (
    this: AssetStore,
    page?: number,
    limit?: number,
    searchParams?: APICurrencyListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.currencies.list(page, limit, searchParams));
    this.currencyListItems.clear();
    const results = resultsRaw.map((item) => this.saveCurrencyListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listPackSizes = _async(function* (
    this: AssetStore,
    page?: number,
    limit?: number,
    searchParams?: APIPackSizeListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.packSizes.list(page, limit, searchParams));
    const results = resultsRaw.map((item) => this.savePackSizeListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listPlants = _async(function* (
    this: AssetStore,
    page?: number,
    limit?: number,
    searchParams?: APIPlantListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.plants.list(page, limit, searchParams));
    const results = resultsRaw.map((item) => this.savePlantListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listTerms = _async(function* (
    this: AssetStore,
    page?: number,
    limit?: number,
    searchParams?: APITermsListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.terms.list(page, limit, searchParams));
    const results = resultsRaw.map((item) => this.saveTermsListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listUnitsOfMeasurement = _async(function* (
    this: AssetStore,
    page?: number,
    limit?: number,
    searchParams?: APIUnitOfMeasurementListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.unitsOfMeasurement.list(page, limit, searchParams));
    const results = resultsRaw.map((item) => this.saveUnitListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @computed
  get clusterSelect() {
    return Array.from(this.clusterListItems.entries())
      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      .map(([id, item]) => ({
        value: id,
        label: item.name,
      }));
  }

  @computed
  get countrySelect() {
    return Array.from(this.countryListItems.entries())
      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      .map(([id, item]) => ({
        value: id,
        label: item.name,
      }));
  }

  @computed
  get currencySelect() {
    return Array.from(this.currencyListItems.entries())
      .sort(([, a], [, b]) => a.code.localeCompare(b.code))
      .sort((currency1, currency2) => {
        const mainCurrencyCodes = ["USD", "EUR"];
        return mainCurrencyCodes.includes(currency1[1].code)
          ? -1
          : mainCurrencyCodes.includes(currency1[1].code)
          ? 1
          : 0;
      })
      .map(([id, item]) => ({
        value: id,
        label: item.code,
        code: item.code,
      }));
  }

  @computed
  get packSizeSelect() {
    return Array.from(this.packSizeListItems.entries())
      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      .map(([id, item]) => ({
        value: id,
        label: item.name,
      }));
  }

  @computed
  get plantSelect() {
    return Array.from(this.plantListItems.entries())
      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      .map(([id, item]) => ({
        value: id,
        label: item.name,
        name: item.name,
        code: item.code,
      }));
  }

  @computed
  get termsSelect() {
    return Array.from(this.termsListItems.entries())
      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      .map(([id, item]) => ({
        value: id,
        label: item.name,
      }));
  }

  @computed
  get unitSelect() {
    return Array.from(this.unitListItems.entries())
      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      .map(([id, item]) => ({ value: id, label: item.name }));
  }
}

export default AssetStore;
