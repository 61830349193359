export const columnsNames = [
  "Group",
  "Admin",
  "Admin",
  "CLT Analyst",
  "Global Analyst",
  "CLT Reviewer",
  "Global Reviewer",
  "ATP/GSC Ops Users",
  "Procurement RM Price Input",
  "Project Output Reciepients",
];

export const headData = [
  [
    "Permission Management Location",
    "Django",
    "Collab",
    "Django",
    "Django",
    "Django",
    "Django",
    "Collab",
    "Collab",
    "Collab",
  ],
  [
    "Role at BP",
    "Global Analyst",
    "Project Owner",
    "CLT Analyst",
    "Global Analyst",
    "CLT Reviewer",
    "Global Reviewer",
    "ATP/GSC Ops",
    "Procurement",
    "Output",
  ],
  [
    "Scope",
    "All Projects",
    "Project Spec",
    "All Projects (CLT security overlay)",
    "All Projects",
    "All Projects (CLT security overlay)",
    "All Projects",
    "Project Spec",
    "Project Spec",
    "Project Spec",
  ],
];

export const defineData = [
  [
    "Key Assumptions",
    "",
    "Edit",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "",
    "",
    "View Only",
  ],
  [
    "Forex",
    "",
    "Edit",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "",
    "",
    "View Only",
  ],
  [
    "Requirements Builder",
    "",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
  [
    "Formulations",
    "",
    "Edit",
    "View Only",
    "View Only",
    "View Only",
    "View Only",
    "Edit",
    "",
    "",
  ],
  [
    "Cost Sheet (Frame)",
    "",
    "Edit",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "View Only",
    "",
    "",
  ],
  [
    "Delivery Info",
    "",
    "Edit",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
  [
    "RM Price Assumptions",
    "",
    "Edit",
    "View Only",
    "View Only",
    "View Only",
    "View Only",
    "",
    "Edit",
    "",
  ],
  // [
  //   "Raw Material Report",
  //   "",
  //   "View Only",
  //   "View Only",
  //   "View Only",
  //   "View Only",
  //   "View Only",
  //   "",
  //   "",
  //   "",
  // ],
];

export const collectData = [
  [
    "RM Working",
    "",
    "Edit",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
  [
    "Capex Summary",
    "",
    "Edit",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
  [
    "OH Pack Mfg",
    "",
    "Edit",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
  ["OH Logs", "", "Edit", "Edit", "Edit", "View Only", "View Only", "", "", ""],
  [
    "Marg Costing %",
    "",
    "Edit",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
  [
    "CLT Assumptions",
    "",
    "Edit",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
];

export const outputData = [
  [
    "Costing Sheet",
    "",
    "Edit",
    "View Only",
    "View Only",
    "View Only",
    "View Only",
    "",
    "",
    "View - Selected Rows",
  ],
  [
    "Formulation (CPL)",
    "",
    "View Only",
    "View Only",
    "View Only",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
  // [
  //   "Formulation Selections",
  //   "",
  //   "Edit",
  //   "View Only",
  //   "View Only",
  //   "View Only",
  //   "View Only",
  //   "",
  //   "",
  //   "",
  // ],
  // [
  //   "Sourcing Selections",
  //   "",
  //   "Edit",
  //   "View Only",
  //   "View Only",
  //   "View Only",
  //   "View Only",
  //   "",
  //   "",
  //   "",
  // ],
  [
    "RM Summary",
    "",
    "Edit",
    "View Only",
    "View Only",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
];

export const statusData = [
  [
    "Status Dashboard",
    "",
    "Edit",
    "View Only",
    "View Only",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
  [
    "Activity Feed",
    "",
    "View Only",
    "View Only",
    "View Only",
    "View Only",
    "View Only",
    "",
    "",
    "",
  ],
];

export const settingsData = [
  [
    "Permissions",
    "Edit",
    "Edit",
    "View Only",
    "View Only",
    "View Only",
    "",
    "",
    "",
    "",
  ],
  ["Assets", "Edit", "", "", "", "", "", "", "", ""],
];

export const permissionTablesData = [
  { name: "Define", data: defineData },
  { name: "Collect", data: collectData },
  { name: "Output", data: outputData },
  { name: "Status", data: statusData },
  { name: "Settings", data: settingsData },
];
