export const convertFileToBase64 = async (file: File | Blob) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event && event.target) {
        resolve(event.target.result as string);
      }
      resolve("");
    };

    reader.readAsDataURL(file);
  });
};
