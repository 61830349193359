import { InsertCommentOutlined } from "@material-ui/icons";
import clsx from "clsx";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useStore } from "store";
import Comment from "store/models/Comment";
import styled from "styled-components";
import AtMentionBox from "./AtMentionBox";
import { CellSelection } from "./types";

type Props = {
  cancelable?: boolean;
  comment?: Comment;
  isActive?: boolean;
  onSetInactive: () => void;
  onSubmit?: () => void;
  selection: CellSelection;
};

interface CommentFormValues extends FieldValues {
  comment?: string;
}

const Container = styled(Form)`
  display: none;

  &.active {
    display: block;
    margin-top: 1rem;
  }

  .input-group > textarea.form-control {
    margin-bottom: 1rem;
  }

  .input-group > textarea.form-control.is-invalid {
    margin-bottom: 0;

    + .invalid-feedback {
      margin-bottom: 1rem;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;

const CommentForm: React.FC<Props> = ({
  cancelable = true,
  comment,
  isActive = false,
  onSetInactive,
  onSubmit,
  selection,
}) => {
  const store = useStore();
  const project = useProject();

  const { handleSubmit, watch, control, setValue } = useForm<CommentFormValues>(
    {
      defaultValues: { comment: comment?.comment },
    }
  );

  const handleSave: SubmitHandler<CommentFormValues> = useCallback(
    async (data) => {
      if (!project) {
        return;
      }

      if (comment) {
        await store.comments.update(comment.id, {
          comment: data.comment,
        });
      } else {
        await store.comments.create(project.id, {
          ...selection,
          comment: data.comment,
        });
      }

      setValue("comment", "");
      onSubmit?.();
      onSetInactive();
    },
    [
      project,
      comment,
      setValue,
      onSubmit,
      onSetInactive,
      store.comments,
      selection,
    ]
  );

  const handleCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onSetInactive();
      setValue("comment", "");
    },
    [onSetInactive, setValue]
  );

  const handleFormKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (e.ctrlKey && e.key === "Enter") {
        handleSubmit(handleSave)();
      } else if (e.key === "Escape") {
        onSetInactive();
        setValue("comment", "");
      }
    },
    [handleSave, handleSubmit, onSetInactive, setValue]
  );

  return (
    <Container
      noValidate
      className={clsx({ active: isActive })}
      onKeyDown={handleFormKeyDown}
      onSubmit={handleSubmit(handleSave)}
    >
      <AtMentionBox<CommentFormValues>
        control={control}
        name="comment"
        placeholder={cancelable ? "Reply to this thread" : "Add comment"}
      />

      <div className="buttons">
        {cancelable ? (
          <Button type="button" variant="borderless" onClick={handleCancel}>
            Cancel
          </Button>
        ) : null}
        <Button type="submit" disabled={!watch("comment")}>
          <InsertCommentOutlined /> Comment
        </Button>
      </div>
    </Container>
  );
};

export default observer(CommentForm);
