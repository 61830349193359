import React from "react";
import { FormatterProps } from "react-data-grid";

interface MapWithName {
  name: string;
}

function valueMapMapper<T extends MapWithName>(
  key: string,
  map: Map<identifier, T>,
  multiple?: boolean,
  format?: (mapped?: T) => string
) {
  const _format = format ? format : (mapped?: T) => mapped?.name || "";

  return React.memo((props: FormatterProps) => {
    if (!props.row[key] || props.row[key].length === 0) {
      return <></>;
    }

    if (multiple) {
      const values = props.row[key].split(",") as string[];

      return (
        <>
          {values
            .map((value) => _format(map.get(parseInt(value, 10))))
            .join(", ")}
        </>
      );
    }

    return <>{_format(map.get(parseInt(props.row[key] as string, 10)))}</>;
  });
}

export default valueMapMapper;
