import { APICLTAssumptionsBulkUpdateInput } from "api/cltAssumptions";
import CLTFiles from "components/CLTFiles";
import Editor from "components/Editor";
import SecondaryTabs from "components/SecondaryTabs";
import { observer } from "mobx-react-lite";
import { lighten } from "polished";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useUnmount } from "react-use";
import { useStore } from "store";
import styled from "styled-components";
import { useProject, useSave } from "../Workspace/hooks";

type Props = { showFiles?: boolean };

const EmptyText = styled.div`
  color: ${lighten(0.5, "#000000")};
  text-align: center;
  user-select: none;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  margin: 5rem 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 1.25rem;
  height: 0;
  overflow: auto;
  padding: 1.25rem;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const CLTAssumptionsGlobal: React.FC<Props> = ({ showFiles }) => {
  const { control, formState, handleSubmit } =
    useFormContext<APICLTAssumptionsBulkUpdateInput>();
  const store = useStore();
  const { current: currentUser } = store.auth;
  const project = useProject();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const hasEditPermission = !!(
    currentUser &&
    (currentUser.isGlobalAnalystOrAdmin ||
      project?.adminUsers.includes(currentUser.id))
  );
  const editable = hasEditPermission && !project?.isLocked;

  useEffect(() => {
    if (project) {
      if (formState.isDirty) {
        project.setDirty();
      } else {
        project.setNotDirty();
      }
    }
  }, [project, formState.isDirty]);

  useUnmount(() => {
    if (project && project.isDirty) {
      project.setNotDirty();
    }
  });

  const cltAssumptions = project
    ? Array.from(store.cltAssumptions.listItems.values()).sort((a, b) =>
        a.clusterName
          .toLocaleLowerCase()
          .localeCompare(b.clusterName.toLocaleLowerCase())
      )
    : [];

  const handleSave = useCallback(
    async (data: APICLTAssumptionsBulkUpdateInput) => {
      await store.cltAssumptions.bulkUpdate({
        instances: data.instances.filter((item) => item !== null),
      });
    },
    [store]
  );

  useSave(
    async () => {
      if (!editable) {
        throw new Error("CLT Assumptions not editable");
      }
      await handleSubmit(handleSave, () => {
        throw new Error("Project not saved.");
      })();
    },
    { saveOnUnmount: true }
  );

  if (!cltAssumptions || cltAssumptions.length === 0) {
    return <EmptyText>No CLT Assumptions</EmptyText>;
  }

  const selectedCltAssumption = cltAssumptions[selectedTabIndex];
  const cltAssumptionEditable = editable && selectedCltAssumption.isEditable;

  return (
    <>
      <Container>
        <Editor
          key={`editor-${selectedTabIndex}`}
          readOnly={!cltAssumptionEditable}
          defaultValue={selectedCltAssumption.notes}
          name={`instances[${selectedTabIndex}].notes`}
        />
        <Controller
          key={`controller-${selectedTabIndex}`}
          control={control}
          name={`instances.${selectedTabIndex}.id`}
          render={({ field }) => <input type="hidden" {...field} />}
          defaultValue={selectedCltAssumption.id}
        />
        {showFiles && (
          <CLTFiles
            clusterId={selectedCltAssumption.cluster}
            sheetsStatus={selectedCltAssumption.sheetsStatus}
          />
        )}
      </Container>
      <SecondaryTabs
        tabs={cltAssumptions.map((item) => ({
          title: item.clusterName,
          key: `${item.id}`,
        }))}
        onChangeTab={(key) => setSelectedTabIndex(Number(key))}
        selectedTabIndex={selectedTabIndex}
      />
    </>
  );
};

export default observer(CLTAssumptionsGlobal);
