import { Model, _async, _await, model, modelFlow, prop } from "mobx-keystone";
import ActivityStore from "./ActivityStore";
import AssetStore from "./AssetStore";
import AuthStore from "./AuthStore";
import CLTAssumptionsStore from "./CLTAssumptionsStore";
import CLTFileStore from "./CLTFileStore";
import ClusterInputSheetsStatusStore from "./ClusterInputSheetsStatusStore";
import CommentStore from "./CommentStore";
import DefineSheetStore from "./DefineSheetStore";
import DeliveryInfoStore from "./DeliveryInfoStore";
import ForexStore from "./ForexStore";
import FormulationStore from "./FormulationStore";
import InputSheetStore from "./InputSheetStore";
import OutputSheetStore from "./OutputSheetStore";
import ProjectFolderStore from "./ProjectFolderStore";
import ProjectStore from "./ProjectStore";
import ProjectVersionStore from "./ProjectVersionStore";
import RMFormulationStore from "./RMFormulationStore";
import RMPriceAssumptionStore from "./RMPriceAssumptionStore";
import RequirementStore from "./RequirementStore";
import TenderOutcomeStore from "./TenderOutcomeStore";
import UserStore from "./UserStore";

@model("collab/RootStore")
export default class RootStore extends Model({
  activities: prop<ActivityStore>(),
  auth: prop<AuthStore>(),
  assets: prop<AssetStore>(),
  cltAssumptions: prop<CLTAssumptionsStore>(),
  cltFiles: prop<CLTFileStore>(),
  clusterInputSheetsStatus: prop<ClusterInputSheetsStatusStore>(),
  comments: prop<CommentStore>(),
  defineSheets: prop<DefineSheetStore>(),
  deliveryInfo: prop<DeliveryInfoStore>(),
  forex: prop<ForexStore>(),
  formulations: prop<FormulationStore>(),
  inputSheets: prop<InputSheetStore>(),
  outputSheets: prop<OutputSheetStore>(),
  projectFolders: prop<ProjectFolderStore>(),
  projectVersions: prop<ProjectVersionStore>(),
  projects: prop<ProjectStore>(),
  requirements: prop<RequirementStore>(),
  rmFormulations: prop<RMFormulationStore>(),
  rmPriceAssumptions: prop<RMPriceAssumptionStore>(),
  users: prop<UserStore>(),
  tenderOutcomes: prop<TenderOutcomeStore>(),
}) {
  @modelFlow
  init = _async(function* (this: RootStore) {
    yield* _await(this.assets.init());
    yield* _await(this.formulations.init());
    yield* _await(this.users.init());
  });
}
