import React from "react";
import { observer } from "mobx-react-lite";
import Project from "../../store/models/Project";
import { Worksheet as IWorksheet } from "../../pages/Workspace/types";
import { useStore } from "../../store";
import ProjectApprovedLabel from "../ProjectApprovedLabel";
import ProjectLockedLabel from "../ProjectLockedLabel";
import ProjectSubmittedLabel from "../ProjectSubmittedLabel";

type Props = {
  project: Project;
  tab: IWorksheet;
};

const ToolbarStatus: React.FC<Props> = ({ tab, project }) => {
  const store = useStore();
  const { current: currentUser } = store.auth;

  const cltApproved =
    currentUser && project.approvedClusters.includes(currentUser.cluster);
  const cltSubmitted =
    currentUser && project.submittedClusters.includes(currentUser.cluster);

  return (
    <>
      {currentUser?.isGlobalUserOrAdmin
        ? project.isLocked && <ProjectLockedLabel />
        : currentUser?.isClusterUser &&
          tab.type === "sheet-collect" && (
            <>
              {cltApproved && <ProjectApprovedLabel />}
              {cltSubmitted && <ProjectSubmittedLabel />}
            </>
          )}
    </>
  );
};

export default observer(ToolbarStatus);
