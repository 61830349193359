import { IRow } from "csv/types";
import { unparseRows } from "csv/utils";
import { computed, observable } from "mobx";
import {
  ExtendedModel,
  model,
  modelAction,
  modelFlow,
  prop,
  _async,
} from "mobx-keystone";
import DirtyableWithId from "./DirtyableWithId";

export type SheetType = "definesheet" | "inputsheet" | "outputsheet" | "sheet";

@model("collab/Sheet")
class Sheet extends ExtendedModel(DirtyableWithId, {
  project: prop<identifier>(),
  file: prop<string>(""),
  errorFile: prop<string>(""),
}) {
  @observable
  protected _csvString: string = "";

  @computed
  get sheetType(): SheetType {
    return "sheet";
  }

  @computed
  get csvString() {
    return this._csvString;
  }

  @modelAction
  private setCsvStringWithDirty(
    rows: IRow[],
    headers: string[],
    setDirty: boolean
  ) {
    if (setDirty) {
      this.setDirty();
    }
    const csvString = unparseRows(rows, headers);
    this._csvString = csvString;
  }

  @modelAction
  setCsvString(rows: IRow[], headers: string[]) {
    this.setCsvStringWithDirty(rows, headers, true);
  }

  @modelFlow
  save(manual: boolean): Promise<boolean> {
    throw new Error("Not implemented");
  }

  @modelFlow
  fetchUpdate = _async(function* (this: Sheet) {});
}

export default Sheet;
