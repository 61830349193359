import { Lock } from "@material-ui/icons";
import SecondaryTabs from "components/SecondaryTabs";
import { observer } from "mobx-react-lite";
import React from "react";
import { CsvFileSet } from "../Workspace/types";

type Props = {
  selectedSheetIndex: number;
  files: CsvFileSet[];
  onChangeTab: (index: number) => void;
};

const SheetTabs: React.FC<Props> = ({
  files,
  selectedSheetIndex,
  onChangeTab,
}) => {
  return (
    <SecondaryTabs
      tabs={files.map((ff) => {
        const file = ff[0];
        return {
          title: file.title || file.id,
          key: file.id,
          icon: !file.editable ? <Lock /> : undefined,
        };
      })}
      onChangeTab={(key) => onChangeTab(Number(key))}
      selectedTabIndex={selectedSheetIndex}
    />
  );
};

export default observer(SheetTabs);
