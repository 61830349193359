import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Loading from "components/Loading";
import { format, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useAsync } from "react-use";
import { useStore } from "store";
import styled from "styled-components";
import { APISubActivity } from "../../api/activities";
import Button from "../../components/Button";

type Props = {
  activityId: identifier;
  subactivityCount: number;
};

const SubActivityDiv = styled.div`
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  position: relative;
  border-left: 1px dotted #c8c8c8;
  border-top: 1px solid transparent;

  &::before {
    content: " ";
    width: 0.5rem;
    height: 1px;
    border-bottom: 1px dotted #c8c8c8;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0.5rem;
  }
`;

const SubActivityAvatar = styled(Image)`
  border: solid 0.125rem #000000;
  background-color: #c4c4c4;
  margin-right: 0.5rem;
  user-select: none;

  width: 1.5rem;
  height: 1.5rem;
`;

const StyledButton = styled(Button)`
  &.btn {
    font-weight: bold;
    font-size: 0.875rem;
    letter-spacing: 0.031rem;
    text-transform: none;
    line-height: 1.5rem;
    min-height: 1.5rem;
    padding: 0;

    svg {
      margin-right: 0;
    }

    &:focus {
      outline: none;
    }
  }

  &.btn-view {
    color: #8fac42;
  }
`;

const formatter = new Intl.NumberFormat("en-GB");

const CollapsibleSubActivity: React.FC<Props> = ({
  activityId,
  subactivityCount,
}) => {
  const store = useStore();
  const [collapsed, setCollapsed] = useState(true);
  const [loading, setLoading] = useState(false);
  const activity = store.activities.listItems.get(activityId);
  const subActivities = activity ? Array.from(activity.subactivities) : [];

  const state = useAsync(async () => {
    if (!collapsed && subActivities.length === 0) {
      await activity?.listSubActivities(1, 10);
    }
  }, [collapsed]);

  const handleShowMoreClick = async () => {
    setLoading(true);
    await activity?.listSubActivities();
    setLoading(false);
  };

  if (subactivityCount === 0) {
    return null;
  }

  return (
    <>
      {!collapsed &&
        subActivities.map((subActivity: APISubActivity) => {
          const {
            id,
            created,
            userName,
            userPhotoSizes,
            subActivityType,
            formulationSpec,
            requirementSpec,
          } = subActivity;
          const createdDate = parseISO(created);

          return (
            <SubActivityDiv
              key={`${activityId}-${id}`}
              title={format(createdDate, "Pp", { locale: enGB })}
            >
              <SubActivityAvatar roundedCircle src={userPhotoSizes.medium} />
              <strong>{userName}</strong>{" "}
              {subActivityType === "requirement_created" ? (
                <>
                  added the requirement <strong>{requirementSpec}</strong>.
                </>
              ) : subActivityType === "requirement_updated" ? (
                <>
                  changed the requirement <strong>{requirementSpec}</strong>.
                </>
              ) : subActivityType === "requirement_deleted" ? (
                <>
                  deleted the requirement <strong>{requirementSpec}</strong>.
                </>
              ) : subActivityType === "formulation_created" ? (
                <>
                  added the formulation <strong>{formulationSpec}</strong>.
                </>
              ) : subActivityType === "formulation_updated" ? (
                <>
                  changed the formulation <strong>{formulationSpec}</strong>.
                </>
              ) : subActivityType === "formulation_deleted" ? (
                <>
                  deleted the formulation <strong>{formulationSpec}</strong>.
                </>
              ) : undefined}
            </SubActivityDiv>
          );
        })}

      {state.loading || loading ? <Loading /> : null}

      <StyledButton
        variant="view"
        onClick={() => setCollapsed((prev) => !prev)}
      >
        {collapsed ? (
          <>
            View {formatter.format(subactivityCount)}{" "}
            {pluralize("change", subactivityCount)} <ExpandMore />
          </>
        ) : (
          <>
            Hide <ExpandLess />
          </>
        )}
      </StyledButton>

      {!collapsed &&
      subActivities.length > 0 &&
      activity?.hasMoreSubActivities ? (
        <StyledButton variant="view" onClick={handleShowMoreClick}>
          Show more&hellip;
        </StyledButton>
      ) : null}
    </>
  );
};

export default observer(CollapsibleSubActivity);
