interface Environment {
  debugTranslations: boolean;
  environment: string;
  isLocal: boolean;
  isProduction: boolean;
}

interface EnvironmentList {
  [environment: string]: Environment;
}

const environments: EnvironmentList = {
  Local: {
    debugTranslations: true,
    environment: "Local",
    isLocal: true,
    isProduction: false,
  },
  Dev: {
    debugTranslations: false,
    environment: "Dev",
    isLocal: false,
    isProduction: true,
  },
  Staging: {
    debugTranslations: false,
    environment: "Staging",
    isLocal: false,
    isProduction: true,
  },
  Prod: {
    debugTranslations: false,
    environment: "Prod",
    isLocal: false,
    isProduction: true,
  },
};

const environmentCurrent =
  environments[process.env.REACT_APP_ENVIRONMENT || "Local"];

type ConfigValue = string | number | boolean | null | undefined;

type Config = NodeJS.ProcessEnv | Record<string, ConfigValue>;

export class AppConfig {
  env: Config;

  extraConfig: Config;

  constructor(env?: Config) {
    if (env) {
      this.env = env;
    } else {
      this.env = {};
    }
    this.extraConfig = {};
  }

  fetchSetting(settingKey: string): string | undefined;

  fetchSetting(settingKey: string, isRequired: boolean): string | undefined;

  fetchSetting(settingKey: string, isRequired: true): string;

  fetchSetting(settingKey: string, isRequired = false) {
    const setting = this.env[settingKey];

    if (isRequired && !setting) {
      throw new Error(`Missing ${settingKey} in .env.local`);
    }

    return setting;
  }

  addSetting(settingKey: string, envVar: string, isRequired: boolean) {
    this.extraConfig[settingKey] = this.fetchSetting(envVar, isRequired);
  }

  addFixedSetting(settingKey: string, value: string | number | boolean) {
    this.extraConfig[settingKey] = value;
  }

  getSetting(settingKey: string) {
    return this.appConfig[settingKey];
  }

  mergeSettings(other: AppConfig) {
    this.env = { ...this.env, ...other.env };
    return this;
  }

  get appConfig(): Config {
    return {
      appName: this.fetchSetting("REACT_APP_NAME") || "Collab",
      apiAdminUrl: this.fetchSetting("REACT_APP_API_ADMIN_URL", true),
      apiBaseUrl: this.fetchSetting("REACT_APP_API_URL", true),
      apiDefaultLimit: 100,
      apiTimeout: 60000,
      apiTokenDuration: 2 * 60 * 1000,
      apiRefreshTokenKey: "__REFRESH_TOKEN_KEY__",
      authUserKey: "__AUTH_USER__",
      apiTokenKey: "__TOKEN_KEY__",
      apiTokenUpdatedKey: "__TOKEN_UPDATED_KEY__",
      msAuthBaseUrl: this.fetchSetting("REACT_APP_MS_AUTH_BASE_URL", true),
      msAuthClientId: this.fetchSetting("REACT_APP_MS_CLIENT_ID", true),
      msAuthRedirectUri: this.fetchSetting("REACT_APP_MS_REDIRECT_URI", true),
      enableComments: this.fetchSetting("REACT_APP_ENABLE_COMMENTS") === "1",
      sentryDsn: this.fetchSetting("REACT_APP_SENTRY_DSN") || "",
      ...this.extraConfig,
      ...environmentCurrent,
    };
  }
}

const baseConfig = new AppConfig();

// Needed so that Babel transform works
export const FAKE_ENV = {
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT!,
  REACT_APP_API_ADMIN_URL: process.env.REACT_APP_API_ADMIN_URL!,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL!,
  REACT_APP_NAME: process.env.REACT_APP_NAME!,
  REACT_APP_ROOT_DOMAIN_URL: process.env.REACT_APP_ROOT_DOMAIN_URL!,
  REACT_APP_MS_AUTH_BASE_URL: process.env.REACT_APP_MS_AUTH_BASE_URL!,
  REACT_APP_MS_CLIENT_ID: process.env.REACT_APP_MS_CLIENT_ID!,
  REACT_APP_MS_REDIRECT_URI: process.env.REACT_APP_MS_REDIRECT_URI!,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN!,
  REACT_APP_ENABLE_COMMENTS: process.env.REACT_APP_ENABLE_COMMENTS!,
};

function initConfig() {
  const appConfig = new AppConfig(FAKE_ENV);

  baseConfig.mergeSettings(appConfig);
  baseConfig.addFixedSetting("apiDefaultLimit", -1);
  baseConfig.addFixedSetting("apiTimeout", 60000);
  baseConfig.addFixedSetting("apiTokenDuration", 2 * 60 * 1000);

  return baseConfig.appConfig;
}

export default initConfig();
