import appConfig from "../config/appConfig";

export const IT_URL = "https://myit.bpglobal.com/";
export const LOGIN_REDIRECT_URL = `
  ${appConfig.msAuthBaseUrl}/authorize?
  client_id=${appConfig.msAuthClientId}&
  response_type=code&
  redirect_uri=${appConfig.msAuthRedirectUri}&
  response_mode=query&
  scope=offline_access profile openid`;
