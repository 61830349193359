const PDFIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9946 9.14083C19.9921 9.10292 19.985 9.06667 19.9725 9.03083C19.9679 9.0175 19.9646 9.00458 19.9587 8.99167C19.9387 8.94833 19.9133 8.9075 19.8783 8.87292C19.8779 8.8725 19.8779 8.8725 19.8779 8.8725L17.5 6.49417V4.16667C17.5 4.1575 17.4954 4.14958 17.4946 4.14042C17.4921 4.10375 17.485 4.06833 17.4733 4.03333C17.4688 4.02 17.4654 4.00708 17.4592 3.99417C17.4387 3.95 17.4133 3.90792 17.3775 3.87208L13.6275 0.122083C13.5917 0.08625 13.5496 0.0604167 13.505 0.04C13.4925 0.0345833 13.48 0.0308333 13.4675 0.0266667C13.4317 0.0141667 13.395 0.00708333 13.3575 0.00458333C13.3492 0.00416667 13.3417 0 13.3333 0H2.91667C2.68667 0 2.5 0.186667 2.5 0.416667V6.49417L0.122083 8.87208C0.122083 8.87208 0.121667 8.8725 0.12125 8.87292C0.08625 8.90792 0.06125 8.94875 0.04125 8.99167C0.0354167 9.00458 0.0320833 9.0175 0.0275 9.03083C0.015 9.06667 0.0075 9.10292 0.00541667 9.14083C0.00458333 9.15 0 9.1575 0 9.16667V17.0833C0 17.3133 0.186667 17.5 0.416667 17.5H2.5V19.5833C2.5 19.8133 2.68667 20 2.91667 20H17.0833C17.3133 20 17.5 19.8133 17.5 19.5833V17.5H19.5833C19.8133 17.5 20 17.3133 20 17.0833V9.16667C20 9.1575 19.9954 9.15 19.9946 9.14083ZM18.5775 8.75H17.5V7.6725L18.5775 8.75ZM16.0775 3.75H13.75V1.4225L16.0775 3.75ZM3.33333 0.833333H12.9167V4.16667C12.9167 4.39667 13.1033 4.58333 13.3333 4.58333H16.6667V6.66667V8.75H3.33333V6.66667V0.833333ZM2.5 7.6725V8.75H1.4225L2.5 7.6725ZM16.6667 19.1667H3.33333V17.5H16.6667V19.1667ZM19.1667 16.6667H0.833333V9.58333H2.91667H17.0833H19.1667V16.6667Z"
      fill="black"
    />
    <path
      d="M7.6059 11.1334C7.47131 11.0179 7.31923 10.9363 7.14881 10.8892C6.97881 10.8421 6.8134 10.8184 6.65298 10.8184H5.26465V15.8334H6.10048V13.8217H6.69548C6.87007 13.8217 7.04006 13.7909 7.20548 13.7296C7.3709 13.6684 7.51715 13.5738 7.64465 13.4463C7.77215 13.3188 7.87465 13.1596 7.95298 12.9679C8.0309 12.7767 8.07007 12.5538 8.07007 12.2988C8.07007 12.0154 8.02631 11.7792 7.93881 11.5904C7.85131 11.4017 7.74048 11.2492 7.6059 11.1334ZM7.07132 12.9188C6.94382 13.0579 6.7809 13.1275 6.58257 13.1275H6.1009V11.5338H6.58257C6.7809 11.5338 6.94382 11.595 7.07132 11.7179C7.19882 11.8409 7.26257 12.0367 7.26257 12.3059C7.26257 12.575 7.19882 12.7792 7.07132 12.9188Z"
      fill="black"
    />
    <path
      d="M12.8008 15.8334H13.6579V13.68H14.9399V12.9504H13.6512V11.5834H15.0462V10.8184H12.8008V15.8334Z"
      fill="black"
    />
    <path
      d="M10.0313 10.8184H8.77051V15.8334H10.0243C10.6713 15.8334 11.1613 15.615 11.4943 15.1784C11.8272 14.7413 11.9934 14.1263 11.9934 13.3329C11.9934 12.5396 11.8272 11.9221 11.4943 11.4804C11.1613 11.0392 10.6738 10.8184 10.0313 10.8184ZM11.0622 14.1013C11.008 14.3213 10.9309 14.5042 10.8322 14.6504C10.733 14.7967 10.6151 14.9067 10.478 14.9796C10.3409 15.0529 10.1922 15.0896 10.0318 15.0896H9.62801V11.5621H10.0318C10.1922 11.5621 10.3409 11.5988 10.478 11.6721C10.6151 11.745 10.733 11.855 10.8322 12.0013C10.9313 12.1475 11.008 12.3317 11.0622 12.5538C11.1163 12.7759 11.1438 13.0354 11.1438 13.3329C11.1438 13.6259 11.1163 13.8817 11.0622 14.1013Z"
      fill="black"
    />
  </svg>
);
export default PDFIcon;
