import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { CustomSheet, Worksheet } from "pages/Workspace/types";
import React, { useCallback } from "react";
import styled from "styled-components";
import Comments from "../../components/Comments";
import { useComments } from "../../components/Comments/hooks";

type Props = {
  tab: Worksheet | CustomSheet;
};

const Container = styled.div`
  border-left: 1px solid #d8d8d8;
  box-shadow: -0.25rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05);
  display: flex;
  position: absolute;
  right: -18.75rem;
  top: 0;
  bottom: 0;
  transition: right 0.25s ease-out;
  width: 18.75rem;
  z-index: 10;

  &.show {
    right: 0;
  }
`;
const CommentsPanel: React.FC<Props> = ({ tab }) => {
  const { show, onClose } = useComments();

  const handleCommentsCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Container className={clsx({ show })}>
      <Comments tab={tab} onClose={handleCommentsCloseClick} />
    </Container>
  );
};

export default observer(CommentsPanel);
