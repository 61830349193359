import { CSVHeaderMapping } from ".";
import { scaledChars as _ } from "../../utils";

export const mappingRequirements: CSVHeaderMapping = {
  uniqueRef: ["Unique\nRef", _(8)],
  destinationCluster: ["Dest\nCLT", _(10)],
  destinationCountry: ["Dest\nCountry", _(12)],
  deliveryLocation: ["Delivery\nLocation", _(15)],
  deliveryTerms: ["Delivery\nTerms", _(8)],
  productSpecification: ["Product\nSpec", _(20)],
  packSize: ["Pack Size\nTo Offer", _(15)],
  packageType: ["Pack Type\n(if reqd)", _(8)],
  volumeYear1: ["Volume\n(Yr 1)", _(9)],
  volumeYear2: ["Volume\n(Yr 2)", _(9)],
  volumeYear3: ["Volume\n(Yr 3)", _(9)],
  volumeYear4: ["Volume\n(Yr 4)", _(9)],
  volumeYear5: ["Volume\n(Yr 5)", _(9)],
  packSizeRfq: ["Pack Size\nRFI/RFQ", _(15)],
  altScenario: ["Alt\nScenario", _(8)],
};

export const mappingPriceAssumptions: CSVHeaderMapping = {
  is_locked: ["Locked", _(8)],
  rcode: ["R Code", _(10)],
  raw_material_desc: ["Supplier\nProduct Name", _(30)],
  plant: ["Source\nPlant", _(12)],
  pack_type: ["Bulk\nDrum", _(12)],
  supplier: ["Supplier", _(15)],
  supply_location: ["Supply\nLocation/Country", _(13)],
  inco_term: ["Inco\nTerm", _(10)],
  currency: ["Purchase\nCurrency", _(12)],
  rm_purchase_price: ["RM\nPrice", _(12)],
  supplier_freight: ["Supplier\nFreight", _(12)],
  "drumming_diff/mt": ["Drumming\nDifferential", _(12)],
  unit_of_measure: ["Unit of\nMeasure", _(12)],
  comments: ["Comments", _(50)],
};
