import { detach, Model, model, prop, rootRef } from "mobx-keystone";

export function makeClusterId(id: number) {
  return `collab/Cluster/${id}`;
}

@model("collab/Cluster")
class Cluster extends Model({
  id: prop<identifier>(),
  name: prop<string>(),
}) {
  getRefId() {
    return makeClusterId(this.id);
  }
}

export const clusterRef = rootRef<Cluster>("collab/ClusterRef", {
  onResolvedValueChange(ref, newCluster, oldCluster) {
    if (oldCluster && !newCluster) {
      detach(ref);
    }
  },
});

export default Cluster;
