import { useDrag, useDrop } from "react-dnd";
import React from "react";
import { HeaderRendererProps } from "react-data-grid";
import HeaderCell from "./HeaderCell";
import { useCombinedRefs } from "../../utils";
import styled from "styled-components";

interface ColumnDragObject {
  key: string;
}

interface DraggableHeaderRendererProps<R, SR>
  extends HeaderRendererProps<R, SR> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
}

const Container = styled.div`
  > div {
    cursor: inherit;
  }
`;

export default function DraggableHeaderCell<R, SR>({
  onColumnsReorder,
  ...props
}: DraggableHeaderRendererProps<R, SR>) {
  const { column } = props;

  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN_DRAG",
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "COLUMN_DRAG",
    drop({ key }: ColumnDragObject) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <Container
      ref={useCombinedRefs(drag, drop)}
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? "#ececec" : "inherit",
        cursor: isDragging ? "grabbing" : "grab",
      }}
    >
      <HeaderCell {...props} />
    </Container>
  );
}
