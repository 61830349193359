import base, { APIDetail, APIList, APISearchParams, config } from "./base";
import { APIProjectFolder } from "./types";

export type APIProjectFolderInput = Partial<
  Omit<APIProjectFolder, "id" | "slug">
>;

export interface APIProjectFolderDetail extends APIDetail<APIProjectFolder> {}
export const detail = (folderSlug: string) =>
  base.get<APIProjectFolderDetail>(`folders/${folderSlug}/`);

export interface APIProjectFolderList extends APIList<APIProjectFolder> {}
export interface APIProjectFolderListSearchParams extends APISearchParams {}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIProjectFolderListSearchParams
) =>
  base.list<APIProjectFolderList, APIProjectFolderListSearchParams>(
    "folders/",
    page,
    limit,
    searchParams
  );

export const create = (input: Partial<APIProjectFolderInput>) =>
  base.post<APIProjectFolderDetail>("folders/", { json: input });

export interface APIProjectFolderUpdateInput
  extends Pick<APIProjectFolderInput, "name"> {}
export const update = (
  projectFolderSlug: string,
  input: Partial<APIProjectFolderInput>
) =>
  base.patch<APIProjectFolderDetail>(`folders/${projectFolderSlug}/`, {
    json: input,
  });

export const destroy = (projectFolderSlug: string) =>
  base.deleteEmpty(`folders/${projectFolderSlug}/`);
