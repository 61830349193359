import dropdownEditorFactory from "components/DataGrid/DropdownEditor";
import multipleDropdownEditorFactory from "components/DataGrid/MultipleDropdownEditor";
import { IRow } from "csv/types";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React from "react";
import { EditorProps, TextEditor } from "react-data-grid";
import { useStore } from "store";

type Props = EditorProps<IRow>;

const FormulationEditor: React.FC<Props> = (props) => {
  const { row, column } = props;
  const store = useStore();
  const project = useProject();

  switch (row.id) {
    case "plantType": {
      const Dropdown = dropdownEditorFactory(column.key, [
        {
          value: "master",
          label: "Master",
        },
        {
          value: "plant",
          label: "Plant",
        },
      ]);
      return <Dropdown {...props} />;
    }
    case "plant":
    case "repackagingPlant": {
      const Dropdown = dropdownEditorFactory(
        column.key,
        store.assets.plantSelect
      );
      return <Dropdown {...props} />;
    }
    case "sourcePlants": {
      const Dropdown = multipleDropdownEditorFactory(
        column.key,
        store.assets.plantSelect
      );
      return <Dropdown {...props} />;
    }
    case "clusters": {
      let clusters = store.assets.clusterSelect;
      if (project) {
        const requirements = store.requirements.ofProject(project.id);
        const projectCountryIds = requirements.map((e) =>
          Number(e.destinationCountry)
        );
        const projectClusterIds = projectCountryIds.map(
          (e) => store.assets.countryListItems.get(e)?.cluster
        );
        clusters = clusters.filter((e) => projectClusterIds.includes(e.value));
      } else {
        clusters = [];
      }
      const Dropdown = multipleDropdownEditorFactory(
        column.key,
        clusters,
        store.assets.clusterSelect,
        true
      );
      return <Dropdown {...props} />;
    }
    case "countries": {
      let countries = store.assets.countrySelect;
      if (project) {
        const requirements = store.requirements.ofProject(project.id);
        let projectCountryIds = requirements.map((e) =>
          Number(e.destinationCountry)
        );
        const formulation = store.formulations.listItems.get(
          Number(column.key)
        );
        if (formulation) {
          const formulationCountries: Array<number> = [
            ...(formulation.asAPI.countries || []),
          ];
          store.assets.countryListItems.forEach((value) => {
            if (formulation.asAPI.clusters?.includes(value.cluster)) {
              formulationCountries.push(value.id);
            }
          });
          projectCountryIds = projectCountryIds.filter((e) =>
            formulationCountries.includes(e)
          );
        }
        countries = countries.filter((e) =>
          projectCountryIds.includes(e.value)
        );
      } else {
        countries = [];
      }
      const Dropdown = multipleDropdownEditorFactory(
        column.key,
        countries,
        store.assets.countrySelect,
        true
      );
      return <Dropdown {...props} />;
    }
    case "packSizes": {
      let packSizes = store.assets.packSizeSelect;
      if (project) {
        const requirements = store.requirements.ofProject(project.id);
        const packSizeIds = requirements.map((e) => Number(e.packSize));
        const currentIds = `${row[column.key]}`
          .split(",")
          .map((e) => Number(e));
        packSizes = packSizes.filter(
          (e) => packSizeIds.includes(e.value) || currentIds.includes(e.value)
        );
      } else {
        packSizes = [];
      }

      const Dropdown = multipleDropdownEditorFactory(
        column.key,
        packSizes,
        store.assets.packSizeSelect,
        true
      );
      return <Dropdown {...props} />;
    }
    case "repackagingPackSizes": {
      const Dropdown = multipleDropdownEditorFactory(
        column.key,
        store.assets.packSizeSelect,
        store.assets.packSizeSelect,
        true
      );
      return <Dropdown {...props} />;
    }
  }
  return <TextEditor {...props} />;
};

export default observer(FormulationEditor);
