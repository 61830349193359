import { ColumnExtraOptions } from "../../../components/DataGrid/types";
import { mappingMarginalCostPctPlant } from "../../../csv/mappings/collect";
import { IRow } from "../../../csv/types";
import { getNumberCell, givenCell, idCell } from "./utils";

const schemaMarginalCostPctPlant = (key: string, sheetEditable?: boolean) => {
  const defaultCell = {
    name: mappingMarginalCostPctPlant[key]
      ? mappingMarginalCostPctPlant[key][0]
      : key,
    width: mappingMarginalCostPctPlant[key]
      ? mappingMarginalCostPctPlant[key][1]
      : 50,
  };
  const numberCellPct = {
    ...defaultCell,
    ...getNumberCell(sheetEditable ?? true, 2, 2, true),
  };
  const givenMarginal = {
    ...defaultCell,
    ...givenCell,
  };

  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: idCell,
    plant: givenMarginal,
    plant_name: givenMarginal,
  };
  return options[key] || numberCellPct;
};

export default schemaMarginalCostPctPlant;
