import { Model, model, prop } from "mobx-keystone";

@model("collab/RMPriceAssumption")
class RMPriceAssumption extends Model({
  id: prop<identifier>(),
  rCode: prop<string>(),
  productName: prop<string>(),
  plantName: prop<string>(),
  vendorName: prop<string>(),
  supplyLocationCountry: prop<string>(),
  incoTerm: prop<string>(),
  currencyCode: prop<string>(),
  rmPurchasePrice: prop<number>(),
  supplyFreight: prop<number>(),
  drummingDifferential: prop<number>(),
  unitOfMeasure: prop<string>(),
}) {}

export default RMPriceAssumption;
