import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import api from "../../api";
import { APIProjectCreateInput } from "../../api/projects";
import { useStore } from "../../store";
import Button from "../../components/Button";
import ProjectNameForm from "./ProjectNameForm";
import { Create } from "@material-ui/icons";
import history from "../../services/history";
import { Toast } from "../../utils";

type Props = {
  slug?: string;
  isOpen: boolean;
  setModal: (visible: boolean) => void;
};

const NewProject: React.FC<Props> = ({ slug, isOpen, setModal }) => {
  const methods = useForm();
  const urlPrefix = "/projects/";
  const store = useStore();

  const handleSubmit = useCallback(
    async (data: APIProjectCreateInput) => {
      try {
        const { id } = await store.projects.create(data, slug);
        history.push(`${urlPrefix}${id}/settings/details/`);
      } catch (errorResponse: any) {
        const { detail } = await api.handleError(errorResponse);
        if (errorResponse.description !== "Unauthorized") {
          if (detail) {
            Object.entries(detail!).forEach(([key, message]) => {
              methods.setError(key as any, {
                message: (message as string[])[0],
              });
            });
          }
          return;
        }
      }
      setModal(false);
      Toast.success("Project Created");
    },
    [urlPrefix, slug, store, methods, setModal]
  );

  return (
    <FormProvider {...methods}>
      <ProjectNameForm
        isOpen={isOpen}
        setModal={setModal}
        headerText="Create New Project"
        submitButton={
          <Button type="submit">
            <Create /> Create
          </Button>
        }
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
};

export default observer(NewProject);
