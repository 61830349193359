import { CloudUploadOutlined } from "@material-ui/icons";
import React, { useRef } from "react";
import styled from "styled-components";
import { convertFileToBase64 } from "../../utils";
import Button from "../Button";

const StyledInput = styled.input`
  visibility: hidden;
  position: absolute;
  left: -999999999;
`;

type Props = {
  onFileUpload: (file: string) => Promise<void>;
  showButtonText?: boolean;
};

const UploadButton: React.FC<Props> = ({
  onFileUpload,
  showButtonText = true,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const handleUploadClick = async () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      const file = await convertFileToBase64(e.target.files[0]);
      await onFileUpload(file);
    }
    if (fileRef?.current) {
      fileRef.current.value = "";
    }
  };

  return (
    <Button size="thin" onClick={handleUploadClick}>
      <CloudUploadOutlined /> {showButtonText && "Upload CSV/Excel File"}
      <StyledInput
        type="file"
        accept=".csv,.xlsx,.xls,.xlsm,"
        ref={fileRef}
        onChange={handleFileUpload}
      />
    </Button>
  );
};

export default UploadButton;
