import {
  Model,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import api from "../api";
import {
  APIDefineSheet,
  APIDefineSheetFileType,
  APIDefineSheetSearchParams,
} from "../api/defineSheets";
import DefineSheet from "./models/DefineSheet";

@model("collab/DefineSheetStore")
class DefineSheetStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, DefineSheet>()),
}) {
  @modelAction
  saveListItem(item: APIDefineSheet) {
    const listItem = new DefineSheet(item);
    const existing = this.listItems.get(item.id);
    if (!existing) {
      this.listItems.set(item.id, listItem);
      return listItem;
    }

    if (!existing.isDirty) {
      existing.update(listItem);
    }
    return existing;
  }

  @modelFlow
  list = _async(function* (
    this: DefineSheetStore,
    projectId: identifier,
    page?: number,
    limit?: number,
    searchParams?: APIDefineSheetSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(
      api.defineSheets.list(page, limit, {
        ...searchParams,
        project: projectId,
      })
    );

    // Clear cached items matching the search params
    Array.from(this.listItems.entries())
      .filter(([id, item]) => item.project === projectId)
      .forEach(([id]) => {
        this.listItems.delete(id);
      });

    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  ofProject(projectId: identifier) {
    const perType = new Map<APIDefineSheetFileType, DefineSheet[]>();
    Array.from(this.listItems.values())
      .sort((a, b) => (a.clusterName || "").localeCompare(b.clusterName || ""))
      .filter((sheet) => sheet.project === projectId)
      .forEach((sheet) => {
        if (!perType.has(sheet.fileType)) {
          perType.set(sheet.fileType, []);
        }
        perType.get(sheet.fileType)!.push(sheet);
      });
    return perType;
  }

  @modelFlow
  saveAll = _async(function* (this: DefineSheetStore, projectId: identifier) {
    const sheetsGrouped = this.ofProject(projectId);
    const sheets = Array.from(sheetsGrouped.values()).reduce(
      (acc, cur) => [...acc, ...cur],
      []
    );
    yield* _await(Promise.all(sheets.map((sheet) => sheet.save())));
    yield* _await((async () => {})());
  });
}

export default DefineSheetStore;
