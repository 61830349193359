import { Refresh } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React from "react";
import { Toast } from "utils";
import { useProject } from "../../pages/Workspace/hooks";
import Button from "../Button";

type Props = {
  text?: string;
  onClick?: () => Promise<void>;
};

const GenerateOutputsButton: React.FC<Props> = ({
  text = "Update outputs",
  onClick,
}) => {
  const project = useProject();

  const handleUpdateOutputs = async () => {
    if (!project) {
      return;
    }

    try {
      await project.generateOutputs();
    } catch (e) {
      Toast.danger("Unable to generate outputs.");
      return;
    }
    await onClick?.();
    Toast.success("Outputs updated");
  };

  return (
    <Button size="thin" onClick={handleUpdateOutputs}>
      <Refresh /> {text}
    </Button>
  );
};

export default observer(GenerateOutputsButton);
