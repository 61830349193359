import queryString from "query-string";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";
import Loading from "../../components/Loading";
import { useStore } from "../../store";

interface SearchParams {
  error?: string;
  error_description?: string;
  code?: string;
  session_state?: string;
  state?: string;
}

const AuthCallback: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const store = useStore();
  const parameters: SearchParams = queryString.parse(location.search);

  useEffectOnce(() => {
    if (parameters.error) {
      history.push({
        pathname: "/login",
        state: {
          authError: {
            error: parameters.error,
            errorDescription: parameters.error_description,
          },
        },
      });
    } else if (parameters.code) {
      (async () => {
        try {
          await store.auth.login(parameters.code);
          history.push("/");
        } catch (error: any) {
          const authError = error.error ? error : { error };
          history.push({
            pathname: "/login",
            state: {
              authError,
            },
          });
        }
      })();
    }
  });

  return <Loading full showLogo />;
};

export default AuthCallback;
