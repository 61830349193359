const FileIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99967 1.6665H11.6663L16.6663 6.6665V16.6665C16.6663 17.5832 15.9163 18.3332 14.9997 18.3332H4.99134C4.07467 18.3332 3.33301 17.5832 3.33301 16.6665L3.34134 3.33317C3.34134 2.4165 4.08301 1.6665 4.99967 1.6665ZM4.99967 3.33317V16.6665H14.9997V7.49984H10.833V3.33317H4.99967Z"
      fill="black"
    />
  </svg>
);
export default FileIcon;
