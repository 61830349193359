import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import Button from "../../components/Button";
import history from "../../services/history";

type MenuItem = {
  icon: any;
  text: string;
  link: string;
};

type Props = {
  items: MenuItem[];
  urlPrefix: string;
};

const RootContainer = styled.div`
  background-color: #ffffff;
  border-right: 1px solid #d8d8d8;
  height: 100%;
  padding: 1.25rem 0.3125rem;
  width: 5rem;
`;

const SidebarTabButton = styled(Button)`
  &.btn {
    display: block;
    height: 4.5rem;
    line-height: 1.4;
    margin-bottom: 1.25rem;
    padding: 0 0.15rem;
    text-align: center;
    width: 100%;

    svg {
      display: block;
      margin: 0 auto 0.3125rem auto;
    }
  }

  &.btn-primary {
    border-color: transparent;

    &:hover,
    &:not(:disabled).active,
    &:not(:disabled):active {
      border-color: transparent;
      color: #439b34;
    }

    &:not(:disabled).active,
    &:not(:disabled):active {
      background: #74b469;
      color: #ffffff;
    }
  }
`;

const Sidebar: React.FC<Props> = ({ items, urlPrefix }) => {
  const location = useLocation();

  const handleClick = (page: string) => {
    history.push(`${urlPrefix}${page}`);
  };

  return (
    <RootContainer>
      {items.map((menuItem: MenuItem) => (
        <SidebarTabButton
          key={menuItem.link}
          active={
            location.pathname.endsWith(`${urlPrefix}${menuItem.link}`)
              ? "active"
              : ""
          }
          onClick={() => handleClick(menuItem.link)}
        >
          <menuItem.icon />
          {menuItem.text}
        </SidebarTabButton>
      ))}
    </RootContainer>
  );
};

export default observer(Sidebar);
