import { toast, Slide, ToastContent } from "react-toastify";

export class Toast {
  static success(content: ToastContent, toastId?: string) {
    return toast.success(content, {
      toastId,
      transition: Slide,
    });
  }

  /** Shows a toast message styled as a "danger" or an "error" message. */
  static danger(content: ToastContent, toastId?: string) {
    return toast.error(content, {
      toastId,
      transition: Slide,
    });
  }
}
