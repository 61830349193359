import {
  Chip,
  createTheme,
  MuiThemeProvider,
  TextField,
} from "@material-ui/core";
import {
  AttachFileOutlined,
  Close,
  DeleteOutlined,
  SendOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import FolderStar from "icons/FolderStar";
import ChipInput from "material-ui-chip-input";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { FormProvider, useForm } from "react-hook-form";
import { useClickAway } from "react-use";
import Project from "store/models/Project";
import styled from "styled-components";
import { convertFileToBase64, Toast } from "utils";
import validator from "validator";
import { DEFAULT_TO_EMAILS } from ".";
import Button from "../Button";
import Editor from "../Editor";
import { Header, SubHeader } from "../Header";

const HelpHeader = styled(Header)`
  max-width: 12rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.5rem;
  text-transform: capitalize;
`;

const HelpSubHeader = styled(SubHeader)`
  font-weight: bold;
  letter-spacing: 0.00625rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  user-select: none;
  opacity: 0.6;
  text-transform: none;
`;

const DocumentationLinkContainer = styled(SubHeader)`
  font-weight: semi-bold;
  letter-spacing: 0.00625rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  user-select: none;
  opacity: 0.8;
  text-transform: none;
  cursor: pointer;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const FooterLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  gap: 1rem;
`;

const EmailHeadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  gap: 1rem;
  border-bottom: 2px solid #d8d8d8;
`;

const CustomSendOutlined = styled(SendOutlined)`
  margin-bottom: 0.21rem;
`;

const AttachFileIcon = styled(AttachFileOutlined)`
  transform: rotate(90deg);
`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#484848",
    },
  },
});

type Props = {
  onSubmit: (data: any) => void;
  onHide: () => void;
  show: boolean;
  project: Project;
  toEmails: string[];
  setToEmails: (toEmails: string[]) => void;
  ccEmails: string[];
  setCcEmails: (ccEmails: string[]) => void;
  showCC: boolean;
  setShowCC: (showCC: boolean) => void;
  subject: string;
  setSubject: (subject: string) => void;
  body: string;
  setBody: (body: string) => void;
  onDiscard: () => void;
  file?: string;
  setFile: (file: string) => void;
  fileName?: string;
  setFileName: (s: string) => void;
};

const Help: React.FC<Props> = ({
  onSubmit,
  onHide,
  project,
  toEmails,
  setToEmails,
  ccEmails,
  setCcEmails,
  showCC,
  setShowCC,
  subject,
  setSubject,
  body,
  setBody,
  file,
  setFile,
  fileName,
  setFileName,
  onDiscard,
}) => {
  const [toInput, setToInput] = useState("");
  const [ccInput, setCcInput] = useState("");
  const toRef = useRef(null);
  const ccRef = useRef(null);

  useClickAway(toRef, () => {
    if (toInput) {
      setToEmails([...toEmails, toInput]);
      setToInput("");
    }
  });

  useClickAway(ccRef, () => {
    if (ccInput) {
      setCcEmails([...ccEmails, ccInput]);
      setCcInput("");
    }
  });

  const methods = useForm({
    defaultValues: {
      body: body,
      subject: subject,
    },
  });
  const bodyInput = methods.watch("body");
  const subjectInput = methods.watch("subject");

  useEffect(() => {
    setBody(bodyInput);
  }, [bodyInput, setBody]);

  useEffect(() => {
    setSubject(subjectInput);
  }, [subjectInput, setSubject]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const fileSizeLimit = 10485760;

  const handleFileInputClick = async () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChangeFile = async (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    if (target?.files && target.files[0]?.size < fileSizeLimit) {
      const encodedFile = (await convertFileToBase64(
        target.files[0] as File | Blob
      )) as string;
      setFile(encodedFile);
      setFileName(target?.files[0]?.name);
    } else {
      Toast.danger("File size must be less than 10MB");
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Header className={clsx("d-flex", "justify-content-between")}>
          <Modal.Title>
            <HelpHeader>Send Message</HelpHeader>
            <HelpSubHeader>
              The purpose of this form is to collect feedback and answer any
              questions you might have about Collab. Please note that questions
              concerning individual tenders should be directed towards project
              creators.{" "}
            </HelpSubHeader>
            <DocumentationLinkContainer>
              <Button
                variant="docsLink"
                target="_blank"
                href="https://bp365.sharepoint.com/:f:/s/CastrolCollab/Eufwxrc7LK1EgRI3TMDzZ5EBW4e2SfqhlaBcUfLMZfX-TA"
              >
                <FolderStar />
                Documentation and Training Materials
              </Button>
            </DocumentationLinkContainer>
          </Modal.Title>
          <Button variant="borderless" onClick={onHide}>
            <Close />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <MuiThemeProvider theme={theme}>
            <InputGroup>
              <EmailHeadContainer ref={toRef}>
                <ChipInput
                  aria-multiline
                  disableUnderline
                  label="To: "
                  fullWidth
                  value={toEmails}
                  onAdd={(chip) => {
                    setToEmails([...toEmails, chip]);
                    setToInput("");
                  }}
                  onUpdateInput={(e) => {
                    setToInput(e.target.value);
                  }}
                  onDelete={(chip) =>
                    setToEmails(
                      toEmails.filter(
                        (email) =>
                          email !== chip || DEFAULT_TO_EMAILS.includes(chip)
                      )
                    )
                  }
                  newChipKeyCodes={[13, 32, 9]}
                  chipRenderer={(
                    {
                      value,
                      text,
                      isFocused,
                      isDisabled,
                      isReadOnly,
                      handleClick,
                      handleDelete,
                      className,
                    },
                    key
                  ) => {
                    const bg = validator.isEmail(value) ? undefined : "#E99F5B";
                    return (
                      <Chip
                        key={key}
                        className={className}
                        style={{
                          pointerEvents:
                            isDisabled || isReadOnly ? "none" : undefined,
                          backgroundColor: isFocused ? "#b8b8b8" : bg,
                        }}
                        onClick={handleClick}
                        onDelete={handleDelete}
                        label={text}
                      />
                    );
                  }}
                />
                <Button variant="thin" onClick={() => setShowCC(!showCC)}>
                  {"Cc"}
                </Button>
              </EmailHeadContainer>
            </InputGroup>
            {showCC && (
              <EmailHeadContainer ref={ccRef}>
                <ChipInput
                  aria-multiline
                  disableUnderline
                  label="Cc: "
                  fullWidth
                  value={ccEmails}
                  onAdd={(chip) => {
                    setCcEmails([...ccEmails, chip]);
                    setCcInput("");
                  }}
                  onUpdateInput={(e) => {
                    setCcInput(e.target.value);
                  }}
                  onDelete={(chip) =>
                    setCcEmails(ccEmails.filter((emails) => emails !== chip))
                  }
                  newChipKeyCodes={[13, 32, 9]}
                  chipRenderer={(
                    {
                      value,
                      text,
                      isFocused,
                      isDisabled,
                      isReadOnly,
                      handleClick,
                      handleDelete,
                      className,
                    },
                    key
                  ) => {
                    const bg = validator.isEmail(value) ? undefined : "#E99F5B";
                    return (
                      <Chip
                        key={key}
                        className={className}
                        style={{
                          pointerEvents:
                            isDisabled || isReadOnly ? "none" : undefined,
                          backgroundColor: isFocused ? "#b8b8b8" : bg,
                        }}
                        onClick={handleClick}
                        onDelete={handleDelete}
                        label={text}
                      />
                    );
                  }}
                />
              </EmailHeadContainer>
            )}
            <EmailHeadContainer>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth
                label="Subject: "
                placeholder="Subject"
                multiline
                variant="standard"
                color="primary"
                {...methods.register("subject", {
                  required: true && "Subject is required",
                })}
              />
            </EmailHeadContainer>
          </MuiThemeProvider>
          <Editor name="body" size="md" readOnly={project.loading} />
        </Modal.Body>
        <Modal.Footer>
          <FooterContainer>
            <FooterLeftContainer>
              <Button
                type="submit"
                variant="inverted"
                disabled={project.loading && methods.formState.isSubmitting}
              >
                <CustomSendOutlined /> Send
              </Button>
              <Button
                type="button"
                variant="borderless"
                size="thin"
                onClick={handleFileInputClick}
              >
                <AttachFileIcon />
                <input
                  ref={hiddenFileInput}
                  type="file"
                  onChange={handleChangeFile}
                  style={{ display: "none" }}
                  multiple
                />
                {fileName}
              </Button>
            </FooterLeftContainer>
            <Button type="button" variant="borderless" onClick={onDiscard}>
              <DeleteOutlined />
            </Button>
          </FooterContainer>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};
export default observer(Help);
