import { computed, observable } from "mobx";
import { Model, model, modelAction, prop } from "mobx-keystone";

@model("collab/Dirtyable")
class DirtyableWithId extends Model({
  id: prop<identifier>(),

  /** Is set to `true` if the model is manually set to be dirty. */
  manualDirty: prop<boolean>(false),
}) {
  /** The set of field names that have been marked as dirty. */
  @observable
  private dirtyFields = new Set<string>();

  @computed
  get isDirty() {
    return this.manualDirty || this.id < 0 || this.dirtyFields.size > 0;
  }

  /**
   * Returns only dirty fields that can be passed to calls to `api` functions.
   */
  @computed
  get asAPIChanged() {
    const apiData: { [k: string]: string | number | string[] | number[] } =
      Array.from(this.dirtyFields).reduce(
        (acc, key) => ({
          ...acc,
          // @ts-ignore
          [key]: this[key],
        }),
        {}
      );
    if (this.id > 0) {
      apiData.id = this.id;
    }
    return apiData;
  }

  /**
   * Sets an arbitrary field of this model and marks the entire model dirty.
   *
   * @param key the name of the property
   * @param value the value of the property
   * @param setDirty mark the key as dirty
   */
  @modelAction
  setValue(key: string, value: any, setDirty: boolean = true) {
    // @ts-ignore
    if (this[key] !== value) {
      // @ts-ignore
      this[key] = value;
    }
    if (setDirty) {
      this.dirtyFields.add(key);
    }
  }

  /**
   * Updates the data in this model and unmarks the same as dirty.
   *
   * @param data data to replace contents of this model with
   */
  @modelAction
  update(data: { [k: string]: any }) {
    Object.keys(data).forEach((key) => {
      // @ts-ignore
      this[key] = data[key];
    });
    this.setNotDirty();
  }

  @modelAction
  setDirty() {
    this.setValue("manualDirty", true, true);
  }

  @modelAction
  setNotDirty() {
    this.setValue("manualDirty", false, false);
    this.dirtyFields.clear();
  }
}

export default DirtyableWithId;
