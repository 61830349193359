import { DeleteOutlined, LockOutlined } from "@material-ui/icons";
import { formatDistanceToNow, parseISO } from "date-fns";
import { createConfirmation } from "react-confirm";
import ConfirmModal from "../components/ConfirmModal";
import DismissDataModal from "../components/DismissDataModal";
import DismissModal from "../components/DismissModal";

const confirm = createConfirmation(ConfirmModal);
const dismiss = createConfirmation(DismissModal);
const dismissData = createConfirmation(DismissDataModal);

export function confirmProjectLock(issueDate: string | null, version: number) {
  return confirm({
    subtitle: "Lock Project",
    rightSubtext: `Version ${version}`,
    title: "Lock",
    rightText: issueDate
      ? `Model Issued ${formatDistanceToNow(parseISO(issueDate))} ago`
      : "Model not issued yet",
    confirmation:
      "This will lock the project for all CLTs and functions. No further edits can be made once this project is locked.",
    okLabel: (
      <>
        <LockOutlined /> Lock
      </>
    ),
  });
}

export function confirmCLTLock(
  clt: string,
  issueDate: string,
  version: number
) {
  return confirm({
    subtitle: "Lock Project",
    rightSubtext: `Version ${version}`,
    title: "Lock",
    rightText: `Model Issued ${formatDistanceToNow(parseISO(issueDate))} ago`,
    confirmation: `This will lock the project for ${clt}. No further edits can be made once this project is locked.`,
    okLabel: (
      <>
        <LockOutlined /> Lock
      </>
    ),
  });
}

export function confirmDeleteFolder(folderName: string) {
  return confirm({
    subtitle: "Delete Folder",
    title: folderName,
    confirmation:
      "Folders are shared across users. Are you sure you want to delete this folder?",
    okLabel: (
      <>
        <DeleteOutlined /> Delete{" "}
      </>
    ),
  });
}

export function confirmDeleteComment() {
  return confirm({
    title: "Comment",
    confirmation: "Are you sure you want to delete this comment?",
    okLabel: (
      <>
        <DeleteOutlined /> Delete{" "}
      </>
    ),
  });
}

export function dismissProjectLock() {
  return dismiss({
    subtitle: "Lock",
    dismission: "This project has been locked by the project owner.",
  });
}

export function dismissProjectUnlock() {
  return dismiss({
    subtitle: "Unlock",
    dismission: "This project has been unlocked by the project owner.",
  });
}

export function dismissProjectSubmitted() {
  return dismissData({
    subtitle: "Save",
    dismission: "Data submitted",
  });
}

export function dismissProjectApproved() {
  return dismissData({
    subtitle: "Save",
    dismission: "Data approved",
  });
}

export function dismissProjectRejected() {
  return dismissData({
    subtitle: "Save",
    dismission: "Data rejected and sent back for revisions",
  });
}

export function dismissCLTApproved() {
  return dismiss({
    subtitle: "Approved",
    dismission: "This project has been approved by the CLT Reviewer.",
  });
}

export function dismissCLTRejected() {
  return dismiss({
    subtitle: "Rejected",
    dismission: "This project has been rejected by the CLT Reviewer.",
  });
}
