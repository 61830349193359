import api from "api";
import { APIOutputSheetFileType, APIOutputSheetInput } from "api/outputSheets";
import { computed } from "mobx";
import {
  ExtendedModel,
  getRootStore,
  model,
  modelFlow,
  prop,
  _async,
  _await,
} from "mobx-keystone";
import { RootStore } from "..";
import Sheet, { SheetType } from "./Sheet";

@model("collab/OutputSheet")
class OutputSheet extends ExtendedModel(Sheet, {
  fileType: prop<APIOutputSheetFileType>(),
  specs: prop<string>(""),
  viscosity: prop<string>(""),
  plant: prop<string>(""),
  countryNames: prop<string[]>(() => []),
  notes: prop<string>(""),
}) {
  get sheetType(): SheetType {
    return "outputsheet";
  }

  @computed
  get asAPI(): APIOutputSheetInput {
    return {
      notes: this.notes,
    };
  }

  @modelFlow
  save = _async(function* (this: OutputSheet) {
    const rootStore = getRootStore<RootStore>(this);

    if (!rootStore) {
      return false;
    }

    if (!this.isDirty) {
      return false;
    }

    try {
      const response = yield* _await(
        api.outputSheets.update(this.id, this.csvString, this.asAPI)
      );
      this.update(response);
    } catch (e) {
      throw e;
    }

    return true;
  });
}

export default OutputSheet;
