import base, { APIDetail, APIList, config } from "./base";

export interface APICLTFile {
  id: identifier;
  created: string;
  modified: string;
  project: identifier;
  name: string;
  file: string;
  cluster: identifier;
  clusterName: string;
  sheetsStatus: number;
  inputSheet: number;
  isCltLocked: boolean;
  isCltApproved: boolean;
  isCltSubmitted: boolean;
  isEditable: boolean;
}

export type APICLTFileInput = Partial<
  Omit<
    APICLTFile,
    | "id"
    | "created"
    | "modified"
    | "project"
    | "cluster"
    | "clusterName"
    | "isCltLocked"
    | "isCltApproved"
    | "isCltSubmitted"
    | "isEditable"
  >
>;

type APICLTFileDetail = APIDetail<APICLTFile>;

export type APICLTFileList = APIList<APICLTFile>;

export type APICLTFileSearchParams = {
  project?: number;
};

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APICLTFileSearchParams
) =>
  base.list<APICLTFileList, APICLTFileSearchParams>(
    "clt-files/",
    page,
    limit,
    searchParams
  );

export const create = (data: APICLTFileInput) =>
  base.post<APICLTFileDetail>("clt-files/", { json: data });

export const destroy = (id: identifier) => base.deleteEmpty(`clt-files/${id}/`);
