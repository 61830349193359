import React from "react";
import { SelectCellFormatter as RDGSelectCellFormatter } from "react-data-grid";
import styled from "styled-components";
import { GetComponentProps } from "../../utils/types";

const Container = styled.span`
  .rdg-checkbox-label {
    margin-bottom: 0;
  }

  .rdg-checkbox {
    border-color: #000000;
    border-radius: 3px;
    border-width: 2px;
    width: 0.9375rem;
    height: 0.9375rem;
  }

  .rdg-checkbox-input {
    &:checked + .rdg-checkbox {
      box-shadow: inset 0 0 0 2px var(--background-color);
    }
  }
`;

const SelectCellFormatter: React.FC<
  GetComponentProps<typeof RDGSelectCellFormatter>
> = (props) => {
  return (
    <Container>
      <RDGSelectCellFormatter {...props} />
    </Container>
  );
};

export default SelectCellFormatter;
