import base from "./base";

export type APITicketInput = {
  project: number;
  user?: number;
  subject: string;
  recipients: string;
  ccRecipients?: string;
  body: string;
  file?: string;
  fileName?: string;
  fileType?: string;
};

export const create = (input: APITicketInput) => {
  return base.post<APITicketInput>(`support/submit-ticket/`, { json: input });
};
