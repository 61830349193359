import { computed } from "mobx";
import { Model, model, modelAction, prop_setArray, Ref } from "mobx-keystone";
import Cluster, { clusterRef, makeClusterId } from "./Cluster";

@model("collab/ProjectVersionFilter")
class ProjectVersionFilter extends Model({
  clusters: prop_setArray(() => new Set<Ref<Cluster>>()),
}) {
  @computed
  get isFiltering() {
    return this.clusters.size > 0;
  }

  @modelAction
  setClusters(clusterIds: identifier[]) {
    this.clusters.clear();
    clusterIds.forEach((id) =>
      this.clusters.add(clusterRef(makeClusterId(id)))
    );
  }

  @modelAction
  reset() {
    this.clusters.clear();
  }

  @computed
  get selectedClusterIds() {
    return Array.from(this.clusters).map((ref) => ref.current.id);
  }

  @computed
  get selectedClusters() {
    return Array.from(this.clusters)
      .filter((ref) => ref.isValid)
      .map((ref) => ({
        value: ref.maybeCurrent?.id || 0,
        label: ref.maybeCurrent?.name || "?",
      }));
  }
}

export default ProjectVersionFilter;
