import { AddBoxOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import Button from "../Button";

type Props = {
  onAddFormulations: (formulationAddCount: number) => void;
};

const RootContainer = styled.form`
  cursor: default;
  display: inline-block;
  filter: drop-shadow(0.25rem 0.25rem 0.3125rem rgba(0, 0, 0, 0.02));
  font-size: 0.625rem;
  font-weight: bold;
  letter-spacing: normal;
  min-height: 3rem;
  line-height: 1.5rem;
  padding: 0.25rem;
  margin-left: 1rem;
  text-transform: uppercase;

  input {
    border: 1px solid #cdcdcd;
    border-radius: 2px;
    display: inline-block;
    margin: 0 0.75rem;
    height: 1.75rem;
    width: 4.25rem;
  }

  .btn {
    margin-bottom: 0.3rem;

    svg {
      margin-right: 0;
    }
  }
`;

const AddFormulations: React.FC<Props> = ({ onAddFormulations }) => {
  const addFormulationsRef = useRef<HTMLInputElement>(null);
  const handleCustomAddFormulations = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const toAdd = parseInt(addFormulationsRef.current?.value || "0", 10);
      onAddFormulations?.(toAdd);
    },
    [onAddFormulations]
  );

  return (
    <RootContainer
      onSubmit={handleCustomAddFormulations}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
    >
      Add{" "}
      <input
        type="number"
        min={1}
        defaultValue={1}
        ref={addFormulationsRef}
        required
      />{" "}
      Formulas
      <Button type="submit" variant="borderless" size="thin">
        <AddBoxOutlined />
      </Button>
    </RootContainer>
  );
};

export default observer(AddFormulations);
