import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Column, ValueFormatter } from "react-data-grid";
import { RowsChangeData } from "react-data-grid/lib";
import { useAsync } from "react-use";
import api from "../../api";
import { Cell, IRow } from "../../csv/types";
import {
  parseColumns,
  parseRows,
  tsvToArray,
  unparseRows,
} from "../../csv/utils";
import {
  CSVToDataGridOptions,
  Position,
  RangeSelection,
  ValueParsers,
} from "./types";
import { getBoundingPositions, getDataColumnsFromSchema } from "./utils";

// TODO Move to a better place in the code base.
export function useCsvToGridDataAsync(
  options?: CSVToDataGridOptions
): [
  Column<IRow>[],
  IRow[],
  boolean,
  (rows: IRow[], data?: RowsChangeData<IRow, unknown>) => void
] {
  const [loading, setLoading] = useState<boolean>(true);
  const [columns, setColumns] = useState<Column<IRow>[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);

  const opts = options || {};
  const { csvString, sheet, editable, schema } = opts;

  useAsync(async () => {
    let rawData = "";

    if (csvString) {
      rawData = csvString;
    } else if (sheet?.file) {
      const response = await api.csv.get(sheet.file);
      rawData = await response.text();
    } else {
      setColumns([]);
      setRows([]);
      setLoading(false);
      return;
    }

    let rawColumns = parseColumns(rawData);
    if (
      sheet?.sheetType === "definesheet" &&
      // @ts-expect-error
      sheet?.fileType === "rm_price_assumptions" &&
      !rawColumns.includes("is_locked")
    ) {
      rawColumns = ["is_locked", ...rawColumns];
    }
    if (!rawColumns.includes("id")) {
      rawColumns = ["id", ...rawColumns];
    }
    const dataColumns: Column<IRow>[] = getDataColumnsFromSchema(
      rawColumns,
      editable,
      schema
    );

    const dataRows = parseRows<IRow>(rawData);

    dataRows.forEach((row, index) => {
      if (!row.id) {
        row.id = index + 1;
      }
      return row;
    });

    setColumns(dataColumns);
    setRows(dataRows);
    setLoading(false);
  }, [sheet, sheet?.file, csvString, editable, schema]);

  const loaded = useMemo(() => !loading, [loading]);
  return [columns, rows, loaded, setRows];
}

export function useRangeSelection(rowLength: number, colLength: number) {
  const [selected, setSelected] = useState<Position>();
  const [range, setRange] = useState<RangeSelection>();
  const [selectionType, setSelectionType] = useState<string>("");

  // Move selection if columns or rows are deleted and selection is at the end.
  useEffect(() => {
    if (!selected) {
      return;
    }
    if (selected.idx >= colLength || selected.rowIdx >= rowLength) {
      setSelected(undefined);
    }
  }, [colLength, rowLength, selected]);

  const handleSelectedChange = useCallback((selection: Position) => {
    setSelected(selection);
  }, []);

  const handleColumnSelect = useCallback((idx: number) => {
    const start: Position = { idx, rowIdx: 1 };
    const end: Position = { idx, rowIdx: 1 };
    setSelected(start);
    setRange({ start, end });
  }, []);

  const selectionTools = useMemo(
    () => ({
      range,
      setRange,
      selected,
      setSelected: handleSelectedChange,
      selectionType,
      setSelectionType,
      onColumnSelect: handleColumnSelect,
    }),
    [handleColumnSelect, handleSelectedChange, range, selected, selectionType]
  );

  return selectionTools;
}

export function useClipboard(
  isActive: boolean,
  rows: readonly IRow[],
  filteredRows: readonly IRow[],
  extraHeaderRows: readonly IRow[],
  columns: readonly Column<IRow>[],
  selection: Position | undefined,
  range: RangeSelection | undefined,
  onRowsChange: (rows: IRow[], data?: RowsChangeData<IRow, unknown>) => void,
  setRange: (range: RangeSelection) => void,
  valueParsers?: ValueParsers,
  onCopyDone?: () => void | Promise<void>,
  onPasteDone?: () => void | Promise<void>
): [
  (event: ClipboardEvent) => Promise<void>,
  (event?: ClipboardEvent) => Promise<void>
] {
  // Not useState because this is not used to update any UI.
  const isCopying = useRef(false);

  const handlePaste = useCallback(
    async (event?: ClipboardEvent) => {
      if (!isActive) {
        return;
      }
      if (!selection) {
        return;
      }

      event?.preventDefault();

      const rawTsv = await navigator.clipboard.readText();
      if (!isCopying.current && !rawTsv) {
        return;
      }

      let tsv = tsvToArray<number, Cell>(rawTsv);

      // If pasting an empty string, tsvToArray won't be able to convert it properly,
      // so just initialize a dummy array.
      if (isCopying.current && tsv.length === 0) {
        tsv = [{ 0: "" }];
      } else if (tsv.length === 0) {
        return;
      }

      let isTiledPaste = false;
      const copyDataWidth = Object.keys(tsv[0]).length;
      const copyDataLength = tsv.length;
      let widthBounds = copyDataWidth;
      let lengthBounds = copyDataLength;

      if (range) {
        const bounds = getBoundingPositions(range);
        const selectionWidthBounds = bounds.end.idx - bounds.start.idx + 1;
        const selectionLengthBounds =
          bounds.end.rowIdx - bounds.start.rowIdx + 1;
        if (
          selectionWidthBounds % copyDataWidth === 0 &&
          selectionLengthBounds % copyDataLength === 0
        ) {
          isTiledPaste = true;
          widthBounds = selectionWidthBounds;
          lengthBounds = selectionLengthBounds;
        }
      }

      const tempFilteredRows = [...filteredRows];
      const newRowMap: Record<string, number> = {};
      rows.forEach((item, index) => {
        newRowMap[`${item.id}`] = index;
      });

      // Multi-cell paste
      const start = range ? getBoundingPositions(range).start : selection;
      const extraHeadersLength = extraHeaderRows.length;
      const pasteRowStart = start.rowIdx - extraHeadersLength;

      for (let j = 0; j < widthBounds && start.idx + j < columns.length; j++) {
        const idxs: number[] = [];
        const updatedRows = [...rows];
        const column = columns[start.idx + j];
        const key = column.key;
        if (column.editable !== false) {
          for (
            let i = 0;
            i < lengthBounds && pasteRowStart + i < tempFilteredRows.length;
            i++
          ) {
            if (pasteRowStart + i < 0) {
              continue;
            }

            // Prevent frozen and non-editable data from being modified.
            if (
              typeof column.editable !== "function" ||
              column.editable(tempFilteredRows[pasteRowStart + i])
            ) {
              let newValue = isTiledPaste
                ? tsv[i % copyDataLength][j % copyDataWidth]
                : tsv[i][j];

              if (valueParsers && key in valueParsers) {
                newValue = valueParsers[key](newValue);
              }

              tempFilteredRows[pasteRowStart + i] = {
                ...tempFilteredRows[pasteRowStart + i],
                [key]: newValue,
              };

              idxs.push(newRowMap[`${tempFilteredRows[pasteRowStart + i].id}`]);
            }
          }
        }
        tempFilteredRows.forEach((item) => {
          updatedRows[newRowMap[`${item.id}`]] = item;
        });
        onRowsChange(updatedRows, {
          indexes: idxs,
          column: {
            ...column,
            isLastFrozenColumn: false,
            idx: start.idx + j,
            rowGroup: false,
            resizable: !!column.resizable,
            sortable: !!column.sortable,
            frozen: !!column.frozen,
            formatter: column.formatter ?? ValueFormatter,
          },
        });
      }

      await onPasteDone?.();

      // Adjust the selected range accordingly, after pasting.
      setRange({
        start: start,
        end: {
          idx: Math.min(
            start.idx + (Object.keys(tsv[0]).length || 0) - 1,
            columns.length - 1
          ),
          rowIdx: Math.min(start.rowIdx + tsv.length - 1, rows.length - 1),
        },
      });
    },
    [
      isActive,
      selection,
      filteredRows,
      rows,
      range,
      extraHeaderRows.length,
      onPasteDone,
      setRange,
      columns,
      valueParsers,
      onRowsChange,
    ]
  );

  const handleCopy = useCallback(
    async (event: ClipboardEvent) => {
      event.preventDefault();

      if (!isActive) {
        return;
      }

      if (!range) {
        return;
      }

      isCopying.current = true;

      const tableRows = [...extraHeaderRows, ...filteredRows];

      // Multi-cell copy
      const bounds = getBoundingPositions(range);
      const result: Cell[][] = [];
      for (
        let i = bounds.start.rowIdx, i2 = 0;
        i <= bounds.end.rowIdx;
        i++, i2++
      ) {
        const r: Cell[] = [];
        for (let j = bounds.start.idx, j2 = 0; j <= bounds.end.idx; j++, j2++) {
          r[j2] = tableRows[i][columns[j].key];
        }
        result.push(r);
      }

      event.clipboardData?.setData(
        "text/plain",
        unparseRows(result, undefined, {
          delimiter: "\t",
          header: false,
        })
      );

      await onCopyDone?.();
    },
    [isActive, range, extraHeaderRows, filteredRows, onCopyDone, columns]
  );

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    document.addEventListener("copy", handleCopy);

    return () => {
      document.removeEventListener("paste", handlePaste);
      document.removeEventListener("copy", handleCopy);
    };
  }, [handleCopy, handlePaste]);

  return [handleCopy, handlePaste];
}
