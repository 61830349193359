import { flatten } from "lodash";
import React, { useMemo } from "react";
import { FormControlProps } from "react-bootstrap";
import { Exclamation } from "react-bootstrap-icons";
import styled from "styled-components";

interface ErrorMessageProps extends FormControlProps {
  error: string | string[] | object;
}

const Container = styled.div`
  align-items: center;
  background-color: #ffe4e9;
  color: rgba(17, 17, 17, 0.7);
  display: flex;
  font-size: 12px;
  line-height: 1.75;
  padding-right: 8px;

  svg {
    color: #ff7591;
    font-size: 20px;
  }
`;

const Message = styled.span`
  align-self: center;
`;

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  const errorList = useMemo(() => {
    if (error) {
      return flatten(Object.values(error));
    }
    return undefined;
  }, [error]);

  return (
    <Container data-testid="error-message">
      <span>
        <Exclamation />
      </span>
      <Message data-testid="error-message-content">{errorList}</Message>
    </Container>
  );
};

export default ErrorMessage;
