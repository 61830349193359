import { Dropdown, DropdownButton } from "react-bootstrap";
import styled from "styled-components";

export const OptionButton = styled(DropdownButton)`
  &.show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;

    &:focus {
      box-shadow: none;
    }
  }

  .btn {
    padding: 0;
    min-width: 1.5rem;
    min-height: 1.625rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .btn-primary {
    background-color: transparent;
    color: #000000;
    border-color: transparent;

    &:focus {
      box-shadow: none;
    }

    &:focus,
    &:hover,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      border-color: transparent;
      color: #000000;
      box-shadow: none;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
`;

export const Option = styled(Dropdown.Item)`
  padding: 0.25rem 0.938rem;
  font-size: 0.875rem;
  text-transform: capitalize;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.938rem;
  }
`;
