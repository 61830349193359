import { computed } from "mobx";
import {
  ExtendedModel,
  getRootStore,
  model,
  modelAction,
  modelFlow,
  prop,
  _async,
  _await,
} from "mobx-keystone";
import { RootStore } from "..";
import api from "../../api";
import {
  APIRequirementDetail,
  APIRequirementInput,
} from "../../api/requirements";
import DirtyableWithId from "./DirtyableWithId";

@model("collab/Requirement")
class Requirement extends ExtendedModel(DirtyableWithId, {
  project: prop<identifier>(),
  uniqueRef: prop<string>(""),
  destinationCountry: prop<identifier | undefined>(),
  deliveryTerms: prop<identifier | undefined>(),
  deliveryLocation: prop<string>(""),
  productSpecification: prop<string>(""),
  packSize: prop<identifier | undefined>(),
  packageType: prop<string>(""),
  volumeYear1: prop<number | null>(),
  volumeYear2: prop<number | null>(),
  volumeYear3: prop<number | null>(),
  volumeYear4: prop<number | null>(),
  volumeYear5: prop<number | null>(),
  packSizeRfq: prop<string>(""),
  altScenario: prop<string>(""),
  isCltLocked: prop<boolean>(false),
}) {
  @computed
  get asAPI() {
    const apiData: APIRequirementInput = {
      uniqueRef: this.uniqueRef,
      destinationCountry: this.destinationCountry,
      deliveryTerms: this.deliveryTerms,
      deliveryLocation: this.deliveryLocation,
      productSpecification: this.productSpecification,
      packSize: this.packSize,
      packageType: this.packageType,
      volumeYear1:
        (this.volumeYear1 as unknown as string) === ""
          ? null
          : this.volumeYear1,
      volumeYear2:
        (this.volumeYear2 as unknown as string) === ""
          ? null
          : this.volumeYear2,
      volumeYear3:
        (this.volumeYear3 as unknown as string) === ""
          ? null
          : this.volumeYear3,
      volumeYear4:
        (this.volumeYear4 as unknown as string) === ""
          ? null
          : this.volumeYear4,
      volumeYear5:
        (this.volumeYear5 as unknown as string) === ""
          ? null
          : this.volumeYear5,
      packSizeRfq: this.packSizeRfq,
      altScenario: this.altScenario,
    };
    if (this.id > 0) {
      apiData.id = this.id;
    }
    return apiData;
  }

  @computed
  get asRow() {
    return {
      id: this.id,
      uniqueRef: this.uniqueRef,
      destinationCluster: this.destinationCluster,
      destinationCountry: this.destinationCountry,
      deliveryLocation: this.deliveryLocation,
      deliveryTerms: this.deliveryTerms,
      productSpecification: this.productSpecification,
      packSize: this.packSize,
      packageType: this.packageType,
      volumeYear1: this.volumeYear1,
      volumeYear2: this.volumeYear2,
      volumeYear3: this.volumeYear3,
      volumeYear4: this.volumeYear4,
      volumeYear5: this.volumeYear5,
      packSizeRfq: this.packSizeRfq,
      altScenario: this.altScenario,
    };
  }

  @computed
  get asCSV() {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return this.asRow;
    }

    const destinationCountryObj = rootStore.assets.countryListItems.get(
      this.destinationCountry || 0
    );
    const deliveryTermsObj = rootStore.assets.termsListItems.get(
      this.deliveryTerms || 0
    );
    const packSizeObj = rootStore.assets.packSizeListItems.get(
      this.packSize || 0
    );

    return {
      uniqueRef: this.uniqueRef,
      destinationCluster: this.destinationCluster,
      destinationCountry: destinationCountryObj?.name || "",
      deliveryLocation: this.deliveryLocation,
      deliveryTerms: deliveryTermsObj?.name || "",
      productSpecification: this.productSpecification,
      packSize: packSizeObj?.name || "",
      packageType: this.packageType,
      volumeYear1: this.volumeYear1,
      volumeYear2: this.volumeYear2,
      volumeYear3: this.volumeYear3,
      volumeYear4: this.volumeYear4,
      volumeYear5: this.volumeYear5,
      packSizeRfq: this.packSizeRfq,
      altScenario: this.altScenario,
    };
  }

  @computed
  get destinationCluster() {
    const store = getRootStore<RootStore>(this);
    if (!this.destinationCountry) {
      return "";
    }
    const country = store?.assets.countryListItems.get(
      Number(this.destinationCountry)
    );
    if (!country) {
      return "";
    }
    const cluster = store?.assets.clusterListItems.get(country.cluster);
    if (!cluster) {
      return "";
    }
    return cluster.name;
  }

  private _findValue(options: { value: number; label: string }[], value: any) {
    // If value is not an id, check if it's a label.
    let v = value;
    if (!options.find((opt) => opt.value === Number(v))) {
      v =
        options.find(
          (opt) =>
            opt.label.toString().trim().toLowerCase() ===
            v.toString().trim().toLowerCase()
        )?.value || "";
    }
    return v;
  }

  @modelAction
  setValue(key: string, value: any) {
    const store = getRootStore<RootStore>(this);
    let v = value;
    if (store && key === "destinationCountry") {
      v = this._findValue(store.assets.countrySelect, v);
    } else if (store && key === "deliveryTerms") {
      v = this._findValue(store.assets.termsSelect, v);
    } else if (store && key === "packSize") {
      v = this._findValue(store.assets.packSizeSelect, v);
    } else if (
      key === "volumeYear1" ||
      key === "volumeYear2" ||
      key === "volumeYear3" ||
      key === "volumeYear4" ||
      key === "volumeYear5"
    ) {
      v = v ? v : null;
    }
    super.setValue(key, v);
  }

  @modelAction
  update(data: APIRequirementDetail) {
    const store = getRootStore<RootStore>(this);
    if (store) {
      store.requirements.listItems.delete(this.id);
      super.update(data);
      store.requirements.listItems.set(data.id, this);
    }
  }

  @modelFlow
  save = _async(function* (this: Requirement) {
    if (!this.isDirty) {
      return;
    }

    if (!this.uniqueRef) {
      return;
    }

    let response: APIRequirementDetail;
    try {
      if (this.id < 0) {
        response = yield* _await(
          api.requirements.create(this.project, this.asAPI)
        );
      } else {
        response = yield* _await(api.requirements.patch(this.id, this.asAPI));
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
    this.update(response);

    yield null;
  });
}

export default Requirement;
