const Folder: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33366 3.3335H8.33366L10.0003 5.00016H16.667C17.5837 5.00016 18.3337 5.75016 18.3337 6.66683V15.0002C18.3337 15.9168 17.5837 16.6668 16.667 16.6668H3.33366C2.41699 16.6668 1.66699 15.9168 1.66699 15.0002L1.67533 5.00016C1.67533 4.0835 2.41699 3.3335 3.33366 3.3335ZM9.30866 6.66683L7.64199 5.00016H3.33366V15.0002H16.667V6.66683H9.30866Z"
        fill="#FDFDFD"
      />
    </svg>
  );
};
export default Folder;
