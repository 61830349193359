import { ToastContainer as RTToastifyContainer } from "react-toastify";
import styled from "styled-components";

const ToastContainer = styled(RTToastifyContainer).attrs({
  position: "bottom-center",
  hideProgressBar: true,
  theme: "colored",
  autoClose: 3000,
})`
  --toastify-color-success: #76c669;
  --toastify-toast-min-height: fit-content;

  .Toastify__toast {
    font-size: 0.875rem;
  }
`;

export default ToastContainer;
