import {
  Model,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import api from "../api";
import {
  APICLTAssumptions,
  APICLTAssumptionsBulkUpdateInput,
  APICLTAssumptionsInput,
  APICLTAssumptionsSearchParams,
} from "../api/cltAssumptions";
import CLTAssumptions from "./models/CLTAssumptions";

@model("collab/CLTAssumptionsStore")
class CLTAssumptionsStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, CLTAssumptions>()),
}) {
  @modelAction
  saveListItem(item: APICLTAssumptions) {
    const listItem = new CLTAssumptions(item);
    this.listItems.set(item.id, listItem);
    return listItem;
  }

  @modelFlow
  list = _async(function* (
    this: CLTAssumptionsStore,
    page?: number,
    limit?: number,
    searchParams?: APICLTAssumptionsSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.cltAssumptions.list(page, limit, searchParams));
    this.listItems.clear();
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  update = _async(function* (
    this: CLTAssumptionsStore,
    cltAssumptionsId: identifier,
    apiData: APICLTAssumptionsInput
  ) {
    try {
      const result = yield* _await(
        api.cltAssumptions.update(cltAssumptionsId, apiData)
      );
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });

  @modelFlow
  bulkUpdate = _async(function* (
    this: CLTAssumptionsStore,
    apiData: APICLTAssumptionsBulkUpdateInput
  ) {
    try {
      const result = yield* _await(api.cltAssumptions.bulkUpdate(apiData));
      const { instances } = result;
      const results = instances.map((item) => this.saveListItem(item));
      return results;
    } catch (e) {
      throw e;
    }
  });
}

export default CLTAssumptionsStore;
