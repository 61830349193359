import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import CalendarInput from "./CalendarInput";

const DatePickerWrapper = styled.div`
  cursor: pointer;
  display: block;

  .react-datepicker-wrapper .is-invalid ~ .invalid-feedback {
    display: block;
  }

  .react-datepicker-popper {
    z-index: 101;
  }

  .react-datepicker__day--outside-month {
    color: #bdbdbd;
  }

  .react-datepicker__day--selected {
    background-color: #74b469;
    border-radius: 50%;

    &:focus {
      outline: none;
    }
  }

  .input-group {
    width: 10rem;

    &.disabled {
      .btn-calendar,
      .form-control {
        background-color: #e9ecef;
      }

      &:focus-within {
        outline: none;
      }
    }

    &.is-invalid {
      border: 1px solid #dc3545;
      border-radius: 0.25rem;
    }

    &:focus-within {
      outline: #74b468 auto 1px;
    }

    .form-control {
      background-color: #ffffff;
      border-color: #ced4da;
      border-left: 0;
      height: 2.375rem;

      &[readonly] {
        background-color: #ffffff;
      }

      &:focus {
        color: #495057;
      }

      .is-invalid {
        border-color: #ced4da;
      }
    }

    .btn-calendar,
    .form-control {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 0.875rem;

      &:focus {
        border-color: #ced4da;
        box-shadow: none;
        outline: none;
      }
    }
  }
`;

type Props = {
  dateFormat?: string;
  errors?: any;
  onChange: (...event: any[]) => void;
  onBlur: () => void;
  value: Date | null;
  className?: string;
  isStatusDashboard?: boolean;
  disabled?: boolean;
};

const DatePicker: React.FC<Props> = ({
  dateFormat = "P", // locale-aware format by default
  errors,
  onChange,
  onBlur,
  value,
  className,
  disabled,
}) => {
  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);
  return (
    <DatePickerWrapper>
      <ReactDatePicker
        onChange={onChange}
        onBlur={onBlur}
        dateFormat={dateFormat}
        selected={value}
        disabled={disabled}
        onSelect={() => setCalendarIsOpen((prev) => !prev)}
        onClickOutside={() => setCalendarIsOpen((prev) => !prev)}
        open={calendarIsOpen}
        customInput={
          <CalendarInput
            disabled={disabled}
            className={className}
            errors={errors}
            onCalendarOpen={setCalendarIsOpen}
          />
        }
        strictParsing
      />
    </DatePickerWrapper>
  );
};

export default DatePicker;
