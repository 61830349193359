import { CloudUploadOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { APIClusterInputSheetsStatus } from "../../api/types/clusterInputSheetsStatus";
import Project from "../../store/models/Project";
import { Toast } from "../../utils";
import Button from "../Button";
import Editor from "../Editor";

type Props = {
  project: Project;
  onHide: () => void;
};

type Inputs = {
  notes: string;
};

const ModalBodyHeader = styled.h4`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 0.875rem;
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;
`;

const GlobalPricing: React.FC<Props> = ({ project, onHide }) => {
  const methods = useForm<Inputs>();

  const handleSave = useCallback(
    async (data: Pick<APIClusterInputSheetsStatus, "notes">) => {
      try {
        await project.submitGlobalPricing(data);
      } catch (e) {
        Toast.danger("Not Published. Please review your notes and try again.");
        return;
      }

      Toast.success("Global pricing published.");
      onHide();
    },
    [onHide, project]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSave)}>
        <Modal.Body>
          <p>
            This will email all project output recipients with links to the
            finalised costing sheet output and supporting assumptions.
          </p>

          <ModalBodyHeader>Notes</ModalBodyHeader>

          <Editor
            defaultValue=""
            name="notes"
            size="sm"
            readOnly={project.loading}
            noToolbar
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="borderless" onClick={onHide}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={project.loading}>
            <CloudUploadOutlined /> Submit
          </Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

export default observer(GlobalPricing);
