import clsx from "clsx";
import React from "react";
import { Container, Image, Spinner } from "react-bootstrap";
import styled from "styled-components";
import bpLogo from "../../assets/images/bp-logo-green.svg";

interface LoadingProps {
  full?: boolean;
  showLogo?: boolean;
  overlay?: boolean;
  size?: "sm" | "md";
}

const StyledContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 90% !important;
  padding: 32px 15px;
  user-select: none;

  .spinner-border {
    color: #007833;
  }

  &.full {
    height: 100%;
  }

  &.overlay {
    background-color: rgba(0, 0, 0, 0.2);
    bottom: 0;
    left: 0;
    margin: 0;
    max-width: none !important;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100000;
  }

  &.small {
    padding: 15px;

    .spinner-border {
      height: 1rem;
      width: 1rem;
    }
  }
`;

const Logo = styled(Image)`
  margin-bottom: 10px;
  user-select: none;
`;

const Loading: React.FC<LoadingProps> = ({
  full = false,
  overlay = false,
  showLogo,
  size = "md",
}) => {
  return (
    <StyledContainer className={clsx({ full, small: size === "sm", overlay })}>
      {showLogo && <Logo src={bpLogo} />}
      <Spinner data-testid="loading-spinner" animation="border" />
    </StyledContainer>
  );
};

export default Loading;
