import {
  Model,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import api from "../api";
import {
  APIDeliveryInfo,
  APIDeliveryInfoBulkUpdateInput,
  APIDeliveryInfoInput,
  APIDeliveryInfoSearchParams,
} from "../api/deliveryInfo";
import DeliveryInfo from "./models/DeliveryInfo";

@model("collab/DeliveryInfoStore")
class DeliveryInfoStore extends Model({
  listItems: prop_mapObject(() => new Map<number, DeliveryInfo>()),
}) {
  @modelAction
  saveListItem(item: APIDeliveryInfo) {
    const listItem = new DeliveryInfo(item);
    const existing = this.listItems.get(item.id);
    if (!existing) {
      this.listItems.set(item.id, listItem);
      return listItem;
    }

    if (!existing.isDirty) {
      existing.update(item);
    }
    return existing;
  }

  @modelFlow
  list = _async(function* (
    this: DeliveryInfoStore,
    page?: number,
    limit?: number,
    searchParams?: APIDeliveryInfoSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.deliveryInfo.list(page, limit, searchParams));
    this.listItems.clear();
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  create = _async(function* (
    this: DeliveryInfoStore,
    apiData: APIDeliveryInfoInput
  ) {
    try {
      const result = yield* _await(api.deliveryInfo.create(apiData));
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });

  @modelFlow
  update = _async(function* (
    this: DeliveryInfoStore,
    deliveryInfoId: identifier,
    apiData: APIDeliveryInfoInput
  ) {
    try {
      const result = yield* _await(
        api.deliveryInfo.update(deliveryInfoId, apiData)
      );
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });

  @modelFlow
  bulkUpdate = _async(function* (
    this: DeliveryInfoStore,
    apiData: APIDeliveryInfoBulkUpdateInput
  ) {
    try {
      const result = yield* _await(api.deliveryInfo.bulkUpdate(apiData));
      const { instances } = result;
      const results = instances.map((item) => this.saveListItem(item));
      return results;
    } catch (e) {
      throw e;
    }
  });

  ofProject(projectId: identifier) {
    return Array.from(this.listItems.values()).filter(
      (deliveryInfo) => deliveryInfo.project === projectId
    );
  }
}

export default DeliveryInfoStore;
