import { CloudUploadOutlined } from "@material-ui/icons";
import { APIProjectSubmitInput } from "api/projects";
import { APIClusterInputSheetsStatus } from "api/types/clusterInputSheetsStatus";
import clsx from "clsx";
import { format, isBefore, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useEffectOnce } from "react-use";
import { useStore } from "store";
import Project from "store/models/Project";
import styled from "styled-components";
import { Toast } from "utils";
import { dismissProjectSubmitted } from "utils/confirm";
import Button from "../Button";
import Editor from "../Editor";

type Props = {
  project: Project;
  clusterStatus?: APIClusterInputSheetsStatus;
  onHide: () => void;
};

type Inputs = {
  notes: string;
};

const ModalBodyHeader = styled.h4`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 0.875rem;
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const DateDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`;

const DateHeader = styled.h2`
  font-weight: 600;
  font-size: 0.7rem;
  display: inline-block;
`;

const DateDiv = styled.div`
  background-color: #fdfdfd;
  border: 0.063rem solid #c8c8c8;
  box-sizing: border-box;
  border-radius: 0.25rem;

  padding: 0.688rem 0.938rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-align: center;
  width: 7.5rem;
  height: 2.375rem;

  &.date-on-time {
    background-color: #c3da87;
    border-color: #8fac42;
  }

  &.date-not-on-time {
    background: #f89964;
    border-color: #b66436;
  }
`;

const Submit: React.FC<Props> = ({ project, clusterStatus, onHide }) => {
  const methods = useForm<Inputs>();
  const store = useStore();

  useEffectOnce(() => {
    store.requirements.list(project.id);
  });

  const handleSave = useCallback(
    async (data: APIProjectSubmitInput) => {
      try {
        await project.submit(data);
      } catch (e) {
        Toast.danger("Submit failed.");
        return;
      }

      onHide();
      dismissProjectSubmitted();
    },
    [onHide, project]
  );

  const isOnTime = clusterStatus?.dueDate
    ? isBefore(new Date(), parseISO(clusterStatus.dueDate))
    : true;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSave)}>
        <Modal.Body>
          <DateContainer>
            <DateDisplayContainer>
              <DateHeader>Model Issued</DateHeader>
              <DateDiv>
                {clusterStatus?.issueDate
                  ? format(parseISO(clusterStatus?.issueDate), "P", {
                      locale: enGB,
                    })
                  : "--/--/----"}
              </DateDiv>
            </DateDisplayContainer>
            <DateDisplayContainer>
              <DateHeader>Due Date</DateHeader>
              <DateDiv
                className={clsx({
                  "date-on-time": isOnTime,
                  "date-not-on-time": !isOnTime,
                })}
              >
                {clusterStatus?.dueDate
                  ? format(parseISO(clusterStatus?.dueDate), "P", {
                      locale: enGB,
                    })
                  : "--/--/----"}
              </DateDiv>
            </DateDisplayContainer>
          </DateContainer>
          <p>
            I confirm that I have completed all relevant inputs on behalf of
            this CLT. I have also reviewed the Output - Costing Sheet for Full
            and Marginal costing (if appropriate) and therefore am submitting
            for approval and global consolidation. I’m aware that these costs
            provided will be used for tender submissions and key internal
            project decisions accordingly.
          </p>

          <ModalBodyHeader>Notes</ModalBodyHeader>

          <Editor name="notes" size="sm" readOnly={project.loading} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="borderless" onClick={onHide}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={project.loading}>
            <CloudUploadOutlined /> Submit
          </Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

export default observer(Submit);
