import { darken } from "polished";
import { Button as BSButton } from "react-bootstrap";
import styled from "styled-components";

const Button = styled(BSButton)`
  &.btn {
    border-radius: 0.25rem;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: calc(2.25rem - 2px);
    min-height: 2.25rem;
    padding: 0 0.875rem;
    text-transform: uppercase;

    svg {
      margin-right: 0.625rem;
      width: 1.25rem;
      height: 1.25rem;
    }

    &:focus {
      outline: 2px solid #c1c1c1;
    }
  }

  &:focus,
  &:hover,
  &:not(:disabled):active {
    box-shadow: none;
    outline: 0;
  }

  /* Variants */
  &.btn-borderless {
    background-color: transparent;
    border-color: transparent;
    color: #000000;

    &:focus,
    &:hover,
    &:not(:disabled):active {
      border-color: transparent;
    }

    &:not(:disabled):active {
      background-color: transparent;
      color: #000000;
    }
  }

  &.btn-primary,
  &.btn-primary.dropdown-toggle {
    background-color: #ffffff;
    border-color: #c8c8c8;
    color: #000000;

    &:focus,
    &:hover,
    &:not(:disabled):active {
      border-color: #000000;
    }

    &:not(:disabled):active {
      background-color: #000000;
      color: #ffffff;
    }
  }

  &.btn-inverted {
    background-color: #484848;
    border-color: #242424;
    color: #ffffff;

    &:focus,
    &:hover,
    &:not(:disabled):active {
      border-color: #000000;
    }

    &:not(:disabled):active {
      background-color: #000000;
      color: #ffffff;
    }
  }

  &.btn-cost-sheet-pending {
    background-color: #74b469;
    border-color: #439b34;
    color: #ffffff;

    &:focus,
    &:hover,
    &:not(:disabled):active {
      border-color: #000000;
    }

    &:not(:disabled):active {
      background-color: #74b469;
      color: #ffffff;
    }
  }

  &.btn-tender-won {
    width:100%;
    text-transform: capitalize;
    background-color: #ffffff;
    border-color: #c8c8c8;
    color: #000;

    &:focus,
    &:hover,
    &:not(:disabled):active {
      border-color: #000000;
    }
    &:disabled {
      opacity: 1;
      border-color: #c8c8c8;
    }

    &:not(:disabled).active {
      background-color: #8fac42;
      color: #000;
    }

     &:disabled:active {
      opacity: 1;
      border-color: #c8c8c8;
    }
    
    &:disabled.active {
      opacity: 1;
      background-color: #8fac42;
      border-color: #000000,
      color: #000;
    }
  }

  &.btn-tender-lost {
    width:100%;
    text-transform: capitalize;
    background-color: #ffffff;
    border-color: #c8c8c8;
    color: #000;

    &:focus,
    &:hover,
    &:not(:disabled):active {
      border-color: #000000;
    }
    &:disabled {
      opacity: 1;
      border-color: #c8c8c8;
    }

    &:not(:disabled).active {
      background-color: #f3d989;
      border-color: #CEA834,
      color: #000;
    }

    &:disabled:active {
      opacity: 1;
      border-color: #c8c8c8;
    }
    
    &:disabled.active {
      opacity: 1;
      background-color: #f3d989;
      border-color: #CEA834,
      color: #000;
    }
  }

  &.btn-clt-files {
    background-color: #74b469;
    border-color: #439b34;
    color: #ffffff;

    &:focus,
    &:hover,
    &:not(:disabled):active {
      background-color: #439b34;
    }

    &:not(:disabled):active {
      background-color: #74b469;
      color: #ffffff;
    }
  }

  &.btn-file-box {
    background-color: #fafafa;
    border-color: #b66436;
    color: #f89964;

    &:hover {
      background-color: #b66436;
      color: #000;
    }

    &:not(:disabled):active {
      background-color: ${darken(0.1, "#b66436")};
      border-color: ${darken(0.1, "#B66436")};
    }

    &:not(:disabled).active {
      background: #484848;
      border-color: #242424;
      color: #ffffff;

      &:hover {
        background-color: #242424;
      }

      &:not(:disabled):active {
        background-color: ${darken(0.1, "#242424")};
        border-color: ${darken(0.1, "#242424")};
      }
    }
  }

  &.btn-sheet-initial {
    background-color: #f3d989;
    border-color: #cea834;
    color: #000000;

    &:hover {
      background-color: #cea834;
    }

    &:not(:disabled):active {
      background-color: ${darken(0.1, "#cea834")};
      border-color: ${darken(0.1, "#cea834")};
    }

    &:not(:disabled).active {
      background: #484848;
      border-color: #242424;
      color: #ffffff;

      &:hover {
        background-color: #242424;
      }

      &:not(:disabled):active {
        background-color: ${darken(0.1, "#242424")};
        border-color: ${darken(0.1, "#242424")};
      }
    }
  }

  &.btn-sheet-collect {
    background-color: #f89964;
    border-color: #b66436;
    color: #000000;

    &:hover {
      background-color: #b66436;
    }

    &:not(:disabled):active {
      background-color: ${darken(0.1, "#b66436")};
      border-color: ${darken(0.1, "#b66436")};
    }

    &:not(:disabled).active {
      background: #484848;
      border-color: #242424;
      color: #ffffff;

      &:hover {
        background-color: #242424;
      }

      &:not(:disabled):active {
        background-color: ${darken(0.1, "#242424")};
        border-color: ${darken(0.1, "#242424")};
      }
    }
  }

  &.btn-sheet-output {
    background-color: #c3da87;
    border-color: #8fac42;
    color: #000000;

    &:hover {
      background-color: #8fac42;
    }

    &:not(:disabled):active {
      background-color: ${darken(0.1, "#8fac42")};
      border-color: ${darken(0.1, "#8fac42")};
    }

    &:not(:disabled).active {
      background: #484848;
      border-color: #242424;
      color: #ffffff;

      &:hover {
        background-color: #242424;
      }

      &:not(:disabled):active {
        background-color: ${darken(0.1, "#242424")};
        border-color: ${darken(0.1, "#242424")};
      }
    }
  }

  &.btn-sheet-status {
    background-color: #c8c8c8;
    border-color: #484848;
    color: #000000;

    &:hover {
      background-color: #484848;
    }

    &:not(:disabled):active {
      background-color: ${darken(0.1, "#484848")};
      border-color: ${darken(0.1, "#484848")};
    }

    &:not(:disabled).active {
      background: #484848;
      border-color: #242424;
      color: #ffffff;

      &:hover {
        background-color: #242424;
      }

      &:not(:disabled):active {
        background-color: ${darken(0.1, "#242424")};
        border-color: ${darken(0.1, "#242424")};
      }
    }
  }

  &.btn-green {
    background-color: #76c669;
    border-color: transparent;
    color: #ffffff;

    &:focus,
    &:hover {
      background-color: ${darken(0.1, "#76c669")};
      border-color: transparent;
    }

    &:not(:disabled):active {
      background-color: ${darken(0.3, "#76c669")};
      border-color: transparent;
    }
  }
  
  &.btn-docsLink {
    line-height: calc(1.75rem - 2px);
    min-height: 1.75rem;

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
    
    background-color: #C3DA87;
    border-color: #C8C8C8;
    color: #242424;

    &:focus,
    &:hover {
      background-color: ${darken(0.1, "#F89964")};
      border-color: #C8C8C8;
    }

    &:not(:disabled):active {
      background-color: ${darken(0.3, "#C3DA87")};
      border-color: #C8C8C8;
    }
  }
  &.btn-cancel {
    border: 0;
    color: #484848;

    &:focus {
      outline: 0;
    }
  }

  /* Sizes */
  &.btn-sheet-tab {
    font-size: 0.6875rem;
  }

  &.btn-thin {
    line-height: calc(1.75rem - 2px);
    min-height: 1.75rem;

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  &.btn-form {
    font-size: 0.875rem;
    padding: 0 1.875rem;

    &-cancel {
      font-size: 0.875rem;
      padding: 0;
    }
    &-save {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 6.25rem;

      font-size: 0.75rem;
      line-height: 0.75rem;
    }
  }

  &.btn-page {
    font-size: 0.875rem;
    padding: 0 2.8125rem;
  }
`;

export default Button;
