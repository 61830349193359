import base, { APIDetail, APIList, APISearchParams, config } from "./base";
import { APIUser } from "./types";

export type APIUserInput = Partial<
  Omit<
    APIUser,
    | "id"
    | "phoneNumber"
    | "address"
    | "isActive"
    | "isAdmin"
    | "isGlobalAnalyst"
    | "isGlobalReviewer"
    | "isAnalyst"
    | "isReviewer"
    | "photoSizes"
    | "analystCluster"
    | "reviewerCluster"
    | "analystClusterName"
    | "reviewerClusterName"
  >
>;

export interface APIUserDetail extends APIDetail<APIUser> {}
export const detail = (userId: number) =>
  base.get<APIUserDetail>(`users/${userId}/`);

export interface APIUserList extends APIList<APIUser> {}
export interface APIUserListSearchParams extends APISearchParams {}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIUserListSearchParams
) =>
  base.list<APIUserList, APIUserListSearchParams>(
    "users/",
    page,
    limit,
    searchParams
  );
