import {
  CancelOutlined,
  FilterList,
  FolderOpen,
  SortOutlined,
} from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { lighten } from "polished";
import React, { useState } from "react";
import { ButtonGroup, Form, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import Button from "../../components/Button";
import FolderCard from "../../components/FolderCard";
import { Header, SubHeader } from "../../components/Header";
import Loading from "../../components/Loading";
import ProjectCard from "../../components/ProjectCard";
import { useStore } from "../../store";
import ProjectFolder from "../../store/models/ProjectFolders";
import ProjectMinimal from "../../store/models/ProjectMinimal";
import FolderForm from "./FolderForm";

const Container = styled.div`
  margin-top: 1.875rem;

  &:first-of-type {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.625rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
  gap: 1.25rem;
`;

const HeaderContainer = styled.div`
  min-width: 141px;
  margin-right: 3.125rem;
`;

const EmptyText = styled.div`
  color: ${lighten(0.5, "#000000")};
  text-align: center;
  user-select: none;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  margin: 5rem 0;
`;

const FilterContainer = styled(Form)`
  background-color: #ffffff;
  border: 1px solid #b9b9b9;
  border-radius: 0.25rem;
  box-shadow: 0px 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 0.5rem;
  width: 9rem;
  z-index: 10;
`;

const SortContainer = FilterContainer;

const FilterHeaderContainer = styled.div`
  opacity: 0.5;
  padding: 0.5rem 0.75rem;
  font-size: 0.5rem;
  text-transform: uppercase;
`;
const SortHeaderContainer = FilterHeaderContainer;

const ProjectTypeOption = styled.div`
  width: 100%;
  padding: 0.5rem 0.75rem;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const SortOption = ProjectTypeOption;

interface Props {
  isFolder?: boolean;
  archived?: boolean;
  title: string;
  subTitle?: string;
  loading?: boolean;
  folders?: ProjectFolder[];
  projects?: ProjectMinimal[];
  projectCount?: number;
  urlPrefix: string;
  noFilter?: boolean;

  onFolderSubmit?: () => Promise<void>;
  onProjectSubmit: () => Promise<void>;
  onArchiveRestore: () => Promise<void>;
}

const Projects: React.FC<Props> = ({
  isFolder,
  archived,
  title,
  subTitle = "Projects",
  folders,
  projects,
  projectCount,
  urlPrefix,
  noFilter,
  onFolderSubmit,
  onProjectSubmit,
  onArchiveRestore,
}) => {
  const [createFolderModalShow, setCreateFolderModalShow] =
    useState<boolean>(false);

  const [projectTypeFilter, setProjectTypeFilter] = useState<{
    value: string;
    label: string;
  }>({ value: "all", label: "All" });

  const [projectSortType, setProjectSortType] = useState<{
    value: string;
    label: string;
  }>({ value: "modified", label: "Last Modified" });

  const projectList = projects
    ?.filter(
      (item) =>
        !(!isFolder && item.folder) &&
        (projectTypeFilter.value !== "all"
          ? item.type === projectTypeFilter.value
          : true)
    )
    .sort((a, b) => {
      if (projectSortType.value === "modified") {
        return new Date(b.modified).getTime() - new Date(a.modified).getTime();
      }
      if (projectSortType.value === "created") {
        return new Date(b.created).getTime() - new Date(a.created).getTime();
      }
      return 0;
    });

  const projectSortTypeOptions = [
    { value: "name", label: "Project Name" },
    { value: "created", label: "Date Created" },
    { value: "modified", label: "Last Modified" },
  ];
  const projectTypeOptions = [
    { value: "all", label: "All" },
    { value: "costing", label: "Costing Exercise" },
    { value: "project", label: "Project" },
    { value: "tender", label: "Tender" },
  ];

  const store = useStore();
  const currentUser = store.auth.current;

  const renderFolders = folders?.map((item) => {
    return (
      <div key={item.id}>
        <FolderCard
          folder={item}
          archived={archived}
          onFolderSubmit={onFolderSubmit}
        />
      </div>
    );
  });

  if (!projectList) {
    return <Loading />;
  }
  return (
    <Container>
      <Content>
        <HeaderContainer>
          <SubHeader>{subTitle}</SubHeader>
          <Header>
            {projectCount} {title}
          </Header>
        </HeaderContainer>
        {currentUser?.isAdmin && !isFolder && (
          <Button onClick={() => setCreateFolderModalShow(true)}>
            <FolderOpen />
            New Folder
          </Button>
        )}
        {!noFilter && (
          <ButtonGroup>
            <OverlayTrigger
              trigger="click"
              placement="bottom-start"
              offset={[0, 20]}
              rootClose
              overlay={
                <FilterContainer>
                  <FilterHeaderContainer>Filter By:</FilterHeaderContainer>
                  {projectTypeOptions.map((option) => (
                    <ProjectTypeOption
                      onClick={() => setProjectTypeFilter(option)}
                    >
                      {option.label}
                    </ProjectTypeOption>
                  ))}
                </FilterContainer>
              }
            >
              <Button>
                <FilterList />
                Filter Projects: {projectTypeFilter.label}
              </Button>
            </OverlayTrigger>
            {projectTypeFilter.value !== "all" && (
              <Button
                variant="inverted"
                size="thin"
                onClick={() =>
                  setProjectTypeFilter({ value: "all", label: "All" })
                }
              >
                <CancelOutlined />
                Clear Filter
              </Button>
            )}
          </ButtonGroup>
        )}
        <ButtonGroup>
          <OverlayTrigger
            trigger="click"
            placement="bottom-start"
            offset={[0, 20]}
            rootClose
            overlay={
              <SortContainer>
                <SortHeaderContainer>Sort By:</SortHeaderContainer>
                {projectSortTypeOptions.map((option) => (
                  <SortOption onClick={() => setProjectSortType(option)}>
                    {option.label}
                  </SortOption>
                ))}
              </SortContainer>
            }
          >
            <Button>
              <SortOutlined />
              Sort Projects: {projectSortType.label}
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </Content>

      {folders && <Content>{renderFolders}</Content>}
      {projectList.length !== 0 ? (
        <Content>
          {projectList.map((item) => (
            <ProjectCard
              key={item.id}
              project={item}
              archived={archived}
              urlPrefix={urlPrefix}
              onProjectSubmit={onProjectSubmit}
              onArchiveRestore={onArchiveRestore}
            />
          ))}
        </Content>
      ) : (
        <EmptyText>
          No {isFolder ? "" : archived ? "Archived" : "Active"}{" "}
          {projectTypeFilter.value !== "all" && projectTypeFilter.label}{" "}
          Projects
        </EmptyText>
      )}

      <FolderForm
        archived={archived}
        isOpen={createFolderModalShow}
        setModal={(visible: boolean) => setCreateFolderModalShow(visible)}
        onFolderSubmit={onFolderSubmit}
      />
    </Container>
  );
};

export default observer(Projects);
