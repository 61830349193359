import CommentProvider from "components/Comments/CommentProvider";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { TabList } from "../Workspace/types";
import ContentComments from "./ContentComments";
import WorksheetTabs from "./WorksheetTabs";

type Props = {
  tabs: TabList;
};

const Container = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
`;

const WorkArea: React.FC<Props> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<string>(Object.keys(tabs)[0]);
  const tab = tabs[activeTab];
  const project = useProject();

  useEffect(() => {
    if (!project) {
      return;
    }

    project.setActiveTab(activeTab);
    return () => {
      project.setActiveTab(null);
    };
  }, [activeTab, project]);

  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  return (
    <CommentProvider>
      <Container>
        <WorksheetTabs
          activeTab={activeTab}
          tabs={tabs}
          onChangeTab={handleChangeTab}
        />
        <ContentComments tab={tab} />
      </Container>
    </CommentProvider>
  );
};

export default observer(WorkArea);
