import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import api from "../../api";
import { APIProjectInput } from "../../api/projects";
import { useStore } from "../../store";
import Button from "../../components/Button";
import ProjectNameForm from "./ProjectNameForm";
import { BorderColor } from "@material-ui/icons";
import ProjectMinimal from "../../store/models/ProjectMinimal";
import { Toast } from "../../utils";

type Props = {
  instance: ProjectMinimal;
  isOpen: boolean;
  setModal: (visible: boolean) => void;
  onProjectSubmit: () => Promise<void>;
};

const RenameProject: React.FC<Props> = ({
  instance,
  isOpen,
  setModal,
  onProjectSubmit,
}) => {
  const methods = useForm();
  const store = useStore();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: APIProjectInput) => {
      setLoading(true);
      try {
        await store.projects.rename(instance.id, data);
      } catch (errorResponse: any) {
        const { detail } = await api.handleError(errorResponse);
        if (errorResponse.description !== "Unauthorized") {
          if (detail) {
            Object.entries(detail!).forEach(([key, message]) => {
              const errorMessage = Array.isArray(message)
                ? message[0]
                : message;
              methods.setError(key as any, {
                message: errorMessage as string,
              });
            });
            return;
          }
        }
      } finally {
        setLoading(false);
      }

      methods.reset();

      await onProjectSubmit();
      setModal(false);
      Toast.success("Project Renamed");
    },
    [instance, store, methods, setModal, onProjectSubmit]
  );

  return (
    <FormProvider {...methods}>
      <ProjectNameForm
        disabled={loading}
        instance={instance}
        isOpen={isOpen}
        setModal={setModal}
        headerText="Rename Project"
        submitButton={
          <Button disabled={loading} type="submit">
            <BorderColor /> Rename
          </Button>
        }
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
};

export default observer(RenameProject);
