import { ColumnExtraOptions } from "../../../components/DataGrid/types";
import { mappingMarginalCostPctCountry } from "../../../csv/mappings/collect";
import { IRow } from "../../../csv/types";
import { getNumberCell, givenCell, idCell } from "./utils";

const schemaMarginalCostPctCountry = (key: string, sheetEditable?: boolean) => {
  const defaultCell = {
    name: mappingMarginalCostPctCountry[key]
      ? mappingMarginalCostPctCountry[key][0]
      : key,
    width: mappingMarginalCostPctCountry[key]
      ? mappingMarginalCostPctCountry[key][1]
      : 50,
  };

  const numberCellPct = {
    ...defaultCell,
    ...getNumberCell(sheetEditable ?? true, 2, 2, true),
  };
  const givenMarginal = {
    ...defaultCell,
    ...givenCell,
  };

  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: idCell,
    dest_country: givenMarginal,
  };
  return options[key] || numberCellPct;
};

export default schemaMarginalCostPctCountry;
