import { CSVFrozenHeaderMapping } from "csv/mappings";
import { ColumnExtraOptions } from "../../../components/DataGrid/types";
import { mappingOHPackMfg } from "../../../csv/mappings/collect";
import { IRow } from "../../../csv/types";
import {
  clusterRestrictedCellGenerator,
  editableCellGenerator,
  getActiveColumn,
  getGivenCell,
  getGivenNumberCell,
  getInputCell,
  getNumberCell,
  givenCell,
  idCell,
} from "./utils";

const schemaOHPackMfg = (
  key: string,
  sheetEditable?: boolean,
  clusterName?: string,
  frozenIdentifiers?: CSVFrozenHeaderMapping
) => {
  const defaultCell = {
    name: mappingOHPackMfg[key] ? mappingOHPackMfg[key][0] : key,
    width: mappingOHPackMfg[key] ? mappingOHPackMfg[key][1] : 50,
  };

  const givenCellOHPackMfg = {
    ...defaultCell,
    ...givenCell,
  };
  const givenNumberCellOHPackMfg = {
    ...defaultCell,
    ...getGivenNumberCell(1, 1, false, true),
  };
  const unfrozenGivenNumberCellOHPackMfg = {
    ...defaultCell,
    ...getGivenNumberCell(1, 1, false),
  };
  const unfrozenGivenCellOHMfg = {
    ...defaultCell,
    ...getGivenCell(false),
  };
  const inputCell = {
    ...defaultCell,
    ...getInputCell(sheetEditable ?? true),
  };
  const numberCellDefault = {
    ...defaultCell,
    ...getNumberCell(sheetEditable ?? true, 3, 3),
  };

  const percentCellDefault = {
    ...defaultCell,
    ...getNumberCell(sheetEditable ?? true, 3, 3, true),
  };

  const sourceRestrictedNumberCellDefault = clusterRestrictedCellGenerator(
    numberCellDefault,
    "source_clt",
    clusterName,
    sheetEditable
  );

  const sourceRestrictedNumberCellYieldPct = clusterRestrictedCellGenerator(
    {
      ...defaultCell,
      ...getNumberCell(sheetEditable ?? true, 2, 2, true),
    },
    "source_clt",
    clusterName,
    sheetEditable
  );

  const repackagingRestrictedNumberCellDefault = clusterRestrictedCellGenerator(
    numberCellDefault,
    "repackaging_clt",
    clusterName,
    sheetEditable
  );

  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: idCell,
    active: getActiveColumn(frozenIdentifiers?.active),
    concatenate: givenCellOHPackMfg,
    source_plant: !frozenIdentifiers?.source_plant
      ? unfrozenGivenCellOHMfg
      : givenCellOHPackMfg,
    repackaging_plant: !frozenIdentifiers?.repackaging_plant
      ? unfrozenGivenCellOHMfg
      : givenCellOHPackMfg,
    pack_size_from_source: !frozenIdentifiers?.pack_size_from_source
      ? unfrozenGivenCellOHMfg
      : givenCellOHPackMfg,
    pack_size: !frozenIdentifiers?.pack_size
      ? unfrozenGivenCellOHMfg
      : givenCellOHPackMfg,
    formulation_code: !frozenIdentifiers?.formulation_code
      ? unfrozenGivenCellOHMfg
      : givenCellOHPackMfg,
    pack_type: !frozenIdentifiers?.pack_type
      ? unfrozenGivenCellOHMfg
      : givenCellOHPackMfg,
    volume_yr1: !frozenIdentifiers?.volume_yr1
      ? unfrozenGivenNumberCellOHPackMfg
      : givenNumberCellOHPackMfg,
    alt_scenario: !frozenIdentifiers?.alt_scenario
      ? unfrozenGivenCellOHMfg
      : givenCellOHPackMfg,
    alt_sku_manufacturing: inputCell,
    packaging_labelling_cost: sourceRestrictedNumberCellDefault,
    specific_packaging_labelling_cost: sourceRestrictedNumberCellDefault,
    yield_loss: sourceRestrictedNumberCellDefault,
    bp_blending_fee: sourceRestrictedNumberCellDefault,
    "3p_toll_blending_cost": sourceRestrictedNumberCellDefault,
    other_bp_manufacturing_cost: sourceRestrictedNumberCellDefault,
    group_purchase: sourceRestrictedNumberCellDefault,
    "3p_purchase": sourceRestrictedNumberCellDefault,
    percent_cogs_efficiency_savings: sourceRestrictedNumberCellDefault,
    bp_filling_fee: sourceRestrictedNumberCellDefault,
    depreciation: sourceRestrictedNumberCellDefault,
    percent_yield_loss: sourceRestrictedNumberCellYieldPct,
    packaging_labelling_cost_repacking_country:
      repackagingRestrictedNumberCellDefault,
    specific_packaging_labelling_cost_repacking_country:
      repackagingRestrictedNumberCellDefault,
    decanting_cost: repackagingRestrictedNumberCellDefault,
    br_icms_tax: editableCellGenerator(
      numberCellDefault,
      "Brazil" === clusterName,
      sheetEditable
    ),
    my_sales_tax: editableCellGenerator(
      percentCellDefault,
      "Malaysia & Singapore" === clusterName,
      sheetEditable
    ),
  };
  return options[key] || numberCellDefault;
};

export default schemaOHPackMfg;
