import { ArrowDropDown, Menu } from "@material-ui/icons";
import Logo from "components/Logo";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import {
  Button,
  Image,
  Nav,
  Navbar as BSNavbar,
  NavDropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useStore } from "../../store";
import NavbarItem from "./NavbarItem";
import SideNav from "./SideNav";
import { BreadcrumbItem } from "./types";

type Props = {
  /** An array describing a breadcrumb that is rendered into the top navbar. */
  breadcrumb?: BreadcrumbItem[];
  renderRight?: () => JSX.Element;
};

const StyledNavbar = styled(BSNavbar)`
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  height: 4rem;
  line-height: 1;
  padding-bottom: 0;
  padding-top: 0;
`;

const StyledNav = styled(Nav).attrs({ className: "mr-auto" })`
  align-items: center;
`;

const SideMenuButton = styled(Button)`
  background-color: transparent;
  color: #292929;
  border: 0;
  padding: 1rem 0.5rem;

  &:active,
  &:hover,
  &:focus {
    background-color: transparent !important;
    color: #292929 !important;
    box-shadow: none !important;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Right = styled.div`
  align-items: center;
  display: flex;
  max-height: 2.5rem;
  margin-right: 1.25rem;
  gap: 0.6875rem;
`;

const UserNavDropdown = styled(NavDropdown)`
  margin-left: 1.25rem;

  .nav-link {
    padding: 0 !important;
  }

  .dropdown-toggle:after {
    display: none;
  }

  .dropdown-menu {
    top: calc(100% + 0.6rem);
  }
`;

const Name = styled.span`
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: right;
  align-self: center;
  min-width: 6.25rem;
  max-width: 12.5rem;
  color: #000000;
`;

const DefaultAvatar = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background-color: #e2e2e2;
  border-radius: 1.25rem;
  border: solid 1px #e8e8e8;
`;

const Avatar = styled(Image)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  border: solid 1px #e8e8e8;
`;

const NavLogo = styled(Logo)`
  height: 32px;
  margin-left: 0.5rem;
`;

const Navbar: React.FC<Props> = ({ breadcrumb, renderRight }) => {
  const store = useStore();
  const { auth } = store;
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  return (
    <StyledNavbar className="justify-content-between" fixed="top">
      <SideNav show={showSidebar} onClose={() => setShowSidebar(false)} />

      <StyledNav>
        <SideMenuButton onClick={() => setShowSidebar(true)}>
          <Menu />
        </SideMenuButton>
        <Link to="/">
          <NavLogo />
        </Link>
        {breadcrumb?.map(({ link, title, subtitle }) => (
          <NavbarItem
            key={title}
            title={title}
            subtitle={subtitle}
            link={link}
          />
        ))}
      </StyledNav>

      <Nav>
        <Right>{renderRight?.()}</Right>
        <Name>{auth.user?.user?.name}</Name>
        <UserNavDropdown
          alignRight
          title={
            auth.user?.user?.photo ? (
              <>
                <Avatar src={auth.user?.user?.photoSizes.big} />
                <ArrowDropDown />
              </>
            ) : (
              <>
                <DefaultAvatar />
                <ArrowDropDown />
              </>
            )
          }
        >
          <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
        </UserNavDropdown>
      </Nav>
    </StyledNavbar>
  );
};

export default observer(Navbar);
