import {
  model,
  Model,
  prop_mapObject,
  _await,
  modelFlow,
  _async,
  modelAction,
} from "mobx-keystone";
import api from "../api";
import { APIClusterInputSheetsStatus } from "../api/types/clusterInputSheetsStatus";
import {
  APIClusterInputSheetsStatusBulkUpdateInput,
  APIClusterInputSheetsStatustSearchParams,
} from "../api/clusterInputSheetsStatus";
import ClusterInputSheetsStatus from "./models/ClusterInputSheetsStatus";

@model("collab/ClusterInputSheetsStatusStore")
class ClusterInputSheetsStatusStore extends Model({
  listItems: prop_mapObject(
    () => new Map<identifier, ClusterInputSheetsStatus>()
  ),
}) {
  @modelAction
  saveListItem(item: APIClusterInputSheetsStatus) {
    const listItem = new ClusterInputSheetsStatus(item);
    this.listItems.set(item.id, listItem);
    return listItem;
  }

  @modelFlow
  list = _async(function* (
    this: ClusterInputSheetsStatusStore,
    page?: number,
    limit?: number,
    searchParams?: APIClusterInputSheetsStatustSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(
      api.clusterInputSheetsStatus.list(page, limit, searchParams)
    );
    this.listItems.clear();
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  lock = _async(function* (
    this: ClusterInputSheetsStatusStore,
    clusterInputSheetsStatusId: identifier
  ) {
    try {
      const result = yield* _await(
        api.clusterInputSheetsStatus.lock(clusterInputSheetsStatusId)
      );
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });

  @modelFlow
  unlock = _async(function* (
    this: ClusterInputSheetsStatusStore,
    clusterInputSheetsStatusId: identifier
  ) {
    try {
      const result = yield* _await(
        api.clusterInputSheetsStatus.unlock(clusterInputSheetsStatusId)
      );
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });

  @modelFlow
  bulkUpdate = _async(function* (
    this: ClusterInputSheetsStatusStore,
    apiData: APIClusterInputSheetsStatusBulkUpdateInput
  ) {
    try {
      const result = yield* _await(
        api.clusterInputSheetsStatus.bulk_update(apiData)
      );
      const { instances } = result;
      const results = instances.map((item) => this.saveListItem(item));
      return results;
    } catch (e) {
      throw e;
    }
  });
}

export default ClusterInputSheetsStatusStore;
