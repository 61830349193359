import { Tabs as BSTabs, Tab as BSTab } from "react-bootstrap";
import styled from "styled-components";

const Tabs = styled(BSTabs)`
  &.nav-modal {
    border-bottom: 1px solid #000000;

    .nav-link {
      color: #000000;
      font-size: 0.875rem;
      font-weight: bold;
      border-bottom: 4px solid transparent;

      &.active {
        border-color: #9bbe41;
      }
    }
  }
`;

export const Tab = styled(BSTab)``;

export default Tabs;
