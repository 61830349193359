import i18next from "i18next";
import { HTTPError, TimeoutError } from "ky";
import * as activities from "./activities";
import * as auth from "./auth";
import { config } from "./base";
import * as cltAssumptions from "./cltAssumptions";
import * as cltFiles from "./cltFiles";
import * as clusterInputSheetsStatus from "./clusterInputSheetsStatus";
import * as clusters from "./clusters";
import * as comments from "./comments";
import * as countries from "./countries";
import * as csv from "./csv";
import * as currencies from "./currencies";
import * as defineSheets from "./defineSheets";
import * as deliveryInfo from "./deliveryInfo";
import * as files from "./files";
import * as forex from "./forex";
import * as formulations from "./formulations";
import * as formulationsDatahub from "./formulationsDatahub";
import * as inputSheets from "./inputSheets";
import * as outputSheets from "./outputSheets";
import * as packSizes from "./packSizes";
import * as plants from "./plants";
import * as projectFolders from "./projectFolders";
import * as projectVersions from "./projectVersions";
import * as projects from "./projects";
import * as requirements from "./requirements";
import * as rmFormulations from "./rmFormulations";
import * as rmPriceAssumptions from "./rmPriceAssumptions";
import * as tenderOutcomes from "./tenderOutcomes";
import * as terms from "./terms";
import * as tickets from "./tickets";
import * as unitsOfMeasurement from "./unitsOfMeasurement";
import * as users from "./users";
export interface APIErrorDetail {
  [key: string]: APIErrorDetail | string[];
}

export type APIValidationErrors<T> = Partial<{ [key in keyof T]: string[] }> & {
  nonFieldErrors?: string[];
} & { [key: string]: string[] };

export interface APINestedErrorDetail<T> {
  [key: string]: string[] | APIValidationErrors<T> | APIValidationErrors<T>[];
}

export type APINestedValidationErrors<T> = Partial<{
  [key in keyof T]:
    | string[]
    | APINestedErrorDetail<T>
    | APINestedErrorDetail<T>[];
}> & {
  nonFieldErrors?: string[];
} & {
  [key: string]: string[] | APINestedErrorDetail<T> | APINestedErrorDetail<T>[];
};

export type APIErrorCode =
  | "authentication_failed"
  | "auth/auth0_management"
  | "auth/missing_user"
  | "generic/400"
  | "generic/authentication"
  | "generic/deletion"
  | "generic/missing"
  | "generic/network"
  | "generic/permission"
  | "generic/syntax"
  | "generic/throttled"
  | "generic/timeout"
  | "generic/validation"
  | "generic/web"
  | "token_not_valid";

export interface APIErrorResponse<T extends APIErrorDetail> {
  code: APIErrorCode;
  description: string;
  detail?: T | null;
  message?: string;
}

async function handleError<T extends APIErrorDetail>(
  error: Error
): Promise<APIErrorResponse<T>> {
  let errorResponse: APIErrorResponse<T>;

  if (error instanceof HTTPError) {
    const data = await error.response.json();

    errorResponse = {
      code: "generic/400",
      description: error.message,
      detail: data,
      message: i18next.t("errors.generic/400"),
    };
  } else if (error instanceof TimeoutError) {
    errorResponse = {
      code: "generic/timeout",
      description: error.message,
      message: i18next.t("errors.generic/timeout"),
    };
  } else if (error instanceof TypeError) {
    errorResponse = {
      code: "generic/network",
      description: error.message,
      message: i18next.t("errors.generic/network"),
    };
  } else {
    errorResponse = {
      code: "generic/syntax",
      description: error.message,
      message: i18next.t("errors.generic/web"),
    };
  }
  return errorResponse;
}

const api = {
  activities,
  auth,
  cltAssumptions,
  clusters,
  clusterInputSheetsStatus,
  comments,
  config,
  countries,
  currencies,
  defineSheets,
  deliveryInfo,
  files,
  forex,
  formulations,
  formulationsDatahub,
  inputSheets,
  outputSheets,
  packSizes,
  plants,
  projectVersions,
  projectFolders,
  projects,
  requirements,
  rmFormulations,
  rmPriceAssumptions,
  terms,
  unitsOfMeasurement,
  csv,
  users,
  tenderOutcomes,
  tickets,
  cltFiles,
  handleError,
};

export default api;
