import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { APIProjectInput } from "../../api/projects";
import Button from "../../components/Button";
import { Header, SubHeader } from "../../components/Header";
import ModalForm from "../../components/ModalForm";
import ProjectMinimal from "../../store/models/ProjectMinimal";

const HeaderContainer = styled.div`
  min-width: 141px;
`;

const ErrorDiv = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
`;

interface Props {
  disabled?: boolean;
  isOpen: boolean;
  headerText: string;
  submitButton: JSX.Element;
  setModal: (visible: boolean) => void;
  onSubmit: (data: APIProjectInput) => void;
  instance?: ProjectMinimal;
}

const ProjectNameForm: React.FC<Props> = ({
  disabled = false,
  isOpen,
  headerText,
  submitButton,
  setModal,
  onSubmit,
  instance,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <ModalForm show={isOpen} onHide={() => setModal(false)} centered>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalForm.Header closeButton>
          <HeaderContainer>
            <SubHeader>Projects</SubHeader>
            <Header>{headerText}</Header>
          </HeaderContainer>
        </ModalForm.Header>
        <ModalForm.Body>
          {errors.detail && <ErrorDiv>{errors.detail.message}</ErrorDiv>}
          <Form.Control
            type="text"
            {...register("name")}
            placeholder="Project Name"
            disabled={disabled}
            isInvalid={!!errors.name}
            defaultValue={instance ? instance.name : ""}
          />
          {errors.name && (
            <Form.Control.Feedback type="invalid">
              {errors.name.message}
            </Form.Control.Feedback>
          )}
        </ModalForm.Body>
        <ModalForm.Footer>
          <Button
            disabled={disabled}
            onClick={() => setModal(false)}
            variant="cancel"
          >
            Cancel
          </Button>
          {submitButton}
        </ModalForm.Footer>
      </Form>
    </ModalForm>
  );
};

export default ProjectNameForm;
