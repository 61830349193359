import {
  model,
  Model,
  prop_mapObject,
  _await,
  modelFlow,
  _async,
  modelAction,
} from "mobx-keystone";
import api from "../api";
import { APIActivity, APIActivityListSearchParams } from "../api/activities";
import Activity from "./models/Activity";

@model("collab/ActivityStore")
class ActivityStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, Activity>()),
}) {
  @modelAction
  saveListItem(item: APIActivity) {
    const listItem = new Activity(item);
    this.listItems.set(item.id, listItem);
    return listItem;
  }

  @modelFlow
  list = _async(function* (
    this: ActivityStore,
    page?: number,
    limit?: number,
    searchParams?: APIActivityListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.activities.list(page, limit, searchParams));
    if (page === 1) {
      this.listItems.clear();
    }
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelAction
  reset() {
    this.listItems.clear();
  }
}

export default ActivityStore;
