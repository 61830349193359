import api from "api";
import { addCsvSepHeader, reparseMapHeadersFromCsvString } from "csv/utils";
import Emittery from "emittery";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { flatten } from "lodash";
import { useCallback, useContext, useEffect } from "react";
import { useUnmount } from "react-use";
import { useStore } from "store";
import InputSheet from "store/models/InputSheet";
import Project from "../../store/models/Project";
import { Toast } from "../../utils";
import { ProjectContext } from "./contexts";
import { Worksheet } from "./types";

export function useProject() {
  const project = useContext(ProjectContext);
  return project;
}

interface UseSaveOptions {
  saveOnUnmount?: boolean;
  preSaveFn?: () => Promise<void>;
  postSaveFn?: () => Promise<void>;
}

export const saveEmitter = new Emittery();

export function useSave(
  saveFn: (manual: boolean) => Promise<void>,
  options?: UseSaveOptions
) {
  const opts = options || { saveOnUnmount: false };

  useUnmount(async () => {
    if (opts.saveOnUnmount) {
      try {
        await saveFn(false);
      } catch (e) {}
    }
  });

  useEffect(() => {
    const { preSaveFn, postSaveFn } = options || {};

    if (preSaveFn) {
      saveEmitter.on("presave", preSaveFn);
    }

    saveEmitter.on("save", saveFn);

    if (postSaveFn) {
      saveEmitter.on("postsave", postSaveFn);
    }

    return () => {
      saveEmitter.off("save", saveFn);
      if (preSaveFn) {
        saveEmitter.off("presave", preSaveFn);
      }
      if (postSaveFn) {
        saveEmitter.off("postsave", postSaveFn);
      }
    };
  }, [options, saveFn]);
}

export function useSaveEmit(project?: Project) {
  const store = useStore();

  if (!project) {
    return async () => {};
  }

  return async () => {
    project.setLoading("Saving...");

    await saveEmitter.emit("presave");

    try {
      await saveEmitter.emit("save", true);
    } catch (e: any) {
      Toast.danger(e.message);
      project.setNotLoading();
      throw e;
    }

    await store.projects.detail(project.id); // update project last updated display

    await saveEmitter.emit("postsave");

    Toast.success("Project updated.");

    project.setNotLoading();
    project.setNotDirty();
  };
}

export function useDownload() {
  const onDownload = useCallback(
    async (worksheet: Worksheet, merge: boolean, eu?: boolean) => {
      const csvStrings: string[][] = [];
      const csvFiles = flatten(worksheet.csvFiles).concat(
        flatten(worksheet.marginalCsvFiles)
      );

      await csvFiles.reduce(
        (prev, file) =>
          prev
            .then(() => {
              if (eu) {
                const euSheet = (file.sheet as InputSheet).fileEu;
                if (euSheet) {
                  return api.csv.getEu(euSheet);
                }
              }
              return api.csv.get(file.sheet.file);
            })
            .then((response) => response.text())
            .then(
              (text) =>
                reparseMapHeadersFromCsvString(text, file.headerMapping, eu)[0]
            )
            .then((text) => {
              csvStrings.push([file.id, text]);
            }),
        Promise.resolve()
      );

      const zip = new JSZip();
      if (merge) {
        zip.file(
          `${worksheet.id}.csv`,
          addCsvSepHeader(csvStrings.map(([, text]) => text).join("\r\n\r\n"))
        );
      } else {
        csvStrings.forEach(([id, text]) => {
          zip.file(`${id}.csv`, addCsvSepHeader(text));
        });
      }
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${worksheet.id}.zip`);
    },
    []
  );

  return onDownload;
}

export function useUpload() {
  const project = useProject();
  const store = useStore();

  const onUpload = useCallback(
    async (file: string, fileType: string, cluster: identifier) => {
      if (!project) {
        return;
      }
      const data: { file: string; cluster?: number; fileType: string } = {
        file,
        fileType,
        cluster,
      };
      await api.projects.uploadInputSheet(project.id, data);
      await store.projects.detail(project.id);
      await store.inputSheets.list(project.id);
      await store.defineSheets.list(project.id);
    },
    [project, store.inputSheets, store.projects, store.defineSheets]
  );

  return onUpload;
}
