import clsx from "clsx";
import { CSVFrozenHeaderMapping } from "csv/mappings";
import React from "react";
import dropdownEditorFactory from "../../../components/DataGrid/DropdownEditor";
import { ColumnExtraOptions } from "../../../components/DataGrid/types";
import { mappingRMWorking } from "../../../csv/mappings/collect";
import { IRow } from "../../../csv/types";
import { useStore } from "../../../store";
import {
  getActiveColumn,
  getGivenCell,
  getInputCell,
  getNumberCell,
  idCell,
} from "./utils";

const schemaRMWorking = (
  key: string,
  sheetEditable?: boolean,
  frozenIdentifiers?: CSVFrozenHeaderMapping
) => {
  const defaultCell = {
    name: mappingRMWorking[key] ? mappingRMWorking[key][0] : key,
    width: mappingRMWorking[key] ? mappingRMWorking[key][1] : 50,
  };
  const givenCellRM = {
    ...getGivenCell(true),
    ...defaultCell,
  };
  const unfrozenGivenCellRM = {
    ...defaultCell,
    ...getGivenCell(false),
  };
  const inputCell = {
    ...getInputCell(sheetEditable ?? true),
    ...defaultCell,
  };
  const numberCell = {
    ...getNumberCell(sheetEditable ?? true),
    ...defaultCell,
  };
  const numberCell0 = {
    ...getNumberCell(sheetEditable ?? true, 0, 0),
    ...defaultCell,
  };
  const numberCellCurrency = {
    ...getNumberCell(sheetEditable ?? true, 2, 2),
    ...defaultCell,
  };

  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: idCell,
    concatenate: givenCellRM,
    active: getActiveColumn(frozenIdentifiers?.active),
    r_code: !frozenIdentifiers?.r_code ? unfrozenGivenCellRM : givenCellRM,
    supplier_product_name: !frozenIdentifiers?.supplier_product_name
      ? unfrozenGivenCellRM
      : givenCellRM,
    plant: !frozenIdentifiers?.plant ? unfrozenGivenCellRM : givenCellRM,
    source_clt: !frozenIdentifiers?.source_clt
      ? unfrozenGivenCellRM
      : givenCellRM,
    sap_no: !frozenIdentifiers?.sap_no ? unfrozenGivenCellRM : givenCellRM,
    supplier: inputCell,
    sap_stock_location: inputCell,
    inco_term: inputCell,
    bulk_or_drum: {
      ...inputCell,
      editor: (props) => {
        const Dropdown = dropdownEditorFactory(key, [
          { value: "Bulk", label: "Bulk" },
          { value: "Drum", label: "Drum" },
        ]);
        return <Dropdown {...props} />;
      },
      editorOptions: {
        editOnClick: true,
      },
    },
    rm_purchase_price: {
      ...numberCellCurrency,
      colSpan(args: any) {
        if (args.row && !args.row.id) {
          return 5;
        }
      },
      cellClass(row) {
        if (!row.id) {
          return "cell-subheader";
        }
        return (numberCell.cellClass! as Function)(row);
      },
      formatter: (props) => {
        if (!props.row.id) {
          return <>{props.row.rm_purchase_price}</>;
        }
        const Formatter = numberCellCurrency.formatter as React.ComponentType;
        return <Formatter {...props} />;
      },
    },
    supply_country: inputCell,
    supplier_freight: numberCellCurrency,
    drumming_differential_per_mt: {
      ...getNumberCell(sheetEditable ?? true, 2, 2),
      ...defaultCell,
      editable: (row: IRow) => {
        return row["bulk_or_drum"] !== "Bulk";
      },
      cellClass: (row: IRow) => {
        if (!sheetEditable) {
          return "cell-readonly";
        }
        return row["bulk_or_drum"] !== "Bulk"
          ? clsx((inputCell.cellClass! as Function)(row), "cell-numeric")
          : "cell-input cell-disallowed";
      },
    },
    rebates_per_mt: {
      ...numberCellCurrency,
      colSpan(args: any) {
        if (args.row && !args.row.id) {
          return 2;
        }
      },
      cellClass(row) {
        if (!row.id) {
          return "cell-subheader";
        }
        return (numberCellCurrency.cellClass! as Function)(row);
      },
      formatter: (props) => {
        if (!props.row.id) {
          return <>{props.row.rebates_per_mt}</>;
        }
        const Formatter = numberCellCurrency.formatter as React.ComponentType;
        return <Formatter {...props} />;
      },
    },
    discounts_per_mt: numberCellCurrency,
    freight_cost: {
      ...numberCellCurrency,
      cellClass(row) {
        if (!row.id) {
          return "cell-subheader";
        }
        return (numberCellCurrency.cellClass! as Function)(row);
      },
      formatter: (props) => {
        if (!props.row.id) {
          return <>{props.row.freight_cost}</>;
        }
        const Formatter = numberCellCurrency.formatter as React.ComponentType;
        return <Formatter {...props} />;
      },
    },
    currency_of_purchase: {
      ...inputCell,
      editor: function Editor(props) {
        const store = useStore();
        const Dropdown = dropdownEditorFactory(
          key,
          store.assets.currencySelect.map(({ code }) => ({
            value: code,
            label: code,
          }))
        );
        return <Dropdown {...props} />;
      },
      editorOptions: {
        editOnClick: true,
      },
    },
    unit_of_measure: {
      ...inputCell,
      editor: function Editor(props) {
        const store = useStore();
        const Dropdown = dropdownEditorFactory(
          key,
          store.assets.unitSelect.map(({ label }) => ({
            value: label,
            label,
          }))
        );
        return <Dropdown {...props} />;
      },
      editorOptions: {
        editOnClick: true,
      },
    },
    price_obtained_from: {
      ...inputCell,
      editor: (props) => {
        const Dropdown = dropdownEditorFactory(key, [
          { value: "PLMS", label: "PLMS" },
          { value: "RMPA", label: "RMPA" },
          { value: "Invoice", label: "Invoice" },
          { value: "PCTP list", label: "PCTP list" },
          { value: "Other", label: "Other" },
        ]);
        return <Dropdown {...props} />;
      },
      editorOptions: {
        editOnClick: true,
      },
    },
    cost_per_mt: {
      ...numberCell0,
      editable: false,
      colSpan(args: any) {
        if (args.row && !args.row.id) {
          return 2;
        }
      },
      cellClass(row) {
        if (!row.id) {
          return "cell-subheader";
        }
        return clsx((numberCell0.cellClass! as Function)(row), "cell-given");
      },
      formatter: (props) => {
        if (!props.row.id) {
          return <>{props.row.cost_per_mt}</>;
        }
        const Formatter = numberCell0.formatter as React.ComponentType;
        return <Formatter {...props} />;
      },
    },
    cost_per_mt_spec_curr: {
      ...numberCell0,
      editable: false,
      cellClass(row) {
        if (!row.id) {
          return "cell-subheader";
        }
        return clsx((numberCell0.cellClass! as Function)(row), "cell-given");
      },
    },
  };
  return options[key] || inputCell;
};

export default schemaRMWorking;
