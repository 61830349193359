import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import { Form, Image, InputGroup } from "react-bootstrap";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { Mention, MentionsInput, MentionsInputProps } from "react-mentions";
import { useStore } from "store";
import styled from "styled-components";

type Props<I extends FieldValues> = UseControllerProps<I> &
  Pick<MentionsInputProps, "placeholder">;

const Control = styled(MentionsInput)`
  margin-bottom: 1rem;
  width: 100%;
`;

const Avatar = styled(Image)`
  display: inline-block;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: solid 1px #e8e8e8;
`;

const style = {
  "&multiLine": {
    control: {
      backgroundColor: "#ffffff",
      borderRadius: "0.25rem 0.25rem 0 0",
      fontSize: "0.875rem",
      minHeight: "3rem",
    },
    input: { padding: "0.25rem 0.5rem" },
    highlighter: { padding: "0.25rem 0.5rem" },
  },
  suggestions: {
    boxShadow: "0 0.25rem 0.75rem rgb(0 0 0 / 16%)",
    borderRadius: "0 0 0.25rem 0.25rem",
    marginTop: 0,
    top: "100%",
    left: 0,
    right: 0,
    zIndex: 9999,
    list: {
      margin: 0,
    },
    item: {
      fontSize: "0.875rem",
      padding: "0.46875rem 1.875rem 0.46875rem 0.9375rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&focused": {
        backgroundColor: "#e9ecef",
      },
    },
  },
};

function AtMentionBox<I extends FieldValues>(props: Props<I>) {
  const store = useStore();
  const project = useProject();
  const { field, fieldState } = useController(props);

  const mentionData = project
    ? store.users.atMentionUsersForCluster(project?.id, project?.activeCluster)
    : [];

  return (
    <InputGroup
      hasValidation
      onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}
    >
      {/*
      // @ts-ignore */}
      <Control
        {...field}
        placeholder={props.placeholder}
        style={style}
        a11ySuggestionsListLabel="Suggested mentions"
        onPaste={(e) => e.stopPropagation()}
        ignoreAccents
      >
        <Mention
          trigger="@"
          markup="@[__id__]"
          data={mentionData}
          appendSpaceOnAdd
          displayTransform={(id, display) => `@${id}`}
          style={{ backgroundColor: "#e9ecef" }}
          renderSuggestion={(suggestion) => (
            <div>
              <Avatar src={(suggestion as any).photo} /> {suggestion.display}
            </div>
          )}
        />
      </Control>
      <Form.Control.Feedback type="invalid">
        {fieldState.error}
      </Form.Control.Feedback>
    </InputGroup>
  );
}

export default observer(AtMentionBox);
