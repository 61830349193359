import React from "react";

import { Modal as BSModal } from "react-bootstrap";
import { confirmable } from "react-confirm";
import styled from "styled-components";
import Button from "../Button";
import { Header, SubHeader } from "../Header";

const Modal = styled(BSModal)`
  .modal-content {
    background: #ffffff;
    border: 0.063rem solid #000000;
    box-sizing: border-box;
    box-shadow: 0 0.25rem 0.875rem rgba(0, 0, 0, 0.14);
    border-radius: 0.25rem;
  }

  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-header {
    padding: 1.875rem 1.875rem 0.625rem;
    div:last-of-type {
      text-align: right;

      h3 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 0.75rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .modal-body {
    padding: 0.625rem 1.875rem 0.25rem;

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.625rem;
      margin-bottom: 0;
    }
  }
  .modal-footer {
    padding: 0.25rem 1.875rem 1.875rem;
  }
`;

type Props = {
  okLabel?: string | JSX.Element;
  cancelLabel?: string;
  title?: string;
  subtitle?: string;
  rightText?: string;
  rightSubtext?: string;
  confirmation?: string;
  show?: boolean;
  proceed: (value: boolean) => void;
  enableEscape?: boolean;
};

const ConfirmModal = ({
  okLabel = "OK",
  cancelLabel = "Cancel",
  subtitle,
  title,
  rightText,
  rightSubtext,
  confirmation,
  show,
  proceed,
  enableEscape = true,
}: Props) => {
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      backdrop={enableEscape ? true : "static"}
      keyboard={enableEscape}
      centered
    >
      <Modal.Header>
        <div>
          <SubHeader>{subtitle}</SubHeader>
          <Header>{title}</Header>
        </div>
        <div>
          <SubHeader>{rightSubtext}</SubHeader>
          <h3>{rightText}</h3>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>{confirmation}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="cancel" onClick={() => proceed(false)}>
          {cancelLabel}
        </Button>
        <Button onClick={() => proceed(true)}>{okLabel}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default confirmable(ConfirmModal);
