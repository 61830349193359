import { observer } from "mobx-react-lite";
import { EditorProps } from "react-data-grid";
import Select from "react-select";
import { IRow } from "../../csv/types";
import { scaledRem } from "../../utils";

interface Choice {
  value: number | string;
  label: string;
  disabled?: boolean;
}

export default function dropdownEditorFactory(key: string, choices: Choice[]) {
  return observer(function DropDownEditor({
    row,
    onRowChange,
    editorPortalTarget,
  }: EditorProps<IRow>) {
    const options = [{ value: "", label: "------" }, ...choices];

    return (
      <Select
        autoFocus
        menuIsOpen
        classNamePrefix="react-select"
        value={options.find((o) => o.value.toString() === row[key]?.toString())}
        onChange={(value) => {
          onRowChange(
            {
              ...row,
              [key]: value?.value || "",
            },
            true
          );
        }}
        options={options}
        menuPortalTarget={editorPortalTarget as HTMLElement}
        styles={{
          control: (provided) => ({
            ...provided,
            height: scaledRem(24) - 1,
            minHeight: scaledRem(24),
            lineHeight: "normal",
            border: 0,
            borderRadius: 0,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            height: scaledRem(24) - 1,
          }),
          clearIndicator: (provided) => ({
            ...provided,
            display: "none",
          }),
          option: (provided) => ({
            ...provided,
            minHeight: scaledRem(24),
            fontSize: scaledRem(10),
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: 0,
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 1000,
          }),
        }}
      />
    );
  });
}
