import { observer } from "mobx-react-lite";
import React from "react";
import { APIOutputSheetFileType } from "../../api/outputSheets";
import { CustomSheet } from "../Workspace/types";
import SelectionsTab from "./SelectionsTab";

type Props = {
  tab: CustomSheet;
};

const fileType: APIOutputSheetFileType = "sourcing_selection";

const SourcingSelectionsTab: React.FC<Props> = ({ tab }) => {
  return <SelectionsTab id={tab.id} fileType={fileType} />;
};

export default observer(SourcingSelectionsTab);
