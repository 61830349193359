import base, { APIDetail, APIList, APISearchParams, config } from "./base";
import { APIClusterInputSheetsStatus } from "./types/clusterInputSheetsStatus";

export type APIClusterInputSheetsStatusInput = Partial<
  Omit<
    APIClusterInputSheetsStatus,
    "id" | "reissueDate" | "reissueVersion" | "submitVersion"
  >
>;

export interface APIClusterInputSheetsStatusDetail
  extends APIDetail<APIClusterInputSheetsStatus> {}

export interface APIClusterInputSheetsStatusList
  extends APIList<APIClusterInputSheetsStatus> {}
export interface APIClusterInputSheetsStatustSearchParams
  extends APISearchParams {
  projects?: identifier;
}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIClusterInputSheetsStatustSearchParams
) =>
  base.list<
    APIClusterInputSheetsStatusList,
    APIClusterInputSheetsStatustSearchParams
  >("cluster-input-sheets-status/", page, limit, searchParams);

export type APIClusterInputSheetsStatusUpdateInput = Partial<
  Pick<
    APIClusterInputSheetsStatus,
    "id" | "dueDate" | "hasQueries" | "submitVersion"
  >
>;
export type APIClusterInputSheetsStatusBulkUpdateInput = {
  instances: APIClusterInputSheetsStatusUpdateInput[];
};

export const lock = (clusterInputSheetsStatusId: identifier) =>
  base.patch<APIClusterInputSheetsStatusDetail>(
    `cluster-input-sheets-status/${clusterInputSheetsStatusId}/lock/`
  );

export const unlock = (clusterInputSheetsStatusId: identifier) =>
  base.patch<APIClusterInputSheetsStatusDetail>(
    `cluster-input-sheets-status/${clusterInputSheetsStatusId}/unlock/`
  );

export type APIClusterInputSheetsStatusBulkUpdateDetail = {
  instances: APIClusterInputSheetsStatusDetail[];
};

export const bulk_update = (
  input: APIClusterInputSheetsStatusBulkUpdateInput
) =>
  base.patch<APIClusterInputSheetsStatusBulkUpdateDetail>(
    `cluster-input-sheets-status/bulk-update/`,
    { json: input }
  );
