import valueMapMapper from "components/DataGrid/Mapper";
import { getNumberFormatter } from "components/DataGrid/NumberFormatter";
import SelectCellFormatter from "components/DataGrid/SelectCellFormatter";
import { IRow } from "csv/types";
import { observer } from "mobx-react-lite";
import React from "react";
import { FormatterProps } from "react-data-grid";
import { useStore } from "store";

type Props = FormatterProps<IRow>;

const FormulationFormatter: React.FC<Props> = (props) => {
  const { row, column } = props;
  const store = useStore();

  const cell = row[column.key];

  switch (row.id) {
    case "plantType":
      return (
        <>
          {(cell as string).length > 0
            ? (cell as string)[0].toLocaleUpperCase() +
              (cell as string).slice(1)
            : ""}
        </>
      );
    case "plant":
    case "repackagingPlant": {
      const Formatter = valueMapMapper(
        column.key,
        store.assets.plantListItems,
        false,
        (mapped) => (mapped ? mapped.name : "")
      );
      return <Formatter {...props} />;
    }
    case "sourcePlants": {
      const Formatter = valueMapMapper(
        column.key,
        store.assets.plantListItems,
        true,
        (mapped) => (mapped ? mapped.name : "")
      );
      return <Formatter {...props} />;
    }
    case "clusters": {
      const Formatter = valueMapMapper(
        column.key,
        store.assets.clusterListItems,
        true
      );
      return <Formatter {...props} />;
    }
    case "countries": {
      const Formatter = valueMapMapper(
        column.key,
        store.assets.countryListItems,
        true
      );
      return <Formatter {...props} />;
    }
    case "packSizes":
    case "repackagingPackSizes": {
      const Formatter = valueMapMapper(
        column.key,
        store.assets.packSizeListItems,
        true
      );
      return <Formatter {...props} />;
    }
    case "isApproved": {
      const value = cell === true || cell === "true" ? true : false;

      return (
        <SelectCellFormatter
          tabIndex={-1}
          value={value}
          disabled={!column.editable}
          onChange={() => {
            if (column.editable) {
              props.onRowChange({
                ...row,
                [column.key]: String(!Boolean(value)),
              });
            }
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          isCellSelected={props.isCellSelected}
        />
      );
    }
    case "plantSplitPercent":
      const Formatter = getNumberFormatter(0, 2, true);
      return <Formatter {...props} />;
  }
  return <>{cell}</>;
};

export default observer(FormulationFormatter);
