import { Check, SaveOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import Project from "../../store/models/Project";
import Button from "../Button";

type Props = {
  isSubmitting: boolean;
  isSubmitSuccessful: boolean;
  project: Project;
  setNotSubmitSuccessful: () => void;
  onClick: () => void;
};

const StyledButton = styled(Button)`
  &.btn {
    .spinner-border {
      margin-right: 0.625rem;
    }
  }
`;

const SaveButton: React.FC<Props> = ({
  isSubmitting,
  isSubmitSuccessful,
  project,
  onClick,
  setNotSubmitSuccessful,
}) => {
  useEffect(() => {
    if (isSubmitSuccessful) {
      setTimeout(() => {
        setNotSubmitSuccessful();
      }, 5000);
    }
  }, [isSubmitSuccessful, setNotSubmitSuccessful]);

  return (
    <StyledButton disabled={project.loading || isSubmitting} onClick={onClick}>
      {isSubmitting ? (
        <>
          <Spinner animation="border" size="sm" /> Saving
        </>
      ) : isSubmitSuccessful ? (
        <>
          <Check /> Saved
        </>
      ) : (
        <>
          <SaveOutlined /> Save
        </>
      )}
    </StyledButton>
  );
};

export default observer(SaveButton);
