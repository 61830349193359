// import { PresenceData } from '../../api/types';
import { observer } from "mobx-react-lite";
import TenderOutcomes from "pages/TenderOutcomes";
import React from "react";
import { Redirect, Router, Switch } from "react-router-dom";
import history from "../../services/history";
import { AuthCallback, Login, Logout } from "../Auth";
import ProjectsDrive from "../Projects";
import Folder from "../Projects/Folder";
import PermissionsSummary from "../Projects/PermissionsSummary";
import Workspace from "../Workspace";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";

const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Redirect exact from="/" to="/projects" />

        <UnauthenticatedRoute path="/login">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute path="/auth-callback">
          <AuthCallback />
        </UnauthenticatedRoute>

        <AuthenticatedRoute exact path="/projects">
          <ProjectsDrive />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path="/tender-outcomes">
          <TenderOutcomes />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/projects/:id">
          <Workspace />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/folders/:slug">
          <Folder />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/permissions">
          <PermissionsSummary />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/logout" component={Logout} />
      </Switch>
    </Router>
  );
};

export default observer(Routes);
