import { CancelOutlined, FilterList } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { ButtonGroup, Form, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import styled from "styled-components";
import { useProject } from "../../pages/Workspace/hooks";
import { useStore } from "../../store";
import RMFormulationFilterModel from "../../store/models/RMFormulationFilter";
import Button from "../Button";

type Props = {};

const Filters = styled(Form)`
  background-color: #ffffff;
  border: 1px solid #b9b9b9;
  border-radius: 0.25rem;
  box-shadow: 0px 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  font-weight: bold;
  padding: 1.25rem;
  width: 18rem;
  z-index: 10;
`;

const FormFooter = styled.footer`
  text-align: right;
`;

const FormulationFilter: React.FC<Props> = () => {
  const store = useStore();
  const project = useProject();

  useEffect(() => {
    if (project) {
      store.rmFormulations.createFilters(project.id);
    }
  }, [project, store.rmFormulations]);

  const filter =
    store.rmFormulations.filters.get(project?.id || 0) ||
    new RMFormulationFilterModel({});

  const { isFiltering } = filter;

  const productSpecs = project
    ? store.rmFormulations.productSpecsOfProject(project.id)
    : [];
  const formulationCodes = project
    ? store.rmFormulations.formulationCodesOfProject(project.id)
    : [];
  const plants = project
    ? store.rmFormulations.plantsOfProject(project.id)
    : [];
  const formulationOptions = project
    ? store.rmFormulations.formulationOptionsOfProject(project.id)
    : [];
  const plantOptions = project
    ? store.rmFormulations.plantOptionsOfProject(project.id)
    : [];
  const altScenarios = project
    ? store.rmFormulations.altScenariosOfProject(project.id)
    : [];

  return (
    <ButtonGroup>
      <OverlayTrigger
        trigger="click"
        placement="bottom-start"
        offset={[0, 20]}
        rootClose
        overlay={
          <Filters>
            <Form.Group>
              <Form.Label>Product Spec</Form.Label>
              <Select
                options={productSpecs}
                onChange={(value) => {
                  filter.setProductSpecs(value.map((v) => v.value));
                  if (project) {
                    store.rmFormulations.commitCsv(project.id);
                  }
                }}
                value={filter.selectedProductSpecs}
                isMulti
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Formulation Code</Form.Label>
              <Select
                options={formulationCodes}
                onChange={(value) => {
                  filter.setFormulationCodes(value.map((v) => v.value));
                  if (project) {
                    store.rmFormulations.commitCsv(project.id);
                  }
                }}
                value={filter.selectedFormulationCodes}
                isMulti
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Plant</Form.Label>
              <Select
                options={plants}
                onChange={(value) => {
                  filter.setPlants(value.map((v) => v.value));
                  if (project) {
                    store.rmFormulations.commitCsv(project.id);
                  }
                }}
                value={filter.selectedPlants}
                isMulti
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Formulation Option</Form.Label>
              <Select
                options={formulationOptions}
                onChange={(value) => {
                  filter.setFormulationOptions(value.map((v) => v.value));
                  if (project) {
                    store.rmFormulations.commitCsv(project.id);
                  }
                }}
                value={filter.selectedFormulationOptions}
                isMulti
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Plant Option</Form.Label>
              <Select
                options={plantOptions}
                onChange={(value) => {
                  filter.setPlantOptions(value.map((v) => v.value));
                  if (project) {
                    store.rmFormulations.commitCsv(project.id);
                  }
                }}
                value={filter.selectedPlantOptions}
                isMulti
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Alt Scenario</Form.Label>
              <Select
                options={altScenarios}
                onChange={(value) => {
                  filter.setAltScenarios(value.map((v) => v.value));
                  if (project) {
                    store.rmFormulations.commitCsv(project.id);
                  }
                }}
                value={filter.selectedAltScenarios}
                isMulti
              />
            </Form.Group>

            <FormFooter>
              <Button
                type="button"
                variant="borderless"
                size="thin"
                onClick={() => {
                  filter.reset();
                  if (project) {
                    store.rmFormulations.commitCsv(project.id);
                  }
                }}
              >
                Clear
              </Button>
            </FormFooter>
          </Filters>
        }
      >
        <Button variant={isFiltering ? "inverted" : "primary"} size="thin">
          <FilterList /> Filter
        </Button>
      </OverlayTrigger>
      {isFiltering ? (
        <Button
          variant="inverted"
          size="thin"
          onClick={() => {
            filter.reset();
            if (project) {
              store.rmFormulations.commitCsv(project.id);
            }
          }}
        >
          <CancelOutlined />
          Clear Filter
        </Button>
      ) : null}
    </ButtonGroup>
  );
};

export default observer(FormulationFilter);
