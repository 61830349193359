import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { CheckCircle, Warning, Cancel } from "@material-ui/icons";
import { ValidationItem } from "./types";

type ValidationSectionProps = {
  title: string;
  validations: ValidationItem[];
  keys?: Set<string>;
};

const ValidationCheckIcon = styled(CheckCircle)`
  color: #9bbe41;
  margin-left: 15px;
  margin-right: 10px;
`;

const ValidationWarningIcon = styled(Warning)`
  color: #cea834;
  margin-left: 15px;
  margin-right: 10px;
`;

const ValidationErrorIcon = styled(Cancel)`
  color: #eb5757;
  margin-left: 15px;
  margin-right: 10px;
`;

const ValidationSectionHeader = styled.div`
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: capitalize;
  background-color: #e0e0e0;
  padding: 5px 15px;
  margin-top: 10px;
`;

const ValidationSectionItem = styled.div`
  font-size: 12px;
  font-weight: 600;
  padding-top: 10px;
  display: flex;
  align-items: center;
  line-height: 1.5;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const ValidationSection: React.FC<ValidationSectionProps> = ({
  title,
  validations,
  keys,
}) => {
  return (
    <>
      <ValidationSectionHeader>{title}</ValidationSectionHeader>
      {validations.map((validation, index) => {
        return (
          <ValidationSectionItem key={`section-item-${index}`}>
            {!keys || validation.keys.filter((e) => keys.has(e)).length > 0 ? (
              validation.type === "E" ? (
                <ValidationErrorIcon />
              ) : validation.type === "W" ? (
                <ValidationWarningIcon />
              ) : (
                <ValidationCheckIcon />
              )
            ) : (
              <ValidationCheckIcon />
            )}
            <div>{validation.text}</div>
          </ValidationSectionItem>
        );
      })}
    </>
  );
};

export default observer(ValidationSection);
