import React from "react";
import { Column, SortDirection } from "react-data-grid/lib";
import { IRow } from "../../csv/types";
import {
  HeaderCheckboxFilters,
  HeaderSelectionFilters,
  Position,
  RangeSelection,
} from "./types";

export interface RangeSelectionContextProps {
  rows: readonly IRow[];
  columns: readonly Column<IRow>[];
  range?: RangeSelection;
  setRange?: React.Dispatch<React.SetStateAction<RangeSelection | undefined>>;
  selected?: Position;
  setSelected?: (selected: Position) => void;
  selectionType: string;
  setSelectionType?: React.Dispatch<React.SetStateAction<string>>;
}

export const RangeSelectionContext =
  React.createContext<RangeSelectionContextProps>({
    rows: [],
    columns: [],
    selectionType: "",
  });

export interface DataGridContextProps {
  id: string;
  rowIdentifier: string;
  errors?: Record<string, string>;
  commentIdentifiers?: Record<string, boolean>;
  onCellSelect?: (row: IRow, column: Column<IRow>) => void;
}

export const DataGridContext = React.createContext<DataGridContextProps>({
  id: "",
  rowIdentifier: "id",
});

export interface FilterContextProps {
  filterable: boolean;
  columnBoundingLeft: number;
  setColumnBoundingLeft: (columnBoundingLeft: number) => void;
  boundingTop: number;
  setBoundingTop: (boundingTop: number) => void;
  columnKey: string;
  setColumnKey: (columnKey: string) => void;
  showDropdown: boolean;
  setShowDropdown: (showDropdown: boolean) => void;
  checkboxFilters: HeaderCheckboxFilters;
  selectionFilters: HeaderSelectionFilters;
  sortDirection: SortDirection;
  sortColumn: string;
}

export const FilterContext = React.createContext<FilterContextProps>({
  filterable: false,
  columnBoundingLeft: 0,
  setColumnBoundingLeft: () => {},
  boundingTop: 0,
  setBoundingTop: () => {},
  columnKey: "",
  setColumnKey: () => {},
  showDropdown: false,
  setShowDropdown: () => {},
  checkboxFilters: {},
  selectionFilters: {},
  sortDirection: "NONE",
  sortColumn: "",
});
