import base, { APIList, APISearchParams, config } from "./base";

export interface APIActivity {
  id: identifier;
  created: string;
  project: identifier;
  projectName: string;
  projectVersion: identifier;
  projectVersionNumber: number;
  user: identifier;
  userName: string;
  userPhotoSizes: {
    big?: string;
    medium?: string;
  };
  clusters: identifier[];
  clusterNames: string[];
  activityType: string;
  notes: string;
  subActivityCount: number;
}

export interface APISubActivity {
  id: identifier;
  created: string;
  project: identifier;
  projectName: string;
  projectVersion: identifier;
  projectVersionNumber: number;
  user: identifier;
  userName: string;
  userPhotoSizes: {
    big?: string;
    medium?: string;
  };
  activity: identifier;
  subActivityType: string;
  formulation: identifier;
  formulationSpec: string;
  requirement: identifier;
  requirementSpec: string;
}

export interface APIActivityList extends APIList<APIActivity> {}
export interface APIActivityListSearchParams extends APISearchParams {}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIActivityListSearchParams
) =>
  base.list<APIActivityList, APIActivityListSearchParams>(
    "activities/",
    page,
    limit,
    searchParams
  );

export interface APISubActivityList extends APIList<APISubActivity> {}
export interface APISubActivityListSearchParams extends APISearchParams {}
export const listSubActivities = (
  activityId: identifier,
  url: string | null,
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APISubActivityListSearchParams
) =>
  url
    ? base.get<APISubActivityList>(url, { prefixUrl: "" })
    : base.list<APISubActivityList, APISubActivityListSearchParams>(
        url || "subactivities/",
        page,
        limit,
        { activity: activityId, ...searchParams }
      );
