import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import { BreadcrumbItem } from "../components/Navbar/types";

const Container = styled.div`
  background-color: #ffffff;
  color: #000000;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  height: 100%;
`;

const Content = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`;

const NavContent = styled(Content)`
  background-color: #ffffff;
  padding-top: 4rem;
`;

type Props = React.PropsWithChildren<{
  /** An array describing a breadcrumb that is rendered into the top navbar. */
  breadcrumb?: BreadcrumbItem[];

  /** If enabled, the top navbar will not be rendered. */
  hideNav?: boolean;

  /** String to be prepended to the page title. */
  title?: string;

  renderHeaderRight?: () => JSX.Element;
}>;

export const Page: React.FC<Props> = ({
  breadcrumb,
  hideNav = false,
  title,
  children,
  renderHeaderRight,
}) => {
  return (
    <Container>
      <Helmet>
        <title>{title ? `${title} – Collab` : "Collab"}</title>
      </Helmet>
      {!hideNav && (
        <Navbar breadcrumb={breadcrumb} renderRight={renderHeaderRight} />
      )}
      {hideNav ? (
        <Content>{children}</Content>
      ) : (
        <NavContent>{children}</NavContent>
      )}
    </Container>
  );
};
