import api from "api";
import { APIAdditive, APIBaseOil } from "api/formulations";
import {
  APIRMFormulation,
  APIRMFormulationListSearchParams,
} from "api/rmFormulations";
import { arrayToCsv } from "csv/utils";
import { computed, observable } from "mobx";
import {
  getRootStore,
  Model,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import RMFormulation from "./models/RMFormulation";
import RMFormulationFilter from "./models/RMFormulationFilter";
import RootStore from "./RootStore";

type RMFormulationHeadRow = (
  | string
  | number
  | boolean
  | identifier
  | identifier[]
)[];

const RM_VOL_TO_KG_MULTIPLIER = 0.85;

@model("collab/RMFormulationStore")
class RMFormulationStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, RMFormulation>()),
  filters: prop_mapObject(() => new Map<identifier, RMFormulationFilter>()),
}) {
  @observable
  private _headCsv = "";

  @observable
  private _oilCsv = "";

  @observable
  private _checkCsv = "";

  @modelAction
  saveListItem(item: APIRMFormulation) {
    const listItem = new RMFormulation(item);
    this.listItems.set(item.id, listItem);
    return listItem;
  }

  @modelFlow
  list = _async(function* (
    this: RMFormulationStore,
    projectId: identifier,
    page?: number,
    limit?: number,
    searchParams?: APIRMFormulationListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(
      api.rmFormulations.list(page, limit, {
        ...searchParams,
        project: projectId,
      })
    );

    // Clear existing items.
    this.ofProject(projectId).forEach((rmFormulation) => {
      this.listItems.delete(rmFormulation.id);
    });

    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  ofProject(projectId: identifier) {
    const rmFormulations = Array.from(this.listItems.values()).filter(
      (item) => item.project === projectId
    );

    return rmFormulations.sort(
      ({ id: idA, ordering: orderingA }, { id: idB, ordering: orderingB }) => {
        if (orderingA !== orderingB) {
          return orderingA - orderingB;
        }
        if (idA > 0 && idB > 0) {
          return idA - idB;
        }
        return idB - idA;
      }
    );
  }

  filterListItems(projectId: identifier, rmFormulations: RMFormulation[]) {
    const filter = this.filters.get(projectId);

    if (filter) {
      rmFormulations = rmFormulations.filter((item) => {
        let out = true;
        if (filter.selectedProductSpecsIds.length > 0) {
          out =
            out &&
            !!item.specs &&
            filter.selectedProductSpecsIds.includes(item.specs);
        }
        if (filter.selectedFormulationCodesIds.length > 0) {
          out =
            out &&
            !!item.code &&
            filter.selectedFormulationCodesIds.includes(item.code);
        }
        if (filter.selectedPlantIds.length > 0) {
          out =
            out &&
            !!item.plant &&
            filter.selectedPlantIds.indexOf(item.plant) >= 0;
        }
        if (filter.selectedFormulationOptionsIds.length > 0) {
          out =
            out &&
            filter.selectedFormulationOptionsIds.includes(
              item.formulationOption
            );
        }
        if (filter.selectedPlantOptionsIds.length > 0) {
          out =
            out && filter.selectedPlantOptionsIds.includes(item.plantOption);
        }
        if (filter.selectedAltScenariosIds.length > 0) {
          out =
            out && filter.selectedAltScenariosIds.includes(item.altScenario);
        }
        return out;
      });
    }
    return rmFormulations;
  }

  private populateOils(projectId: number) {
    const baseOils = new Map<identifier, APIBaseOil>();
    const baseOilVols = new Map<identifier, Map<identifier, number>>();
    const baseOilTreats = new Map<identifier, Map<identifier, number>>();
    const additives = new Map<identifier, APIAdditive>();
    const additiveVols = new Map<identifier, Map<identifier, number>>();
    const additiveTreats = new Map<identifier, Map<identifier, number>>();

    const rmFormulations = this.filterListItems(
      projectId,
      this.ofProject(projectId)
    );

    // Populate base oils and additives.
    rmFormulations.forEach((rmFormulation) => {
      rmFormulation.baseOilPercentages.forEach((baseOilPercentage) => {
        if (!baseOils.has(baseOilPercentage.baseOil.id)) {
          baseOils.set(baseOilPercentage.baseOil.id, baseOilPercentage.baseOil);
          baseOilVols.set(
            baseOilPercentage.baseOil.id,
            new Map<identifier, number>()
          );
          baseOilTreats.set(
            baseOilPercentage.baseOil.id,
            new Map<identifier, number>()
          );
        }
        baseOilVols
          .get(baseOilPercentage.baseOil.id)!
          .set(rmFormulation.id, baseOilPercentage.rmVolume);
        baseOilTreats
          .get(baseOilPercentage.baseOil.id)!
          .set(rmFormulation.id, parseFloat(baseOilPercentage.treat));
      });

      rmFormulation.additivePercentages.forEach((additivePercentage) => {
        if (!additives.has(additivePercentage.additive.id)) {
          additives.set(
            additivePercentage.additive.id,
            additivePercentage.additive
          );
          additiveVols.set(
            additivePercentage.additive.id,
            new Map<identifier, number>()
          );
          additiveTreats.set(
            additivePercentage.additive.id,
            new Map<identifier, number>()
          );
        }
        additiveVols
          .get(additivePercentage.additive.id)!
          .set(rmFormulation.id, additivePercentage.rmVolume);
        additiveTreats
          .get(additivePercentage.additive.id)!
          .set(rmFormulation.id, parseFloat(additivePercentage.treat));
      });
    });

    return {
      rmFormulations,
      baseOils,
      baseOilVols,
      baseOilTreats,
      additives,
      additiveVols,
      additiveTreats,
    };
  }

  @modelAction
  commitHeadCsv(projectId: identifier) {
    this._headCsv = this.getHeadCsv(projectId, false);
  }

  getHeadCsv(projectId: identifier, forExport: boolean = false) {
    const rmFormulations = this.filterListItems(
      projectId,
      this.ofProject(projectId)
    );

    const headers: RMFormulationHeadRow = ["id", "rcode", "description"];
    const specs: RMFormulationHeadRow = ["specs", "Product Spec", ""];
    const codes: RMFormulationHeadRow = ["code", "Formulation Code", ""];
    const plants: RMFormulationHeadRow = ["plant", "Plant", ""];
    const plantTypes: RMFormulationHeadRow = [
      "plantType",
      "Master vs Plant",
      "",
    ];
    const formulationOptions: RMFormulationHeadRow = [
      "formulationOption",
      "Formulation Option",
      "",
    ];
    const plantOptions: RMFormulationHeadRow = [
      "plantOption",
      "Plant Option",
      "",
    ];
    const altScenarios: RMFormulationHeadRow = [
      "altScenario",
      "Alt Scenario",
      "",
    ];
    const fgVols: RMFormulationHeadRow = ["fgVol", "FG Vol (L)", ""];

    let totalFg = 0;

    rmFormulations.forEach((rmFormulation) => {
      headers.push(rmFormulation.id);
      specs.push(rmFormulation.specs);
      codes.push(rmFormulation.code);
      plants.push(rmFormulation.plantName);
      plantTypes.push(rmFormulation.plantType || "");
      formulationOptions.push(rmFormulation.formulationOption);
      plantOptions.push(rmFormulation.plantOption);
      altScenarios.push(rmFormulation.altScenario);
      fgVols.push(rmFormulation.fgVol);

      totalFg += rmFormulation.fgVol;

      // Push extra column so that oil CSV matches head CSV columns for RM Vol.
      headers.push(`_${rmFormulation.id}`);
      specs.push("");
      codes.push("");
      plants.push("");
      plantTypes.push("");
      formulationOptions.push("");
      plantOptions.push("");
      altScenarios.push("");
      fgVols.push("");
    });

    // Push extra columns so that oil CSV matches head CSV columns for totals.
    headers.push("total_fgVol");
    headers.push("total_fgVol85");
    specs.push("");
    specs.push("");
    codes.push("");
    codes.push("");
    plants.push("");
    plants.push("");
    plantTypes.push("");
    plantTypes.push("");
    formulationOptions.push("");
    formulationOptions.push("");
    plantOptions.push("");
    plantOptions.push("");
    altScenarios.push("");
    altScenarios.push("");
    fgVols.push(totalFg);
    fgVols.push("");

    // "ID" column not needed when exporting to CSV.
    if (forExport) {
      headers.splice(0, 1);
      specs.splice(0, 1);
      codes.splice(0, 1);
      plants.splice(0, 1);
      plantTypes.splice(0, 1);
      formulationOptions.splice(0, 1);
      plantOptions.splice(0, 1);
      altScenarios.splice(0, 1);
      fgVols.splice(0, 1);
    }

    let exportedRows = [
      headers,
      specs,
      codes,
      plants,
      plantTypes,
      formulationOptions,
      plantOptions,
      altScenarios,
      fgVols,
    ];

    // Header row not needed when exporting to CSV.
    if (forExport) {
      exportedRows.splice(0, 1);
    }

    return arrayToCsv(exportedRows);
  }

  @computed
  get headAsCsv() {
    return this._headCsv;
  }

  @modelAction
  commitOilCsv(projectId: identifier) {
    this._oilCsv = this.getOilCsv(projectId, false);
  }

  getOilCsv(projectId: identifier, forExport: boolean = false) {
    const {
      rmFormulations,
      baseOils,
      baseOilVols,
      baseOilTreats,
      additives,
      additiveVols,
      additiveTreats,
    } = this.populateOils(projectId);

    const headers: RMFormulationHeadRow = ["id", "rcode", "description"];

    // Build headers.
    rmFormulations.forEach((rmFormulation) => {
      headers.push(`treat_${rmFormulation.id}`);
      headers.push(`vol_${rmFormulation.id}`);
    });
    headers.push("total_fgVol");
    headers.push("total_fgVol85");

    // Populate cells.
    const baseOilRows = Array.from(baseOils.values()).map((baseOil) => {
      const row = [`bo.${baseOil.id}`, baseOil.rcode, baseOil.description];
      const baseOilVol = baseOilVols.get(baseOil.id)!;
      const baseOilTreat = baseOilTreats.get(baseOil.id)!;

      let rmVolTotal = 0;
      rmFormulations.forEach((rmFormulation) => {
        const rmVol = baseOilVol.get(rmFormulation.id);
        const treat = baseOilTreat.get(rmFormulation.id);
        row.push(`${treat ?? ""}`);
        row.push(`${rmVol ?? ""}`);
        rmVolTotal += rmVol ?? 0;
      });

      // Push totals for row.
      row.push(`${rmVolTotal}`);
      row.push(`${rmVolTotal * RM_VOL_TO_KG_MULTIPLIER}`);
      return row;
    });
    const additiveRows = Array.from(additives.values()).map((additive) => {
      const row = [`add.${additive.id}`, additive.rcode, additive.description];
      const additiveVol = additiveVols.get(additive.id)!;
      const additiveTreat = additiveTreats.get(additive.id)!;

      let rmVolTotal = 0;
      rmFormulations.forEach((rmFormulation) => {
        const rmVol = additiveVol.get(rmFormulation.id);
        const treat = additiveTreat.get(rmFormulation.id);
        row.push(`${treat ?? ""}`);
        row.push(`${rmVol ?? ""}`);
        rmVolTotal += rmVol ?? 0;
      });

      // Push totals for row.
      row.push(`${rmVolTotal}`);
      row.push(`${rmVolTotal * RM_VOL_TO_KG_MULTIPLIER}`);
      return row;
    });

    const exportedRows = [headers, ...baseOilRows, ...additiveRows];

    // "ID" column not needed when exporting to CSV.
    if (forExport) {
      exportedRows.forEach((row) => row.splice(0, 1));
    }

    return arrayToCsv(exportedRows);
  }

  @computed
  get oilAsCsv() {
    return this._oilCsv;
  }

  @modelAction
  commitCheckCsv(projectId: identifier) {
    this._checkCsv = this.getCheckCsv(projectId, false);
  }

  getCheckCsv(projectId: identifier, forExport: boolean = false) {
    const {
      rmFormulations,
      baseOilVols,
      baseOilTreats,
      additiveVols,
      additiveTreats,
    } = this.populateOils(projectId);

    const headers: RMFormulationHeadRow = ["id", "total", "description"];

    // Build headers.
    rmFormulations.forEach((rmFormulation) => {
      headers.push(`treat_${rmFormulation.id}`);
      headers.push(`vol_${rmFormulation.id}`);
    });
    headers.push("total_fgVol");
    headers.push("total_fgVol85");

    const checkRow: RMFormulationHeadRow = ["0", "Total", ""];

    let finalVol = 0;
    rmFormulations.forEach((rmFormulation) => {
      // Total treat%
      let totalTreat = 0;
      Array.from(baseOilTreats.values()).forEach((baseOilTreat) => {
        totalTreat += baseOilTreat.get(rmFormulation.id) ?? 0;
      });
      Array.from(additiveTreats.values()).forEach((additiveTreat) => {
        totalTreat += additiveTreat.get(rmFormulation.id) ?? 0;
      });

      // Total RM Vol
      let totalVol = 0;
      Array.from(baseOilVols.values()).forEach((baseOilVol) => {
        totalVol += baseOilVol.get(rmFormulation.id) ?? 0;
      });
      Array.from(additiveVols.values()).forEach((additiveVol) => {
        totalVol += additiveVol.get(rmFormulation.id) ?? 0;
      });
      finalVol += totalVol;

      checkRow.push(totalTreat);
      checkRow.push(totalVol);
    });

    checkRow.push(finalVol);
    checkRow.push(finalVol * RM_VOL_TO_KG_MULTIPLIER);

    return arrayToCsv([headers, checkRow]);
  }

  @computed
  get checkAsCsv() {
    return this._checkCsv;
  }

  @modelAction
  commitCsv(projectId: identifier) {
    this.commitHeadCsv(projectId);
    this.commitOilCsv(projectId);
    this.commitCheckCsv(projectId);
  }

  @modelAction
  createFilters(projectId: identifier) {
    if (!this.filters.has(projectId)) {
      this.filters.set(projectId, new RMFormulationFilter({}));
    }
  }

  productSpecsOfProject(projectId: identifier) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return [];
    }

    const uniqueSpecs = new Set<string>();
    this.ofProject(projectId).forEach((rmFormulation) => {
      uniqueSpecs.add(rmFormulation.specs);
    });
    return Array.from(uniqueSpecs)
      .sort()
      .map((spec) => ({
        label: spec,
        value: spec,
      }));
  }

  formulationCodesOfProject(projectId: identifier) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return [];
    }

    const uniqueCodes = new Set<string>();
    this.ofProject(projectId).forEach((rmFormulation) => {
      uniqueCodes.add(rmFormulation.code);
    });
    return Array.from(uniqueCodes)
      .sort()
      .map((code) => ({
        label: code,
        value: code,
      }));
  }

  plantsOfProject(projectId: identifier) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return [];
    }

    let projectPlantIds: Array<number> = [];
    this.ofProject(projectId).forEach((rmFormulation) => {
      if (rmFormulation.plant) {
        projectPlantIds.push(rmFormulation.plant);
      }
    });
    return rootStore.assets.plantSelect.filter((plant) =>
      projectPlantIds.includes(plant.value)
    );
  }

  formulationOptionsOfProject(projectId: identifier) {
    const uniqueOptions = new Set<string>();
    this.ofProject(projectId).forEach((rmFormulation) => {
      uniqueOptions.add(rmFormulation.formulationOption);
    });
    return Array.from(uniqueOptions)
      .sort()
      .map((option) => ({
        label: option || "(Blank)",
        value: option,
      }));
  }

  plantOptionsOfProject(projectId: identifier) {
    const uniqueOptions = new Set<string>();
    this.ofProject(projectId).forEach((rmFormulation) => {
      uniqueOptions.add(rmFormulation.plantOption);
    });
    return Array.from(uniqueOptions)
      .sort()
      .map((option) => ({
        label: option || "(Blank)",
        value: option,
      }));
  }

  altScenariosOfProject(projectId: identifier) {
    const uniqueScenarios = new Set<string>();
    this.ofProject(projectId).forEach((rmFormulation) => {
      uniqueScenarios.add(rmFormulation.altScenario);
    });
    return Array.from(uniqueScenarios)
      .sort()
      .map((altScenario) => ({
        label: altScenario || "(Blank)",
        value: altScenario,
      }));
  }
}

export default RMFormulationStore;
