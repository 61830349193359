import { InfoOutlined } from "@material-ui/icons";
import DatePicker from "components/DatePicker";
import Editor from "components/Editor";
import FormLabel from "components/FormLabel";
import ReactSelect from "components/ReactSelect";
import { parseISO } from "date-fns";
import Money from "icons/Money";
import React, { useMemo } from "react";
import { Form, FormCheck, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useStore } from "store";
import Project from "store/models/Project";
import styled from "styled-components";

const FormFieldContainer = styled.div`
  display: flex;
  column-gap: 2.5rem;
  flex-wrap: wrap;

  select.form-control {
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 600;
    vertical-align: middle;
    height: 2.375rem;
    width: 10rem;

    &:focus {
      color: #495057;
      border-color: #74b468;
      box-shadow: none;
      background-color: #ffffff;
      outline: #74b468 auto 1rem;
    }
  }

  .form-label {
    min-height: 1.25rem;
  }

  .form-group {
    min-width: 10rem;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 2.75rem;
  height: 2.375rem;
  width: 30rem;

  .form-check {
    &-inline {
      margin-right: 0;
    }

    .form-check-input {
      box-sizing: border-box;
      height: 0.063rem;
      opacity: 0;
      position: absolute;
      width: 0.063rem;

      &:checked {
        & + .form-check-label::after {
          background-color: #000000;
          transform: scale(1);
          transition: transform ease 280ms, background-color ease 280ms;
        }
      }

      &:disabled + .form-check-label {
        &::before {
          border-color: #dadada;
        }
        &::after {
          background-color: #dadada;
        }
      }
    }

    .form-check-label {
      cursor: pointer;
      user-select: none;
      vertical-align: middle;

      span {
        font-size: 0.875rem;
        line-height: 0.875rem;
        padding-left: 1.75rem;
        margin-right: 0.625rem;
      }

      &::before {
        background-color: #fff;
        border: 0.125rem solid #000000;
        border-radius: 50%;
        box-sizing: border-box;
        content: "";
        height: 1.25rem;
        left: 0;
        position: absolute;
        top: 0.625rem;
        width: 1.25rem;
      }
      &::after {
        border-radius: 50%;
        box-sizing: border-box;
        content: "";
        height: 0.625rem;
        left: 0.313rem;
        position: absolute;
        top: 0.938rem;
        transform: scale(0);
        width: 0.625rem;
      }
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: #dadada;
    }
  }
`;

interface Props {
  instance: Project;
  editable?: boolean;
}

interface CostingChoices {
  value: string;
  label: string;
  hint: string;
}

const costingChoices: CostingChoices[] = [
  {
    value: "full_cogs",
    label: "Full Cogs",
    hint: "All GSC cost elements included (raw material, manufacturing conversation and logistics costs)",
  },
  {
    value: "ex_plant",
    label: "Ex Plant",
    hint: "GSC cost elements to point of plant exit only (raw material and manufacturing conversion costs)",
  },
  {
    value: "formulation",
    label: "Formulation",
    hint: "Plant formulation costing only (raw material costs) ",
  },
];

const KeyAssumptionsForm: React.FC<Props> = ({ instance, editable }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const store = useStore();
  const currencyIds = Array.from(store.assets.currencyListItems.keys());

  const renderRadioChoices = useMemo(
    () => (
      <Controller
        control={control}
        name="costingType"
        defaultValue={instance?.costingType}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <>
            {costingChoices.map((item, count) => (
              <FormCheck key={item.value} inline>
                <FormCheck.Input
                  type="radio"
                  name={name}
                  disabled={!editable}
                  id={`costing-type_${count}`}
                  defaultChecked={instance.costingType === item.value}
                  value={item.value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <FormCheck.Label htmlFor={`costing-type_${count}`}>
                  <span>{item.label}</span>
                </FormCheck.Label>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 200, hide: 400 }}
                  overlay={
                    <Tooltip id={`costing-type-info_${count}`}>
                      {item.hint}
                    </Tooltip>
                  }
                >
                  <InfoOutlined />
                </OverlayTrigger>
              </FormCheck>
            ))}
          </>
        )}
      />
    ),
    [control, instance, editable]
  );

  return (
    <>
      <FormFieldContainer>
        <Form.Group>
          <FormLabel>
            <Money /> Required Costing Type
          </FormLabel>
          <RadioGroup>{renderRadioChoices}</RadioGroup>
        </Form.Group>
        {currencyIds && (
          <Form.Group>
            <FormLabel>
              <Money /> Currency
            </FormLabel>
            <ReactSelect
              disabled={!editable}
              name="currency"
              required
              hideSelectedOptions={false}
              defaultValue={instance.currency}
              options={[
                { value: null, label: "(Select)" },
                ...store.assets.currencySelect,
              ]}
              placeholder="Select Currency"
            />
          </Form.Group>
        )}
        <Form.Group>
          <FormLabel>Costing Date</FormLabel>
          <Controller
            control={control}
            name="costingDate"
            rules={{ required: true }}
            defaultValue={
              instance.costingDate ? parseISO(instance.costingDate) : null
            }
            render={({ field: { onBlur, onChange, value } }) => (
              <DatePicker
                dateFormat="MMM/yyyy"
                disabled={!editable}
                errors={errors.costingDate}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Form.Group>
        <Form.Group>
          <FormLabel>Forex End Rate Date</FormLabel>
          <Controller
            control={control}
            name="forexDate"
            rules={{ required: true }}
            defaultValue={
              instance.forexDate ? parseISO(instance.forexDate) : null
            }
            render={({ field: { onBlur, onChange, value } }) => (
              <DatePicker
                dateFormat="MMM/yyyy"
                disabled={!editable}
                errors={errors.costingDate}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Form.Group>
      </FormFieldContainer>
      <Form.Group>
        <Editor
          defaultValue={instance.notes}
          name="notes"
          readOnly={!editable}
        />
      </Form.Group>
    </>
  );
};

export default KeyAssumptionsForm;
