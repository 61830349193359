import { computed } from "mobx";
import {
  getRootStore,
  model,
  Model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import { SuggestionDataItem } from "react-mentions";
import { RootStore } from "store";
import api from "../api";
import { APIUser } from "../api/types";
import { APIUserListSearchParams } from "../api/users";
import User from "./models/User";

@model("collab/UserStore")
class UserStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, User>()),
}) {
  @modelFlow
  init = _async(function* (this: UserStore) {
    yield* _await(this.list());
  });

  @modelAction
  saveListItem(item: APIUser) {
    const listItem = new User(item);
    this.listItems.set(item.id, listItem);
    return listItem;
  }

  @modelFlow
  list = _async(function* (
    this: UserStore,
    page?: number,
    limit?: number,
    searchParams?: APIUserListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.users.list(page, limit, searchParams));
    this.listItems.clear();
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @computed
  get usersForCluster() {
    return (projectId: identifier, clusterId?: identifier | null) => {
      const rootStore = getRootStore<RootStore>(this);
      if (!rootStore) {
        return [];
      }

      const project = rootStore.projects.listItems.get(projectId);
      if (!project) {
        return [];
      }

      return Array.from(this.listItems.values()).filter(
        (user) =>
          user.isGlobalAnalystOrAdmin ||
          project.adminUsers.includes(user.id) ||
          project.rmPriceUsers.includes(user.id) ||
          project.opsInputUsers.includes(user.id) ||
          project.projectOutputUsers.includes(user.id) ||
          (clusterId && user.isAnalyst && user.analystCluster === clusterId) ||
          (clusterId && user.isReviewer && user.reviewerCluster === clusterId)
      );
    };
  }

  @computed
  get atMentionUsersForCluster() {
    return (projectId: identifier, clusterId?: identifier | null) =>
      (
        search: string,
        callback: (data: (SuggestionDataItem & { photo?: string })[]) => void
      ) =>
        callback(
          this.usersForCluster(projectId, clusterId)
            .filter(
              (user) =>
                user.email.toLowerCase().includes(search.toLowerCase()) ||
                user.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((user) => ({
              id: user.email,
              display: user.name,
              photo: user.photoSizes.medium,
            }))
        );
  }

  getByEmail(email: string) {
    return Array.from(this.listItems.values()).find(
      (user) => user.email === email
    );
  }
}

export default UserStore;
