import base, { APIDetail, APIList, APISearchParams, config } from "./base";
import { APIUser } from "./types";

export interface APIComment {
  id: identifier;
  user: Pick<APIUser, "name" | "email" | "photoSizes">;
  datePosted: string;
  project: identifier;
  sheetType: string;
  sheetId: identifier;
  rowIndex: string;
  colName: string;
  comment: string;
  isResolved: boolean;
}

export type APICommentInput = Partial<
  Omit<APIComment, "id" | "user" | "datePosted">
>;

export interface APICommentList extends APIList<APIComment> {}

export interface APICommentSearchParams extends APISearchParams {
  project?: identifier;
  sheet_type__model?: string;
  sheet_id?: identifier;
  row_index?: string;
  col_name?: string;
  is_resolved?: boolean;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APICommentSearchParams
) => base.list<APICommentList>("comments/", page, limit, searchParams);

export interface APICommentDetail extends APIDetail<APIComment> {}

export const get = (commentId: identifier) =>
  base.get<APICommentDetail>(`comments/${commentId}/`);

export const create = (project: identifier, data: APICommentInput) =>
  base.post<APICommentDetail>("comments/", { json: { ...data, project } });

export const update = (commentId: identifier, data: APICommentInput) =>
  base.patch<APICommentDetail>(`comments/${commentId}/`, { json: data });

export const del = (commentId: identifier) =>
  base.deleteEmpty(`comments/${commentId}/`);

export const resolve = (commentId: identifier) =>
  base.patch<APICommentDetail>(`comments/${commentId}/resolve/`);

export const unresolve = (commentId: identifier) =>
  base.patch<APICommentDetail>(`comments/${commentId}/unresolve/`);
