import { Lock } from "@material-ui/icons";
import {
  APIDeliveryInfoBulkUpdateInput,
  APIDeliveryInfoSearchParams,
} from "api/deliveryInfo";
import Button from "components/Button";
import CLTFiles from "components/CLTFiles";
import Editor from "components/Editor";
import Loading from "components/Loading";
import ProjectLockedLabel from "components/ProjectLockedLabel";
import SecondaryTabs from "components/SecondaryTabs";
import Folder from "icons/Folder";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useAsync, useUnmount } from "react-use";
import { useStore } from "store";
import styled from "styled-components";
import Toolbar from "../../components/Toolbar";
import { useProject, useSave } from "../Workspace/hooks";

type Props = {};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 1.25rem;
  height: 100%;
  overflow: auto;
  padding: 1.25rem;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const DeliveryInfoToolbar = styled(Toolbar)`
  width: 100%;
  justify-content: end;
`;
const FilesButton = styled(Button)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  justify-self: end;
  font-size: 0.875rem;
  width: 6.25rem;
  right: 0;
`;

const DeliveryInfoTab: React.FC<Props> = () => {
  const store = useStore();
  const project = useProject();
  const [selectedIndex, setSelectedTabIndex] = useState(0);
  const currentUser = store.auth.current;

  const hasEditPermission = !!(
    currentUser &&
    (currentUser.isAdmin ||
      currentUser.isAnalyst ||
      project?.adminUsers.includes(currentUser.id))
  );
  const editable = hasEditPermission && !project?.isLocked;

  const methods = useForm<APIDeliveryInfoBulkUpdateInput>();
  const { formState } = methods;

  const { loading } = useAsync(async () => {
    if (!project) {
      return;
    }

    await project.getPublishedClusters();
    const params: APIDeliveryInfoSearchParams = {
      project: project.id,
    };
    await store.deliveryInfo.list(undefined, undefined, params);
  });

  const clusters = project
    ? Array.from(project.clusters).sort((a, b) =>
        a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
      )
    : [];

  const deliveryInfo =
    project && clusters[selectedIndex]
      ? store.deliveryInfo
          .ofProject(project.id)
          .find(({ cluster }) => cluster === clusters[selectedIndex].id)
      : undefined;

  useEffect(() => {
    if (project) {
      if (formState.isDirty) {
        project.setDirty();
      } else {
        project.setNotDirty();
      }
    }
  }, [project, formState.isDirty]);

  useUnmount(() => {
    if (project && project.isDirty) {
      project.setNotDirty();
    }
  });

  const handleSave = useCallback(
    async (data: APIDeliveryInfoBulkUpdateInput) => {
      await store.deliveryInfo.bulkUpdate({
        instances: data.instances.filter((item) => item !== null),
      });
    },
    [store]
  );

  useSave(
    async () => {
      if (!editable) {
        throw new Error("Delivery info not editable.");
      }

      await methods.handleSubmit(handleSave, () => {
        throw new Error("Project not saved.");
      })();
    },
    { saveOnUnmount: true }
  );

  const handleClusterChange = useCallback((selectedIndex: identifier) => {
    setSelectedTabIndex(selectedIndex);
  }, []);

  const [showFiles, setShowFiles] = useState(true);
  const handleFilesButtonClick = () => {
    setShowFiles((prev) => !prev);
  };

  if (!project || loading) {
    return <Loading full />;
  }

  if (clusters.length === 0) {
    return <Toolbar />;
  }

  return (
    <FormProvider {...methods}>
      <DeliveryInfoToolbar>
        {project.isLocked && <ProjectLockedLabel />}
        <FilesButton variant="clt-files" onClick={handleFilesButtonClick}>
          FILES
          <Folder />
        </FilesButton>
      </DeliveryInfoToolbar>
      <Container>
        <Editor
          key={`editor-${selectedIndex}`}
          readOnly={!editable || deliveryInfo?.isCltLocked}
          defaultValue={deliveryInfo?.notes ?? ""}
          name={`instances[${selectedIndex}].notes`}
        />
        <Controller
          key={`project-${selectedIndex}`}
          control={methods.control}
          name={`instances.${selectedIndex}.project`}
          render={({ field }) => <FormControl type="hidden" {...field} />}
          defaultValue={project.id}
        />
        <Controller
          key={`cluster-${selectedIndex}`}
          control={methods.control}
          name={`instances.${selectedIndex}.cluster`}
          render={({ field }) => <FormControl type="hidden" {...field} />}
          defaultValue={clusters[selectedIndex].id}
        />
        {deliveryInfo ? (
          <Controller
            key={`id-${selectedIndex}`}
            control={methods.control}
            name={`instances.${selectedIndex}.id`}
            render={({ field }) => <FormControl type="hidden" {...field} />}
            defaultValue={deliveryInfo.id}
          />
        ) : null}
        {showFiles && (
          <CLTFiles clusterId={clusters[selectedIndex].id} define />
        )}
      </Container>
      <SecondaryTabs
        tabs={clusters.map((item) => ({
          title: item.name,
          key: `${item.id}`,
          icon: !(
            currentUser &&
            (currentUser.isGlobalUserOrAdmin ||
              project?.adminUsers.includes(currentUser.id))
          ) ? (
            <Lock />
          ) : null,
        }))}
        onChangeTab={(key) => handleClusterChange(Number(key))}
        selectedTabIndex={selectedIndex}
      />
    </FormProvider>
  );
};

export default observer(DeliveryInfoTab);
