import { CloudDownloadOutlined } from "@material-ui/icons";
import Button from "components/Button";
import DataGrid, { FrozenDataGrid } from "components/DataGrid";
import { useCsvToGridDataAsync } from "components/DataGrid/hooks";
import NumberFormatter, {
  getNumberFormatter,
} from "components/DataGrid/NumberFormatter";
import {
  ColumnExtraOptions,
  DataGridHandleWithData,
} from "components/DataGrid/types";
import Loading from "components/Loading";
import ProjectLockedLabel from "components/ProjectLockedLabel";
import RMFormulationFilter from "components/RMFormulationFilter";
import Toolbar from "components/Toolbar";
import { IRow } from "csv/types";
import { addCsvSepHeader } from "csv/utils";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import { CustomSheet } from "pages/Workspace/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormatterProps } from "react-data-grid";
import { ScrollSync } from "react-scroll-sync";
import { useAsyncFn } from "react-use";
import { useStore } from "store";
import styled from "styled-components";
import { scaledRem } from "utils";
import GenerateCostSheetButton from "./GenerateCostSheetButton";

type Props = {
  tab: CustomSheet;
};

const SheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  overflow: auto;
`;

const NumberFormatter0 = (props: FormatterProps<IRow>) => {
  const Formatter = getNumberFormatter(0, 0);
  return <Formatter {...props} />;
};

const NumberFormatter1Percentage = (props: FormatterProps<IRow>) => {
  const Formatter = getNumberFormatter(1, 1, true);
  return <Formatter {...props} />;
};

const baseWidth = scaledRem(100);

const schemaHead = (columnKey: string): ColumnExtraOptions<IRow> => {
  switch (columnKey) {
    case "id":
      return {};
    case "rcode":
      return {
        editable: false,
        name: "",
        resizable: false,
        frozen: true,
        width: baseWidth,
        cellClass: "cell-readonly",
        colSpan() {
          return 2;
        },
      };
    case "description":
      return {
        frozen: true,
        width: baseWidth * 1.5,
        resizable: true,
        editable: false,
      };
    case "total_fgVol":
      return {
        editable: false,
        name: "TOTAL FG Vol (Litres)",
        resizable: false,
        width: baseWidth,
        cellClass: "cell-readonly cell-numeric",
        formatter: NumberFormatter,
      };
    case "total_fgVol85":
      return {
        editable: false,
        name: "",
        resizable: false,
        width: baseWidth,
        cellClass: "cell-readonly cell-numeric",
        formatter: NumberFormatter,
      };
    default:
      if (columnKey.startsWith("_")) {
        return {
          width: baseWidth,
          resizable: false,
          editable: false,
        };
      }
      return {
        editable: false,
        cellClass: "cell-readonly",
        colSpan() {
          return 2;
        },
        name: "\xa0", // Insert non-breaking space so column select keeps working.
        resizable: false,
        width: baseWidth,
        formatter: (props) => {
          const { row, column } = props;
          const cell = row[column.key];
          switch (row.id) {
            case "plantType":
              return (
                <>
                  {(cell as string).length > 0
                    ? (cell as string)[0].toLocaleUpperCase() +
                      (cell as string).slice(1)
                    : ""}
                </>
              );
            case "fgVol":
              const Formatter = getNumberFormatter(0, 2);
              return <Formatter {...props} />;
          }
          return <>{cell}</>;
        },
      };
  }
};

const schemaOil = (columnKey: string): ColumnExtraOptions<IRow> => {
  if (columnKey === "id") {
    return {};
  }
  if (columnKey === "rcode") {
    return {
      editable: false,
      name: "R Code",
      resizable: false,
      frozen: true,
      width: baseWidth,
      cellClass: "cell-readonly",
    };
  }
  if (columnKey === "total") {
    return {
      editable: false,
      name: "R Code",
      resizable: false,
      frozen: true,
      width: baseWidth,
      colSpan: () => 2,
      cellClass: "cell-readonly",
    };
  }
  if (columnKey === "description") {
    return {
      editable: false,
      name: "RM Desc",
      resizable: false,
      frozen: true,
      width: baseWidth * 1.5,
      cellClass: "cell-readonly",
    };
  }
  if (columnKey === "total_fgVol") {
    return {
      editable: false,
      name: "TOTAL RM REQT (Litres)",
      resizable: false,
      width: baseWidth,
      cellClass: "cell-readonly cell-numeric",
      formatter: NumberFormatter0,
    };
  }
  if (columnKey === "total_fgVol85") {
    return {
      editable: false,
      name: "TOTAL RM REQT (KG) based on  0.85 avg",
      resizable: false,
      width: baseWidth,
      cellClass: "cell-readonly cell-numeric",
      formatter: NumberFormatter0,
    };
  }
  if (columnKey.startsWith("treat_")) {
    return {
      name: "Treat",
      editable: false,
      width: baseWidth,
      resizable: false,
      cellClass: "cell-readonly cell-numeric",
      formatter: NumberFormatter1Percentage,
    };
  }
  if (columnKey.startsWith("vol_")) {
    return {
      name: "RM Vol (L)",
      editable: false,
      width: baseWidth,
      resizable: false,
      cellClass: "cell-readonly cell-numeric",
      formatter: NumberFormatter0,
    };
  }
  return {};
};

const RMFormulationsTab: React.FC<Props> = ({ tab }) => {
  const store = useStore();
  const project = useProject();
  const currentUser = store.auth.current;
  const refHead = useRef<DataGridHandleWithData>(null);
  const refOil = useRef<DataGridHandleWithData>(null);
  const refCheck = useRef<DataGridHandleWithData>(null);
  const [activeSheet, setActiveSheet] = useState("");

  const hasEditPermission = !!(
    currentUser &&
    (currentUser.isAdmin ||
      project?.opsInputUsers.includes(currentUser.id) ||
      project?.adminUsers.includes(currentUser.id))
  );
  const editable = hasEditPermission && !project?.isLocked;

  const [state, fetch] = useAsyncFn(async () => {
    if (!project) {
      return;
    }
    await store.rmFormulations.list(project.id);
    store.rmFormulations.commitCsv(project.id);
  }, [project]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const csvStringHead = project ? store.rmFormulations.headAsCsv : "";
  const [columnsHead, rowsHead, loadedHead] = useCsvToGridDataAsync({
    csvString: csvStringHead,
    schema: schemaHead,
  });

  const csvStringOil = project ? store.rmFormulations.oilAsCsv : "";
  const [columnsOil, rowsOil, loadedOil] = useCsvToGridDataAsync({
    csvString: csvStringOil,
    schema: schemaOil,
  });

  const csvStringCheck = project ? store.rmFormulations.checkAsCsv : "";
  const [columnsCheck, rowsCheck, loadedCheck] = useCsvToGridDataAsync({
    csvString: csvStringCheck,
    schema: schemaOil,
  });

  const handleActiveSheetChange = useCallback((sheetId: string) => {
    setActiveSheet(sheetId);
  }, []);

  const handleDownloadClick = useCallback(async () => {
    if (!project) {
      return;
    }

    project.setLoading("Preparing download...");

    const headCsv = store.rmFormulations.getHeadCsv(project.id, true);
    const oilCsv = store.rmFormulations.getOilCsv(project.id, true);

    const csv = addCsvSepHeader(`${headCsv}\r\n${oilCsv}`);

    const zip = new JSZip().file(`rmReport.csv`, csv);

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `rmReport-${project.id}.zip`);

    project.setNotLoading();
  }, [project, store.rmFormulations]);

  const ready = !state.loading && loadedHead && loadedOil && loadedCheck;

  if (!ready) {
    return <Loading full />;
  }

  return (
    <>
      <Toolbar>
        <RMFormulationFilter />
        <Button size="thin" onClick={handleDownloadClick}>
          <CloudDownloadOutlined /> Download CSV File
        </Button>
        {editable && <GenerateCostSheetButton onPostUpdate={() => fetch()} />}
        {project?.isLocked && <ProjectLockedLabel />}
      </Toolbar>
      <ScrollSync vertical={false}>
        <SheetContainer>
          <FrozenDataGrid
            ref={refHead}
            id="rmFormulations"
            isActive={activeSheet === "rmFormulations"}
            multiple
            title="Product"
            defaultColumnOptions={{ resizable: false, editable: false }}
            rows={rowsHead}
            maxRowsShown="auto"
            columns={columnsHead}
            onActive={handleActiveSheetChange}
            onRowsChange={() => {}}
            rowIdentifier="id"
          />
          <DataGrid
            ref={refOil}
            id="rmFormulationsOil"
            isActive={activeSheet === "rmFormulationsOil"}
            multiple
            rows={rowsOil}
            maxRowsShown="auto"
            columns={columnsOil}
            onActive={handleActiveSheetChange}
            onRowsChange={() => {}}
            collapsible={false}
            headerRowHeight={scaledRem(64)}
            rowIdentifier="id"
          />
          <DataGrid
            ref={refCheck}
            id="rmFormulationsCheck"
            isActive={activeSheet === "rmFormulationsCheck"}
            multiple
            rows={rowsCheck}
            maxRowsShown="auto"
            columns={columnsCheck}
            onActive={handleActiveSheetChange}
            onRowsChange={() => {}}
            collapsible={false}
            headerRowHeight={0}
            rowIdentifier="id"
          />
        </SheetContainer>
      </ScrollSync>
    </>
  );
};

export default observer(RMFormulationsTab);
