import { APICLTFileInput } from "api/cltFiles";
import { computed } from "mobx";
import { Model, model, prop } from "mobx-keystone";

@model("collab/CLTFile")
class CLTFile extends Model({
  id: prop<identifier>(),
  created: prop<string>(),
  modified: prop<string>(),
  file: prop<string>(),
  name: prop<string>(),
  project: prop<identifier>(),
  cluster: prop<identifier>(),
  clusterName: prop<string>(),
  sheetsStatus: prop<identifier>(),
  inputSheet: prop<identifier>(),
  isCltLocked: prop<boolean>(),
  isCltApproved: prop<boolean>(),
  isCltSubmitted: prop<boolean>(),
  isEditable: prop<boolean>(),
}) {
  @computed
  get asAPI(): APICLTFileInput {
    return {
      name: this.name,
      file: this.file,
      sheetsStatus: this.sheetsStatus,
    };
  }
}

export default CLTFile;
