import { ValidationCheck } from "../../components/ValidationDashboard/types";

export const rmWorkingValidations: ValidationCheck[] = [
  {
    title: "RM Working",
    validations: [
      {
        keys: [
          "sap_stock_location-W018",
          "supply_country-W018",
          "inco_term-W018",
          "supplier-W018",
        ],
        type: "W",
        text: "SAP Stock Location, Supply Location/Country, Inco Terms, Supplier must be populated where available",
      },
      {
        keys: ["bulk_or_drum-E002", "price_obtained_from-E002"],
        type: "E",
        text: "Bulk or Drum and Price Obtained must be populated",
      },
      {
        keys: ["currency_of_purchase-E028", "unit_of_measure-E028"],
        type: "E",
        text: "Purchase Currency and Unit of Measure must be populated unless RMPA is selected",
      },
    ],
  },
];

export const marginalCostPctValidations: ValidationCheck[] = [
  {
    title: "Marginal Cost %",
    validations: [
      {
        keys: [
          "percent_internal_storage_handling-E001",
          "percent_3p_storage_handling-E001",
          "percent_yield_loss-E001",
          "percent_internal_blending-E001",
          "percent_internal_filling-E001",
          "percent_cogs_efficiency_savings-E001",
          "percent_internal_primary_transport-E001",
          "percent_3p_storage_handling-E001",
          "percent_internal_storage_handling-E001",
        ],
        type: "E",
        text: "All values must be between 0% and 100%",
      },
    ],
  },
];

export const ohPackMfgValidations: ValidationCheck[] = [
  {
    title: "OH Fully Pack MFG",
    validations: [
      {
        keys: [
          "specific_packaging_labelling_cost_repacking_country-W015",
          "decanting_cost-W015",
          "percent_yield_loss-W015",
          "yield_loss-W015",
          "packaging_labelling_cost-W015",
          "specific_packaging_labelling_cost-W015",
          "3p_toll_blending_cost-W015",
          "bp_blending_fee-W015",
          "bp_filling_fee-W015",
          "depreciation-W015",
        ],
        type: "W",
        text: "Values are above maximum pre set threshold - please check these are correct",
      },
      {
        keys: [
          "packaging_labelling_cost_repacking_country-W016",
          "packaging_labelling_cost-W016",
        ],
        type: "W",
        text: "Labeling cost must be blank or zero when pack size is Bulk/Mini Bulk/ISO",
      },
      {
        keys: ["percent_cogs_efficiency_savings-W003"],
        type: "W",
        text: "COGS Efficiency Savings must be negative",
      },
    ],
  },
];

export const ohLogsValidations: ValidationCheck[] = [
  {
    title: "OH Logistics",
    validations: [
      {
        keys: [
          "percent_formulation_cost-W015",
          "percent_ex_plant_cost-W015",
          "percent_ex_plant_import_cost-W015",
        ],
        type: "W",
        text: "Import/export related tax/duties to be applied cost < 30%",
      },
      {
        keys: [
          "3p_primary_transport_cost_source_country-W016",
          "internal_primary_transport_cost-W016",
          "3p_storage_handling_cost_source_country-W016",
        ],
        type: "W",
        text: "3rd party primary transport cost, internal primary transport cost, and 3rd party storage handling cost must be blank or zero when pack size is Bulk/Mini Bulk/ISO",
      },
      {
        keys: [
          "extra_freight_cost_source_country-W015",
          "extra_freight_cost_repacking_country-W015",
          "extra_freight_cost_destination_country-W015",
          "3p_primary_transport_cost_source_country-W015",
          "3p_primary_transport_cost_repacking_country-W015",
          "3p_primary_transport_cost_destination_country-W015",
          "internal_primary_transport_cost-W015",
          "3p_storage_handling_cost_source_country-W015",
          "3p_primary_transport_cost_repacking_country-W015",
          "3p_storage_handling_cost_destination_country-W015",
          "internal_storage_cost_source_country-W015",
          "internal_storage_cost_repacking_country-W015",
          "internal_storage_cost_destination_country-W015",
          "logistics_specific_cost-W015",
          "logistics_secondary_cost-W015",
        ],
        type: "W",
        text: "Values are above maximum pre set threshold - please check these are correct",
      },
      {
        keys: ["rtm-E002"],
        type: "E",
        text: "RTM must be populated",
      },
    ],
  },
];
