import ImageResize from "@looop/quill-image-resize-module-react/dist/ImageResize.es";
import { Controller, useFormContext } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled, { css } from "styled-components";

Quill.register("modules/imageResize", ImageResize);

const EditorContainer = styled.div<{
  size: "sm" | "md" | "lg";
  fullWidth?: boolean;
  readOnly?: boolean;
  noToolbar?: boolean;
}>`
  width: 100%;

  .quill {
    display: flex;
    flex-direction: column;

    ${({ fullWidth }) =>
      fullWidth
        ? css`
            max-width: 100%;
          `
        : css`
            max-width: 75rem;
          `};

    .ql-container {
      order: 1;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      background-color: #ffffff;

      font-family: "Open Sans", var(--font-family-sans-serif);
      font-size: 0.875rem;

      ${({ size }) =>
        size === "lg"
          ? css`
              min-height: 31.25rem;
            `
          : size === "md"
          ? css`
              min-height: 21.75rem;
            `
          : ""};

      &.ql-snow {
        border-top: 1px solid #989898;
        border-bottom: 0;
      }

      .ql-editor {
        padding: 1.25rem;
        line-height: 1.375rem;
      }
    }

    .ql-toolbar {
      order: 2;
      background-color: #f9f9f9;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;

      &.ql-snow {
        padding: 0.5rem 1rem;
      }

      .ql-formats {
        margin: 0;
        padding: 0 10px;
        border-right: 0.063rem solid #bdbdbd;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
          border-right: 0;
        }
      }
    }

    ${({ readOnly, noToolbar }) =>
      readOnly || noToolbar
        ? css`
            .ql-toolbar {
              display: none;
            }

            .ql-container.ql-snow {
              border-bottom: 1px solid #989898;
            }
          `
        : ""}

    .ql-container,
    .ql-toolbar {
      border: 0.063rem solid #989898;
    }
  }
`;

interface Props {
  className?: string;
  defaultValue?: string;
  readOnly?: boolean;
  name: string;
  size?: "sm" | "md" | "lg";
  noToolbar?: boolean;
  fullWidth?: boolean;
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "align",
  "bold",
  "bullet",
  "calltoaction",
  "code-block",
  "color",
  "float",
  "height",
  "image",
  "indent",
  "italic",
  "link",
  "list",
  "placeholder",
  "size",
  "strike",
  "underline",
  "width",
];

const Editor: React.FC<Props> = ({
  className,
  defaultValue,
  readOnly,
  name,
  size = "lg",
  fullWidth,
  noToolbar,
}) => {
  const { control } = useFormContext();

  const modules = {
    ...(!noToolbar
      ? {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ align: "" }, { align: "center" }, { align: "right" }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["image"],
          ],
        }
      : { toolbar: [] }),
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <EditorContainer
      fullWidth={fullWidth}
      className={className}
      size={size}
      readOnly={readOnly}
      noToolbar={noToolbar}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue ?? ""}
        render={({ field }) => (
          <ReactQuill
            {...field}
            theme="snow"
            readOnly={readOnly}
            modules={modules}
            formats={formats}
            bounds={".app"}
          />
        )}
      />
    </EditorContainer>
  );
};

export default Editor;
