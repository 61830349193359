import { CancelOutlined, FilterList } from "@material-ui/icons";
import Select from "react-select";
import { observer } from "mobx-react-lite";
import React from "react";
import { ButtonGroup, Form, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import Button from "../../components/Button";
import { WorksheetFilterOptions } from "../Workspace/types";

type Props = {
  filterOptions: WorksheetFilterOptions;
  selectedFilters: Record<string, { label: string; value: string }[]>;
  onChange: (key: string, values: { label: string; value: string }[]) => void;
  onReset: () => void;
};

const Filters = styled(Form)`
  background-color: #ffffff;
  border: 1px solid #b9b9b9;
  border-radius: 0.25rem;
  box-shadow: 0px 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  font-weight: bold;
  padding: 1.25rem;
  width: 18rem;
  z-index: 10;
`;

const FormFooter = styled.footer`
  text-align: right;
`;

const FilterOptions: React.FC<Props> = ({
  filterOptions,
  selectedFilters,
  onChange,
  onReset,
}) => {
  const isFiltering = Object.keys(selectedFilters).length > 0;

  return (
    <ButtonGroup>
      <OverlayTrigger
        trigger="click"
        placement="bottom-start"
        offset={[0, 20]}
        rootClose
        overlay={
          <Filters>
            {Object.entries(filterOptions).map(([key, filter]) => (
              <Form.Group key={key}>
                <Form.Label>{filter.label}</Form.Label>
                <Select
                  options={filter.choices}
                  value={selectedFilters[key]}
                  onChange={(values) => onChange(key, values as any)}
                  isMulti
                />
              </Form.Group>
            ))}
            <FormFooter>
              <Button
                type="button"
                variant="borderless"
                size="thin"
                onClick={onReset}
              >
                Clear
              </Button>
            </FormFooter>
          </Filters>
        }
      >
        <Button variant={isFiltering ? "inverted" : "primary"} size="thin">
          <FilterList /> Filter
        </Button>
      </OverlayTrigger>
      {isFiltering ? (
        <Button variant="inverted" size="thin" onClick={onReset}>
          <CancelOutlined />
          Clear Filter
        </Button>
      ) : null}
    </ButtonGroup>
  );
};

export default observer(FilterOptions);
