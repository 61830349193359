import valueMapMapper from "components/DataGrid/Mapper";
import SelectCellFormatter from "components/DataGrid/SelectCellFormatter";
import { IRow } from "csv/types";
import { observer } from "mobx-react-lite";
import React from "react";
import { FormatterProps } from "react-data-grid";
import { useStore } from "store";

type Props = FormatterProps<IRow>;

const FormulationFormatter: React.FC<Props> = (props) => {
  const { row, column } = props;
  const store = useStore();

  const cell = row[column.key];

  switch (row.id) {
    case "plant": {
      const Formatter = valueMapMapper(
        column.key,
        store.assets.plantListItems,
        false,
        (mapped) => (mapped ? mapped.name : "")
      );
      return <Formatter {...props} />;
    }
    case "toImport": {
      const value = cell === true || cell === "true" ? true : false;
      return (
        <SelectCellFormatter
          tabIndex={-1}
          value={value}
          disabled={!column.editable}
          onChange={() => {
            if (column.editable) {
              props.onRowChange({
                ...row,
                [column.key]: !value,
              });
            }
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          isCellSelected={props.isCellSelected}
        />
      );
    }
  }
  return <>{cell}</>;
};

export default observer(FormulationFormatter);
