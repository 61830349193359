import AddFormulations from "components/AddFormulations";
import { FrozenDataGrid } from "components/DataGrid";
import { useCsvToGridDataAsync } from "components/DataGrid/hooks";
import {
  ColumnExtraOptions,
  DataGridHandleWithData,
  RangeSelection,
} from "components/DataGrid/types";
import { CsvValidationErrorContext } from "components/ValidationDashboard/contexts";
import { IRow } from "csv/types";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { RowsChangeData } from "react-data-grid";
import { useStore } from "store";
import { Toast, scaledRem } from "utils";
import FormulationEditor from "./FormulationEditor";
import FormulationFormatter from "./FormulationFormatter";

type Props = {
  editable: boolean;
  isSheetActive: boolean;
  onSheetActive: (sheet: string) => void;
};

const blankValidations = [
  "specs",
  "code",
  "plant",
  "clusters",
  "plantType",
  "countries",
  "packSizes",
];

const FormulationsTabHead: React.FC<Props> = ({
  editable,
  isSheetActive,
  onSheetActive,
}) => {
  const store = useStore();
  const project = useProject();
  const ref = useRef<DataGridHandleWithData>(null);
  const validationSummary = useContext(CsvValidationErrorContext);

  const schema = useCallback(
    (columnKey: string, sheetEditable?: boolean): ColumnExtraOptions<IRow> => {
      switch (columnKey) {
        case "id":
          return {
            editable: false,
            formatter: ({ rowIdx }) => <>{rowIdx + 1}</>,
            name: "",
            resizable: false,
            frozen: true,
          };
        case "rcode":
          return {
            editable: false,
            name: "",
            resizable: false,
            frozen: true,
            width: scaledRem(333),
            cellClass: "cell-readonly",
            colSpan() {
              return 3;
            },
          };
        case "description":
        case "crdStatus":
          return {
            frozen: true,
            minWidth: 0,
            maxWidth: 0,
            editable: false,
          };
      }
      return {
        editor: FormulationEditor,
        cellClass: () => {
          if (!sheetEditable) {
            return sheetEditable ?? true ? undefined : "cell-readonly";
          }
          const formulation = store.formulations.listItems.get(
            parseInt(columnKey, 10)
          );
          if (formulation) {
            return formulation.isCltLocked ? "cell-readonly" : undefined;
          }
          return undefined;
        },
        editable: () => {
          if (!sheetEditable) {
            return sheetEditable ?? true;
          }
          const formulation = store.formulations.listItems.get(
            parseInt(columnKey, 10)
          );
          if (formulation) {
            return !formulation.isCltLocked;
          }
          return false;
        },
        editorOptions: {
          editOnClick: false,
        },
        formatter: FormulationFormatter,
        name: "\xa0", // Insert non-breaking space so column select keeps working.
        resizable: false,
        width: scaledRem(123),
      };
    },
    [store.formulations.listItems]
  );

  const csvString = project ? store.formulations.headAsCsv : "";
  const [columns, rows, loaded] = useCsvToGridDataAsync({
    csvString,
    editable,
    schema,
  });

  const headErrors = useMemo(() => {
    const errors: Record<string, string> = {};
    rows.forEach((item) => {
      if (blankValidations.includes(`${item.id}`)) {
        columns
          .filter((e) => e.key !== "crdStatus" && e.key !== "description")
          .forEach((columnItem) => {
            if (item[columnItem.key] === "") {
              const cellKey = `${columnItem.key}-${item.id}`;
              errors[cellKey] = "E:This field cannot be left blank";
            }
          });
      }
    });
    return errors;
  }, [columns, rows]);

  useEffect(() => {
    const newErrorKeys = new Set<string>();
    const newColumnNameMap: Record<string, string> = {};

    rows.forEach((item) => {
      if (blankValidations.includes(`${item.id}`)) {
        columns
          .filter((e) => e.key !== "crdStatus" && e.key !== "description")
          .forEach((columnItem) => {
            if (item[columnItem.key] === "") {
              newErrorKeys.add(`${item.id}-E002`);
              newColumnNameMap[item.id] = `${item.rcode}`;
            }
          });
      }
    });

    const headErrorSize = Object.keys(headErrors).length;

    validationSummary.setters.setErrorCount((prev) => ({
      ...prev,
      head: headErrorSize,
    }));
    validationSummary.setters.setErrorKeys((prev) => ({
      ...prev,
      head: newErrorKeys,
    }));
    validationSummary.setters.setColumnNameMaps((prev) => ({
      ...prev,
      head: newColumnNameMap,
    }));
  }, [columns, headErrors, rows, validationSummary.setters]);

  const handleAddFormula = useCallback(
    (addCount: number) => {
      if (!project || !project.id) {
        return;
      }
      for (let i = 0; i < addCount; i++) {
        store.formulations.create(project.id);
      }
      store.formulations.commitCsv(project.id);
    },
    [project, store.formulations]
  );

  const handleColumnsReorder = useCallback(
    (sourceKey: string, targetKey: string) => {
      if (!project) {
        return;
      }
      store.formulations.reorder(
        project.id,
        parseInt(sourceKey, 10),
        parseInt(targetKey, 10)
      );
      store.formulations.commitCsv(project.id);
    },
    [project, store.formulations]
  );

  const handleHeadRowsChange = useCallback(
    (rows: IRow[], data?: RowsChangeData<IRow, unknown>) => {
      if (!project) {
        return;
      }
      if (!data) {
        return;
      }

      const { key } = data.column; // key = id of formulation
      const { indexes } = data;

      indexes.forEach((index) => {
        const { id } = rows[index]; // id = field name
        const formulation = store.formulations.listItems.get(parseInt(key, 10));
        formulation?.setValue(id as string, rows[index][key]);
      });

      store.formulations.commitHeadCsv(project.id);
      project.setDirty();
    },
    [project, store.formulations]
  );

  const handleDelete = useCallback(
    async (
      event: React.TouchEvent<HTMLDivElement>,
      data: { range: RangeSelection }
    ) => {
      if (!project) {
        return;
      }

      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm("Are you sure you want to delete the selected formulations?")
      ) {
        return;
      }

      const {
        range: { start, end },
      } = data;

      project?.setLoading("Deleting selected formulations...");

      const promises: Promise<void>[] = [];
      for (let i = start.idx; i <= end.idx; i++) {
        const formulationId = columns[i].key;
        if (!formulationId) {
          return;
        }
        promises.push(store.formulations.delete(parseInt(formulationId, 10)));
      }

      try {
        await Promise.allSettled(promises);
      } catch (e) {
        Toast.danger("Some formulations were not deleted.");
        return;
      } finally {
        store.formulations.commitCsv(project.id);
        project?.setNotLoading();
      }
      Toast.success("Selected formulations have been deleted.");
    },
    [columns, project, store.formulations]
  );

  const handleDuplicate = useCallback(
    (
      event: React.TouchEvent<HTMLDivElement>,
      data: { range: RangeSelection }
    ) => {
      if (!project || !project.id) {
        return;
      }

      const {
        range: { start, end },
      } = data;

      for (let i = start.idx; i <= end.idx; i++) {
        const formulationId = columns[i].key;

        store.formulations.create(
          project.id,
          parseInt(formulationId),
          parseInt(formulationId)
        );
      }

      store.formulations.commitCsv(project.id);
    },
    [columns, project, store.formulations]
  );

  if (!loaded) {
    return null;
  }

  return (
    <FrozenDataGrid
      ref={ref}
      id="formulations"
      isActive={isSheetActive}
      multiple
      title="Formulations"
      titleExtra={<AddFormulations onAddFormulations={handleAddFormula} />}
      defaultCollapsed
      defaultColumnOptions={{ resizable: false }}
      rows={rows}
      minRowsShown={6}
      maxRowsShown="auto"
      columns={columns}
      onActive={onSheetActive}
      onColumnsReorder={handleColumnsReorder}
      onRowsChange={handleHeadRowsChange}
      rowIdentifier="id"
      extraContextMenu={(filteredRows, selected, range) => [
        {
          id: "deleteRows",
          label: "Delete selected formulations…",
          onClick: handleDelete,
          data: { range },
        },
        {
          id: "duplicateFormulation",
          label: "Duplicate selected formulation…",
          onClick: handleDuplicate,
          data: { range },
        },
      ]}
      errors={headErrors}
    />
  );
};

export default observer(FormulationsTabHead);
