import { CloudUploadOutlined } from "@material-ui/icons";
import { IRow } from "csv/types";
import capitalize from "lodash/capitalize";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { useCallback, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { RowsChangeData, TextEditor } from "react-data-grid";
import { ScrollSync } from "react-scroll-sync";
import { useStore } from "store";
import Additive from "store/models/Additive";
import BaseOil from "store/models/BaseOil";
import TreatRate from "store/models/TreatRate";
import styled from "styled-components";
import { epsilonRound, scaledRem } from "utils";
import Button from "../Button";
import DataGrid from "../DataGrid";
import { useCsvToGridDataAsync } from "../DataGrid/hooks";
import { ColumnExtraOptions, DataGridHandleWithData } from "../DataGrid/types";
import FormulationFormatter from "./FormulationFormatter";

const TheDataGrid = styled(DataGrid)`
  .rdg-container .rdg {
    // TEMP
    height: 201px;
  }
`;

const BackButton = styled(Button)`
  margin-right: auto;
`;
const Note = styled.p`
  margin-top: 0.5rem;
`;

type SearchFormulationsResultsProps = {
  onBack: () => void;
  onCancel: () => void;
  onSubmit: () => void;
};

const SearchFormulationsResults: React.FC<SearchFormulationsResultsProps> = ({
  onBack,
  onCancel,
  onSubmit,
}) => {
  const store = useStore();
  const project = useProject();
  const gridRef = useRef<DataGridHandleWithData>(null);

  const schema = useCallback(
    (columnKey: string, sheetEditable?: boolean): ColumnExtraOptions<IRow> => {
      switch (columnKey) {
        case "id":
          return {
            editable: false,
            formatter: ({ rowIdx }) => <>{rowIdx + 1}</>,
            name: "",
            resizable: false,
            frozen: true,
          };
        case "rcode":
          return {
            editable: false,
            name: "",
            resizable: false,
            frozen: true,
            width: scaledRem(325),
            cellClass: "cell-readonly",
            colSpan() {
              return 3;
            },
          };
        case "description":
        case "crdStatus":
          return {
            frozen: true,
            minWidth: 0,
            maxWidth: 0,
            editable: false,
          };
      }
      return {
        editor: TextEditor,
        // TODO: Set `cellClass` and `editable` for each row independently
        cellClass: undefined,
        editable: true,
        editorOptions: {
          editOnClick: false,
        },
        formatter: FormulationFormatter,
        name: "\xa0", // Insert non-breaking space so column select keeps working.
        resizable: false,
        width: scaledRem(123),
      };
    },
    []
  );
  const csvString = project ? store.formulations.datahubAsCsv : "";
  const [columns, rows, loaded] = useCsvToGridDataAsync({
    csvString,
    editable: true,
    schema,
  });

  const handleHeadRowsChange = useCallback(
    (rows: IRow[], data?: RowsChangeData<IRow, unknown>) => {
      if (!project) return;
      if (!data) return;

      const { key } = data.column; // key = id of formulation
      const { indexes } = data;

      indexes.forEach((index) => {
        const { id } = rows[index]; // id = field name
        if (id !== "toImport") return;
        const formulation = store.formulations.datahubListItems.get(
          parseInt(key, 10)
        );
        formulation?.setValue(id as string, rows[index][key]);
      });

      store.formulations.commitDatahubCsv(project.id);
    },
    [project, store.formulations]
  );

  const handleImport = useCallback(() => {
    if (!project) return;

    store.formulations.datahubListItems.forEach(async (data) => {
      if (!data.toImport) return;

      const formulation = store.formulations.create(project.id);
      formulation.setValue("code", data?.masterFormulationCode || "");
      formulation.setValue("plant", data?.plantFormulationPlant || undefined);

      for (const [key, value] of Object.entries(data.treatRates)) {
        let rawMaterial: Additive | BaseOil | TreatRate | null = null;
        const percentage = value.percentage || 0;

        switch (value.marketSector) {
          case "additive":
            rawMaterial = store.formulations.findOrCreateAdditive(key);
            formulation.setAdditivePercentage(
              rawMaterial.id,
              epsilonRound(percentage).toString()
            );
            break;
          case "base oil":
            rawMaterial = store.formulations.findOrCreateBaseOil(key);
            formulation.setBaseOilPercentage(
              rawMaterial.id,
              epsilonRound(percentage).toString()
            );
            break;
          default:
            rawMaterial = store.formulations.findOrCreateTreatRate(key);
            formulation.setTreatRatePercentage(
              rawMaterial.id,
              epsilonRound(percentage).toString()
            );
            break;
        }
        rawMaterial.setValue("rcode", key);
        rawMaterial.setValue("description", value.description);
        rawMaterial.setValue("crdStatus", capitalize(value.crdStatus));
      }
    });

    store.formulations.commitCsv(project.id);
    project.setDirty();

    onSubmit();
  }, [onSubmit, project, store.formulations]);

  useEffect(() => {
    if (!project) return;
    store.formulations.commitDatahubCsv(project.id);
  }, [project, store.formulations]);

  return (
    <>
      <Modal.Body>
        <p>Please select the data to import.</p>
        <ScrollSync vertical={false}>
          <TheDataGrid
            ref={gridRef}
            id="formulations"
            rows={rows}
            columns={columns}
            rowIdentifier="id"
            maxRowsShown="auto"
            onRowsChange={handleHeadRowsChange}
            collapsible={false}
            headerRowHeight={scaledRem(64)}
          />
        </ScrollSync>
        <Note>
          <b>NOTE:</b> Results are imported as Unclassified treat rates by
          default.
        </Note>
      </Modal.Body>
      <Modal.Footer>
        <BackButton variant="borderless" onClick={onBack}>
          Back
        </BackButton>
        <Button variant="borderless" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleImport}>
          <CloudUploadOutlined /> Import
        </Button>
      </Modal.Footer>
    </>
  );
};

export default observer(SearchFormulationsResults);
