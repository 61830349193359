import base, { APIDetail } from "./base";
import { APIFile } from "./types";

export interface APIFileCreate extends APIDetail<APIFile> {}
export const create = (file: File) => {
  const data = new FormData();
  data.append("file", file);

  return base.post<APIFile>("files/", {
    body: data,
  });
};
