import {
  CancelOutlined,
  CheckCircleOutlined,
  ErrorOutlineOutlined,
  ViewListOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import { sheetErrorMap } from "csv/constants";
import { formatDistanceToNow, parseISO } from "date-fns";
import { uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { useCallback, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import Button from "../Button";
import { Header, SubHeader } from "../Header";
import ModalForm from "../ModalForm";
import { CsvValidationErrorContext } from "./contexts";
import { ValidationCheck, ValidationItem } from "./types";
import ValidationSection from "./ValidationSection";

type ValidationDashboardProps = {
  validationChecks?: ValidationCheck[];
};

const ValidationDashboardContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

const CountText = styled.div`
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: bold;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const ErrorIcon = styled(CancelOutlined)`
  color: #eb5757;
  margin-right: 5px;
`;

const WarningIcon = styled(ErrorOutlineOutlined)`
  color: #cea834;
  margin-right: 5px;
`;

const CheckIcon = styled(CheckCircleOutlined)`
  color: #9bbe41;
`;

const ValidationDashboardModalContainer = styled(ModalForm)`
  .modal-dialog {
    font-size: 0.875rem;
    line-height: 1.625rem;
    min-width: 34.75rem;
  }
`;

const HeaderRight = styled(Header)`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: capitalize;
`;

const ValidationDashboard: React.FC<ValidationDashboardProps> = ({
  validationChecks,
}) => {
  const project = useProject();
  const { errorCount, warningCount, errorKeys, validationColumnNameMap } =
    useContext(CsvValidationErrorContext).display;
  const [show, setShow] = useState(false);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleHide = useCallback(() => {
    setShow(false);
  }, []);

  let validationKeys: Set<string> = new Set();

  if (validationChecks) {
    validationChecks.forEach((section) => {
      section.validations.forEach((validation) => {
        validation.keys.forEach((e) => validationKeys.add(e));
      });
    });
  }

  const extraKeys = [...errorKeys].filter((e) => !validationKeys.has(e));
  const extraValidationItems: ValidationItem[] = uniqBy(
    extraKeys.map((key) => {
      const [columnId, errorID] = key.split("-");
      if (
        columnId in validationColumnNameMap &&
        validationColumnNameMap[columnId].trim()
      ) {
        return {
          type: errorID.charAt(0),
          text: `${validationColumnNameMap[columnId]}: ${sheetErrorMap[errorID]}`,
          keys: [],
        };
      }
      return {
        type: errorID.charAt(0),
        text: `${sheetErrorMap[errorID]}`,
        keys: [],
      };
    }),
    (item) => item.text
  );

  return (
    <ValidationDashboardContainer>
      {errorCount > 0 && (
        <CountText>
          <ErrorIcon /> {errorCount} Potential Errors
        </CountText>
      )}
      {warningCount > 0 && (
        <CountText>
          <WarningIcon />
          {warningCount} Warnings
        </CountText>
      )}
      {project && (validationChecks || errorCount > 0 || warningCount > 0) && (
        <>
          {warningCount === 0 && errorCount === 0 && (
            <CountText>
              <CheckIcon />
            </CountText>
          )}
          <Button size="thin" onClick={handleShow}>
            <ViewListOutlined /> View Validations Summary
          </Button>
          <ValidationDashboardModalContainer
            centered
            show={show}
            onHide={handleHide}
          >
            <Modal.Header className={clsx("d-flex", "justify-content-between")}>
              <Modal.Title>
                <SubHeader>View</SubHeader>
                <Header>Validations Dashboard</Header>
              </Modal.Title>
              <Modal.Title className={clsx("text-right")}>
                <SubHeader>Version {project.versionNumber}</SubHeader>
                {project.latestIssueDate ? (
                  <HeaderRight>
                    Model Issued{" "}
                    {formatDistanceToNow(parseISO(project.latestIssueDate))} ago
                  </HeaderRight>
                ) : (
                  <HeaderRight>Model not published</HeaderRight>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {validationChecks &&
                validationChecks.map((validationSection, index) => {
                  return (
                    <ValidationSection
                      key={`validation-section-${index}`}
                      title={validationSection.title}
                      validations={validationSection.validations}
                      keys={errorKeys}
                    />
                  );
                })}
              {extraValidationItems.length > 0 && (
                <ValidationSection
                  key="validation-section-extra"
                  title="Additional Validations"
                  validations={extraValidationItems}
                />
              )}
              {extraValidationItems.length === 0 &&
                (!validationChecks || validationChecks.length === 0) && (
                  <ValidationSection
                    key="validation-section-valid"
                    title="Validations"
                    validations={[
                      {
                        keys: [],
                        text: "All data are Valid",
                        type: "C",
                      },
                    ]}
                  />
                )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="borderless" onClick={handleHide}>
                Cancel
              </Button>
            </Modal.Footer>
          </ValidationDashboardModalContainer>
        </>
      )}
    </ValidationDashboardContainer>
  );
};

export default observer(ValidationDashboard);
