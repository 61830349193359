import { CSVFrozenHeaderMapping } from "csv/mappings";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { APIOutputSheetFileType } from "../../api/outputSheets";
import GenerateOutputsButton from "../../components/GenerateOutputsButton";
import Loading from "../../components/Loading";
import {
  getFrozenMappingCostingOutput,
  mappingCostSheet,
  mappingRawMaterialSummary,
} from "../../csv/mappings/output";
import { useStore } from "../../store";
import OutputSheet from "../../store/models/OutputSheet";
import WorkArea from "../WorkArea";
import { useProject } from "../Workspace/hooks";
import { TabList } from "../Workspace/types";
import FormulationsCpl from "./FormulationsCpl";
import FormulationSelectionsTab from "./FormulationSelectionsTab";
import { schemaCostingSheet, schemaRawMaterialSummary } from "./schema";
import SourcingSelectionsTab from "./SourcingSelectionsTab";
// import FormulationSelectionsTab from "./FormulationSelectionsTab";
// import SourcingSelectionsTab from "./SourcingSelectionsTab";

type Props = {};

const OutputSection: React.FC<Props> = () => {
  const store = useStore();
  const project = useProject();
  const { current } = store.auth;

  const [frozenCosting, setFrozenCosting] = useState<CSVFrozenHeaderMapping>(
    getFrozenMappingCostingOutput(true)
  );

  const sheets = project
    ? store.outputSheets.ofProject(project.id)
    : new Map<APIOutputSheetFileType, OutputSheet[]>();

  const sheetEditable =
    !project?.isLocked &&
    !!current &&
    (!!current.isAdmin || !!project?.adminUsers?.find((id) => current.id));

  const tabs: TabList = {
    costingSheet: {
      kind: "worksheet",
      id: "costingSheet",
      title: "Costing Sheet",
      type: "sheet-output",
      fileType: "costing_sheet",
      uploadable: false,
      frozenColumns: frozenCosting,
      setFrozenColumns: setFrozenCosting,
      csvFiles:
        sheets.get("costing_sheet")?.map((sheet) => [
          {
            id: `costingSheet-${sheet.id}`,
            sheet,
            editable: sheetEditable ?? true,
            insertable: false,
            downloadable: true,
            updatable: true,
            rowIdentifier: "costing_option_id",
            hideColumns: () => [
              "costing_option_id",
              "formulation_id",
              "plant_split_link",
              "concatenate_formulation",
              "concatenate_manufacturing",
              "concatenate_logistics",
            ],
            headerMapping: mappingCostSheet,
            schema: (key, sheetEditable) =>
              schemaCostingSheet(key, sheetEditable, frozenCosting),
          },
        ]) || [],
      marginalCsvFiles:
        sheets.get("marginal_cost")?.map((sheet) => [
          {
            id: `marginalCostingSheet-${sheet.id}`,
            sheet,
            downloadable: true,
            editable: false,
            updatable: true,
            rowIdentifier: "costing_option_id",
            hideColumns: () => [
              "costing_option_id",
              "formulation_id",
              "concatenate_formulation",
              "concatenate_manufacturing",
              "concatenate_logistics",
            ],
            headerMapping: mappingCostSheet,
            schema: (key, sheetEditable) =>
              schemaCostingSheet(key, sheetEditable, frozenCosting),
          },
        ]) || [],
      renderExtraToolbar: (worksheet) => (
        <GenerateOutputsButton onClick={worksheet.csvLoader} />
      ),

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.outputSheets.list(project.id, undefined, undefined, {
          file_type__in: ["costing_sheet", "marginal_cost"],
        });
      },
    },
    formulationsCpl: {
      kind: "custom",
      id: "formulationsCpl",
      title: "Formulations (CPL)",
      type: "sheet-output",
      render: FormulationsCpl,
    },
    formulationSelections: {
      kind: "custom",
      id: "formulationSelections",
      title: "Formulation Selections",
      type: "sheet-output",
      render: FormulationSelectionsTab,
    },
    sourcingSelections: {
      kind: "custom",
      id: "sourcingSelections",
      title: "Sourcing Selections",
      type: "sheet-output",
      render: SourcingSelectionsTab,
    },
    rawMaterialSummary: {
      kind: "worksheet",
      id: "rawMaterialSummary",
      title: "Raw Material Summary",
      type: "sheet-output",
      fileType: "raw_material_summary",
      uploadable: false,
      csvFiles:
        sheets.get("raw_material_summary")?.map((sheet) => [
          {
            id: `rawMaterialSummary-${sheet.id}`,
            sheet,
            downloadable: true,
            editable: false,
            rowIdentifier: "raw_material_id",
            hideColumns: () => ["raw_material_id"],
            headerMapping: mappingRawMaterialSummary,
            schema: schemaRawMaterialSummary,
          },
        ]) || [],
      marginalCsvFiles: [],
      renderExtraToolbar: (worksheet) => (
        <GenerateOutputsButton onClick={worksheet.csvLoader} />
      ),

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.outputSheets.list(project.id, undefined, undefined, {
          file_type: "raw_material_summary",
        });
      },
    },
  };

  if (!project) {
    return <Loading full />;
  }

  return <WorkArea tabs={tabs} />;
};

export default observer(OutputSection);
