import { Model, model, prop, prop_dateString } from "mobx-keystone";

@model("collab/Forex")
class Forex extends Model({
  id: prop<identifier>(),
  currencyCode: prop<string>(),
  usdExchangeRate: prop<number>(),
  eurExchangeRate: prop<number>(),
  effectiveOn: prop_dateString<Date>(),
}) {}

export default Forex;
