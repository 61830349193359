import { CloudDownloadOutlined } from "@material-ui/icons";
import { getNumberFormatter } from "components/DataGrid/NumberFormatter";
import { ColumnExtraOptions } from "components/DataGrid/types";
import GenerateOutputsButton from "components/GenerateOutputsButton";
import { IRow } from "csv/types";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ScrollSync } from "react-scroll-sync";
import { useAsyncFn, useMount } from "react-use";
import styled from "styled-components";
import { scaledChars } from "utils";
import api from "../../api";
import { APIOutputSheetFileType } from "../../api/outputSheets";
import Button from "../../components/Button";
import Toolbar from "../../components/Toolbar";
import { useStore } from "../../store";
import { useProject } from "../Workspace/hooks";
import { CsvFile } from "../Workspace/types";
import CSVSheetWithEditor from "./CSVSheetWithEditor";

type Props = {
  id: string;
  fileType: APIOutputSheetFileType;
};

const SheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  overflow: auto;
`;

const schema = (key: string): ColumnExtraOptions<IRow> => {
  const defaultSchema: ColumnExtraOptions<IRow> = {
    editable: false,
    width: scaledChars(10),
    cellClass(row) {
      if (row.source_plant === "") {
        return "cell-subheader";
      }
      return "cell-readonly";
    },
  };

  switch (key) {
    case "id":
      return {};
    case "dest_country":
      return {
        ...defaultSchema,
        name: "Destination Country",
      };
    case "source_plant":
    case "pack_size":
      return defaultSchema;
    case "pack_type":
      return {
        ...defaultSchema,
        width: scaledChars(16),
      };
    case "volume_yr1":
      return {
        ...defaultSchema,
        name: "Vol Yr 1",
        formatter(props) {
          if (props.row.source_plant === "") {
            return <>{props.row[props.column.key]}</>;
          }
          const Formatter = getNumberFormatter(0, 0);
          return <Formatter {...props} />;
        },
      };
  }
  return {
    ...defaultSchema,
    name: key,
    formatter(props) {
      if (props.row.source_plant === "") {
        return <>{props.row[props.column.key]}</>;
      }
      const Formatter = getNumberFormatter(3, 3);
      return <Formatter {...props} />;
    },
  };
};

const SelectionsTab: React.FC<Props> = ({ id, fileType }) => {
  const [activeSheet, setActiveSheet] = useState<string>();
  const store = useStore();
  const project = useProject();

  const [, loadFn] = useAsyncFn(async () => {
    if (!project) {
      return;
    }
    await store.outputSheets.list(project.id, undefined, undefined, {
      file_type: fileType,
    });
  }, [project, store.outputSheets, fileType]);

  useMount(() => {
    loadFn();
  });

  const sheets =
    (project &&
      (store.outputSheets.ofProject(project.id).get(fileType) || [])) ||
    [];

  const files: CsvFile[] = sheets.map((sheet) => ({
    id: `${fileType}-${sheet.id}`,
    sheet,
    downloadable: true,
    editable: true,
    updatable: true,
    maxRowsShown: "auto",
    rowIdentifier: "id",
    schema,
  }));

  const handleDownloadClick = async () => {
    const zip = new JSZip();

    await files.reduce(
      (prevPromise, file) =>
        prevPromise
          .then(() => api.csv.get(file.sheet.file))
          .then((response) => response.text())
          .then((text) => {
            zip.file(`${file.id}.csv`, text);
          }),
      Promise.resolve()
    );

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, id);
  };

  return (
    <>
      <Toolbar>
        <Button size="thin" onClick={handleDownloadClick}>
          <CloudDownloadOutlined /> Download CSV File
        </Button>
        <GenerateOutputsButton onClick={loadFn} />
      </Toolbar>

      <ScrollSync enabled={false}>
        <SheetContainer className="multiple">
          {files.map((file) => (
            <CSVSheetWithEditor
              key={`${id}__${file.id}`}
              isActive={activeSheet === file.id}
              onActive={setActiveSheet}
              file={file}
            />
          ))}
        </SheetContainer>
      </ScrollSync>
    </>
  );
};

export default observer(SelectionsTab);
