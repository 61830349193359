import clsx from "clsx";
import { isNaN, omit } from "lodash";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { ChevronCompactDown } from "react-bootstrap-icons";
import { SortDirection } from "react-data-grid";
import OutsideClickHandler from "react-outside-click-handler";
import Select from "react-select";
import { useScrollbarWidth, useWindowSize } from "react-use";
import styled, { CSSObject } from "styled-components";
import { Cell, IRow } from "../../csv/types";
import Button from "../Button";
import {
  FILTER_DROPDOWN_OPTIONS,
  NUMERIC_COLUMNS_FILTER_DROPDOWN_OPTIONS,
  SORT_DROPDOWN_OPTIONS,
} from "./constants";
import { FilterContext } from "./contexts";
import {
  HeaderCheckboxFilters,
  HeaderSelectionFilters,
  SelectionFilter,
  SelectionFilterOption,
} from "./types";

const StyledContainer = styled.div`
  position: fixed;
  top: 32px;
  left: 40px;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  padding: 20px;
  display: flex;
  flex-direction: column;
  z-index: 999;
`;

const SectionHeader = styled.div`
  display: flex;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
`;

const SectionContainer = styled.div`
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

const StyledDropdownButton = styled(DropdownButton)`
  display: flex;
  height: 36px;
  margin-bottom: 12px;

  &.empty .dropdown-toggle {
    color: rgba(15, 15, 15, 0.5);
  }

  .dropdown-toggle {
    align-items: center;
    background-color: #ffffff !important;
    border: #a0a0a0 1px solid !important;
    border-radius: 5px;
    color: rgba(15, 15, 15, 1);
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    justify-content: space-between;
    letter-spacing: 0.3px;
    line-height: 1.5;
    padding: 2px 10px;
    text-align: left;
    width: 100%;

    .title {
      width: 90%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 5px;
    }

    svg {
      font-size: 16px;
      color: #000000;
    }

    &::after {
      content: none;
    }
    &:focus {
      box-shadow: none !important;
    }
  }

  .dropdown-menu {
    background-color: #ffffff;
    border: none;
    border-radius: 0;
    min-width: 1rem;
    overflow-y: scroll;
    padding: 0px;
    box-shadow: 0 2px 4px 0 rgba(216, 216, 216, 0.5);
    overflow-y: auto;
    max-height: 255px;
    width: 100%;

    .dropdown-item {
      color: rgba($color: #0b0b0b, $alpha: 0.6);
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.3px;
      line-height: 1.5;
      padding: 8px 10px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: #dedede;
        color: rgba($color: #0b0b0b, $alpha: 1);
      }
    }
  }
`;

const StyledTextInput = styled(Form.Control)`
  width: 100%;
  background-color: #ffffff !important;
  border: #a0a0a0 1px solid !important;
  border-radius: 5px;
  height: 36px;
  padding: 0 10px;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;
  margin-left: 15px;
`;

const keywordDropdownStyles = {
  control: (base: CSSObject, state: any) => ({
    ...base,
    border: "#a0a0a0 1px solid !important",
    boxShadow: "none",
    fontSize: "0.875rem",
    lineHeight: "0.875rem",
  }),
  option: (base: CSSObject, state: any) => ({
    ...base,
    backgroundColor: "#ffffff",

    "&:hover": {
      backgroundColor: "#dedede",
    },
  }),
};

interface MultiSelectOption {
  value: number;
  label: string;
}

interface IProps {
  rows: readonly IRow[];
  sortColumn: string;
  setSortColumn: (sortColumn: string) => void;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
  checkboxFilters: HeaderCheckboxFilters;
  setCheckboxFilters: (checkboxFilters: HeaderCheckboxFilters) => void;
  selectionFilters: HeaderSelectionFilters;
  setSelectionFilters: (selectionFilters: HeaderSelectionFilters) => void;
  filterDisplayMappers?: Record<string, (value: string) => string>;
}

const DataGridHeaderDropdown: FC<IProps> = ({
  rows,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  checkboxFilters,
  setCheckboxFilters,
  selectionFilters,
  setSelectionFilters,
  filterDisplayMappers = {},
}) => {
  const {
    columnKey,
    columnBoundingLeft,
    boundingTop,
    setColumnKey,
    setShowDropdown,
  } = useContext(FilterContext);
  const [dropdownLeftOffset, setDropdownLeftOffset] = useState<number>(0);
  const [dropdownTopOffset, setDropdownTopOffset] = useState<number>(0);
  const [uniqueColumnValues, setUniqueColumnValues] = useState<Cell[]>([]);
  const [selectionFilter, setSelectionFilter] =
    useState<SelectionFilterOption>();
  const [selectionFilterText, setSelectionFilterText] = useState<string>("");
  const [areAllNumbers, setAreAllNumbers] = useState<boolean>(false);

  const { width, height } = useWindowSize();
  const scrollBarWidth = useScrollbarWidth();
  const displayMapper = filterDisplayMappers
    ? filterDisplayMappers[columnKey]
    : undefined;

  const keywordOptions = uniqueColumnValues
    .map((e) =>
      e !== ""
        ? {
            value: `${e}`,
            label: displayMapper ? displayMapper(`${e}`) : `${e}`,
          }
        : { value: `${e}`, label: "(Blanks)" }
    )
    .sort((a, b) =>
      a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())
    );

  const keywordValues = checkboxFilters[columnKey]
    ? checkboxFilters[columnKey].map((e) =>
        e !== ""
          ? {
              value: `${e}`,
              label: displayMapper ? displayMapper(`${e}`) : `${e}`,
            }
          : { value: `${e}`, label: "(Blanks)" }
      )
    : [];

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const leftOffset =
        columnBoundingLeft + 320 > width
          ? Math.max(columnBoundingLeft - 300, 20)
          : Math.min(columnBoundingLeft, width - 320);
      const topOffset = Math.max(Math.min(boundingTop + 20, height - 460), 120);
      setDropdownTopOffset(topOffset);
      setDropdownLeftOffset(leftOffset);
    }

    return () => {
      isMounted = false;
    };
  }, [width, height, scrollBarWidth, columnBoundingLeft, boundingTop]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (columnKey) {
        if (selectionFilters[columnKey]) {
          const columnFilter = selectionFilters[columnKey];

          setSelectionFilter(columnFilter.option);
          setSelectionFilterText(columnFilter.text);
        } else {
          setSelectionFilter(undefined);
          setSelectionFilterText("");
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [columnKey, selectionFilters]);

  useEffect(() => {
    let isMounted = true;

    if (columnKey && isMounted) {
      const columnValues = rows.map(
        (row) => row[columnKey]?.toLocaleString() || ""
      );
      const _uniqueColumnValues = [...new Set(columnValues)];

      const index = _uniqueColumnValues.indexOf("");
      if (index !== -1) {
        const value = _uniqueColumnValues.splice(index, 1).shift()!;
        _uniqueColumnValues.unshift(value);
      }

      let _areAllNumbers = false;
      if (_uniqueColumnValues.length) {
        _areAllNumbers = true;

        for (const value of _uniqueColumnValues) {
          if (value && isNaN(Number(value))) {
            _areAllNumbers = false;
            break;
          }
        }
      }

      setUniqueColumnValues(_uniqueColumnValues);
      setAreAllNumbers(_areAllNumbers);
    }

    return () => {
      isMounted = false;
    };
  }, [rows, columnKey]);

  const handleDropdownItemClick = useCallback(
    (filterOption: SelectionFilterOption) => {
      setSelectionFilter(filterOption);
      setSelectionFilterText("");
      if (columnKey) {
        const updatedColumnSelectionFilter: SelectionFilter = {
          option: { ...filterOption },
          text: "",
        };
        const updatedSelectionFilters: HeaderSelectionFilters = {
          ...selectionFilters,
          [columnKey]: { ...updatedColumnSelectionFilter },
        };
        setSelectionFilters(updatedSelectionFilters);
      }
    },
    [columnKey, selectionFilters, setSelectionFilters]
  );

  const handleSortDropdownClick = useCallback(
    (filterOption: SelectionFilterOption) => {
      setSortDirection(filterOption.key as SortDirection);
      if (filterOption.key === "NONE") {
        setSortColumn("");
      } else {
        setSortColumn(columnKey);
      }
    },
    [columnKey, setSortColumn, setSortDirection]
  );

  const handleKeywordClick = useCallback(
    (dropdownValue) => {
      let updatedValues: HeaderCheckboxFilters = {
        ...checkboxFilters,
      };

      if (columnKey) {
        if (dropdownValue.length === 0) {
          updatedValues = omit(checkboxFilters, columnKey);
        } else {
          updatedValues[columnKey] = dropdownValue.map(
            (e: MultiSelectOption) => `${e.value}`
          );
        }
      }
      setCheckboxFilters(updatedValues);
    },
    [columnKey, checkboxFilters, setCheckboxFilters]
  );

  const handleSelectionFilterTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (columnKey) {
        const filterText = e.target.value;
        const currrentColumnSelectionFilter = {
          ...selectionFilters[columnKey],
        };
        const updatedColumnSelectionFilter: SelectionFilter = {
          option: { ...currrentColumnSelectionFilter.option },
          text: filterText,
        };
        const updatedSelectionFilters: HeaderSelectionFilters = {
          ...selectionFilters,
          [columnKey]: { ...updatedColumnSelectionFilter },
        };
        setSelectionFilters(updatedSelectionFilters);
      }
    },
    [columnKey, selectionFilters, setSelectionFilters]
  );

  const handleClearFilterClick = useCallback(
    (clearAll = false) => {
      if (columnKey) {
        if (clearAll) {
          setCheckboxFilters({});
          setSelectionFilters({});
          return;
        } else {
          const updatedCheckboxFilters = omit(checkboxFilters, columnKey);
          const updatedSelectionFilters = omit(selectionFilters, columnKey);

          setCheckboxFilters(updatedCheckboxFilters);
          setSelectionFilters(updatedSelectionFilters);
        }
      }
    },
    [
      columnKey,
      checkboxFilters,
      selectionFilters,
      setCheckboxFilters,
      setSelectionFilters,
    ]
  );

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (columnKey) {
        setColumnKey("");
        setShowDropdown(false);
      }
    },
    [columnKey, setColumnKey, setShowDropdown]
  );

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <StyledContainer
        className={clsx({ hidden: !columnKey })}
        style={{
          left: `${dropdownLeftOffset}px`,
          top: `${dropdownTopOffset}px`,
        }}
      >
        <SectionHeader>Order</SectionHeader>
        <SectionContainer>
          <StyledDropdownButton
            title={
              <>
                <span className="title">
                  {sortDirection !== "NONE"
                    ? SORT_DROPDOWN_OPTIONS[sortDirection].title
                    : "Select Order"}
                </span>
                <ChevronCompactDown />
              </>
            }
            className={clsx({ empty: sortDirection === "NONE" })}
          >
            {Object.values(SORT_DROPDOWN_OPTIONS).map((value) => (
              <Dropdown.Item
                key={value.key}
                onClick={() => handleSortDropdownClick(value)}
              >
                {value.title}
              </Dropdown.Item>
            ))}
          </StyledDropdownButton>
        </SectionContainer>
        <SectionHeader>Filter</SectionHeader>
        <SectionContainer>
          <StyledDropdownButton
            title={
              <>
                <span className="title">
                  {selectionFilter?.title
                    ? selectionFilter.title
                    : "Select Filter"}
                </span>
                <ChevronCompactDown />
              </>
            }
            className={clsx({ empty: !selectionFilter })}
          >
            {Object.values(FILTER_DROPDOWN_OPTIONS).map((value) => (
              <Dropdown.Item
                key={value.key}
                onClick={() => handleDropdownItemClick(value)}
              >
                {value.title}
              </Dropdown.Item>
            ))}
            {areAllNumbers &&
              Object.values(NUMERIC_COLUMNS_FILTER_DROPDOWN_OPTIONS).map(
                (value) => (
                  <Dropdown.Item
                    key={value.key}
                    onClick={() => handleDropdownItemClick(value)}
                  >
                    {value.title}
                  </Dropdown.Item>
                )
              )}
          </StyledDropdownButton>
          <StyledTextInput
            as="input"
            type="text"
            value={selectionFilterText || ""}
            placeholder={
              selectionFilter &&
              selectionFilter.key ===
                NUMERIC_COLUMNS_FILTER_DROPDOWN_OPTIONS.between.key
                ? "e.g. 1-100"
                : ""
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleSelectionFilterTextChange(e)
            }
          />
        </SectionContainer>
        <SectionHeader>Keyword</SectionHeader>
        <SectionContainer>
          <Select
            isMulti
            styles={keywordDropdownStyles}
            value={keywordValues}
            placeholder={"Select Keyword"}
            noOptionsMessage={() => "No Keywords"}
            components={{ IndicatorSeparator: () => null }}
            options={keywordOptions}
            onChange={handleKeywordClick}
          />
        </SectionContainer>
        <ButtonContainer>
          <StyledButton onClick={() => handleClearFilterClick()}>
            Clear
          </StyledButton>
          <StyledButton onClick={() => handleClearFilterClick(true)}>
            Clear All
          </StyledButton>
        </ButtonContainer>
      </StyledContainer>
    </OutsideClickHandler>
  );
};

export default observer(DataGridHeaderDropdown);
