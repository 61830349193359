import clsx from "clsx";
import {
  endsWith,
  gt,
  gte,
  includes,
  isEmpty,
  isEqual,
  isNaN,
  isString,
  startsWith,
} from "lodash";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { MenuItem } from "react-contextmenu";
import ReactDataGrid, {
  Column,
  DataGridHandle,
  RowsChangeData,
  SortDirection,
} from "react-data-grid";
import { HeaderRendererProps } from "react-data-grid/lib";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createPortal } from "react-dom";
import { ScrollSyncPane } from "react-scroll-sync";
import { useClickAway } from "react-use";
import styled, { css } from "styled-components";
import { Cell, IRow } from "../../csv/types";
import { scaledRem } from "../../utils";
import ContextMenu from "../ContextMenu";
import AddRows from "./AddRows";
import DataGridHeaderDropdown from "./DataGridHeaderDropdown";
import DraggableHeaderCell from "./DraggableHeaderCell";
import Row from "./Row";
import {
  FILTER_DROPDOWN_OPTIONS,
  NUMERIC_COLUMNS_FILTER_DROPDOWN_OPTIONS,
} from "./constants";
import {
  DataGridContext,
  FilterContext,
  RangeSelectionContext,
} from "./contexts";
import { useClipboard, useRangeSelection } from "./hooks";
import {
  ColumnExtraOptions,
  DataGridHandleWithData,
  HeaderCheckboxFilters,
  HeaderSelectionFilters,
  Position,
  RangeSelection,
  ValueParsers,
} from "./types";

type Props = {
  className?: string;
  collapsed?: boolean;
  collapsible?: boolean;
  columns: readonly Column<IRow>[];
  commentIdentifiers?: Record<string, boolean>;
  defaultCollapsed?: boolean;
  defaultColumnOptions?: ColumnExtraOptions<IRow>;
  errors?: Record<string, string>;
  extraContextMenu?: (
    rows: readonly IRow[],
    selected?: Position,
    range?: RangeSelection
  ) => {
    data?: any;
    disabled?: boolean;
    id: string;
    label: string;
    onClick?: (
      event: React.TouchEvent<HTMLDivElement>,
      data: any
    ) => void | Promise<void>;
  }[];
  extraHeaderRows?: readonly IRow[];
  filterDisplayMappers?: Record<string, (value: string) => string>;
  filterable?: boolean;
  headerRowHeight?: number;
  id: string;
  isActive?: boolean;
  maxRowsShown?: number | "auto";
  minRowsShown?: number;
  multiple?: boolean;
  onActive?: (sheetId: string) => void;
  onAddRows?: (newRowCount: number) => void;
  onCellSelect?: (row: IRow, column: Column<IRow>) => void;
  onColumnsReorder?: (sourceKey: string, targetKey: string) => void;
  onCopyDone?: () => void | Promise<void>;
  onPasteDone?: () => void | Promise<void>;
  onRowsChange: (rows: IRow[], data?: RowsChangeData<IRow, unknown>) => void;
  rowIdentifier: string;
  rows: readonly IRow[];
  title?: string | JSX.Element;
  titleExtra?: JSX.Element | null;
  valueParsers?: ValueParsers;
};

type ContainerProps = Pick<
  Props,
  "minRowsShown" | "maxRowsShown" | "headerRowHeight"
> & {
  canAddRows: boolean;
};

const RootContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  &.multiple {
    flex: 0;
    min-width: 50%;
    padding: 1.25rem 1.25rem 0 1.25rem;

    &:not(:first-child) {
      padding-top: 0;
    }
  }
`;

const ScrollContainer = styled.div<ContainerProps>`
  flex-grow: 1;
  height: 100%;
  transition: min-height 0.25s ease-in-out, max-height 0.25s ease-in-out;

  .rdg {
    border: 0;
    height: 100%;

    &:focus {
      outline: none;
    }

    .rdg-cell {
      font-size: 0.625rem;
      font-weight: bold;

      &[role="columnheader"] {
        background-color: #e3e3e3;
        text-transform: uppercase;
      }
    }
  }

  &.multiple {
    border: 1px solid #cecece;
    border-radius: 0.25rem;

    // calc(numRows * 1.5rem + headerHeight + containerRoundedBorders * 2 + 3px addt'l border + 16px horizontal scrollbar)
    min-height: ${(props) =>
      props.maxRowsShown !== undefined
        ? `calc(${props.maxRowsShown} * 1.5rem + ${
            props.headerRowHeight ?? 32
          }px + 0.5rem * 2 + 3px + 16px)`
        : "calc(30rem - 0.75rem)"};
    max-height: ${(props) =>
      props.maxRowsShown !== undefined
        ? `calc(${props.maxRowsShown} * 1.5rem + ${
            props.headerRowHeight ?? 32
          }px)`
        : "calc(30rem - 0.75rem)"};

    ${(props) =>
      !props.canAddRows
        ? css`
            margin-bottom: 1.25rem;
          `
        : null}

    ${(props) =>
      !props.canAddRows
        ? css`
            margin-bottom: 1.25rem;
          `
        : null}

    &::before {
      background-color: #e3e3e3;
      border-bottom: 1px solid #cecece;
      border-radius: 0.25rem 0.25rem 0 0;
      content: "";
      display: block;
      height: 0.5rem;
    }

    &::after {
      background-color: #e3e3e3;
      border-radius: 0 0 0.25rem 0.25rem;
      border-top: 1px solid #cecece;
      content: "";
      display: block;
      height: 0.5rem;
    }

    .rdg {
      height: calc(100% - 1rem);
    }

    &.collapsed {
      border-width: ${(props) => (props.minRowsShown! > 0 ? "1px" : "0")};

      min-height: ${(props) =>
        props.minRowsShown! > 0
          ? `calc(2rem + ${props.minRowsShown} * 1.5rem + 1rem + 3px + 16px)`
          : props.canAddRows
          ? "1rem"
          : "0"};
      max-height: ${(props) =>
        props.minRowsShown! > 0
          ? `calc(2rem + ${props.minRowsShown} * 1.5rem + 1rem + 3px + 16px)`
          : props.canAddRows
          ? "1rem"
          : "0"};

      &::before,
      &::after {
        display: ${(props) => (props.minRowsShown! > 0 ? "block" : "none")};
      }
    }
  }
`;

const Title = styled.div<ContainerProps>`
  background-color: #fdfdfd;
  border-radius: 0.25rem 0.25rem 0 0;
  border: 1px solid #c8c8c8;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  height: 3.125rem;
  letter-spacing: 0.03125rem;
  line-height: 3rem;
  margin-bottom: calc(-0.5rem - 1px);
  margin-right: auto;
  padding: 0 0.8125rem;
  user-select: none;
  z-index: 2;

  ${(props) =>
    props.minRowsShown! === 0
      ? css`
          &.collapsed {
            border-radius: 0.25rem;
            margin-bottom: 0;
          }
        `
      : undefined}

  svg {
    margin-right: 0.625rem;
    height: 1rem;
    width: 1rem;
  }
`;

const DataGridBase = (
  {
    className = "",
    collapsible = true,
    columns,
    commentIdentifiers,
    defaultCollapsed = false,
    defaultColumnOptions,
    errors,
    extraContextMenu,
    extraHeaderRows = [],
    filterable = false,
    filterDisplayMappers,
    headerRowHeight = scaledRem(32),
    id,
    isActive = false,
    maxRowsShown,
    minRowsShown = 0,
    multiple,
    onActive,
    onAddRows,
    onCellSelect,
    onColumnsReorder,
    onCopyDone,
    onPasteDone,
    onRowsChange,
    rowIdentifier,
    rows,
    title = "",
    titleExtra,
    valueParsers = {},
  }: Props,
  ref: React.Ref<DataGridHandleWithData>
) => {
  const gridRef = useRef<DataGridHandle>(null);
  const rootContainerRef = useRef<HTMLDivElement>(null);

  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>("NONE");
  const [sortColumn, setSortColumn] = useState<string>("");
  const [columnBoundingLeft, setColumnBoundingLeft] = useState<number>(0);
  const [boundingTop, setBoundingTop] = useState<number>(0);
  const [filterColumnKey, setFilterColumnKey] = useState<string>("");
  const [checkboxFilters, setCheckboxFilters] = useState<HeaderCheckboxFilters>(
    {}
  );
  const [selectionFilters, setSelectionFilters] =
    useState<HeaderSelectionFilters>({});

  const handleFocus = useCallback(() => {
    if (!isActive) {
      onActive?.(id);
    }
  }, [isActive, onActive, id]);

  useClickAway(rootContainerRef, () => {
    onActive?.("");
  });

  useEffect(() => {
    const r = gridRef.current;
    r?.element?.setAttribute("tabindex", "0");
    r?.element?.addEventListener("focus", handleFocus);
    return () => {
      r?.element?.removeEventListener("focus", handleFocus);
    };
  }, [handleFocus, gridRef]);

  const handleCopyClick = useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    document.execCommand("copy");
  }, []);

  const handleCollapseClick = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  const draggableColumns = useMemo(() => {
    if (!onColumnsReorder) {
      return columns;
    }

    function HeaderRenderer(props: HeaderRendererProps<IRow, Column<IRow>>) {
      return (
        <DraggableHeaderCell {...props} onColumnsReorder={onColumnsReorder!} />
      );
    }

    return columns.map((c) => {
      if (c.key === "id") return c;
      return { ...c, headerRenderer: HeaderRenderer };
    }) as Column<IRow>[];
  }, [columns, onColumnsReorder]);

  const sortedRows = useMemo((): readonly IRow[] => {
    if (sortDirection === "NONE") {
      return rows;
    }

    let sortedRows: IRow[] = [...rows];

    const columnValues: Cell[] = sortedRows.map((row) => row[sortColumn]);

    let containsNumbers = false;
    let containsStrings = false;
    let uniqueValues = [...new Set(columnValues)].filter(
      (value) => value !== ""
    );

    for (const value of uniqueValues) {
      containsNumbers = !isNaN(Number(value));

      if (containsNumbers) {
        if (containsStrings) break;
      } else {
        containsStrings = containsStrings || isString(value);
      }
    }

    if (containsStrings) {
      sortedRows = sortedRows.sort((a, b) => {
        const x = a[sortColumn]?.toLocaleString() || "";
        const y = b[sortColumn]?.toLocaleString() || "";
        return x.localeCompare(y);
      });
    } else if (containsNumbers) {
      sortedRows = sortedRows.sort((a, b) => {
        const x = Number(a[sortColumn] || NaN);
        const y = Number(b[sortColumn] || NaN);

        if (isNaN(x) && isNaN(y)) return 0;
        else if (isNaN(x)) return 1;
        else if (isNaN(y)) return -1;
        else return x - y;
      });
    }

    return sortDirection === "DESC" ? sortedRows.reverse() : sortedRows;
  }, [rows, sortDirection, sortColumn]);

  const selectionFilteredRows: readonly IRow[] = useMemo(() => {
    const filteredRows = sortedRows.filter((row) => {
      let isEligible = true;

      Object.entries(selectionFilters)
        .filter(([columnKey, filter]) => !isEmpty(filter.option))
        .forEach(([columnKey, filter]) => {
          if (!isEligible || !filter.text || !filter.option) return;

          let columnValue = row[columnKey]?.toLocaleString();
          if (columnValue && filterDisplayMappers?.[columnKey]) {
            columnValue = filterDisplayMappers?.[columnKey](columnValue);
          }
          columnValue = columnValue?.toLocaleLowerCase();

          const filterText = filter.text.toLocaleLowerCase();

          switch (filter.option.key) {
            case FILTER_DROPDOWN_OPTIONS.equals.key:
              isEligible = isEligible && isEqual(columnValue, filterText);
              break;
            case FILTER_DROPDOWN_OPTIONS.does_not_equal.key:
              isEligible = isEligible && !isEqual(columnValue, filterText);
              break;
            case FILTER_DROPDOWN_OPTIONS.begins_with.key:
              isEligible = isEligible && startsWith(columnValue, filterText);
              break;
            case FILTER_DROPDOWN_OPTIONS.does_not_begin_with.key:
              isEligible = isEligible && !startsWith(columnValue, filterText);
              break;
            case FILTER_DROPDOWN_OPTIONS.ends_with.key:
              isEligible = isEligible && endsWith(columnValue, filterText);
              break;
            case FILTER_DROPDOWN_OPTIONS.does_not_end_with.key:
              isEligible = isEligible && !endsWith(columnValue, filterText);
              break;
            case FILTER_DROPDOWN_OPTIONS.contains.key:
              isEligible = isEligible && includes(columnValue, filterText);
              break;
            case FILTER_DROPDOWN_OPTIONS.does_not_contain.key:
              isEligible = isEligible && !includes(columnValue, filterText);
              break;
            case NUMERIC_COLUMNS_FILTER_DROPDOWN_OPTIONS.greater_than.key:
              if (columnValue && filterText && !isNaN(Number(filterText))) {
                isEligible =
                  isEligible && gt(Number(columnValue), Number(filterText));
              } else isEligible = false;
              break;
            case NUMERIC_COLUMNS_FILTER_DROPDOWN_OPTIONS.less_than.key:
              if (columnValue && filterText && !isNaN(Number(filterText))) {
                isEligible =
                  isEligible && !gte(Number(columnValue), Number(filterText));
              } else isEligible = false;
              break;
            case NUMERIC_COLUMNS_FILTER_DROPDOWN_OPTIONS.between.key:
              const [lowerBound, upperBound] = filterText.split("-");

              if (columnValue && lowerBound && upperBound) {
                isEligible =
                  isEligible &&
                  Number(columnValue) >= Number(lowerBound) &&
                  Number(columnValue) <= Number(upperBound);
              } else isEligible = false;
              break;
          }
        });

      return isEligible;
    });

    return filteredRows;
  }, [sortedRows, selectionFilters, filterDisplayMappers]);

  const checkboxFilteredRows: readonly IRow[] = useMemo(() => {
    const filteredRows = selectionFilteredRows.filter((row) => {
      let isEligible = true;

      Object.entries(checkboxFilters).forEach(([columnKey, values]) => {
        isEligible = isEligible && values.includes(row[columnKey]);
      });

      return isEligible;
    });

    return filteredRows;
  }, [selectionFilteredRows, checkboxFilters]);

  const filteredRows = useMemo(
    () => [...extraHeaderRows, ...checkboxFilteredRows],
    [checkboxFilteredRows, extraHeaderRows]
  );

  // Enable multi-cell selection via context read from the custom Cell component.
  const {
    range,
    selected,
    setRange,
    setSelected,
    selectionType,
    setSelectionType,
  } = useRangeSelection(filteredRows.length, columns.length);

  // Enable custom copy and paste operations.
  const [, onPaste] = useClipboard(
    isActive,
    rows,
    checkboxFilteredRows,
    extraHeaderRows,
    columns,
    selected,
    range,
    onRowsChange,
    setRange,
    valueParsers,
    onCopyDone,
    onPasteDone
  );

  const rowKeyGetter = useCallback(
    (row: IRow) => `${row[rowIdentifier]}`,
    [rowIdentifier]
  );

  const handlePasteClick = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      await onPaste();
    },
    [onPaste]
  );

  const handleRowsChange = useCallback(
    (newRows: IRow[], changes: RowsChangeData<IRow, unknown>) => {
      const columnKey = changes.column.key;

      if (valueParsers && columnKey in valueParsers) {
        changes.indexes.forEach((index) => {
          newRows[index][columnKey] = valueParsers[columnKey](
            newRows[index][columnKey]
          );
        });
      }

      let rowsCopy: IRow[] = [...rows];
      const indexes: number[] = [];
      const newRowMap: Record<string, { row: IRow; index: number }> = {};
      newRows.forEach((item, index) => {
        newRowMap[`${item.id}`] = { row: item, index };
      });

      rows.forEach((item, index) => {
        if (newRowMap[`${item.id}`]) {
          rowsCopy[index] = newRowMap[`${item.id}`].row;
          if (changes.indexes.includes(newRowMap[`${item.id}`].index)) {
            indexes.push(index);
          }
        }
      });

      onRowsChange(rowsCopy, { ...changes, indexes });
    },
    [rows, onRowsChange, valueParsers]
  );

  // Back-propagate DataGridHandle properties to `ref`.
  useImperativeHandle(ref, () =>
    gridRef.current
      ? {
          element: gridRef.current.element,
          scrollToColumn: gridRef.current.scrollToColumn,
          scrollToRow: gridRef.current.scrollToRow,
          selectCell: gridRef.current.selectCell,
          rows: filteredRows,
          columns: draggableColumns,
        }
      : {
          element: null,
          scrollToColumn() {},
          scrollToRow() {},
          selectCell() {},
          rows: [],
          columns: [],
        }
  );

  return (
    <RangeSelectionContext.Provider
      value={{
        rows: [...extraHeaderRows, ...checkboxFilteredRows],
        columns,
        range,
        setRange,
        setSelected,
        selectionType,
        setSelectionType,
      }}
    >
      <DataGridContext.Provider
        value={{ id, errors, commentIdentifiers, rowIdentifier, onCellSelect }}
      >
        <FilterContext.Provider
          value={{
            filterable,
            columnBoundingLeft,
            setColumnBoundingLeft,
            boundingTop,
            setBoundingTop,
            columnKey: filterColumnKey,
            setColumnKey: setFilterColumnKey,
            showDropdown: showFilterDropdown,
            setShowDropdown: setShowFilterDropdown,
            checkboxFilters,
            selectionFilters,
            sortDirection,
            sortColumn,
          }}
        >
          <RootContainer
            className={clsx({ multiple }, className)}
            ref={rootContainerRef}
          >
            {collapsible && multiple ? (
              <Title
                maxRowsShown={
                  maxRowsShown === "auto" ? rows.length : maxRowsShown
                }
                minRowsShown={minRowsShown}
                canAddRows={!!onAddRows}
                className={clsx({ collapsed })}
                onClick={handleCollapseClick}
              >
                {collapsed ? <ChevronUp /> : <ChevronDown />}
                {title}
                {titleExtra}
              </Title>
            ) : null}

            <ScrollContainer
              className={clsx({ multiple, collapsed }, "rdg-container")}
              maxRowsShown={
                maxRowsShown === "auto" ? rows.length : maxRowsShown
              }
              minRowsShown={minRowsShown}
              headerRowHeight={headerRowHeight}
              canAddRows={!!onAddRows}
            >
              <ScrollSyncPane>
                <DndProvider backend={HTML5Backend}>
                  <ReactDataGrid
                    ref={gridRef}
                    className="rdg-light"
                    rows={filteredRows}
                    columns={draggableColumns}
                    defaultColumnOptions={{
                      resizable: true,
                      ...defaultColumnOptions,
                    }}
                    headerRowHeight={headerRowHeight}
                    onRowsChange={handleRowsChange}
                    onSelectedCellChange={setSelected}
                    sortColumn={"alt_sku_manufacturing"}
                    sortDirection={"DESC"}
                    rowHeight={scaledRem(24)}
                    rowKeyGetter={rowKeyGetter}
                    rowRenderer={Row}
                  />
                </DndProvider>
              </ScrollSyncPane>
            </ScrollContainer>

            {onAddRows ? (
              <AddRows
                className={clsx({ multiple, collapsed })}
                onAddRows={onAddRows}
              />
            ) : null}
          </RootContainer>

          {showFilterDropdown && (
            <DataGridHeaderDropdown
              rows={rows}
              filterDisplayMappers={filterDisplayMappers}
              sortColumn={sortColumn}
              setSortColumn={setSortColumn}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              checkboxFilters={checkboxFilters}
              setCheckboxFilters={setCheckboxFilters}
              selectionFilters={selectionFilters}
              setSelectionFilters={setSelectionFilters}
            />
          )}

          {createPortal(
            <ContextMenu id={`grid-context-menu-${id}`}>
              <MenuItem onClick={handleCopyClick}>Copy</MenuItem>
              <MenuItem onClick={handlePasteClick}>Paste</MenuItem>
              {extraContextMenu
                ? (() => {
                    const contextMenu = extraContextMenu(
                      filteredRows,
                      selected,
                      range
                    );

                    return (
                      <>
                        {contextMenu.length > 0 ? <MenuItem divider /> : null}
                        {contextMenu.map(
                          ({ id, data, label, disabled, onClick }) => (
                            <MenuItem
                              key={id}
                              onClick={onClick}
                              data={data}
                              disabled={disabled}
                            >
                              {label}
                            </MenuItem>
                          )
                        )}
                      </>
                    );
                  })()
                : null}
            </ContextMenu>,
            document.body
          )}
        </FilterContext.Provider>
      </DataGridContext.Provider>
    </RangeSelectionContext.Provider>
  );
};

const DataGrid = observer(DataGridBase, { forwardRef: true });

export const FrozenDataGrid = styled(DataGrid)`
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #f1f1f1;
`;

export default DataGrid;
