import {
  model,
  Model,
  prop_mapObject,
  _await,
  modelFlow,
  _async,
  modelAction,
} from "mobx-keystone";
import api from "../api";
import { APISearchParams } from "../api/base";
import { APIProjectFolderUpdateInput } from "../api/projectFolders";
import { APIProjectFolder } from "../api/types";
import ProjectFolder from "./models/ProjectFolders";

@model("collab/ProjectFolderStore")
class ProjectFolderStore extends Model({
  listItems: prop_mapObject(() => new Map<string, ProjectFolder>()),
  listActiveItems: prop_mapObject(() => new Map<string, ProjectFolder>()),
  listArchivedItems: prop_mapObject(() => new Map<string, ProjectFolder>()),
}) {
  @modelAction
  saveListItem(item: APIProjectFolder) {
    const listItem = new ProjectFolder(item);
    this.listItems.set(item.slug, listItem);
    return listItem;
  }

  @modelAction
  saveListActiveItem(item: APIProjectFolder) {
    const listActiveItem = new ProjectFolder(item);
    this.listActiveItems.set(item.slug, listActiveItem);
    return listActiveItem;
  }

  @modelAction
  saveListArchivedItem(item: APIProjectFolder) {
    const listArchiveItem = new ProjectFolder(item);
    this.listArchivedItems.set(item.slug, listArchiveItem);
    return listArchiveItem;
  }

  @modelFlow
  listActive = _async(function* (
    this: ProjectFolderStore,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.projectFolders.list(page, limit, searchParams));
    this.listActiveItems.clear();
    const results = resultsRaw.map((item) => this.saveListActiveItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listArchived = _async(function* (
    this: ProjectFolderStore,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.projectFolders.list(page, limit, searchParams));
    this.listArchivedItems.clear();
    const results = resultsRaw.map((item) => this.saveListArchivedItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  detail = _async(function* (this: ProjectFolderStore, slug: string) {
    try {
      const result = yield* _await(api.projectFolders.detail(slug));
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });

  @modelFlow
  patch = _async(function* (
    this: ProjectFolderStore,
    projectFolderSlug: string,
    apiData: APIProjectFolderUpdateInput
  ) {
    try {
      const result = yield* _await(
        api.projectFolders.update(projectFolderSlug, apiData)
      );
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });
}

export default ProjectFolderStore;
