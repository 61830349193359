import { CloudDownloadOutlined, ErrorOutline } from "@material-ui/icons";
import clsx from "clsx";
import Button from "components/Button";
import CLTFiles from "components/CLTFiles";
import Switch from "components/Switch";
import Toolbar from "components/Toolbar";
import ToolbarStatus from "components/ToolbarStatus";
import UploadButton from "components/UploadButton";
import ValidationDashboard from "components/ValidationDashboard";
import { CsvValidationErrorContext } from "components/ValidationDashboard/contexts";
import { useValidationSummary } from "components/ValidationDashboard/hooks";
import { CSVFrozenHeaderMapping } from "csv/mappings";
import { getFrozenMapping, getMapping } from "csv/mappings/utils";
import Folder from "icons/Folder";
import { flatten } from "lodash";
import { observer } from "mobx-react-lite";
import { useDownload, useProject, useUpload } from "pages/Workspace/hooks";
import { Worksheet as IWorksheet } from "pages/Workspace/types";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { ScrollSync } from "react-scroll-sync";
import { useAsync, useWindowSize } from "react-use";
import { useStore } from "store";
import InputSheet from "store/models/InputSheet";
import styled from "styled-components";
import { Toast } from "utils";
import CollabCheckbox from "../../icons/CollabCheckbox";
import CSVSheet from "./CSVSheet";
import SheetTabs from "./SheetTabs";

type Props = {
  tab: IWorksheet;
};

const SheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  overflow: auto;
`;

const Comment = styled.div`
  align-items: center;
  background-color: #f8f9d7;
  border-bottom: 1px solid #c8c8c8;
  color: #242424;
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  min-height: 3rem;
  padding: 0 1.25rem;
  user-select: none;
`;
const CheckboxContainer = styled.div`
  height: 1.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  font-size: 12px;
  text-transform: uppercase;
`;

const FilesButton = styled(Button)`
  display: flex;
  flex-direction: row;
  gap: 0.125rem;
  justify-content: center;
  align-items: center;
  justify-self: end;
  font-size: 0.875rem;
  width: 5.25rem;
  right: 0;
`;

const Worksheet: React.FC<Props> = ({ tab }) => {
  const project = useProject();
  const store = useStore();

  const [activeSheet, setActiveSheet] = useState<string>();
  const {
    id: tabId,
    csvFiles,
    marginalCsvFiles,
    comments,
    fileType,
    mergeSheetsOnDownload = false,
    valueParsers,
    uploadable,
    csvLoader,
  } = tab;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [marginalMode, setMarginalMode] = useState(false);
  const files = marginalMode ? marginalCsvFiles : csvFiles;
  const tabbed = files.length > 1;

  const selectedTab = useMemo(
    () => files[selectedTabIndex] ?? [],
    [files, selectedTabIndex]
  );
  const multiple = selectedTab.length > 1;

  const filesFlattened = flatten(files);
  const downloadable = filesFlattened.some((file) => file.downloadable);

  const validationSummary = useValidationSummary(
    files,
    tabbed ? selectedTabIndex : undefined
  );

  useAsync(async () => {
    await csvLoader?.();
  });

  const handleMarginalModeChange = () => {
    setMarginalMode((prev) => !prev);
  };

  const onDownload = useDownload();
  const onUpload = useUpload();

  const handleDownloadClick = async (event: React.MouseEvent, eu?: boolean) => {
    project?.setLoading("Preparing download...");
    await onDownload(tab, mergeSheetsOnDownload, eu);
    Toast.success("CSV files successfully downloaded.");
    project?.setNotLoading();
  };

  const handleDownloadEUClick = async (event: React.MouseEvent) => {
    await handleDownloadClick(event, true);
  };

  const handleFileUpload = async (file: string) => {
    if (!project) {
      return;
    }

    // Call uploadable function if it is a function,
    // bypassing the worksheet's default upload handling.
    if (typeof uploadable === "function") {
      await uploadable(file);
      await csvLoader?.();
      return;
    }

    const sheet = selectedTab[0].sheet as InputSheet;

    project?.setLoading("Preparing upload...");

    try {
      await onUpload(file, fileType, sheet.cluster);
    } catch (e) {
      Toast.danger("Unable to process the CSV file.");
      return;
    } finally {
      if (showFiles) {
        await store.cltFiles.list(project.id);
      }
      project?.setNotLoading();
    }

    Toast.success("CSV file successfully uploaded.");
  };

  const handleTabSelect = (key: number) => {
    setSelectedTabIndex(key);
  };

  useEffect(() => {
    if (!project) {
      return;
    }
    if (!selectedTab || !selectedTab[0]) {
      return;
    }
    project.setActiveCluster(
      (selectedTab[0].sheet as InputSheet).cluster || null
    );
    return () => {
      if (!project) {
        return;
      }
      project.setActiveCluster(null);
    };
  }, [project, selectedTab]);

  const hasSelector = [
    "rm_working",
    "oh_full_logs",
    "oh_full_pack_mfg",
    "costing_sheet",
    "marginal_cost",
  ].includes(fileType);
  const frozenMapping = getFrozenMapping(fileType, true);
  const unfrozenMapping = getFrozenMapping(fileType, false);
  const sheetMapping = getMapping(fileType);
  type types = CSVFrozenHeaderMapping;

  const [showColSelector, setShowColSelector] = useState<boolean>(false);
  const [unfreezeAll, setUnfreezeAll] = useState<boolean>(
    Object.values(frozenMapping).filter((b) => b).length === 0
  ); // Set true if no cols are frozen
  const handleToggleUnfreezeAll = (e: MouseEvent) => {
    e.stopPropagation();
    setUnfreezeAll(unfreezeAll ? false : true);
    if (!(tab.frozenColumns && tab.setFrozenColumns)) return;
    tab.setFrozenColumns(unfreezeAll ? frozenMapping : unfrozenMapping);
  };
  const width = useWindowSize().width;
  const showButtonText = width >= 960;

  const handleToggleUnfreezeColumn = (
    e: MouseEvent,
    col: string,
    frozenColumns?: types
  ) => {
    if (!frozenColumns) return;
    e.preventDefault();
    e.stopPropagation();
    let update = frozenColumns;
    if (!(!!tab.frozenColumns && !!tab.setFrozenColumns)) return;
    update[col as keyof types] = !tab.frozenColumns[col as keyof types];
    tab.setFrozenColumns({ ...update });
    setUnfreezeAll(Object.values(frozenMapping).filter((b) => b).length === 0);
  };

  const [showFiles, setShowFiles] = useState(false);
  const handleFilesButtonClick = () => {
    setShowFiles((prev) => !prev);
  };

  const TheCSVSheet = useMemo(
    () => (tab.customCSVSheet !== undefined ? tab.customCSVSheet : CSVSheet),
    [tab.customCSVSheet]
  );

  return (
    <CsvValidationErrorContext.Provider value={validationSummary}>
      <Toolbar>
        {marginalCsvFiles.length > 0 ? (
          <Switch
            id="marginal-mode"
            label="Marginal costs"
            onChange={handleMarginalModeChange}
            checked={marginalMode}
          />
        ) : null}
        {downloadable ? (
          <Dropdown as={ButtonGroup}>
            <Button size="thin" onClick={handleDownloadClick}>
              <CloudDownloadOutlined /> {showButtonText && "Download CSV File"}
            </Button>
            <Dropdown.Toggle
              as={Button}
              variant="primary"
              className="btn-thin"
              split
              id="dropdown-split-basic"
            />
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleDownloadClick}>
                <CloudDownloadOutlined /> Download CSV File
              </Dropdown.Item>
              <Dropdown.Item onClick={handleDownloadEUClick}>
                <CloudDownloadOutlined /> Download CSV File (EU/BR)
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        {(uploadable ?? true) && !!fileType ? (
          <UploadButton
            onFileUpload={handleFileUpload}
            showButtonText={showButtonText}
          />
        ) : null}

        {project && (
          <>
            {!project.isLocked ? (
              <>{tab.renderExtraToolbar?.(tab) ?? null}</>
            ) : null}
            <ToolbarStatus tab={tab} project={project} />
          </>
        )}
        {hasSelector && (
          <Dropdown
            as={ButtonGroup}
            show={showColSelector}
            onToggle={(isOpen, event, metadata) => {
              if (metadata.source !== "select") {
                setShowColSelector(isOpen);
              }
            }}
          >
            <Button
              size="thin"
              onClick={(e: MouseEvent) => setShowColSelector((prev) => !prev)}
            >
              Unfreeze Columns
            </Button>
            <Dropdown.Toggle
              as={Button}
              variant="primary"
              className="btn-thin"
              split
              id="dropdown-split-basic"
            />
            <Dropdown.Menu>
              <Dropdown.Header>
                <ErrorOutline /> Selected columns will be unfrozen
              </Dropdown.Header>
              <Dropdown.Item onClick={(e: any) => handleToggleUnfreezeAll(e)}>
                <CheckboxContainer>
                  <CollabCheckbox checked={unfreezeAll} />
                  <b>UNFREEZE ALL COLUMNS</b>
                </CheckboxContainer>
              </Dropdown.Item>
              {Object.keys(frozenMapping).map((col, idx) => {
                if (!(tab.frozenColumns && tab.setFrozenColumns)) return <></>;
                return (
                  <Dropdown.Item
                    key={idx}
                    onClick={(e: any) =>
                      handleToggleUnfreezeColumn(e, col, tab.frozenColumns)
                    }
                  >
                    <CheckboxContainer>
                      <CollabCheckbox
                        checked={
                          tab.frozenColumns[col as keyof types] === false
                        }
                      />
                      <b>
                        {typeof sheetMapping[col] !== "undefined" &&
                          sheetMapping[col][0]}
                      </b>
                    </CheckboxContainer>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <ValidationDashboard validationChecks={tab.validationChecks} />
        {(uploadable ?? true) && (
          <FilesButton
            variant="clt-files"
            size="thin"
            onClick={handleFilesButtonClick}
          >
            FILES
            <Folder />
          </FilesButton>
        )}
      </Toolbar>

      {comments ? <Comment>{comments}</Comment> : null}

      <ScrollSync vertical={false}>
        <SheetContainer className={clsx({ multiple })}>
          {selectedTab.map((file) => (
            <TheCSVSheet
              key={`${tabId}__${file.id}`}
              isActive={activeSheet === file.id}
              valueParsers={valueParsers}
              onActive={setActiveSheet}
              onPostSave={csvLoader}
              multiple={multiple}
              file={file}
              collapsible={file.collapsible}
            />
          ))}
        </SheetContainer>
      </ScrollSync>

      {selectedTab[0]?.sheet && showFiles && (
        <CLTFiles
          clusterId={(selectedTab[0].sheet as InputSheet).cluster}
          floating
          inputSheet={(selectedTab[0].sheet as InputSheet).id}
        />
      )}

      {tabbed ? (
        <SheetTabs
          selectedSheetIndex={selectedTabIndex}
          onChangeTab={handleTabSelect}
          files={files}
        />
      ) : null}
    </CsvValidationErrorContext.Provider>
  );
};

export default observer(Worksheet);
