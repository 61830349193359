import base, { APIList, APISearchParams, config } from "./base";

export interface APIRMPA {
  id: identifier;
  rCode: string;
  productName: string;
  plantName: string;
  vendorName: string;
  supplyLocationCountry: string;
  incoTerm: string;
  currencyCode: string;
  rmPurchasePrice: number;
  supplyFreight: number;
  drummingDifferential: number;
  unitOfMeasure: string;
}
export interface APIRMPAList extends APIList<APIRMPA> {}

export interface APIRMPAListSearchParams extends APISearchParams {
  costing_date: string;
  rmpa_data: string;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIRMPAListSearchParams
) =>
  base.list<APIRMPAList, APIRMPAListSearchParams>(
    "purchase-price/",
    page,
    limit,
    searchParams
  );
