import api from "api";
import {
  APIProjectApproveRejectInput,
  APIProjectInput,
  APIProjectPublishInput,
  APIProjectPublishResult,
  APIProjectPublishedCluster,
  APIProjectPublishedClusterList,
  APIProjectSubmitInput,
  TaskState,
} from "api/projects";
import { APICountry } from "api/types";
import appConfig from "config/appConfig";
import { computed, observable } from "mobx";
import {
  Model,
  _async,
  _await,
  getRootStore,
  model,
  modelAction,
  modelFlow,
  prop,
} from "mobx-keystone";
import RootStore from "../RootStore";

@model("collab/Project")
class Project extends Model({
  id: prop<identifier>(),
  modified: prop<string>(),
  name: prop<string>(),
  type: prop<string>(),
  creator: prop<identifier>(),
  creatorName: prop<string>(),
  creatorPhotoSizes: prop<{ big?: string; medium?: string }>(() => ({})),
  created: prop<string>(),
  currency: prop<identifier | null>(null),
  currentVersionId: prop<identifier>(),
  costingDate: prop<string>(""),
  costingType: prop<string>(""),
  forexDate: prop<string>(""),
  opsInputUsers: prop<identifier[]>(() => []),
  rmPriceUsers: prop<identifier[]>(() => []),
  projectOutputUsers: prop<identifier[]>(() => []),
  adminUsers: prop<identifier[]>(() => []),
  additionalCountries: prop<APICountry[]>(() => []),
  folder: prop<identifier>(),
  isActive: prop<boolean>(),
  versionNumber: prop<number>(),
  notes: prop<string>(),
  isLocked: prop<boolean>(),
  latestIssueDate: prop<string | null>(),
  lockedClusters: prop<identifier[]>(() => []),
  openClusters: prop<identifier[]>(() => []),
  submittedClusters: prop<identifier[]>(() => []),
  approvedClusters: prop<identifier[]>(() => []),
  rejectedClusters: prop<identifier[]>(() => []),
  currencyName: prop<string | null>(),
  rmpaSubmitted: prop<boolean>(),
  rmpaIssueDate: prop<string>(),
  isCostSheetPendingUpdate: prop<boolean | undefined>(),
}) {
  @observable
  loading: boolean = false;

  @observable
  loadingMessage: string = "";

  @observable
  private _isDirty: boolean = false;

  @observable
  private _isCostSheetDirty: boolean = false;

  @observable
  private _isGeneratingOutput: boolean = false;

  @observable
  private _activeTab: string | null = null;

  @observable
  private _activeCluster: number | null = null;

  @computed
  get activeTab() {
    return this._activeTab;
  }

  @computed
  get activeCluster() {
    return this._activeCluster;
  }

  @computed
  get isGeneratingOutput() {
    return this._isGeneratingOutput;
  }

  @computed
  get isCostSheetDirty() {
    return this._isCostSheetDirty;
  }

  @computed
  get isDirty() {
    return this._isDirty;
  }

  @computed
  get showComments() {
    if (!appConfig.enableComments) {
      return false;
    }
    return (
      this.activeTab !== "keyAssumptions" &&
      this.activeTab !== "formulation" &&
      this.activeTab !== "requirements" &&
      this.activeTab !== "deliveryInfo" &&
      this.activeTab !== "rawMaterialReport" &&
      this.activeTab !== "cltAssumptions" &&
      this.activeTab !== "formulationsCpl" &&
      this.activeTab !== "formulationSelections" &&
      this.activeTab !== "sourcingSelections"
    );
  }

  publishedClusters = observable.array<APIProjectPublishedCluster>();

  @computed
  get asAPI(): APIProjectInput {
    return {
      name: this.name,
      type: this.type,
      costingDate: this.costingDate,
      forexDate: this.forexDate,
      costingType: this.costingType,
      currency: this.currency,
      opsInputUsers: this.opsInputUsers,
      rmPriceUsers: this.rmPriceUsers,
      projectOutputUsers: this.projectOutputUsers,
      adminUsers: this.adminUsers,
      additionalCountries: this.additionalCountries,
      notes: this.notes,
      isLocked: this.isLocked,
    };
  }

  @computed
  get clusters() {
    return this.publishedClusters;
  }

  @computed
  get editors() {
    const rootStore = getRootStore<RootStore>(this);
    const editorIds = Array.from(
      new Set([
        this.creator,
        ...this.opsInputUsers,
        ...this.rmPriceUsers,
        ...this.projectOutputUsers,
        ...this.adminUsers,
      ])
    );

    if (!rootStore) {
      return [];
    }

    const users = Array.from(rootStore.users.listItems.values());
    const editors = users.filter((user) => editorIds.includes(user.id));

    return editors;
  }

  @computed
  get countryAnalysts() {
    const countryAnalysts = this.editors.filter(
      (user) =>
        !(
          user.isGlobalAnalyst ||
          user.isGlobalReviewer ||
          user.isAdmin ||
          user.isReviewer
        )
    );
    return countryAnalysts;
  }

  @modelAction
  setLoading(message?: string) {
    this.loading = true;
    if (message) {
      this.loadingMessage = message;
    }
  }

  @modelAction
  setNotLoading() {
    this.loading = false;
  }

  @modelAction
  setCostSheetDirty() {
    this._isCostSheetDirty = true;
  }

  @modelAction
  setCostSheetNotDirty() {
    this._isCostSheetDirty = false;
  }

  @modelAction
  setDirty() {
    this._isDirty = true;
  }

  @modelAction
  setNotDirty() {
    this._isDirty = false;
  }

  @modelAction
  setActiveTab(tab: string | null) {
    this._activeTab = tab;
  }

  @modelAction
  setActiveCluster(cluster: number | null) {
    this._activeCluster = cluster;
  }

  @modelFlow
  getPublishedClusters = _async(function* (this: Project) {
    let response: APIProjectPublishedClusterList | undefined;
    try {
      response = yield* _await(api.projects.publishedClusters(this.id));
    } catch (e) {
      console.error(e);
    }
    if (!response) {
      return;
    }
    this.publishedClusters.replace(response.results);
  });

  @modelFlow
  generateCostSheet = _async(function* (this: Project) {
    this.setLoading("Generating initial cost sheet...");

    let response: TaskState;
    try {
      response = yield* _await(api.projects.generateCostSheet(this.id));
    } catch (e) {
      this.setNotLoading();
      throw e;
    }

    try {
      yield* _await(this.pollState(response.id));
    } catch (e) {
      this.setNotLoading();
      throw e;
    }

    // Reload CSV define sheets like the cost sheet frame.
    const rootStore: RootStore | undefined = getRootStore(this);
    if (rootStore) {
      yield* _await(rootStore.defineSheets.list(this.id));
    }

    this.setCostSheetNotDirty();
    this.setNotLoading();
  });

  @modelFlow
  uploadPriceAssumptions = _async(function* (this: Project, file: string) {
    this.setLoading("Preparing upload...");
    try {
      yield* _await(api.projects.uploadPriceAssumptions(this.id, { file }));
    } catch (e) {
      throw e;
    } finally {
      this.setNotLoading();
    }
  });

  @modelFlow
  pollState: any = _async(function* (this: Project, taskId: string) {
    let response: TaskState;
    try {
      response = yield* _await(api.projects.pollState(taskId));
    } catch (e) {
      throw e;
    }

    if (response.state === "FAILURE") {
      throw Error("Task did not complete.");
    }
    if (response.state === "SUCCESS") {
      return;
    }

    // Wait 5 seconds.
    yield* _await(
      new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 5000);
      })
    );
    yield* _await(this.pollState(taskId));
  });

  @modelFlow
  generateOutputs = _async(function* (this: Project) {
    this.setLoading("Generating outputs...");
    this._isGeneratingOutput = true;

    let response: TaskState;
    try {
      response = yield* _await(api.projects.generateOutputs(this.id));
    } catch (e) {
      this.setNotLoading();
      throw e;
    }

    try {
      yield* _await(this.pollState(response.id));
    } catch (e) {
      throw e;
    } finally {
      this.setNotLoading();
    }

    this._isGeneratingOutput = false;
  });

  @modelAction
  update(item: APIProjectInput) {
    Object.keys(item).forEach((key) => {
      //@ts-ignore
      this[key] = item[key];
    });
  }

  @modelFlow
  publish = _async(function* (this: Project, data: APIProjectPublishInput) {
    this.setLoading("Publishing...");

    let response: APIProjectPublishResult;
    try {
      response = yield* _await(api.projects.publish(this.id, data));
    } catch (e) {
      this.setNotLoading();
      throw e;
    }

    try {
      yield* _await(this.pollState(response.state.id));
    } catch (e) {
      throw e;
    } finally {
      this.setNotLoading();
    }
  });

  @modelFlow
  submit = _async(function* (this: Project, data: APIProjectSubmitInput) {
    this.setLoading("Submitting...");
    try {
      const response = yield* _await(api.projects.submit(this.id, data));
      this.update(response.project);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.setNotLoading();
    }
  });

  @modelFlow
  approve = _async(function* (
    this: Project,
    data: APIProjectApproveRejectInput
  ) {
    this.setLoading("Approving...");
    try {
      const response = yield* _await(api.projects.approve(this.id, data));
      this.update(response.project);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.setNotLoading();
    }
  });

  @modelFlow
  reject = _async(function* (
    this: Project,
    data: APIProjectApproveRejectInput
  ) {
    this.setLoading("Rejecting...");
    try {
      const response = yield* _await(api.projects.reject(this.id, data));
      this.update(response.project);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.setNotLoading();
    }
  });

  @modelFlow
  unsubmit = _async(function* (this: Project) {
    this.setLoading("Undoing submit...");
    try {
      const response = yield* _await(api.projects.unsubmit(this.id));
      this.update(response);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.setNotLoading();
    }
  });

  @modelFlow
  submitRMPA = _async(function* (this: Project, data: APIProjectSubmitInput) {
    this.setLoading("Submitting...");
    try {
      const response = yield* _await(api.projects.submitRMPA(this.id, data));
      this.update(response.project);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.setNotLoading();
    }
  });

  @modelFlow
  unsubmitRMPA = _async(function* (this: Project) {
    this.setLoading("Undoing submit...");
    try {
      const response = yield* _await(api.projects.unsubmitRMPA(this.id));
      this.update(response);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.setNotLoading();
    }
  });

  @modelFlow
  submitPriceRequirements = _async(function* (
    this: Project,
    data: { notes: string }
  ) {
    this.setLoading("Submitting...");
    try {
      yield* _await(api.projects.submitPriceRequirements(this.id, data));
    } catch (e) {
      console.error(e);
      this.setNotLoading();
      throw e;
    }
    this.setNotLoading();
  });

  @modelFlow
  submitGlobalPricing = _async(function* (
    this: Project,
    data: { notes: string }
  ) {
    this.setLoading("Submitting...");
    try {
      yield* _await(api.projects.submitGlobalPricing(this.id, data));
    } catch (e) {
      console.error(e);
      this.setNotLoading();
      throw e;
    }
    this.setNotLoading();
  });
}

export default Project;
