import base, { APIDetail, APIList, APISearchParams, config } from "./base";
import { APIDefineSheetSearchParams } from "./defineSheets";

export interface APIDeliveryInfo {
  id: identifier;
  project: identifier;
  cluster: identifier;
  notes: string;
  isCltLocked: boolean;
}

export type APIDeliveryInfoInput = Partial<
  Omit<APIDeliveryInfo, "id" | "isCltLocked">
>;

export interface APIDeliveryInfoList extends APIList<APIDeliveryInfo> {}
export interface APIDeliveryInfoSearchParams extends APISearchParams {
  project?: identifier;
}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIDeliveryInfoSearchParams
) =>
  base.list<APIDeliveryInfoList, APIDefineSheetSearchParams>(
    "delivery-info/",
    page,
    limit,
    searchParams
  );

export interface APIDeliveryInfoDetail extends APIDetail<APIDeliveryInfo> {}

export const create = (data: APIDeliveryInfoInput) =>
  base.post<APIDeliveryInfoDetail>(`delivery-info/`, {
    json: data,
  });

export const update = (
  deliveryInfoId: identifier,
  data: APIDeliveryInfoInput
) =>
  base.patch<APIDeliveryInfoDetail>(`delivery-info/${deliveryInfoId}/`, {
    json: data,
  });

export type APIDeliveryInfoBulkUpdateInput = {
  instances: Partial<Omit<APIDeliveryInfo, "isCltLocked">>[];
};

export type APIDeliveryInfoBulkUpdateDetail = {
  instances: APIDeliveryInfoDetail[];
};

export const bulkUpdate = (input: APIDeliveryInfoBulkUpdateInput) =>
  base.patch<APIDeliveryInfoBulkUpdateDetail>(`delivery-info/bulk-update/`, {
    json: input,
  });
