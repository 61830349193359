import { model, Model, prop } from "mobx-keystone";

@model("collab/ProjectVersion")
class ProjectVersion extends Model({
  id: prop<identifier>(),
  project: prop<identifier>(),
  clusters: prop<identifier[]>(() => []),
  creator: prop<identifier>(),
  creatorName: prop<string>(),
  versionNumber: prop<number>(),
  revisionInstance: prop<identifier>(),
  latestRevisionInstance: prop<identifier>(),
  notes: prop<string>(),
}) {}

export default ProjectVersion;
