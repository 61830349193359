import base, { APIList, APISearchParams, config } from "./base";

export interface APITerms {
  id: identifier;
  name: string;
}

export interface APITermsList extends APIList<APITerms> {}

export interface APITermsListSearchParams extends APISearchParams {
  name?: string;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APITermsListSearchParams
) =>
  base.list<APITermsList, APITermsListSearchParams>(
    "terms/",
    page,
    limit,
    searchParams
  );
