import React from "react";
import {
  ColumnExtraOptions,
  Position,
  PositionData,
  RangeSelection,
} from "./types";
import { Column, TextEditor } from "react-data-grid";
import { IRow } from "../../csv/types";
import { beautifyText } from "../../csv/utils";
import { scaledRem } from "../../utils";
import HeaderCell from "./HeaderCell";

export const emptyPositionData: PositionData = {
  isInside: false,
  isLeft: false,
  isRight: false,
  isTop: false,
  isBottom: false,
};

/**
 * Returns a new `RangeSelection` where `start` is the top-left cell and `end` is the bottom-right cell.
 *
 * @param range RangeSelection
 */
export const getBoundingPositions = (range: RangeSelection): RangeSelection => {
  const positionBound1 = range.start;
  const positionBound2 = range.end;

  const left = Math.max(1, Math.min(positionBound1.idx, positionBound2.idx));
  const right = Math.max(positionBound1.idx, positionBound2.idx);
  const top = Math.min(positionBound1.rowIdx, positionBound2.rowIdx);
  const bottom = Math.max(positionBound1.rowIdx, positionBound2.rowIdx);

  return {
    start: {
      idx: left,
      rowIdx: top,
    },
    end: {
      idx: right,
      rowIdx: bottom,
    },
  };
};

export const getPositionData = (
  inputPosition: Position,
  range: RangeSelection
) => {
  if (!range) return emptyPositionData;

  const { start, end } = getBoundingPositions(range);

  const isInside =
    inputPosition.idx >= start.idx &&
    inputPosition.idx <= end.idx &&
    inputPosition.rowIdx >= start.rowIdx &&
    inputPosition.rowIdx <= end.rowIdx;
  const isLeft = isInside && inputPosition.idx === start.idx;
  const isRight = isInside && inputPosition.idx === end.idx;
  const isTop = isInside && inputPosition.rowIdx === start.rowIdx;
  const isBottom = isInside && inputPosition.rowIdx === end.rowIdx;

  return {
    isInside,
    isLeft,
    isRight,
    isTop,
    isBottom,
  } as PositionData;
};

export const getDataColumnsFromSchema = (
  rawColumns: string[],
  editable?: boolean,
  schema?: (key: string, sheetEditable?: boolean) => ColumnExtraOptions<IRow>
) => {
  const dataColumns: Column<IRow>[] = rawColumns.map((col) => ({
    key: col,
    formatter:
      col === "id"
        ? ({ rowIdx }) => React.createElement(React.Fragment, null, rowIdx + 1)
        : undefined,
    name: col === "id" ? "" : beautifyText(col),
    editable: col === "id" ? false : editable,
    frozen: col === "id" ? true : false,
    resizable: col === "id" ? false : undefined,
    headerRenderer: HeaderCell,
    width: col === "id" ? scaledRem(40) : scaledRem(160),
    minWidth: col === "id" ? scaledRem(40) : undefined,
    editor: TextEditor,
    sortable: true,
    ...schema?.(col, editable),
  }));
  return dataColumns;
};

export function isPositionInRange(position: Position, range: RangeSelection) {
  const nrange = getBoundingPositions(range);
  return (
    position.idx >= nrange.start.idx &&
    position.idx <= nrange.end.idx &&
    position.rowIdx >= nrange.start.rowIdx &&
    position.rowIdx <= nrange.end.rowIdx
  );
}
