import api from "api";
import { APITreatRate, APITreatRateInput } from "api/formulations";
import { computed } from "mobx";
import {
  ExtendedModel,
  _async,
  _await,
  model,
  modelFlow,
  prop,
} from "mobx-keystone";
import DirtyableWithId from "./DirtyableWithId";

@model("collab/TreatRate")
class TreatRate extends ExtendedModel(DirtyableWithId, {
  rcode: prop<string>(""),
  description: prop<string>(""),
  crdStatus: prop<string>(""),
}) {
  _prevId: number = 0;

  onInit() {
    this.updatePrevId();
  }

  updatePrevId() {
    this._prevId = this.id;
  }

  @computed
  get asAPI(): APITreatRateInput {
    return {
      rcode: this.rcode,
      description: this.description,
      crdStatus: this.crdStatus,
    };
  }

  @modelFlow
  save = _async(function* (this: TreatRate) {
    if (!this.isDirty) {
      return;
    }

    let response: APITreatRate;
    try {
      response = yield* _await(api.formulations.additiveCreate(this.asAPI));
    } catch (e) {
      console.error(e);
      throw e;
    }

    this.update(response);
  });
}

export default TreatRate;
