import React from "react";
import appConfig from "../../config/appConfig";
import { LockOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import Button from "../../components/Button";
import { useStore } from "../../store";
import { Page } from "../Page";
import { columnsNames, headData, permissionTablesData } from "./constants";

const UserAccountStatusContainer = styled.div`
  display: block;
  line-height: 1;
  margin: 0 1.25rem;
  padding: 0;
  position: relative;
`;

const HeaderText = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 0.625rem;
  user-select: none;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: #000000;
  opacity: 0.6;
`;

const UserRoleText = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  margin: 0;

  color: #000000;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.875rem;
  height: 0;
  overflow: auto;
  padding: 1.875rem;
  background-color: #f1f1f1;
`;

const PermissionsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
`;

const PermissionsTable = styled.table`
  width: 100%;
  border: 1px solid #c8c8c8;
  font-size: 0.625rem;

  tr td:first-child {
    width: 19%;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const PermissionsTableHeader = styled.thead`
  width: 100%;
  background-color: #e3e3e3;
  text-transform: uppercase;
  font-weight: bold;
`;

const PermissionsTableHeaderBanner = styled.td`
  font-size: 1rem;
  padding: 10px 20px;

  &.center {
    text-align: center;
  }
`;

const PermissionsTableBody = styled.tbody`
  width: 100%;
  font-weight: 600;
  background-color: #ffffff;
`;

const PermissionsTableCell = styled.td`
  width: 9%;
  border: 1px solid #c8c8c8;
  padding: 5px 10px;
`;

const ManagePermissionButton = styled(Button)`
  text-transform: uppercase;
`;

interface PermissionsSummaryProps {}

const PermissionsSummary: React.FC<PermissionsSummaryProps> = () => {
  const store = useStore();
  const currentUser = store.auth.current;
  const apiAdminUrl = appConfig.apiAdminUrl! as string;

  return (
    <Page
      title="Permissions Settings"
      breadcrumb={[
        {
          title: "Permissions Settings",
          subtitle: "Global Settings",
          link: "/permissions",
        },
      ]}
    >
      <Container>
        <PermissionsHeader>
          <UserAccountStatusContainer>
            <HeaderText>Access Role</HeaderText>
            <UserRoleText>
              {currentUser?.isAdmin
                ? "Admin"
                : currentUser?.isGlobalAnalyst
                ? "Global Analyst"
                : currentUser?.isClusterAnalyst
                ? "Cluster Analyst"
                : currentUser?.isGlobalReviewer
                ? "Global Reviewer"
                : currentUser?.isClusterReviewer
                ? "Cluster Reviewer"
                : "User"}
            </UserRoleText>
          </UserAccountStatusContainer>
          {currentUser?.isAdmin ? (
            <ManagePermissionButton
              forwardedAs="a"
              size="thin"
              href={apiAdminUrl}
              target="_blank"
            >
              <LockOutlined />
              Manage Permissions
            </ManagePermissionButton>
          ) : (
            <Button size="thin">
              To request for permission changes, please contact your system
              administrator
            </Button>
          )}
        </PermissionsHeader>
        <PermissionsTable>
          <PermissionsTableHeader>
            <tr>
              <PermissionsTableHeaderBanner colSpan={10} className="center">
                Editor and View Access (Individual/CLT specific)
              </PermissionsTableHeaderBanner>
            </tr>
            <tr>
              {columnsNames.map((e, index) => (
                <PermissionsTableCell key={`cell-${index}`}>
                  {e}
                </PermissionsTableCell>
              ))}
            </tr>
          </PermissionsTableHeader>
          <PermissionsTableBody>
            {headData.map((rows, rowIndex) => (
              <tr key={`body-${rowIndex}`}>
                {rows.map((e, index) => (
                  <PermissionsTableCell key={`cell-${index}`}>
                    {e}
                  </PermissionsTableCell>
                ))}
              </tr>
            ))}
          </PermissionsTableBody>
        </PermissionsTable>
        {permissionTablesData.map((tableData, tableIndex) => (
          <PermissionsTable key={`table-${tableIndex}`}>
            <PermissionsTableHeader>
              <tr>
                <PermissionsTableHeaderBanner colSpan={10}>
                  {tableData.name}
                </PermissionsTableHeaderBanner>
              </tr>
            </PermissionsTableHeader>
            <PermissionsTableBody>
              {tableData.data.map((rows, rowIndex) => (
                <tr key={`body-${rowIndex}`}>
                  {rows.map((e, index) => (
                    <PermissionsTableCell key={`cell-${index}`}>
                      {e}
                    </PermissionsTableCell>
                  ))}
                </tr>
              ))}
            </PermissionsTableBody>
          </PermissionsTable>
        ))}
      </Container>
    </Page>
  );
};

export default observer(PermissionsSummary);
