import { model, Model, prop } from "mobx-keystone";
import { computed } from "mobx";
import { APIUserInput } from "../../api/users";

@model("collab/User")
class User extends Model({
  id: prop<identifier>(),
  phoneNumber: prop<string>(),
  email: prop<string>(),
  address: prop<string>(),
  isActive: prop<boolean>(),
  isAdmin: prop<boolean>(),
  isGlobalAnalyst: prop<boolean>(),
  isGlobalReviewer: prop<boolean>(),
  isAnalyst: prop<boolean>(),
  isReviewer: prop<boolean>(),
  isGlobalAnalystOrAdmin: prop<boolean>(),
  isGlobalUserOrAdmin: prop<boolean>(),
  isClusterAnalyst: prop<boolean>(),
  isClusterReviewer: prop<boolean>(),
  isClusterAnalystAndReviewer: prop<boolean>(),
  isClusterUser: prop<boolean>(),
  analystCluster: prop<identifier>(0),
  reviewerCluster: prop<identifier>(0),
  analystClusterName: prop<string>(),
  reviewerClusterName: prop<string>(),
  cluster: prop<identifier>(0),
  photoSizes: prop<{ big?: string; medium?: string }>(() => ({})),
  name: prop<string>(),
  bio: prop(""),
  photo: prop<string | null>(() => null),
  timezone: prop<string>(),
}) {
  @computed
  get asAPI(): APIUserInput {
    return {
      name: this.name,
      bio: this.bio,
      photo: this.photo,
      timezone: this.timezone,
    };
  }
}

export default User;
