import { CSVFrozenHeaderMapping, CSVHeaderMapping } from ".";
import { scaledChars as _ } from "../../utils";

export const mappingCostSheet: CSVHeaderMapping = {
  requirement_id: ["Req't\nRef", _(8)],
  matched: ["Matched", _(9)],
  plant_split_link: ["US %\nSplit Link", _(8)],
  plant_split_percent: ["US %\nSplit", _(8)],
  not_selected: ["Not\nSelected", _(8)],
  selected: ["Ready for\nOutput", _(9)],
  source_plant_cluster_id: ["Source\nCLT", _(10)],
  repackaging_plant_cluster_id: ["Repack\nCLT", _(10)],
  dest_cluster_id: ["Dest\nCLT", _(10)],
  dest_country: ["Dest\nCountry", _(12)],
  delivery_location: ["Delivery\nLocation", _(15)],
  delivery_terms: ["Delivery\nTerms", _(8)],
  rtm: ["RTM", _(16)],
  viscosity: ["Viscosity", _(9)],
  product_spec: ["Product\nSpec", _(20)],
  formulation_code: ["Formulation\nCode", _(10)],
  pack_size_from_source: ["Source Pack Size\n(if Repack Reqd)", _(16)],
  pack_size: ["Pack Size\nto Offer", _(15)],
  pack_type: ["Pack Type\n(if Reqd)", _(9)],
  volume_yr1: ["Volume\n(Yr 1)", _(9)],
  volume_yr2: ["Volume\n(Yr 2)", _(9)],
  volume_yr3: ["Volume\n(Yr 3)", _(9)],
  volume_yr4: ["Volume\n(Yr 4)", _(9)],
  volume_yr5: ["Volume\n(Yr 5)", _(9)],
  source_plant: ["Source\nPlant", _(12)],
  repackaging_plant: ["Repack Plant\n(if appl)", _(9)],
  formulation_option: ["Formulation\nOption", _(11)],
  source_plant_option: ["Source\nPlant Option", _(12)],
  pack_size_reqd_rfi_rfq: ["Pack Size\nRFI/RFQ", _(15)],
  alt_scenario: ["Alt Scenario", _(8)],
  bo: ["BO", _(12)],
  add: ["Add", _(12)],
  formulation_cost: ["Formulation\nCost", _(16)],
  yield_loss: ["Yield\nLoss", _(12)],
  packaging_labelling_cost: ["Std Pack/\nLabel", _(12)],
  specific_packaging_labelling_cost: ["Specific\nPack/Label", _(12)],
  decanting_cost: ["Decanting/\nRepackaging Cost", _(12)],
  "3p_toll_blending_cost": ["3P Blending\nFilling", _(12)],
  bp_blending_fee: ["bp Blending", _(12)],
  bp_filling_fee: ["bp Filling", _(12)],
  depreciation: ["Depreciation\n(Addnl Capex)", _(14)],
  other_bp_manufacturing_cost: ["Other bp\nMfg Costs", _(12)],
  total_ex_works_cost: ["Unit COGS\nEx Works", _(12)],
  group_purchase: ["Group\nPurchase", _(12)],
  "3p_purchase": ["3P\nPurchase", _(12)],
  br_icms_tax: ["Brazil ICMS/\nOil Collect Tax", _(15)],
  my_sales_tax: ["Malaysia\nSales Tax %", _(12)],
  manufacturing_mark_up: ["Mark Up\n(RM/Mfg)", _(12)],
  unit_cogs_source_country: ["Unit COGS\nEx Source", _(16)],
  cpl_import_cost: ["Import Related\n(CPLs)", _(14)],
  percent_import_cost: ["Import Related\n(from %'s)", _(14)],
  th_import_sales_tax: ["Import Sales\nTax (Thailand)", _(14)],
  cn_consumption_tax: ["Consumption\nTax (China)", _(12)],
  "3p_primary_transport": ["3P Primary\nTrans", _(12)],
  internal_primary_transport: ["bp Primary\nTrans", _(12)],
  "3p_storage_handling_cost": ["3P S&H", _(12)],
  internal_storage_handling_cost: ["bp S&H", _(12)],
  logistics_specific_cost: ["Logistics\nSpecific Reqts", _(14)],
  in_country_storage_handling_mark_up: ["Mark Up\n(Logs)", _(12)],
  unit_cogs_source_country_import_primary_transport_storage_handling: [
    "Unit COGS\nExcl Sec Logs",
    _(16),
  ],
  secondary_logistics: ["Secondary\nLogistics", _(12)],
  unit_cogs_source_country_import_primary_transport_storage_handling_secondary_logistics:
    ["Unit COGS\nIncl Sec Logs", _(12)],
  total_cost: ["Total GSC\nCost", _(12)],
  cogs_efficiency_savings: ["COGS Efficiency\nSavings", _(15)],
  unit_cogs_source_country_import_primary_transport_storage_handling_secondary_logistics_cogs_efficiency_savings:
    ["Unit COGS\nIncl Eff Savings", _(16)],
  comments: ["Comments", _(32)],
  summary_formulation_cost: ["Formulation Cost", _(15)],
  summary_packaging_cost: ["Packaging Cost", _(15)],
  summary_manufacturing: ["Manufacturing (Bl/Fill/YL/Ex Dpn)", _(15)],
  summary_depreciation: ["Depreciation (Addn Capex)", _(15)],
  summary_fg_related_cost: ["FG Import/Export Related Costs", _(15)],
  summary_primary_transport: ["Primary Transport", _(15)],
  summary_storage_handling: ["Storage & Handling", _(15)],
  summary_other_gsc_costs: ["Other GSC Related Costs", _(15)],
  summary_3p_purchase: ["3rd Party Purchase", _(15)],
  summary_taxes: ["Taxes (incl in PCTP)", _(15)],
  summary_future_efficiency_savings: ["Future Efficiency Savings", _(15)],
  summary_gsc_markup: ["GSC Mark Up", _(15)],
  summary_seclogs: ["Seclogs", _(15)],
  summary_marg_cogs_manufacturing: [
    "MARG COGS - Manufacturing (Bl/Fill/YL/Ex Dpn)",
    _(15),
  ],
  summary_marg_cogs_storage_handling: ["MARG COGS - Storage & Handling", _(15)],
  summary_check: ["Check", _(10)],
};

export const mappingRawMaterialSummary: CSVHeaderMapping = {
  supplier: ["Supplier", _(15)],
  r_code: ["R Code", _(8)],
  supplier_product_name: ["Supplier Product Name", _(20)],
};

export const getFrozenMappingCostingOutput = (
  frozen: boolean
): CSVFrozenHeaderMapping => {
  return {
    requirement_id: frozen,
    matched: frozen,
    not_selected: frozen,
    selected: frozen,
  };
};
