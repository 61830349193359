import base, { APIDetail, APIList, APISearchParams, config } from "./base";

export interface APIBaseOil {
  id: identifier;
  rcode: string;
  description: string;
  crdStatus: string;
}

export interface APIBaseOilList extends APIList<APIBaseOil> {}

export type APIBaseOilInput = Partial<APIBaseOil>;

export interface APIBaseOilSearchParams extends APISearchParams {
  project: identifier;
}
export interface APIBaseOilPercentage {
  id: identifier;
  baseOil: APIBaseOil;
  value: string;
}

export interface APIBaseOilPercentageInput {
  baseOil?: APIBaseOilInput;
  value?: string;
}

export interface APIAdditive {
  id: identifier;
  rcode: string;
  description: string;
  crdStatus: string;
}

export interface APIAdditiveList extends APIList<APIAdditive> {}

export type APIAdditiveInput = Partial<APIAdditive>;

export interface APIAdditiveSearchParams extends APISearchParams {
  project: identifier;
}
export interface APIAdditivePercentage {
  id: identifier;
  additive: APIAdditive;
  value: string;
}

export interface APIAdditivePercentageInput {
  additive?: APIAdditiveInput;
  value?: string;
}

export interface APITreatRate {
  id: identifier;
  rcode: string;
  description: string;
  crdStatus: string;
}

export interface APITreatRateList extends APIList<APITreatRate> {}

export type APITreatRateInput = Partial<APITreatRate>;

export interface APITreatRateSearchParams extends APISearchParams {
  project: identifier;
}
export interface APITreatRatePercentage {
  id: identifier;
  treatRate: APITreatRate;
  value: string;
}

export interface APITreatRatePercentageInput {
  treatRate?: APITreatRateInput;
  value?: string;
}

export interface APIFormulation {
  id: identifier;
  clusters: identifier[];
  code: string;
  comments: string;
  countries: identifier[]; // options will be based on chosen cluster.
  density: number;
  isApproved: boolean;
  isCltLocked: boolean;
  notes: string;
  ordering: number;
  packSizes: identifier[];
  plant: identifier;
  plantSplitCode: string;
  plantSplitPercent: number | null;
  plantType: "master" | "plant";
  project: identifier;
  repackagingPackSizes: identifier[];
  repackagingPlant: identifier;
  sourcePlants: identifier[];
  specs: string;
  viscosity: string;
  baseOilPercentages: APIBaseOilPercentage[];
  additivePercentages: APIAdditivePercentage[];
  treatRatePercentages: APITreatRatePercentage[];
}

export type APIFormulationInput = Partial<
  Omit<
    APIFormulation,
    | "id"
    | "isCltLocked"
    | "project"
    | "baseOilPercentages"
    | "additivePercentages"
    | "treatRatePercentages"
  > & {
    baseOilPercentages: APIBaseOilPercentageInput[];
    additivePercentages: APIAdditivePercentageInput[];
    treatRatePercentages: APITreatRatePercentageInput[];
  }
>;

export interface APIFormulationList extends APIList<APIFormulation> {}

export interface APIFormulationListSearchParams extends APISearchParams {
  project: identifier;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIFormulationListSearchParams
) =>
  base.list<APIFormulationList, APIFormulationListSearchParams>(
    "formulations/",
    page,
    limit,
    searchParams
  );

export interface APIFormulationDetail extends APIDetail<APIFormulation> {}

export const detail = (formulationId: number) =>
  base.get<APIFormulationDetail>(`formulations/${formulationId}/`);

export const create = (projectId: identifier, data: APIFormulationInput) =>
  base.post<APIFormulationDetail>(`formulations/`, {
    json: { project: projectId, ...data },
  });

export const patch = (formulationId: identifier, data: APIFormulationInput) =>
  base.patch<APIFormulationDetail>(`formulations/${formulationId}/`, {
    json: data,
  });

export const put = (formulationId: identifier, data: APIFormulationInput) =>
  base.put<APIFormulationDetail>(`formulations/${formulationId}/`, {
    json: data,
  });

export const del = (formulationId: identifier) =>
  base.deleteEmpty(`formulations/${formulationId}/`);

export const baseOilList = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIBaseOilSearchParams
) =>
  base.list<APIBaseOilList, APIBaseOilSearchParams>(
    "base-oils/",
    page,
    limit,
    searchParams
  );

export const baseOilCreate = (data: APIBaseOilInput) =>
  base.post<APIBaseOil>(`base-oils/`, {
    json: data,
  });

export const baseOilPatch = (baseOilId: identifier, data: APIBaseOilInput) =>
  base.patch<APIBaseOil>(`base-oils/${baseOilId}/`, {
    json: data,
  });

export const additiveList = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIAdditiveSearchParams
) =>
  base.list<APIAdditiveList, APIAdditiveSearchParams>(
    "additives/",
    page,
    limit,
    searchParams
  );

export const additiveCreate = (data: APIAdditiveInput) =>
  base.post<APIAdditive>(`additives/`, {
    json: data,
  });

export const additivePatch = (additiveId: identifier, data: APIAdditiveInput) =>
  base.patch<APIAdditive>(`additives/${additiveId}/`, {
    json: data,
  });

export const treatRateList = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APITreatRateSearchParams
) =>
  base.list<APITreatRateList, APITreatRateSearchParams>(
    "treat-rates/",
    page,
    limit,
    searchParams
  );

export const treatRateCreate = (data: APITreatRateInput) =>
  base.post<APITreatRate>(`treat-rates/`, {
    json: data,
  });

export const treatRatePatch = (
  treatRateId: identifier,
  data: APITreatRateInput
) =>
  base.patch<APIAdditive>(`treat-rates/${treatRateId}/`, {
    json: data,
  });
