import appConfig from "../config/appConfig";
import localforage from "localforage";
import base from "./base";
import { AuthenticatedUser } from "./types";

/**
 * Check authenticated user details from localforage to determine if user is authenticated or not
 * Also updates local data from django server
 *
 * @returns {Promise<AuthenticatedUser | null>} indicates if user is authenticated or not
 */
export const checkAuth = async (): Promise<AuthenticatedUser | null> => {
  const authenticatedUser = await localforage.getItem<AuthenticatedUser>(
    appConfig.authUserKey as string
  );
  return authenticatedUser;
};

/**
 * Login to backend and store web token on success
 *
 * @param {string} code
 * @returns {Promise<AuthenticatedUser | undefined>} Promise object with JSON data
 *          containing access token on success, or undefined on error
 * @throws {Error} on http errors or failed attempts
 */
export const login = async (
  code: string
): Promise<AuthenticatedUser | undefined> => {
  try {
    const response: AuthenticatedUser = await base.post("auth/token/", {
      json: { code },
    });

    const authenticatedUser: AuthenticatedUser = {
      ...response,
      permissions: "user",
    };

    return authenticatedUser;
  } catch (error) {
    console.warn("Login failure", { error });
  }

  return Promise.reject("Failed to login.");
};

/**
 * Logout and remove stored tokens
 *
 * @returns {void}
 */
export const logout = async (): Promise<void> => {
  try {
    const authenticatedUser = await localforage.getItem<AuthenticatedUser>(
      appConfig.authUserKey as string
    );
    if (authenticatedUser) {
      await base.postEmpty("auth/logout/", {
        json: { token: authenticatedUser.accessToken },
      });
    }
  } catch (error) {
    console.warn("Logout failure", { error });
  }
};
