import clsx from "clsx";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Badge } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import styled from "styled-components";
import CommentForm from "./CommentForm";
import { useComments } from "./hooks";
import Post from "./Post";
import { Thread } from "./types";

const Container = styled.div`
  background-color: #f2f2f2;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.625rem;
  margin: -1px;
  margin-bottom: calc(0.8rem - 1px);
  border: 1px solid transparent;
  transition: border 0.1s ease;
  padding: 1rem;

  &:hover {
    border: 1px solid #242424;
  }

  &.selected {
    border: 1px solid #2a7307;
  }

  &.resolved {
    color: rgba(0, 0, 0, 0.5);

    .btn {
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const StyledBadge = styled(Badge)`
  font-size: 0.625rem;
  margin-bottom: 0.5rem;
`;

const ShowMore = styled(Button).attrs({ variant: "borderless" })`
  &.btn,
  &.btn:hover {
    display: block;
    border-left: 2px dotted #c4c4c4;
    font-weight: 600;
    margin-left: calc(0.875rem - 2px);
    margin-bottom: 0.5rem;
    padding: 0 1rem;
  }
`;

const AddMore = styled(Button).attrs({
  variant: "borderless",
})`
  &.btn {
    display: block;
    margin-left: -0.675rem;
  }

  &.btn svg {
    color: #242424;
    margin-right: 0;
    margin-bottom: 0.2rem;
  }
`;

type Props = {
  forNewComment?: boolean;
  thread: Thread;
};

const ThreadBox: React.FC<Props> = ({ forNewComment = false, thread }) => {
  const [active, setActive] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const ref = useRef() as React.RefObject<HTMLDivElement>;
  const { show, selection, onCommentSelect } = useComments();

  const selected =
    selection &&
    selection.colName === thread.selection.colName &&
    selection.rowIndex === thread.selection.rowIndex &&
    selection.sheetType === thread.selection.sheetType &&
    selection.sheetId === thread.selection.sheetId;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (selected && ref.current && show) {
        ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [selected, show]);

  const handleActiveToggle = useCallback(() => {
    setActive((prev) => {
      if (prev) {
        setShowMore(false);
      }
      return !prev;
    });
  }, []);

  const handleToggleShowMore = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setShowMore((prev) => !prev);
    },
    []
  );

  const { comments } = thread;

  const firstComment = comments[0];
  const lastComment =
    comments.length - 1 > 0 ? comments[comments.length - 1] : undefined;
  const middleComments = comments.slice(1, -1);

  const resolved = firstComment?.isResolved;

  return (
    <Container
      ref={ref}
      tabIndex={0}
      className={clsx({ active, selected, resolved })}
      onClick={() => (firstComment ? onCommentSelect(firstComment) : undefined)}
    >
      {firstComment ? (
        <StyledBadge variant="secondary">#{firstComment?.id}</StyledBadge>
      ) : null}

      {/* Show the first and last comments. If there are
          other comments in between, collapse them. */}

      {firstComment ? (
        <Post
          comment={firstComment}
          first
          selection={thread.selection}
          onClick={onCommentSelect}
        />
      ) : null}

      {showMore
        ? middleComments.map((comment) => (
            <Post
              key={comment.id}
              comment={comment}
              selection={thread.selection}
            />
          ))
        : null}

      {middleComments.length > 0 ? (
        <ShowMore
          key={showMore ? "less" : "more"}
          onClick={handleToggleShowMore}
        >
          See {showMore ? "Less" : "More"}&hellip;
        </ShowMore>
      ) : null}

      {lastComment ? (
        <Post comment={lastComment} selection={thread.selection} />
      ) : null}

      {!resolved && !forNewComment && !active ? (
        <AddMore onClick={handleActiveToggle}>
          <Plus /> Reply&hellip;
        </AddMore>
      ) : null}

      {!resolved ? (
        <CommentForm
          isActive={forNewComment || active}
          onSetInactive={handleActiveToggle}
          selection={thread.selection}
          cancelable={!forNewComment}
        />
      ) : null}
    </Container>
  );
};

export default observer(ThreadBox);
