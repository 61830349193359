import { model, Model, prop } from "mobx-keystone";
import { computed } from "mobx";
import { APIProjectFolderInput } from "../../api/projectFolders";

@model("collab/ProjectFolder")
class ProjectFolder extends Model({
  id: prop<number>(),
  name: prop<string>(),
  slug: prop<string>(),
  isActive: prop<boolean>(),
}) {
  @computed
  get asAPI(): APIProjectFolderInput {
    return {
      name: this.name,
      isActive: this.isActive,
    };
  }
}

export default ProjectFolder;
