import clsx from "clsx";
import React, { useState } from "react";
import { useAsyncFn, useEffectOnce } from "react-use";
import styled from "styled-components";
import { APIProjectVersionListSearchParams } from "../../api/projectVersions";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import VersionCard from "../../components/VersionCard";
import { useStore } from "../../store";
import ProjectVersion from "../../store/models/ProjectVersion";

type Props = {
  projectId: identifier;
  currentVersionId: identifier;
};

const StyledSpanHeader = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.75rem; ;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const VersionList: React.FC<Props> = ({ projectId, currentVersionId }) => {
  const store = useStore();
  const [page, setPage] = useState<number>(1);
  const limit = 5;
  const params: APIProjectVersionListSearchParams = {
    project: projectId,
  };
  const currentVersion = store.projectVersions.listItems.get(currentVersionId);

  const [{ loading, value: versions }, fetchVersions] = useAsyncFn(
    async (
      page: number,
      limit: number,
      params: APIProjectVersionListSearchParams
    ) => {
      const { count } = await store.projectVersions.list(page, limit, params);

      return {
        count,
        results: Array.from(store.projectVersions.listItems.values()),
      };
    }
  );

  useEffectOnce(() => {
    fetchVersions(page, limit, params);
  });

  return versions && currentVersion ? (
    <>
      <StyledSpanHeader>Current Version</StyledSpanHeader>
      <VersionCard current version={currentVersion} />
      <StyledSpanHeader>Past Versions</StyledSpanHeader>
      {versions.results
        .filter((item) => item.id !== currentVersionId)
        .map((item: ProjectVersion) => (
          <VersionCard key={item.id} version={item} />
        ))}
      {page < Math.ceil(versions.count / limit) ? (
        <ButtonContainer>
          <Button
            onClick={() => {
              const nextPage = page + 1;
              setPage(nextPage);
              fetchVersions(nextPage, limit, params);
            }}
          >
            Load more
          </Button>
        </ButtonContainer>
      ) : (
        <StyledSpanHeader className={clsx("text-center")}>
          Project Created
        </StyledSpanHeader>
      )}
      {loading && <Loading />}
    </>
  ) : null;
};

export default VersionList;
