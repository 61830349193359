/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Model,
  _async,
  _await,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
} from "mobx-keystone";
import api from "../api";
import { APIForex, APIForexListSearchParams } from "../api/forex";
import Forex from "./models/Forex";

@model("collab/ForexStore")
class ForexStore extends Model({
  listItems: prop_mapObject(() => new Map<string, Forex>()),
}) {
  @modelAction
  saveListItem(item: APIForex) {
    const listItem = new Forex(item);
    this.listItems.set(item.currencyCode, listItem);
    return listItem;
  }

  @modelAction
  getUsdExchangeRate(code: string) {
    const item = this.listItems.get(code);
    return item === undefined ? null : item.usdExchangeRate;
  }

  @modelAction
  getEurExchangeRate(code: string) {
    const item = this.listItems.get(code);
    return item === undefined ? null : item.eurExchangeRate;
  }

  @modelFlow
  list = _async(function* (
    this: ForexStore,
    page?: number,
    limit?: number,
    searchParams?: APIForexListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.forex.list(page, limit, searchParams));
    if (page === 1) {
      this.listItems.clear();
    }
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelAction
  reset() {
    this.listItems.clear();
  }
}

export default ForexStore;
