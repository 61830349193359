import { APIProjectMinimal } from "api/types";
import { model, Model, modelAction, prop } from "mobx-keystone";

@model("collab/ProjectMinimal")
class ProjectMinimal extends Model({
  id: prop<number>(),
  created: prop<string>(),
  modified: prop<string>(),
  name: prop<string>(),
  type: prop<string>(),
  folder: prop<number>(),
}) {
  @modelAction
  update(item: APIProjectMinimal) {
    Object.keys(item).forEach((key) => {
      //@ts-ignore
      this[key] = item[key];
    });
  }
}

export default ProjectMinimal;
