import api from "api";
import { APIDefineSheetFileType } from "api/defineSheets";
import {
  ExtendedModel,
  model,
  modelFlow,
  prop,
  _async,
  _await,
} from "mobx-keystone";
import Sheet, { SheetType } from "./Sheet";

@model("collab/DefineSheet")
class DefineSheet extends ExtendedModel(Sheet, {
  cluster: prop<identifier | null>(),
  clusterName: prop<string | null>(""),
  fileType: prop<APIDefineSheetFileType>(),
  isCltLocked: prop<boolean>(),
}) {
  get sheetType(): SheetType {
    return "definesheet";
  }

  @modelFlow
  save = _async(function* (this: DefineSheet) {
    if (!this.isDirty) {
      return false;
    }

    try {
      yield* _await(api.defineSheets.update(this.id, this.csvString));
    } catch (e) {
      throw e;
    }

    return true;
  });
}

export default DefineSheet;
