import { FormLabel as BSFormLabel } from "react-bootstrap";
import styled from "styled-components";

const FormLabel = styled(BSFormLabel)`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.031rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    margin-right: 0.625rem;
  }
`;

export default FormLabel;
