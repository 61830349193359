import { APICLTAssumptionsInput } from "api/cltAssumptions";
import CLTFiles from "components/CLTFiles";
import Editor from "components/Editor";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useUnmount } from "react-use";
import { useStore } from "store";
import styled from "styled-components";
import { useProject, useSave } from "../Workspace/hooks";

type Props = { showFiles?: boolean };

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 1.25rem;
  height: 0;
  overflow: auto;
  padding: 1.25rem;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const CLTAssumptionsForm: React.FC<Props> = ({ showFiles }) => {
  const { formState, handleSubmit } = useFormContext();
  const store = useStore();
  const project = useProject();
  const currentUser = store.auth.current;
  const cltAssumption =
    currentUser && Array.from(store.cltAssumptions.listItems.values())[0];

  const cluster = cltAssumption?.cluster;
  const cltLocked = cluster && project?.lockedClusters.includes(cluster);
  const cltSubmitted = cluster && project?.submittedClusters.includes(cluster);
  const cltApproved = cluster && project?.approvedClusters.includes(cluster);
  const hasEditPermission = !!(
    currentUser &&
    (currentUser.isAdmin ||
      currentUser.isAnalyst ||
      project?.adminUsers.includes(currentUser.id))
  );
  const editable =
    hasEditPermission &&
    !project?.isLocked &&
    !cltLocked &&
    !cltSubmitted &&
    !cltApproved;

  useEffect(() => {
    if (project) {
      if (formState.isDirty) {
        project.setDirty();
      } else {
        project.setNotDirty();
      }
    }
  }, [project, formState.isDirty]);

  useUnmount(() => {
    if (project && project.isDirty) {
      project.setNotDirty();
    }
  });

  const handleSave = useCallback(
    async (data: APICLTAssumptionsInput) => {
      if (cltAssumption) {
        try {
          await store.cltAssumptions.update(cltAssumption.id, data);
        } catch (e) {
          return;
        }
      }
    },
    [cltAssumption, store]
  );

  useSave(
    async () => {
      if (!editable) {
        throw new Error("CLT Assumptions not editable");
      }
      await handleSubmit(handleSave, () => {
        throw new Error("Project not saved.");
      })();
    },
    { saveOnUnmount: true }
  );

  return (
    <>
      <Container>
        {cltAssumption && (
          <Editor
            readOnly={!editable}
            defaultValue={cltAssumption.notes}
            name="notes"
          />
        )}
        {showFiles && (
          <CLTFiles
            clusterId={cltAssumption?.cluster}
            sheetsStatus={cltAssumption?.sheetsStatus}
          />
        )}
      </Container>
    </>
  );
};

export default observer(CLTAssumptionsForm);
