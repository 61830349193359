import { CheckOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { formatDistanceToNow, parseISO } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import Project from "store/models/Project";
import styled from "styled-components";
import Button from "../Button";
import { Header, SubHeader } from "../Header";
import ModalForm from "../ModalForm";
import Submit from "./Submit";

type Props = {
  project: Project;
};

const SubmitModalContainer = styled(ModalForm)`
  .modal-dialog {
    font-size: 0.875rem;
    line-height: 1.625rem;
    min-width: 34.75rem;
  }
`;

const HeaderRight = styled(Header)`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: capitalize;
`;

const SubmitRMPAModal: React.FC<Props> = ({ project }) => {
  const methods = useForm();
  const [show, setShow] = useState(false);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleHide = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <FormProvider {...methods}>
      <Button onClick={handleShow}>
        <CheckOutlined /> Submit
      </Button>
      <SubmitModalContainer centered show={show} onHide={handleHide}>
        <Modal.Header className={clsx("d-flex", "justify-content-between")}>
          <Modal.Title>
            <SubHeader>Procurement</SubHeader>
            <Header>Submit</Header>
          </Modal.Title>
          <Modal.Title className={clsx("text-right")}>
            <SubHeader>Version {project.versionNumber}</SubHeader>
            {project.latestIssueDate ? (
              <HeaderRight>
                Model Issued{" "}
                {formatDistanceToNow(parseISO(project.latestIssueDate))} ago
              </HeaderRight>
            ) : (
              <HeaderRight>Model not published</HeaderRight>
            )}
          </Modal.Title>
        </Modal.Header>
        <Submit project={project} onHide={handleHide} />
      </SubmitModalContainer>
    </FormProvider>
  );
};

export default observer(SubmitRMPAModal);
