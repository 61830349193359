import base, { APIList, APISearchParams, config } from "./base";
import { APICurrency } from "./types";

export interface APICurrencyList extends APIList<APICurrency> {}
export interface APICurrencyListSearchParams extends APISearchParams {}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APICurrencyListSearchParams
) =>
  base.list<APICurrencyList, APICurrencyListSearchParams>(
    "currency/",
    page,
    limit,
    searchParams
  );
