import { Check, MenuOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { darken } from "polished";
import React from "react";
import { Dropdown, Nav } from "react-bootstrap";
import { SelectCallback } from "react-bootstrap/esm/helpers";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";

type Props = {
  tabs: {
    title: string;
    key: string;
    icon?: JSX.Element | null;
  }[];
  selectedTabIndex: number;
  onChangeTab: SelectCallback;
};

const TabContainer = styled.div`
  align-items: center;
  background-color: #e0e0e0;
  border-top: 1px solid #cecece;
  display: flex;

  .dropdown-menu {
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.16);
    max-height: 15rem;
    overflow-y: auto;

    .dropdown-item {
      font-size: 0.875rem;
      margin-right: 1rem;

      svg {
        float: right;
        height: 0.875rem;
        margin-top: 0.125rem;
        width: 0.875rem;
      }
    }
  }
`;

const StyledScrollContainer = styled(ScrollContainer)`
  border-left: 1px solid #cecece;
  flex: 1;
  min-height: 3rem;
`;

const StyledNav = styled(Nav)`
  align-items: center;
  flex-wrap: nowrap;
  min-height: 3rem;
  padding: 0 1.25rem 0 0;
  --indicator-width: 4px;

  &.nav-sheet-tabs {
    .nav-item {
      margin-top: -1px;
      user-select: none;

      .nav-link {
        align-items: center;
        border-top: var(--indicator-width) solid transparent;
        display: flex;
        font-size: 0.6875rem;
        font-weight: bold;
        height: 3rem;
        padding: 0 1.25rem;
        text-transform: uppercase;

        max-width: 12.5rem;

        span {
          min-width: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        svg {
          display: inline-block;
          padding-left: 0.5rem;
          vertical-align: bottom;
        }

        &.active {
          border-top-color: #000000;
        }

        &:link,
        &:visited {
          color: #000000;
        }
        &:hover {
          background-color: ${darken(0.1, "#e0e0e0")};
        }
      }
    }
  }
`;

const SecondaryTabs: React.FC<Props> = ({
  tabs,
  selectedTabIndex,
  onChangeTab,
}) => {
  return (
    <TabContainer>
      <Dropdown drop="up" onSelect={onChangeTab}>
        <Dropdown.Toggle variant="thin" disabled={tabs.length === 0}>
          <MenuOutlined />
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ strategy: "fixed" }}>
          {tabs.map((tab, index) => (
            <Dropdown.Item key={tab.key} eventKey={index}>
              {selectedTabIndex === index ? <b>{tab.title}</b> : tab.title}{" "}
              {selectedTabIndex === index ? <Check /> : null}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <StyledScrollContainer vertical={false}>
        <StyledNav
          variant="sheet-tabs"
          activeKey={selectedTabIndex}
          onSelect={onChangeTab}
        >
          {tabs.map((tab, index) => (
            <Nav.Item key={tab.key} title={tab.title}>
              <Nav.Link eventKey={index}>
                <span>{tab.title}</span>
                {tab.icon ?? null}
              </Nav.Link>
            </Nav.Item>
          ))}
        </StyledNav>
      </StyledScrollContainer>
    </TabContainer>
  );
};

export default observer(SecondaryTabs);
