import base, { APIList, APISearchParams, config } from "./base";

export interface APIUnitOfMeasurement {
  id: identifier;
  name: string;
  conversion: number;
}

export interface APIUnitOfMeasurementList
  extends APIList<APIUnitOfMeasurement> {}

export interface APIUnitOfMeasurementListSearchParams extends APISearchParams {
  name?: string;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIUnitOfMeasurementListSearchParams
) =>
  base.list<APIUnitOfMeasurementList, APIUnitOfMeasurementListSearchParams>(
    "units-of-measurement/",
    page,
    limit,
    searchParams
  );
