import { difference } from "lodash";
import { computed, observable } from "mobx";
import {
  ExtendedModel,
  _async,
  _await,
  getRootStore,
  model,
  modelAction,
  modelFlow,
  prop,
  prop_mapObject,
  prop_setArray,
} from "mobx-keystone";
import { RootStore } from "..";
import api from "../../api";
import {
  APIFormulationDetail,
  APIFormulationInput,
} from "../../api/formulations";
import { forceNumberArray } from "../utils";
import DirtyableWithId from "./DirtyableWithId";

@model("collab/Formulation")
class Formulation extends ExtendedModel(DirtyableWithId, {
  clusters: prop<identifier[]>(() => []),
  code: prop<string>(""),
  comments: prop<string>(""),
  countries: prop<identifier[]>(() => []),
  density: prop<number>(),
  isApproved: prop<boolean>(false),
  isCltLocked: prop<boolean>(false),
  notes: prop<string>(""),
  ordering: prop<number>(),
  packSizes: prop<identifier[]>(() => []),
  plant: prop<identifier | undefined>(),
  plantSplitCode: prop<string>(""),
  plantSplitPercent: prop<number | null | undefined>(),
  plantType: prop<"master" | "plant" | undefined>(),
  project: prop<identifier>(),
  repackagingPackSizes: prop<identifier[]>(() => []),
  repackagingPlant: prop<identifier | undefined>(),
  sourcePlants: prop<identifier[]>(() => []),
  specs: prop<string>(""),
  viscosity: prop<string>(""),

  baseOilPercentages: prop_mapObject(() => new Map<identifier, string>()),
  baseOilPercentages2: prop_mapObject(() => new Map<identifier, string>()),
  dirtyBaseOilPercentages: prop_setArray(() => new Set<identifier>()),

  additivePercentages: prop_mapObject(() => new Map<identifier, string>()),
  additivePercentages2: prop_mapObject(() => new Map<identifier, string>()),
  dirtyAdditivePercentages: prop_setArray(() => new Set<identifier>()),

  treatRatePercentages: prop_mapObject(() => new Map<identifier, string>()),
  treatRatePercentages2: prop_mapObject(() => new Map<identifier, string>()),
  dirtyTreatRatePercentages: prop_setArray(() => new Set<identifier>()),
}) {
  @observable
  _prevId = 0;

  onInit() {
    // Set the original ID on creation, not onAttachedToRootStore,
    // because we want this id to not change even after
    // modifying the map in FormulationStore with this formulation's
    // final ID after saving this to the API.
    this.updatePrevId();
  }

  updatePrevId() {
    this._prevId = this.id;
  }

  @computed
  get asAPI(): APIFormulationInput {
    const store = getRootStore<RootStore>(this)!; // Pretty sure it's there.

    // Build the base oil and additive percentages array.
    const baseOilPercentages = store.formulations
      .baseOilsOfProject(this.project)
      .filter(
        (baseOil) => baseOil.id > 0 && this.baseOilPercentages.get(baseOil.id)
      )
      .map((baseOil) => ({
        baseOil: {
          id: baseOil.id,
          rcode: baseOil.rcode,
          description: baseOil.description,
          crdStatus: baseOil.crdStatus,
        },
        value: this.baseOilPercentages.get(baseOil.id) || "",
      }));

    const additivePercentages = store.formulations
      .additivesOfProject(this.project)
      .filter(
        (additive) =>
          additive.id > 0 && this.additivePercentages.get(additive.id)
      )
      .map((additive) => ({
        additive: {
          id: additive.id,
          rcode: additive.rcode,
          description: additive.description,
          crdStatus: additive.crdStatus,
        },
        value: this.additivePercentages.get(additive.id) || "",
      }));

    const treatRatePercentages = store.formulations
      .treatRatesOfProject(this.project)
      .filter(
        (treatRate) =>
          treatRate.id > 0 && this.treatRatePercentages.get(treatRate.id)
      )
      .map((treatRate) => ({
        treatRate: {
          id: treatRate.id,
          rcode: treatRate.rcode,
          description: treatRate.description,
          crdStatus: treatRate.crdStatus,
        },
        value: this.treatRatePercentages.get(treatRate.id) || "",
      }));

    return {
      specs: this.specs,
      viscosity: this.viscosity,
      code: this.code,
      notes: this.notes,
      ordering: this.ordering,
      plant: this.plant,
      plantType: this.plantType,
      sourcePlants: forceNumberArray(this.sourcePlants),
      clusters: forceNumberArray(this.clusters),
      countries: forceNumberArray(this.countries),
      plantSplitCode: this.plantSplitCode,
      plantSplitPercent: this.plantSplitPercent || null,
      packSizes: forceNumberArray(this.packSizes),
      repackagingPlant: this.repackagingPlant,
      repackagingPackSizes: forceNumberArray(this.repackagingPackSizes),
      isApproved: this.isApproved,
      comments: this.comments,
      density: this.density,
      baseOilPercentages,
      additivePercentages,
      treatRatePercentages,
    };
  }

  private _findValue(
    options: { value: number; label: string; name?: string; code?: string }[],
    value: any
  ) {
    // If value is not an id, check if it's a label,
    // or a name (for plants) or a code (for plants).
    if (!options.find((opt) => opt.value === Number(value))) {
      let v =
        options.find((opt) => {
          const name = opt.name?.toString().trim().toLowerCase();
          const code = opt.code?.toString().trim().toLowerCase();
          const vv = value.toString().trim().toLowerCase();

          if (code && code === vv) {
            return true;
          }
          if (name && name === vv) {
            return true;
          }
          return false;
        })?.value || "";

      // If we still can't find it, then resort to substring matching, but
      // make it a case-sensitive match so we don't match "Gent" with "Argentina".
      if (!v) {
        v =
          options.find((opt) => {
            const label = opt.label.toString().trim();
            return value.length > 0 && label.indexOf(value) >= 0;
          })?.value || "";
      }

      return v;
    }

    return value;
  }

  private _findValueMultiple(
    options: { value: number; label: string }[],
    value: any
  ) {
    let v = value
      .split(",")
      .map((x: string) => x.trim())
      .filter((x: string) => !!x);
    return v
      .map((x: string) => this._findValue(options, x))
      .filter((x: string) => !!x)
      .join(",");
  }

  @modelAction
  setValue(key: string, value: any) {
    const store = getRootStore<RootStore>(this);
    let v = value;

    if (value === undefined || value === null) return;

    if (store && key === "plant") {
      v = this._findValue(store.assets.plantSelect, v);
    } else if (store && key === "plantType") {
      v = value.toLowerCase().trim();
    } else if (store && key === "repackagingPlant") {
      v = this._findValue(store.assets.plantSelect, v);
    } else if (store && key === "sourcePlants") {
      v = this._findValueMultiple(store.assets.plantSelect, v);
    } else if (store && key === "clusters") {
      v = this._findValueMultiple(store.assets.clusterSelect, v);
      this.resetCountriesOnClusterChange(v);
    } else if (store && key === "countries") {
      v = this._findValueMultiple(store.assets.countrySelect, v);
    } else if (store && key === "packSizes") {
      v = this._findValueMultiple(store.assets.packSizeSelect, v);
    } else if (store && key === "repackagingPackSizes") {
      v = this._findValueMultiple(store.assets.packSizeSelect, v);
    } else if (store && key === "isApproved") {
      const a = v.toString().toLowerCase();
      v = a === "y" || a === "true" || a === "t" ? true : false;
    }
    super.setValue(key, v);
  }

  /**
   * Updates the countries based on the difference between the current set of clusters and the upcoming set of clusters.
   *
   * Call this *before* committing the new cluster IDs to the formulation.
   */
  @modelAction
  resetCountriesOnClusterChange(updatedClusterIds: identifier[]) {
    const rootStore = getRootStore<RootStore>(this);
    if (!rootStore) {
      return;
    }

    const requirements = rootStore.requirements.ofProject(this.project);
    let projectCountryIds = requirements.map((e) =>
      Number(e.destinationCountry)
    );

    const oldClusters = forceNumberArray(this.clusters);
    const newClusters = forceNumberArray(updatedClusterIds);
    const addedClusters = difference(newClusters, oldClusters);
    const removedClusters = difference(oldClusters, newClusters);

    const countries = forceNumberArray(this.countries);

    removedClusters.forEach((clusterId) => {
      rootStore.assets.countryListItems.forEach((country) => {
        if (country.cluster === clusterId) {
          const idx = countries.findIndex(
            (countryId) => countryId === country.id
          );
          if (idx >= 0) {
            countries.splice(idx, 1);
          }
        }
      });
    });
    addedClusters.forEach((clusterId) => {
      rootStore.assets.countryListItems.forEach((country) => {
        if (
          country.cluster === clusterId &&
          (!country.project || country.project === this.project) &&
          projectCountryIds.indexOf(country.id) >= 0
        ) {
          countries.push(country.id);
        }
      });
    });

    this.setValue("countries", countries.join(","));
  }

  @modelAction
  copyBaseOilPercentages() {
    Array.from(this.baseOilPercentages.keys()).forEach((id) => {
      this.baseOilPercentages2.set(id, this.baseOilPercentages.get(id) ?? "");
    });
  }

  @modelAction
  copyAdditivePercentages() {
    Array.from(this.additivePercentages.keys()).forEach((id) => {
      this.additivePercentages2.set(id, this.additivePercentages.get(id) ?? "");
    });
  }

  @modelAction
  copyTreatRatePercentages() {
    Array.from(this.treatRatePercentages.keys()).forEach((id) => {
      this.treatRatePercentages2.set(
        id,
        this.treatRatePercentages.get(id) ?? ""
      );
    });
  }

  @modelAction
  setBaseOilPercentage(
    baseOilId: identifier,
    percentage: string,
    setDirty: boolean = true
  ) {
    this.baseOilPercentages.set(baseOilId, percentage);
    if (setDirty) {
      this.dirtyBaseOilPercentages.add(baseOilId);
    }
  }

  @modelAction
  setAdditivePercentage(
    additiveId: identifier,
    percentage: string,
    setDirty: boolean = true
  ) {
    this.additivePercentages.set(additiveId, percentage);
    if (setDirty) {
      this.dirtyAdditivePercentages.add(additiveId);
    }
  }

  @modelAction
  setTreatRatePercentage(
    treatRateId: identifier,
    percentage: string,
    setDirty: boolean = true
  ) {
    this.treatRatePercentages.set(treatRateId, percentage);
    if (setDirty) {
      this.dirtyTreatRatePercentages.add(treatRateId);
    }
  }

  @modelAction
  moveBaseOilPercentage(oldBaseOilId: identifier, newBaseOilId: identifier) {
    if (oldBaseOilId === newBaseOilId) {
      return;
    }

    const value = this.baseOilPercentages.get(oldBaseOilId);

    if (value) {
      this.setBaseOilPercentage(newBaseOilId, value);
      this.baseOilPercentages.delete(oldBaseOilId);
      this.setDirty();
    }
  }

  @modelAction
  moveAdditivePercentage(oldAdditiveId: identifier, newAdditiveId: identifier) {
    if (oldAdditiveId === newAdditiveId) {
      return;
    }

    const value = this.additivePercentages.get(oldAdditiveId);

    if (value) {
      this.setAdditivePercentage(newAdditiveId, value);
      this.additivePercentages.delete(oldAdditiveId);
      this.setDirty();
    }
  }

  @modelAction
  moveTreatRatePercentage(
    oldTreatRateId: identifier,
    newTreatRateId: identifier
  ) {
    if (oldTreatRateId === newTreatRateId) {
      return;
    }

    const value = this.treatRatePercentages.get(oldTreatRateId);

    if (value) {
      this.setTreatRatePercentage(newTreatRateId, value);
      this.treatRatePercentages.delete(oldTreatRateId);
      this.setDirty();
    }
  }

  @modelAction
  deleteBaseOilPercentage(baseOilId: identifier) {
    this.dirtyBaseOilPercentages.delete(baseOilId);
    if (this.baseOilPercentages.delete(baseOilId)) {
      this.setDirty();
    }
  }

  @modelAction
  deleteAdditivePercentage(additiveId: identifier) {
    this.dirtyAdditivePercentages.delete(additiveId);
    if (this.additivePercentages.delete(additiveId)) {
      this.setDirty();
    }
  }

  @modelAction
  deleteTreatRatePercentage(treatRateId: identifier) {
    this.dirtyTreatRatePercentages.delete(treatRateId);
    if (this.treatRatePercentages.delete(treatRateId)) {
      this.setDirty();
    }
  }

  @modelAction
  clearPercentages() {
    this.baseOilPercentages.clear();
    this.dirtyBaseOilPercentages.clear();
    this.additivePercentages.clear();
    this.dirtyAdditivePercentages.clear();
    this.treatRatePercentages.clear();
    this.dirtyTreatRatePercentages.clear();
  }

  @modelFlow
  save = _async(function* (this: Formulation) {
    const isPercentagesDirty =
      this.dirtyBaseOilPercentages.size > 0 ||
      this.dirtyAdditivePercentages.size > 0 ||
      this.dirtyTreatRatePercentages.size > 0;

    if (!this.isDirty && !isPercentagesDirty) {
      return;
    }

    let response: APIFormulationDetail;
    try {
      if (this.id < 0) {
        response = yield* _await(
          api.formulations.create(this.project, this.asAPI)
        );
      } else {
        response = yield* _await(api.formulations.patch(this.id, this.asAPI));
      }
    } catch (e) {
      console.error(e);
      throw e;
    }

    // Do not include percentage data with the update.
    const {
      baseOilPercentages,
      additivePercentages,
      treatRatePercentages,
      ...formulationData
    } = response;

    this.update(formulationData);
    this.dirtyBaseOilPercentages.clear();
    this.dirtyAdditivePercentages.clear();
    this.dirtyTreatRatePercentages.clear();
  });
}

export default Formulation;
