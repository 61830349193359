import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

function Collect(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M2.33366 3.99935H0.666992V15.666C0.666992 16.5827 1.41699 17.3327 2.33366 17.3327H14.0003V15.666H2.33366V3.99935ZM15.667 0.666016H5.66699C4.75033 0.666016 4.00033 1.41602 4.00033 2.33268V12.3327C4.00033 13.2493 4.75033 13.9993 5.66699 13.9993H15.667C16.5837 13.9993 17.3337 13.2493 17.3337 12.3327V2.33268C17.3337 1.41602 16.5837 0.666016 15.667 0.666016ZM14.8337 8.16602H11.5003V11.4993H9.83366V8.16602H6.50033V6.49935H9.83366V3.16602H11.5003V6.49935H14.8337V8.16602Z" />
    </SvgIcon>
  );
}

export default React.memo(Collect);
