import React from "react";
import { ContextMenu as BaseContextMenu } from "react-contextmenu";
import styled from "styled-components";

type props = {
  id: string;
};

const ContextMenu: React.FC<props> = styled(BaseContextMenu)`
  background: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.16);
  padding: 0.46875rem 0;
  user-select: none;
  z-index: 100;

  .react-contextmenu-item {
    color: #242424;
    font-size: 0.875rem;
    line-height: 1;
    padding: 0.46875rem 1.875rem 0.46875rem 0.9375rem;

    &:hover {
      background-color: #f4f4f4;
      cursor: pointer;
    }

    &.react-contextmenu-item--divider {
      border-bottom: 1px solid #f4f4f4;
      margin-bottom: 0.46875rem;
      padding: 0 0 0.46875rem;

      &:hover {
        background-color: unset;
        cursor: default;
      }
    }

    &.react-contextmenu-item--disabled {
      opacity: 0.6;

      &:hover {
        background-color: unset;
        cursor: default;
      }
    }
  }
`;

export default ContextMenu;
