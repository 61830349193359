import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { APIClusterInputSheetsStatus } from "api/types";
import clsx from "clsx";
import DatePicker from "components/DatePicker";
import { format, isBefore, isSameDay, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { observer } from "mobx-react-lite";
import React from "react";
import { FormControl } from "react-bootstrap";
import BootstrapTable, {
  ColumnDescription,
  SortOrder,
} from "react-bootstrap-table-next";
import { Controller, useFormContext } from "react-hook-form";
import { useStore } from "store";
import styled from "styled-components";
import { useProject } from "../Workspace/hooks";
import StatusDashboardCheckbox from "./StatusDashboardCheckbox";

const BootstrapTableWrapper = styled.div`
  .sd-table {
    border-collapse: separate;
    border-spacing: 0 0.625rem;
    font-size: 0.75rem;

    color: #000000;

    .sd-table-header {
      background: #eef1f3;
      font-weight: bold;
      line-height: 1rem;
      text-transform: uppercase;

      th {
        border-bottom: 0.063rem solid #b5c0c8;
        border-top: 0.063rem solid #b5c0c8;

        &:first-of-type {
          border-left: 0.063rem solid #b5c0c8;
        }
        &:last-of-type {
          border-right: 0.063rem solid #b5c0c8;
        }
        &:first-of-type,
        &:last-of-type {
          border-radius: 0.25rem;
        }

        :focus {
          outline: none;
        }
      }
    }

    .sd-table-row {
      line-height: 0.75rem;
      background: #ffffff;
      box-shadow: 0.25rem 0.25rem 0.313rem rgba(0, 0, 0, 0.02);

      td {
        border-bottom: 0.063rem solid #bec5ca;
        border-top: 0.063rem solid #bec5ca;
        text-align: center;

        &:first-of-type {
          border-left: 0.063rem solid #bec5ca;
          text-align: left;
        }
        &:last-of-type {
          border-right: 0.063rem solid #bec5ca;
        }
        &:first-of-type,
        &:last-of-type {
          border-radius: 0.125rem;
        }
      }
    }

    td.react-bs-table-no-data {
      border-top: none;
      color: #808080;
      text-align: center;
      user-select: none;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 600;
    }

    th,
    td {
      vertical-align: middle;
    }

    td {
      padding: 0.625rem 0.75rem;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  gap: 1.125rem;
`;

const CaretWrapper = styled.div`
  svg {
    width: 0.875rem;
    height: 0.875rem;
    stroke: #4e4e4e;
    border: 0.063rem solid #4e4e4e;
    box-sizing: border-box;
    border-radius: 0.125rem;
  }
`;

const ClusterNameDiv = styled.div`
  font-weight: 700;
`;

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
`;

const DateDiv = styled.div`
  background-color: #fdfdfd;
  border: 0.063rem solid #c8c8c8;
  box-sizing: border-box;
  border-radius: 0.25rem;

  padding: 0.688rem 0.938rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-align: center;
  width: 7.5rem;
  height: 2.375rem;

  &.date-late {
    background: #f89964;
    border-color: #b66436;
  }

  &.date-valid {
    background-color: #c3da87;
    border: 0.063rem solid #8fac42;
  }

  &.date-empty {
    user-select: none;
  }
`;

const VersionDiv = styled.div`
  border: 0.063rem solid #c8c8c8;
  border-radius: 0.25rem;
  padding: 0.688rem 0.938rem;
  min-width: 3.75rem;
  text-align: center;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.875rem;

  color: #242424;
`;

const StyledDatePicker = styled(DatePicker)`
  &.input-group {
    .form-control {
      height: 2.375rem;
    }

    .btn-calendar,
    .form-control {
      background-color: #fdfdfd;
      border-color: #c8c8c8;
      color: #000000;
    }

    &.date-on-time {
      .btn-calendar,
      .form-control {
        background-color: #c3da87;
        border-color: #8fac42;
      }
    }

    &.date-not-on-time {
      .btn-calendar,
      .form-control {
        background: #f89964;
        border-color: #b66436;
      }
    }
  }
`;

const StyledSelect = styled(FormControl)`
  width: 7.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #242424;

  &.has-queries {
    background-color: #f3d989;
    border: 0.063rem solid #cea834;
  }

  &:focus {
    color: #495057;
    border-color: #74b468;
    outline: 0;
    box-shadow: none;
  }

  option {
    background-color: #ffffff;
  }
`;

type Props = {
  hasEditPermissions: boolean;
};

const StatusDashboardTable: React.FC<Props> = ({ hasEditPermissions }) => {
  const { control } = useFormContext();
  const project = useProject();
  const store = useStore();
  const data = Array.from(store.clusterInputSheetsStatus.listItems.values());

  const statusDashboardHeaderFormatter = (
    column: ColumnDescription,
    colIndex: number,
    { sortElement }: { sortElement: JSX.Element }
  ) => (
    <>
      <HeaderWrapper>
        <div>{column.text}</div>
        {sortElement}
      </HeaderWrapper>
    </>
  );

  const statusDashboardSortCaret = (order?: SortOrder) => {
    const caret = order === "asc" ? <ArrowDropUp /> : <ArrowDropDown />;
    return <CaretWrapper>{caret}</CaretWrapper>;
  };

  const statusDashboardDateFormatter = (cell: string) => (
    <CellWrapper>
      <DateDiv
        className={clsx({ "date-valid": !!cell, "date-empty": !!!cell })}
      >
        {cell ? format(parseISO(cell), "P") : "--/--/----"}
      </DateDiv>
    </CellWrapper>
  );

  const statusDashboardReissueFormatter = (
    cell: string,
    row: APIClusterInputSheetsStatus
  ) => (
    <CellWrapper>
      <DateDiv
        className={clsx({ "date-valid": !!cell, "date-empty": !!!cell })}
      >
        {cell ? format(parseISO(cell), "P", { locale: enGB }) : "--/--/----"}
      </DateDiv>
      <VersionDiv>{cell ? row.reissueVersion : "n/a"}</VersionDiv>
    </CellWrapper>
  );

  const statusDashboardApprovedFormatter = (
    cell: string,
    row: APIClusterInputSheetsStatus
  ) => (
    <CellWrapper>
      <DateDiv
        className={clsx({ "date-valid": !!cell, "date-empty": !!!cell })}
      >
        {cell ? format(parseISO(cell), "P", { locale: enGB }) : "--/--/----"}
      </DateDiv>
      <VersionDiv>{cell ? row.submitVersion : "n/a"}</VersionDiv>
    </CellWrapper>
  );

  const statusDashboardSubmittedFormatter = (
    cell: string,
    row: APIClusterInputSheetsStatus
  ) => {
    const isOnTime = isSameDay(parseISO(row.submitDate), parseISO(row.dueDate));
    return (
      <CellWrapper>
        <DateDiv
          className={clsx({
            "date-empty": !!!cell,
            "date-late": !!cell && !isOnTime,
            "date-valid": !!cell && isOnTime,
          })}
        >
          {cell ? format(parseISO(cell), "P", { locale: enGB }) : "--/--/----"}
        </DateDiv>
        <VersionDiv className="version-empty">
          {cell ? row.submitVersion : "n/a"}
        </VersionDiv>
      </CellWrapper>
    );
  };

  const statusDashboardDatePickerFormatter = (
    cell: string,
    row: APIClusterInputSheetsStatus,
    rowIndex: number
  ) => (
    <Controller
      control={control}
      name={`instances[${rowIndex}].dueDate`}
      defaultValue={!!cell ? parseISO(cell) : undefined}
      render={({ field }) => {
        const isOnTime = isBefore(new Date(), field.value);
        return (
          <StyledDatePicker
            disabled={row.isLocked || !hasEditPermissions}
            className={clsx({
              "date-on-time": isOnTime,
              "date-not-on-time": !isOnTime,
            })}
            {...field}
          />
        );
      }}
    />
  );

  const statusDashboardSelectFormatter = (
    cell: string,
    row: APIClusterInputSheetsStatus,
    rowIndex: number
  ) => (
    <CellWrapper>
      <Controller
        control={control}
        name={`instances[${rowIndex}].hasQueries`}
        defaultValue={cell.toString()}
        render={({ field }) => (
          <StyledSelect
            disabled={row.isLocked || !hasEditPermissions}
            as="select"
            {...field}
            className={clsx("form-control", {
              "has-queries": field.value === "true",
            })}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </StyledSelect>
        )}
      />
    </CellWrapper>
  );

  const columns = [
    {
      dataField: "clusterName",
      sort: true,
      text: "CLT / Country",
      headerFormatter: statusDashboardHeaderFormatter,
      sortCaret: statusDashboardSortCaret,
      formatter: (
        cell: string,
        row: APIClusterInputSheetsStatus,
        rowIndex: number
      ) => (
        <>
          <ClusterNameDiv>{cell}</ClusterNameDiv>
          <Controller
            control={control}
            name={`instances[${rowIndex}].id`}
            render={({ field }) => <input type="hidden" {...field} />}
            defaultValue={row.id}
          />
        </>
      ),
    },
    {
      dataField: "issueDate",
      text: "Costing Model Published",
      sort: true,
      headerFormatter: statusDashboardHeaderFormatter,
      sortCaret: statusDashboardSortCaret,
      formatter: statusDashboardDateFormatter,
    },
    {
      dataField: "reissueDate",
      text: "Republished",
      sort: true,
      headerFormatter: statusDashboardHeaderFormatter,
      sortCaret: statusDashboardSortCaret,
      formatter: statusDashboardReissueFormatter,
    },
    {
      dataField: "dueDate",
      text: "Deadline",
      sort: true,
      headerFormatter: statusDashboardHeaderFormatter,
      sortCaret: statusDashboardSortCaret,
      formatter: statusDashboardDatePickerFormatter,
    },
    {
      dataField: "submitDate",
      text: "CLT / Country Costing Submitted",
      sort: true,
      headerFormatter: statusDashboardHeaderFormatter,
      sortCaret: statusDashboardSortCaret,
      formatter: statusDashboardSubmittedFormatter,
    },
    {
      dataField: "approvedDate",
      text: "CLT / Country Approval Submitted",
      sort: true,
      headerFormatter: statusDashboardHeaderFormatter,
      sortCaret: statusDashboardSortCaret,
      formatter: statusDashboardApprovedFormatter,
    },
    {
      dataField: "hasQueries",
      text: "CLT / Country Queries Raised",
      sort: true,
      headerFormatter: statusDashboardHeaderFormatter,
      sortCaret: statusDashboardSortCaret,
      formatter: statusDashboardSelectFormatter,
    },
    {
      dataField: "isLocked",
      text: "Lock",
      sort: true,
      headerFormatter: statusDashboardHeaderFormatter,
      sortCaret: statusDashboardSortCaret,
      formatter: (
        cell: string,
        row: APIClusterInputSheetsStatus,
        rowIndex: number
      ) => (
        <StatusDashboardCheckbox
          disabled={project?.isLocked || !hasEditPermissions}
          clusterInputSheetsStatus={row}
          index={rowIndex}
        />
      ),
    },
  ];

  return (
    <BootstrapTableWrapper>
      <BootstrapTable
        classes="sd-table"
        headerClasses="sd-table-header"
        rowClasses="sd-table-row"
        keyField="id"
        bordered={false}
        data={data}
        columns={columns}
        defaultSorted={[{ dataField: "clusterName", order: "asc" }]}
        noDataIndication={() => "No Clusters"}
      />
    </BootstrapTableWrapper>
  );
};

export default observer(StatusDashboardTable);
