import React, { useCallback, useState } from "react";
import { FormCheck as BSFormCheck } from "react-bootstrap";
import styled from "styled-components";
import { APIClusterInputSheetsStatus } from "../../api/types";
import { useStore } from "../../store";
import { Toast } from "../../utils";
import { confirmCLTLock } from "../../utils/confirm";
import { useProject } from "../Workspace/hooks";

type Props = {
  clusterInputSheetsStatus: APIClusterInputSheetsStatus;
  index: number;
  disabled?: boolean;
};

const FormCheck = styled(BSFormCheck)`
  display: inline-block;

  .form-check-input {
    box-sizing: border-box;
    height: 0.063rem;
    opacity: 0;
    position: absolute;
    width: 0.063rem;

    &:checked {
      & + .form-check-label {
        &::before {
          border-color: #000000;
        }
        &::after {
          top: 0.063rem;
          width: 0.313rem;
          height: 0.625rem;
          border: solid #000000;
          border-width: 0 0.125rem 0.125rem 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }

    &:disabled + .form-check-label {
      &::after,
      &::before {
        border-color: #dadada;
      }
    }
  }
  .form-check-label {
    cursor: pointer;
    user-select: none;
    vertical-align: middle;

    &::before {
      background-color: #fff;
      border: 0.125rem solid #dadada;
      box-sizing: border-box;
      content: "";
      height: 0.844rem;
      left: 0;
      position: absolute;
      top: 0;
      width: 0.844rem;
    }
    &::after {
      box-sizing: border-box;
      content: "";
      height: 0.625rem;
      left: 0.313rem;
      position: absolute;
      top: 0.938rem;
      transform: scale(0);
      width: 0.625rem;
    }
  }
`;

const StatusDashboardCheckbox: React.FC<Props> = ({
  clusterInputSheetsStatus,
  index,
  disabled,
}) => {
  const store = useStore();
  const project = useProject();
  const [checked, setChecked] = useState<boolean>(
    clusterInputSheetsStatus.isLocked || false
  );

  const handleChange = useCallback(
    async (value: boolean) => {
      if (!clusterInputSheetsStatus) {
        return;
      }
      if (!project) {
        return;
      }

      const { id, clusterName, issueDate } = clusterInputSheetsStatus;
      const { versionNumber } = project;

      if (value) {
        // TODO: versioning
        if (await confirmCLTLock(clusterName, issueDate, versionNumber)) {
          try {
            await store.clusterInputSheetsStatus.lock(id);
          } catch (e) {
            Toast.danger(`CLT lock failed`);
            return;
          }
          setChecked(value);
          Toast.success(`CLT locked`);
        }
      } else {
        try {
          await store.clusterInputSheetsStatus.unlock(id);
        } catch (e) {
          Toast.danger(`CLT unlock failed`);
          return;
        }
        setChecked(value);
        Toast.success(`CLT unlocked`);
      }

      try {
        await store.projects.detail(project.id);
      } catch (e) {
        Toast.danger("Project could not be updated.");
      }
    },
    [project, clusterInputSheetsStatus, store]
  );

  return (
    <FormCheck>
      <FormCheck.Input
        disabled={disabled}
        type="checkbox"
        id={`is-locked_${index}`}
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.checked)
        }
      />
      <FormCheck.Label htmlFor={`is-locked_${index}`}></FormCheck.Label>
    </FormCheck>
  );
};

export default StatusDashboardCheckbox;
