import {
  model,
  Model,
  prop_mapObject,
  _await,
  modelFlow,
  _async,
  modelAction,
} from "mobx-keystone";
import api from "../api";
import {
  APIProjectVersion,
  APIProjectVersionListSearchParams,
} from "../api/projectVersions";
import ProjectVersion from "./models/ProjectVersion";
import ProjectVersionFilter from "./models/ProjectVersionFilter";

@model("collab/ProjectVersionStore")
class ProjectVersionStore extends Model({
  listItems: prop_mapObject(() => new Map<number, ProjectVersion>()),
  filters: prop_mapObject(() => new Map<identifier, ProjectVersionFilter>()),
}) {
  @modelAction
  saveListItem(item: APIProjectVersion) {
    const listItem = new ProjectVersion(item);
    this.listItems.set(item.id, listItem);
    return listItem;
  }

  @modelFlow
  list = _async(function* (
    this: ProjectVersionStore,
    page?: number,
    limit?: number,
    searchParams?: APIProjectVersionListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.projectVersions.list(page, limit, searchParams));
    if (page === 1) {
      this.listItems.clear();
    }
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  ofProject(projectId: identifier) {
    let pastVersions = Array.from(this.listItems.values());

    const filter = this.filters.get(projectId);
    if (filter) {
      pastVersions = pastVersions.filter((item) => {
        if (item.id < 0) {
          return true;
        }

        let out = true;
        if (filter.selectedClusterIds.length > 0) {
          if (Array.isArray(item.clusters)) {
            out =
              out &&
              item.clusters.filter(
                (x) => filter.selectedClusterIds.indexOf(x) >= 0
              ).length > 0;
          } else {
            out =
              out &&
              (item.clusters as string)
                .split(",")
                .filter(
                  (x) => filter.selectedClusterIds.indexOf(parseInt(x, 10)) >= 0
                ).length > 0;
          }
        }
        return out;
      });
    }
    return pastVersions;
  }

  @modelAction
  createFilters(projectId: identifier) {
    if (!this.filters.has(projectId)) {
      this.filters.set(projectId, new ProjectVersionFilter({}));
    }
  }
}

export default ProjectVersionStore;
