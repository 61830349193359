import React from "react";

export interface CsvValidationErrorContextProps {
  state: {
    errorCount: Record<string, number>;
    warningCount: Record<string, number>;
    errorKeys: Record<string, Set<string>>;
    columnNameMaps: Record<string, Record<string, string>>;
  };
  setters: {
    setErrorCount: React.Dispatch<React.SetStateAction<Record<string, number>>>;
    setWarningCount: React.Dispatch<
      React.SetStateAction<Record<string, number>>
    >;
    setErrorKeys: React.Dispatch<
      React.SetStateAction<Record<string, Set<string>>>
    >;
    setColumnNameMaps: React.Dispatch<
      React.SetStateAction<Record<string, Record<string, string>>>
    >;
  };
  display: {
    errorCount: number;
    warningCount: number;
    errorKeys: Set<string>;
    validationColumnNameMap: Record<string, string>;
  };
}

export const CsvValidationErrorContext =
  React.createContext<CsvValidationErrorContextProps>({
    state: {
      errorCount: {},
      warningCount: {},
      errorKeys: {},
      columnNameMaps: {},
    },
    setters: {
      setErrorCount: () => {},
      setWarningCount: () => {},
      setErrorKeys: () => {},
      setColumnNameMaps: () => {},
    },
    display: {
      errorCount: 0,
      warningCount: 0,
      errorKeys: new Set<string>(),
      validationColumnNameMap: {},
    },
  });
