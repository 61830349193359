import React from "react";
import { LockOutlined } from "@material-ui/icons";
import styled from "styled-components";

type Props = {};
const ProjectLockedText = styled.div`
  span {
    color: #484848;
    font-weight: 600;
    font-size: 0.875rem;
  }

  svg {
    stroke: #484848;
    margin-right: 0.313rem;
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const ProjectLockedLabel: React.FC<Props> = () => {
  return (
    <ProjectLockedText>
      <LockOutlined /> <span>This project is locked</span>
    </ProjectLockedText>
  );
};

export default ProjectLockedLabel;
