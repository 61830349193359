import React from "react";
import Loading from "../../components/Loading";
import WorkArea from "../WorkArea";
import { useProject } from "../Workspace/hooks";
import { TabList } from "../Workspace/types";
import ActivityFeed from "./ActivityFeed";
import StatusDashboard from "./StatusDashboard";
import TenderOutcome from "./TenderOutcome";

type Props = {};

const tabs: TabList = {
  statusDashboard: {
    kind: "custom",
    id: "statusDashboard",
    title: "Status Dashboard",
    type: "sheet-status",
    render: StatusDashboard,
  },
  activityFeed: {
    kind: "custom",
    id: "activityFeed",
    title: "Activity Feed",
    type: "sheet-status",
    render: ActivityFeed,
  },
  tenderOutcome: {
    kind: "custom",
    id: "tenderOutcome",
    title: "Tender Outcome",
    type: "sheet-status",
    render: TenderOutcome,
  },
};

const StatusSection: React.FC<Props> = () => {
  const project = useProject();

  if (!project) {
    return <Loading full />;
  }

  return <WorkArea tabs={tabs} />;
};

export default StatusSection;
