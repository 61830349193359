import { CSVFrozenHeaderMapping, CSVHeaderMapping } from ".";
import { scaledChars as _ } from "../../utils";

export const mappingRMWorking: CSVHeaderMapping = {
  id: ["ID", 0],
  active: ["Active", _(8)],
  source_clt: ["Source CLT", _(12)],
  plant: ["Source\nPlant", _(12)],
  supplier_product_name: ["Supplier\nProduct Name", _(30)],
  r_code: ["R Code", _(10)],
  sap_no: ["SAP No", _(10)],
  supplier: ["Supplier", _(15)],
  sap_stock_location: ["SAP Stock Location (if reqd)", _(18)],
  supply_country: ["Supply\nLocation/Ctry", _(13)],
  inco_term: ["Inco\nTerm", _(10)],
  bulk_or_drum: ["Bulk/\nDrum", _(12)],
  currency_of_purchase: ["Purchase\nCurrency", _(12)],
  rm_purchase_price: ["RM\nPrice", _(10)],
  supplier_freight: ["RM Supplier\nFreight", _(12)],
  drumming_differential_per_mt: ["Drumming\nDifferential", _(12)],
  unit_of_measure: ["Unit of\nMeasure", _(12)],
  price_obtained_from: ["Price\nObtained From", _(15)],
  rebates_per_mt: ["Rebates", _(12)],
  discounts_per_mt: ["Discounts", _(12)],
  freight_cost: ["bp Freight/\nTaxes/Duties", _(12)],
  cost_per_mt: ["Original\nCurrency", _(12)],
  cost_per_mt_spec_curr: ["Cost in Specified\nCurrency", _(15)],
};

export const mappingMarginalCostPctCountry: CSVHeaderMapping = {
  id: ["ID", 0],
  dest_country: ["Destination Country", _(25)],
  percent_3p_storage_handling: [
    "Storage & Handling (3rd Party) % - costs incurred by Destination Country",
    _(25),
  ],
  percent_internal_storage_handling: [
    "Storage & Handling (Internal) % - for Imports Only",
    _(25),
  ],
};

export const mappingMarginalCostPctPlant: CSVHeaderMapping = {
  plant_name: ["Plant", _(25)],
  plant: ["Plant Model Acronym", _(25)],
  percent_yield_loss: ["Yield Loss %", _(25)],
  percent_internal_blending: ["Internal Blending %", _(25)],
  percent_internal_filling: ["Internal Filling %", _(25)],
  percent_cogs_efficiency_savings: [
    "COGS Efficiency Savings % (Not Underpinned)",
    _(25),
  ],
  percent_internal_primary_transport: ["Primary Transport (Internal) %", _(25)],
  percent_3p_storage_handling: [
    "Storage & Handling (3rd Party) % - costs incurred by Source Country",
    _(25),
  ],
  percent_internal_storage_handling: ["Storage & Handling (Internal) %", _(25)],
  percent_other_bp_manufacturing_cost: ["Other bp Manufacturing Cost %", _(25)],
};

export const mappingOHPackMfg: CSVHeaderMapping = {
  id: ["ID", 0],
  active: ["Active?", _(8)],
  source_plant: ["Source Plant", _(12)],
  source_clt: ["Source CLT", _(0)],
  repackaging_plant: ["Repack Plant\n(if appl)", _(12)],
  repackaging_clt: ["Repack CLT", _(0)],
  pack_size_from_source: ["Source Pack Size\n(if Repack Reqd)", _(16)],
  pack_size: ["Pack Size\nto Offer", _(15)],
  formulation_code: ["Formulation\nCode", _(10)],
  pack_type: ["Pack Type\n(if reqd)", _(9)],
  volume_yr1: ["Volume\n(Yr1)", _(9)],
  alt_scenario: ["Alt\nScenario", _(9)],
  alt_sku_manufacturing: ["Alt SKU\nfor PCTP", _(10)],
  percent_yield_loss: ["Yield\nLoss %", _(12)],
  yield_loss: ["Yield\nLoss", _(12)],
  packaging_labelling_cost: ["Std Pack/Label\n(Source CLT)", _(12)],
  packaging_labelling_cost_repacking_country: [
    "Std Pack/Label\n(Repack CLT)",
    _(12),
  ],
  specific_packaging_labelling_cost: [
    "Specific Pack/Label\n(Source CLT)",
    _(12),
  ],
  specific_packaging_labelling_cost_repacking_country: [
    "Specific Pack/Label\n(Repack CLT)",
    _(12),
  ],
  decanting_cost: ["Decanting/\nRepackaging", _(12)],
  "3p_toll_blending_cost": ["3P Blending/\nFilling", _(12)],
  bp_blending_fee: ["bp Blending", _(12)],
  bp_filling_fee: ["bp Filling", _(12)],
  depreciation: ["Depreciation\n(Addnl Capex)", _(14)],
  other_bp_manufacturing_cost: ["Other bp\nMfg Costs", _(12)],
  br_icms_tax: ["Brazil ICMS/\nOil Collect Tax", _(15)],
  my_sales_tax: ["Malaysia\nSales Tax %", _(12)],
  group_purchase: ["Group\nPurchase", _(12)],
  "3p_purchase": ["3P Purchase", _(12)],
  percent_cogs_efficiency_savings: ["COGS Efficiency\nSavings %", _(16)],
};

export const mappingOHLogs: CSVHeaderMapping = {
  id: ["ID", 0],
  active: ["Active?", _(8)],
  delivery_location: ["Delivery\nLocation", _(15)],
  dest_country: ["Dest\nCountry", _(12)],
  destination_clt: ["Dest\nCLT", _(10)],
  source_plant: ["Source\nPlant", _(12)],
  source_clt: ["Source\nCLT", _(12)],
  repackaging_plant: ["Repack Plant\n(if appl)", _(12)],
  repackaging_clt: ["Repack\nCLT", _(15)],
  delivery_terms: ["Delivery\nTerms", _(8)],
  pack_size_from_source: ["Source Pack Size\n(if Repack Reqd)", _(16)],
  pack_size: ["Pack Size\nto Offer", _(15)],
  volume_yr1: ["Volume\n(Yr1)", _(9)],
  alt_scenario: ["Alt\nScenario", _(9)],
  rtm: ["RTM", _(16)],
  warehouse_code: ["WH/Plant\nCode", _(8)],
  alt_sku_logistics: ["Alt SKU\nfor PCTP", _(8)],
  percent_formulation_cost: ["Import taxes/duties\n(% of Formulation)", _(20)],
  percent_ex_plant_cost: ["Import taxes/duties\n(% of Ex Plant)", _(20)],
  percent_ex_plant_import_cost: [
    "Import taxes/duties\n(% of Ex Plant + Import)",
    _(24),
  ],
  extra_freight_cost_source_country: [
    "Import freight/taxes/duties\n(Source CLT)",
    _(27),
  ],
  extra_freight_cost_repacking_country: [
    "Import freight/taxes/duties\n(Repack CLT)",
    _(27),
  ],
  extra_freight_cost_destination_country: [
    "Import freight/taxes/duties\n(Dest CLT)",
    _(27),
  ],
  "3p_primary_transport_cost_source_country": [
    "3P Primary Trans (Source CLT) %",
    _(16),
  ],
  "3p_primary_transport_cost_repacking_country": [
    "3P Primary Trans\n(Repack CLT)",
    _(16),
  ],
  "3p_primary_transport_cost_destination_country": [
    "3P Primary Trans\n(Dest CLT)",
    _(16),
  ],
  internal_primary_transport_cost: ["bp Primary Trans\n(Europe Only)", _(16)],
  "3p_storage_handling_cost_source_country": ["3P S&H\n(Source CLT)", _(16)],
  "3p_storage_handling_cost_repacking_country": ["3P S&H\n(Repack CLT)", _(16)],
  "3p_storage_handling_cost_destination_country": ["3P S&H\n(Dest CLT)", _(16)],
  internal_storage_cost_source_country: ["bp S&H\n(Source CLT)", _(12)],
  internal_storage_cost_repacking_country: ["bp S&H\n(Repack CLT)", _(12)],
  internal_storage_cost_destination_country: ["bp S&H\n(Dest CLT)", _(12)],
  logistics_specific_cost: ["Logistics\nSpecific Reqts", _(14)],
  logistics_secondary_cost: ["Secondary\nLogistics", _(12)],
  cn_construction_tax: ["Consumption\nTax (China)", _(12)],
  th_import_sales_tax: ["Import Sales\nTax (Thailand)", _(14)],
};

export const mappingCapex: CSVHeaderMapping = {
  id: ["ID", 0],
  "#": ["#", _(25)],
  capex_asset_required: ["Capex Asset Required", _(25)],
  quantity: ["Quantity", _(25)],
  "useful_life_assumed_(years)": ["Useful Life Assumed (Years)", _(25)],
  plant: ["Plant", _(25)],
  "formulation/raw_material/pack_size_required_for": [
    "Formulation/Raw Material/Pack Size Required For",
    _(25),
  ],
  total_capex_amount_usd: ["Total Capex Amount (USD)", _(25)],
  "%_of_capex_applied_to_this_tender": [
    "% of Capex Applied to this Tender",
    _(25),
  ],
};

export const getFrozenMappingRMWorking = (
  frozen: boolean
): CSVFrozenHeaderMapping => {
  return {
    active: frozen,
    source_clt: frozen,
    plant: frozen,
    supplier_product_name: frozen,
    r_code: frozen,
    sap_no: frozen,
  };
};

export const getFrozenMappingOHLogs = (
  frozen: boolean
): CSVFrozenHeaderMapping => {
  return {
    active: frozen,
    delivery_location: frozen,
    dest_country: frozen,
    destination_clt: frozen,
    source_plant: frozen,
    repackaging_plant: frozen,
    delivery_terms: frozen,
    pack_size_from_source: frozen,
    pack_size: frozen,
    volume_yr1: frozen,
    alt_scenario: frozen,
  };
};

export const getFrozenMappingOHMfg = (
  frozen: boolean
): CSVFrozenHeaderMapping => {
  return {
    active: frozen,
    source_plant: frozen,
    repackaging_plant: frozen,
    pack_size_from_source: frozen,
    pack_size: frozen,
    formulation_code: frozen,
    pack_type: frozen,
    volume_yr1: frozen,
    alt_scenario: frozen,
  };
};
