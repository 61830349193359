import { useCallback } from "react";

export * from "./auth";
export * from "./files";
export * from "./toast";

export const oneRem = parseFloat(
  getComputedStyle(document.documentElement).fontSize
);

/**
 * Converts the given number of pixels to rems.
 * The given will first be converted to rems using 1 rem = 16 px and then
 * scaled to whatever 1 rem in the document currently is.
 *
 * @param px the number in pixels to convert to rems.
 * @returns the number in rems.
 */
export const scaledRem = (px: number) => (px / 16) * oneRem;

export const scaledChars = (charCount: number) => scaledRem(charCount * 8) + 20;

export function useCombinedRefs<T>(...refs: readonly React.Ref<T>[]) {
  return useCallback(
    (handle: T | null) => {
      for (const ref of refs) {
        if (typeof ref === "function") {
          ref(handle);
        } else if (ref !== null) {
          // @ts-expect-error: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065
          ref.current = handle;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs
  );
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const epsilonRound = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};