import clsx from "clsx";
import React from "react";
import styled from "styled-components";
import ProjectVersion from "../../store/models/ProjectVersion";
import Label from "../Label";

type Props = {
  version: ProjectVersion;
  current?: boolean;
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.563rem;
  background: #fdfdfd;
  border: 0.063rem solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 1.25rem 0.938rem;

  &.current {
    background: #484848;
    color: #ffffff;

    svg {
      stroke: #ffffff;
    }
  }
`;

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardTitle = styled.h1`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  margin-bottom: 0;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.938rem;
`;

const CardUpdated = styled(Label)`
  margin-bottom: 0;

  &::before {
    content: "by ";
  }

  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: none;
  opacity: 0.4;
`;

const CardText = styled.div`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-bottom: 0;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const VersionCard: React.FC<Props> = ({ version, current }) => {
  return (
    <Card className={clsx({ current })}>
      <CardHead>
        <CardTitle>{version.versionNumber}</CardTitle>
      </CardHead>
      <CardBody>
        <CardUpdated>{version.creatorName}</CardUpdated>
        <CardText dangerouslySetInnerHTML={{ __html: version.notes || "" }} />
      </CardBody>
    </Card>
  );
};

export default VersionCard;
