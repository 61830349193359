import clsx from "clsx";
import { useComments } from "components/Comments/hooks";
import { observer } from "mobx-react-lite";
import Worksheet from "pages/Worksheet";
import { useProject } from "pages/Workspace/hooks";
import { CustomSheet, Worksheet as WorksheetType } from "pages/Workspace/types";
import React from "react";
import styled from "styled-components";
import CommentsPanel from "./CommentsPanel";

type Props = {
  tab: WorksheetType | CustomSheet;
};

const Container = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  height: calc(100vh - 7rem);

  &.show {
    margin-right: 18.75rem;
  }
`;

const ContentComments: React.FC<Props> = ({ tab }) => {
  const { show } = useComments();
  const project = useProject();

  return (
    <Container key={tab.id}>
      <Content className={clsx({ show })}>
        {tab.kind === "worksheet" ? (
          <Worksheet tab={tab} />
        ) : (
          <tab.render tab={tab} />
        )}
      </Content>
      {project && project.showComments ? <CommentsPanel tab={tab} /> : null}
    </Container>
  );
};

export default observer(ContentComments);
