import clsx from "clsx";
import DataGrid from "components/DataGrid";
import { useCsvToGridDataAsync } from "components/DataGrid/hooks";
import { getNumberFormatter } from "components/DataGrid/NumberFormatter";
import { numberParser } from "components/DataGrid/parsers";
import {
  ColumnExtraOptions,
  DataGridHandleWithData,
  RangeSelection,
  ValueParsers
} from "components/DataGrid/types";
import { CsvValidationErrorContext } from "components/ValidationDashboard/contexts";
import { IRow } from "csv/types";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from "react";
import { RowsChangeData } from "react-data-grid";
import { useStore } from "store";
import { scaledRem, Toast } from "utils";

type Props = {
  editable: boolean;
  isSheetActive: boolean;
  onSheetActive: (sheet: string) => void;
};

const blankValidations = ["description"];
const labels = ["id", "rcode", "description", "crdStatus"];

const NumberFormatter = getNumberFormatter(2, 4, true);

const FormulationsTabBaseOils: React.FC<Props> = ({
  editable,
  isSheetActive,
  onSheetActive,
}) => {
  const store = useStore();
  const project = useProject();
  const ref = useRef<DataGridHandleWithData>(null);
  const validationSummary = useContext(CsvValidationErrorContext);

  const schema = useCallback(
    (key: string, sheetEditable?: boolean): ColumnExtraOptions<IRow> => {
      const defaults: ColumnExtraOptions<IRow> = {
        cellClass: sheetEditable ?? true ? undefined : "cell-readonly",
      };
      switch (key) {
        case "id":
          return {
            ...defaults,
            editable: false,
            formatter: ({ rowIdx }) => <>{rowIdx + 1}</>,
            frozen: true,
            name: "",
            resizable: false,
          };
        case "rcode":
          return {
            ...defaults,
            name: "R Code",
            resizable: false,
            frozen: true,
            width: scaledRem(111),
          };
        case "description":
          return {
            ...defaults,
            name: "RM Desc",
            resizable: false,
            frozen: true,
            width: scaledRem(111),
          };
        case "crdStatus":
          return {
            ...defaults,
            name: "CRD Status",
            resizable: false,
            frozen: true,
            width: scaledRem(111),
          };
      }
      return {
        ...defaults,
        formatter: NumberFormatter,
        cellClass: () => {
          if (!sheetEditable) {
            return clsx("cell-numeric", { "cell-readonly": !sheetEditable });
          }
          const formulation = store.formulations.listItems.get(
            parseInt(key, 10)
          );
          if (formulation) {
            return clsx("cell-numeric", {
              "cell-readonly": formulation.isCltLocked,
            });
          }
          return undefined;
        },
        editable: () => {
          if (!sheetEditable) {
            return sheetEditable ?? true;
          }
          const formulation = store.formulations.listItems.get(
            parseInt(key, 10)
          );
          if (formulation) {
            return !formulation.isCltLocked;
          }
          return false;
        },
        name: "\xa0", // Insert non-breaking space so column select keeps working.
        resizable: false,
        width: scaledRem(123),
      };
    },
    [store.formulations.listItems]
  );

  const csvString = project ? store.formulations.baseOilsAsCsv : "";
  const [columns, rows, loaded] = useCsvToGridDataAsync({
    csvString,
    editable,
    schema: schema,
  });

  // Iterate through each base oil row
  const baseOilsErrors = useMemo(() => {
    const errors: Record<string, string> = {};
    rows.forEach((item) => {
      // Monitor if the base oil row has a treat rate filled in, and if a validation error will be added (defaults are false)
      let hasTreatRate = false;
      let boValidationError = false;
      let cellKey = "";
      // Iterate through each column in that base oil row
      columns.forEach((columnItem) => {
        // Check whether the item is included in the list of columns that need to have a value
        if (blankValidations.includes(`${columnItem.key}`)) {
          // If the cell is blank, raise an error
          if (item[columnItem.key] === "") {
            boValidationError = true;
            cellKey = `${columnItem.key}-${item.id}`;
          }
          // Check whether the item is a treat rate column
        } else if (!labels.includes(`${columnItem.key}`)) {
          // If the cell has a value, set the hasTreatRate variable to true
          if (item[columnItem.key] !== "") {
            hasTreatRate = true;
          }
        }
      });
      // If a treat rate was filled in for that particular base oil row
      // update the errors variable with the error codes found in that row (if any)
      // Else, no treat rates were filled up. Discard any error codes found (if any)
      if (hasTreatRate && boValidationError) {
        errors[cellKey] = "E:This field cannot be left blank";
      } else if (boValidationError) {
        errors[cellKey] =
          "E:This row has no treat rate data. Add treat rates or delete this row.";
      }
    });
    return errors;
  }, [columns, rows]);

  const baseOilsValueParsers = useMemo(() => {
    const valueParsers: ValueParsers = {};
    columns.forEach((columnItem) => {
      if (!labels.includes(columnItem.key)) {
        valueParsers[columnItem.key] = numberParser;
      }
    });
    return valueParsers;
  }, [columns]);

  useEffect(() => {
    const newErrorKeys = new Set<string>();
    const newColumnNameMap: Record<string, string> = {};

    const errorSize = Object.keys(baseOilsErrors).length;

    validationSummary.setters.setErrorCount((prev) => ({
      ...prev,
      baseOils: errorSize,
    }));

    if (errorSize > 0) {
      newErrorKeys.add("baseOils-E002");
      newColumnNameMap["baseOils"] = "Base Oils";
    }

    validationSummary.setters.setErrorKeys((prev) => ({
      ...prev,
      baseOils: newErrorKeys,
    }));
    validationSummary.setters.setColumnNameMaps((prev) => ({
      ...prev,
      baseOils: newColumnNameMap,
    }));
  }, [baseOilsErrors, validationSummary.setters]);

  const handleAdd = useCallback(
    (numRows: number) => {
      if (!project) {
        return;
      }
      for (let i = 0; i < numRows; i++) {
        store.formulations.createBaseOil();
      }
      store.formulations.commitBaseOilsCsv(project.id);
    },
    [project, store.formulations]
  );

  const handleRowsChange = useCallback(
    (rows: IRow[], data?: RowsChangeData<IRow, unknown>) => {
      if (!project) {
        return;
      }
      if (!data) {
        return;
      }

      const { key, idx } = data.column; // key = id of formulation or field name
      const { indexes } = data;

      const formulation = store.formulations.listItems.get(parseInt(key, 10));

      indexes.forEach((index) => {
        const { id } = rows[index]; // id = id of base oil
        const baseOil = store.formulations.baseOilListItems.get(
          parseInt(id as string, 10)
        );
        if (baseOil) {
          if (idx >= 4) {
            // Editing a percentage.
            formulation?.setBaseOilPercentage(
              baseOil.id,
              rows[index][key] as string
            );
          } else {
            // Editing the base oil itself.
            baseOil?.setValue(key, rows[index][key]);

            // Set percentages on all formulations for "new" base oil
            if (baseOil) {
              store.formulations.listItems.forEach((formulation) => {
                // Set the percentage on the current percentage cell (index, formulation.id) as the percentage of the new base oil
                formulation.setBaseOilPercentage(
                  baseOil.id,
                  rows[index][formulation.id] as string
                );
              });
            }
          }
        }
      });

      store.formulations.commitBaseOilsCsv(project.id);
      project.setDirty();
    },
    [project, store.formulations]
  );

  const handleDelete = useCallback(
    async (
      event: React.TouchEvent<HTMLDivElement>,
      data: { filteredRows: readonly IRow[]; range: RangeSelection }
    ) => {
      if (!project) {
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      if (!confirm("Are you sure you want to delete the selected base oils?")) {
        return;
      }

      const {
        filteredRows,
        range: { start, end },
      } = data;

      project.setLoading("Deleting selected base oils...");

      const promises: Promise<void>[] = [];
      for (let i = start.rowIdx; i <= end.rowIdx; i++) {
        const baseOilId = filteredRows[i].id;
        if (!baseOilId) {
          return;
        }
        promises.push(
          new Promise((resolve, reject) => {
            try {
              store.formulations.deleteBaseOil(
                project.id,
                parseInt(baseOilId as string, 10)
              );
            } catch (e) {
              reject(e);
              return;
            }
            resolve();
          })
        );
      }

      try {
        await Promise.allSettled(promises);
      } catch (e) {
        Toast.danger("Some base oils were not deleted.");
        return;
      } finally {
        store.formulations.commitCsv(project.id);
        project?.setNotLoading();
      }
      Toast.success("Selected base oils have been deleted.");
      project?.setDirty();
    },
    [project, store.formulations]
  );

  if (!loaded) {
    return null;
  }

  return (
    <DataGrid
      ref={ref}
      id="baseOils"
      isActive={isSheetActive}
      multiple
      title="Base Oils"
      defaultColumnOptions={{ resizable: false }}
      rows={rows}
      maxRowsShown="auto"
      columns={columns}
      onActive={onSheetActive}
      onAddRows={editable ? handleAdd : undefined}
      onRowsChange={handleRowsChange}
      valueParsers={baseOilsValueParsers}
      errors={baseOilsErrors}
      rowIdentifier="id"
      extraContextMenu={(filteredRows, selected, range) => [
        {
          id: "deleteRows",
          label: "Delete selected base oils…",
          onClick: handleDelete,
          data: { filteredRows, range },
        },
      ]}
    />
  );
};

export default observer(FormulationsTabBaseOils);
