import dropdownEditorFactory from "components/DataGrid/DropdownEditor";
import { ColumnExtraOptions } from "components/DataGrid/types";
import { mappingCapex } from "csv/mappings/collect";
import { IRow } from "csv/types";
import { observer } from "mobx-react-lite";
import React from "react";
import { EditorProps } from "react-data-grid";
import { useStore } from "store";
import { getInputCell, getNumberCell, idCell } from "./utils";

function capexPlantEditorFactory(key: string) {
  function CapexPlantEditor(props: EditorProps<IRow>) {
    const store = useStore();
    const Dropdown = dropdownEditorFactory(
      key,
      store.assets.plantSelect.map((option) => ({
        label: option.label,
        value: option.name,
      }))
    );
    return <Dropdown {...props} />;
  }
  return observer(CapexPlantEditor);
}

const schemaCapex = (key: string, sheetEditable?: boolean) => {
  const defaultCell = {
    name: mappingCapex[key] ? mappingCapex[key][0] : key,
    width: mappingCapex[key] ? mappingCapex[key][1] : 50,
  };
  const inputCell = {
    ...defaultCell,
    ...getInputCell(sheetEditable ?? true),
  };
  const numberCell = {
    ...defaultCell,
    ...getNumberCell(sheetEditable ?? true, 0, 0),
  };
  const numberCellPercentage = {
    ...defaultCell,
    ...getNumberCell(sheetEditable ?? true, 0, 0, true),
  };

  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: idCell,
    capex_asset_required: {
      ...inputCell,
      editor: (props) => {
        const Dropdown = dropdownEditorFactory(key, [
          {
            value: "New RM Tank",
            label: "New RM Tank",
          },
          {
            value: "New FG Tank",
            label: "New FG Tank",
          },
          {
            value: "New Filling Line",
            label: "New Filling Line",
          },
          {
            value: "Filling Line Modification",
            label: "Filling Line Modification",
          },
          {
            value: "Bottle Mould",
            label: "Bottle Mould",
          },
          {
            value: "Cap Mould",
            label: "Cap Mould",
          },
          {
            value: "Other",
            label: "Other",
          },
        ]);
        return <Dropdown {...props} />;
      },
      editorOptions: {
        editOnClick: true,
      },
    },
    quantity: {
      ...numberCell,
      editor: (props) => {
        const Dropdown = dropdownEditorFactory(key, [
          {
            value: 1,
            label: "1",
          },
          {
            value: 2,
            label: "2",
          },
          {
            value: 3,
            label: "3",
          },
          {
            value: 4,
            label: "4",
          },
          {
            value: 5,
            label: "5",
          },
        ]);
        return <Dropdown {...props} />;
      },
      editorOptions: {
        editOnClick: true,
      },
    },
    "useful_life_assumed_(years)": {
      ...numberCell,
    },
    plant: {
      ...inputCell,

      editor: capexPlantEditorFactory(key),
      editorOptions: {
        editOnClick: true,
      },
    },
    total_capex_amount_usd: {
      ...numberCell,
    },
    "%_of_capex_applied_to_this_tender": {
      ...numberCellPercentage,
    },
  };
  return options[key] || inputCell;
};

export default schemaCapex;
