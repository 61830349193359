import { CSVFrozenHeaderMapping, CSVHeaderMapping } from ".";
import { scaledChars as _ } from "../../utils";
import {
  getFrozenMappingOHLogs,
  getFrozenMappingOHMfg,
  getFrozenMappingRMWorking,
  mappingCapex,
  mappingMarginalCostPctCountry,
  mappingMarginalCostPctPlant,
  mappingOHLogs,
  mappingOHPackMfg,
  mappingRMWorking,
} from "./collect";
import { getFrozenMappingCostingOutput, mappingCostSheet } from "./output";

export const getFrozenMapping = (
  fileType: string,
  frozen: boolean
): CSVFrozenHeaderMapping => {
  if (fileType === "oh_full_logs") return getFrozenMappingOHLogs(frozen);
  if (fileType === "oh_full_pack_mfg") return getFrozenMappingOHMfg(frozen);
  if (fileType === "rm_working") return getFrozenMappingRMWorking(frozen);
  if (fileType === "costing_sheet")
    return getFrozenMappingCostingOutput(frozen);
  else return { id: true };
};

export const getMapping = (fileType: string): CSVHeaderMapping => {
  if (fileType === "oh_full_logs") return mappingOHLogs;
  if (fileType === "oh_full_pack_mfg") return mappingOHPackMfg;
  if (fileType === "oh_marg_logs") return mappingOHLogs;
  if (fileType === "oh_marg_pack_mfg") return mappingOHPackMfg;
  if (fileType === "rm_working") return mappingRMWorking;
  if (fileType === "marginal_cost_percent_country")
    return mappingMarginalCostPctCountry;
  if (fileType === "marginal_cost_percent_plants")
    return mappingMarginalCostPctPlant;
  if (fileType === "capex_summary") return mappingCapex;
  if (fileType === "costing_sheet") return mappingCostSheet;
  else return { id: ["ID", _(0)] };
};
