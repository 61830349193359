export const sheetErrorMap: Record<string, string> = {
  W000: "Warning Example",
  W001: "Must be positive",
  W002: "Destination country input is not present in the Formulations data",
  W003: "Input must be negative",
  // W004: "No input", Disable error display
  // W005: "Not blank", Disable error display
  // W006: "No input, provide reason ", Disable error display
  W007: "Destination country input is not present in the Marginal Costing Adjustment Destination data",
  W008: "Product specification is not present in the Formulations data",
  W009: "Input must be an integer",
  W010: "Not a numeric input",
  W011: "Value must be <= 100%",
  W012: "Must have a value",
  W013: "There must be values in some of the Manufacturing columns",
  W014: "There must be values in some of the Logistics columns",
  W015: "Input above maximum",
  W016: "Input must be blank or zero. Pack size is Bulk/Mini Bulk/ISO",

  W018: "Please populate",
  E001: "Invalid percent input",
  E002: "Must be populated",
  E003: "Not a Numeric input",
  E004: "Input must be a positive integer",
  E005: "Not a string input",
  E006: "Not a valid Delivery Terms value",
  E007: "Not a valid Bulk or Drum value",
  E008: "Not a valid Currency of Purchase",
  E009: "Not a valid Unit of Measurement",
  E010: "Not a valid currency",
  E011: "Must be a positive",
  E012: "Plant not found in Marginal Costing Adjusment Source",
  E013: "Plant not found in Marginal Costing Adjusment Destination",
  E014: "Not a boolean input",
  E015: "Not a valid pack size",
  E016: "Input below minimum",
  E017: "Input must be blank or zero. Pack size is Bulk/Mini Bulk/ISO",
  E018: "Costing Option ID does not match",
  E019: "formulations Treat rates must total 100% per formulation",
  E020: "Must be Base Oil or Additive",
  E021: "Raw Material ID does not match",
  E022: "Not all Formulations where taken account of",
  E023: "Invalid Project Type",
  E024: "No values under 'delivery_location', 'dest_country', 'source_plant', 'repackaging_plant', 'delivery_terms', and 'pack_size'",
  E025: "No values under 'source_plant', 'repackaging_plant', 'pack_size','formulation_id', and 'pack_type'",
  E026: "No match found in the formulations sheet for the 'product_spec', 'pack_size', and 'dest_country' combination",
  E027: "Input must be negative",
  E028: "Must be populated unless RMPA selected",
  E029: "Formulation CPL components does not match Formulation treat rates components",
  E030: "Not a valid 'Price Obtained From' value",
  E031: "Not a valid 'RTM' value",
  E032: "Did not match any raw materials from RMPA",
  E033: "Contains unclassified treat rate data",
};
