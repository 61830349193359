import { APICLTFile, APICLTFileSearchParams } from "api/cltFiles";
import { computed } from "mobx";
import {
  Model,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import api from "../api";
import CLTFile from "./models/CLTFile";

@model("collab/CLTFileStore")
class CLTFileStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, CLTFile>()),
}) {
  @computed
  get ofProject() {
    return (projectId: identifier) =>
      Array.from(this.listItems.values()).filter(
        (cltFile) => cltFile.project === projectId
      );
  }

  // For Delivery Tab files (Define section)
  @computed
  get ofCluster() {
    return (
      projectId: identifier,
      clusterId: identifier | undefined,
      define: boolean = false
    ) =>
      Array.from(this.listItems.values()).filter(
        (cltFile) =>
          cltFile.project === projectId &&
          cltFile.cluster === clusterId &&
          !cltFile.sheetsStatus &&
          !cltFile.inputSheet
      );
  }

  // For CLT Assumptions Tab files (Collect section)
  @computed
  get ofSheetsStatus() {
    return (sheetsStatus: identifier) =>
      Array.from(this.listItems.values()).filter(
        (cltFile) =>
          cltFile.sheetsStatus === sheetsStatus && !cltFile.inputSheet
      );
  }

  // For Input sheet reference files
  @computed
  get ofInputSheet() {
    return (inputSheet: identifier) =>
      Array.from(this.listItems.values()).filter(
        (cltFile) => cltFile.inputSheet === inputSheet
      );
  }

  @modelAction
  saveListItem(item: APICLTFile) {
    const listItem = new CLTFile(item);
    const existing = this.listItems.get(item.id);

    if (!existing) {
      this.listItems.set(item.id, listItem);
      return listItem;
    }
    return existing;
  }

  @modelFlow
  list = _async(function* (
    this: CLTFileStore,
    projectId: identifier,
    page?: number,
    limit?: number,
    searchParams?: APICLTFileSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(
      api.cltFiles.list(page, limit, {
        ...searchParams,
      })
    );

    // Clear cached items matching the search params
    Array.from(this.listItems.entries())
      .filter(([id, item]) => item.project === projectId)
      .forEach(([id]) => {
        this.listItems.delete(id);
      });

    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });
}
export default CLTFileStore;
