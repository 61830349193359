import {
  Model,
  _async,
  _await,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
} from "mobx-keystone";
import api from "../api";
import { APISearchParams } from "../api/base";
import {
  APIProjectCreateInput,
  APIProjectDetail,
  APIProjectDuplicateInput,
  APIProjectInput,
  APIProjectRenameInput,
} from "../api/projects";
import { APIProject, APIProjectMinimal } from "../api/types";
import Project from "./models/Project";
import ProjectMinimal from "./models/ProjectMinimal";

@model("collab/ProjectStore")
class ProjectStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, Project>()),
  listActiveItems: prop_mapObject(() => new Map<identifier, ProjectMinimal>()),
  listArchiveItems: prop_mapObject(() => new Map<identifier, ProjectMinimal>()),
  listFolderItems: prop_mapObject(() => new Map<identifier, ProjectMinimal>()),
}) {
  @modelAction
  saveListItem(item: APIProject) {
    const listItem = new Project(item);
    const existing = this.listItems.get(item.id);
    if (!existing) {
      this.listItems.set(item.id, listItem);
      if (listItem.isCostSheetPendingUpdate) listItem.setCostSheetDirty();
      return listItem;
    }
    existing.update(item);
    if (existing.isCostSheetPendingUpdate) existing.setCostSheetDirty();
    return existing;
  }

  @modelAction
  saveListActiveItem(item: APIProjectMinimal) {
    const listActiveItem = new ProjectMinimal(item);
    const existing = this.listActiveItems.get(item.id);
    if (!existing) {
      this.listActiveItems.set(item.id, listActiveItem);
      return listActiveItem;
    }
    existing.update(item);
    return existing;
  }

  @modelAction
  saveListArchivedItem(item: APIProjectMinimal) {
    const listArchiveItem = new ProjectMinimal(item);
    const existing = this.listArchiveItems.get(item.id);
    if (!existing) {
      this.listArchiveItems.set(item.id, listArchiveItem);
      return listArchiveItem;
    }
    existing.update(item);
    return existing;
  }

  @modelAction
  saveListFolderItem(item: APIProjectMinimal) {
    const listFolderItem = new ProjectMinimal(item);
    const existing = this.listFolderItems.get(item.id);
    if (!existing) {
      this.listFolderItems.set(item.id, listFolderItem);
      return listFolderItem;
    }
    existing.update(item);
    return existing;
  }

  @modelFlow
  listActive = _async(function* (
    this: ProjectStore,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.projects.list(page, limit, searchParams));
    this.listActiveItems.clear();
    const results = resultsRaw.map((item) => this.saveListActiveItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listArchived = _async(function* (
    this: ProjectStore,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.projects.list(page, limit, searchParams));
    this.listArchiveItems.clear();
    const results = resultsRaw.map((item) => this.saveListArchivedItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listFolder = _async(function* (
    this: ProjectStore,
    slug: string,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.projects.listFolder(slug, page, limit, searchParams));
    this.listFolderItems.clear();
    const results = resultsRaw.map((item) => this.saveListFolderItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  detail = _async(function* (this: ProjectStore, projectId: identifier) {
    let response: APIProjectDetail | undefined = undefined;
    try {
      response = yield* _await(api.projects.detail(projectId));
    } catch (e) {
      //
    }
    if (response) {
      return this.saveListItem(response);
    }
  });

  @modelFlow
  lock = _async(function* (this: ProjectStore, projectId: identifier) {
    try {
      const result = yield* _await(api.projects.lock(projectId));
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });

  @modelFlow
  unlock = _async(function* (this: ProjectStore, projectId: identifier) {
    try {
      const result = yield* _await(api.projects.unlock(projectId));
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });

  @modelFlow
  create = _async(function* (
    this: ProjectStore,
    apiData: APIProjectCreateInput,
    slug?: string
  ) {
    const result = yield* _await(api.projects.create(apiData, slug));
    this.saveListItem(result);
    return result;
  });

  @modelFlow
  patch = _async(function* (
    this: ProjectStore,
    projectId: identifier,
    apiData: APIProjectInput
  ) {
    // Encode notes to prevent request flagging "malicious" html
    if (apiData.notes) {
      apiData = { ...apiData, notes: btoa(apiData.notes) };
    }

    try {
      const result = yield* _await(api.projects.update(projectId, apiData));
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw e;
    }
  });

  @modelFlow
  rename = _async(function* (
    this: ProjectStore,
    projectId: identifier,
    apiData: APIProjectRenameInput
  ) {
    const result = yield* _await(api.projects.rename(projectId, apiData));
    return result;
  });

  @modelFlow
  duplicate = _async(function* (
    this: ProjectStore,
    projectId: identifier,
    apiData: APIProjectDuplicateInput
  ) {
    const result = yield* _await(api.projects.duplicate(projectId, apiData));
    this.saveListItem(result);
    return result;
  });
}

export default ProjectStore;
