import base, { APIDetail, APIList, APISearchParams, config } from "./base";
import { APITenderOutcome } from "./types";

export type APITenderOutcomeInput = Partial<Omit<APITenderOutcome, "id">>;

export interface APITenderOutcomeDetail extends APIDetail<APITenderOutcome> {}

export interface APITenderOutcomeSearchParams extends APISearchParams {
  project?: identifier;
}

export interface APITenderOutcomeList extends APIList<APITenderOutcome> {}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APITenderOutcomeSearchParams
) =>
  base.list<APITenderOutcomeList, APITenderOutcomeSearchParams>(
    "tender-outcomes/",
    page,
    limit,
    searchParams
  );

export type APITenderOutcomeUpdateInput = Partial<
  Pick<APITenderOutcome, "date" | "status" | "notes">
>;

export type APITenderOutcomeBulkUpdateInput = {
  instances: APITenderOutcomeUpdateInput[];
};

export type APITenderOutcomeBulkUpdateDetail = {
  instances: APITenderOutcomeDetail[];
};

export const bulk_update = (input: APITenderOutcomeBulkUpdateInput) =>
  base.patch<APITenderOutcomeBulkUpdateDetail>(`tender-outcomes/bulk-update/`, {
    json: input,
  });
