import { DataGridHandleWithData } from "components/DataGrid/types";
import { IRow } from "csv/types";
import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import { CsvFile } from "pages/Workspace/types";
import React, { useCallback, useState } from "react";
import { Column } from "react-data-grid";
import { useStore } from "store";
import Comment from "store/models/Comment";
import CommentContext from "./contexts";
import { CellSelection } from "./types";

type Props = React.PropsWithChildren<{}>;

const CommentProvider: React.FC<Props> = ({ children }) => {
  const store = useStore();
  const project = useProject();
  const [selection, setSelection] = useState<CellSelection>();
  const [createSelection, setCreateSelection] = useState<CellSelection>();
  const [gridRefs, setGridRefs] = useState<{
    [k: string]: [React.RefObject<DataGridHandleWithData>, string];
  }>({});

  const register = useCallback(
    (
      sheetType: string,
      sheetId: identifier,
      ref: React.RefObject<DataGridHandleWithData>,
      rowIdentifier: string
    ) => {
      setGridRefs((prev) => ({
        ...prev,
        [`${sheetType}-${sheetId}`]: [ref, rowIdentifier],
      }));
    },
    []
  );

  const unregister = useCallback((sheetType: string, sheetId: identifier) => {
    setGridRefs((prev) => {
      const next = { ...prev };
      delete next[`${sheetType}-${sheetId}`];
      return next;
    });
  }, []);

  const hasComments = useCallback(
    (csvFile: CsvFile, row?: IRow, column?: Column<IRow>) => {
      if (!project) {
        return false;
      }

      if (!row || !column) {
        return false;
      }

      const {
        sheet: { sheetType, id },
        rowIdentifier,
      } = csvFile;

      const commentIdentifiers = store.comments.ofSheetIdentifiers(
        project.id,
        sheetType,
        id
      );

      const rowIndex = `${row[rowIdentifier]}`;
      const colName = column.key;
      const hasComments = commentIdentifiers[`${colName}-${rowIndex}`];

      return hasComments;
    },
    [project, store.comments]
  );

  const handleCellSelect = useCallback(
    (csvFile: CsvFile, row?: IRow, column?: Column<IRow>) => {
      if (!project) {
        return;
      }

      // If the selection is lost, close the comments sidebar.
      if (!row || !column) {
        setSelection(undefined);
        return;
      }

      const {
        sheet: { sheetType, id },
        rowIdentifier,
      } = csvFile;

      // Open comments sidebar if the selected cell contains comments.
      // Get comment identifiers here instead of outside to reduce renders.
      const rowIndex = `${row[rowIdentifier]}`;
      const colName = column.key;

      setSelection({ sheetType, sheetId: id, rowIndex, colName });
      setCreateSelection(undefined);
    },
    [project]
  );

  const handleCommentSelect = useCallback(
    (comment: Comment) => {
      const { sheetType, sheetId, rowIndex, colName } = comment;
      const [gridRef, rowIdentifier] = gridRefs[`${sheetType}-${sheetId}`] || [
        undefined,
        undefined,
      ];

      if (!gridRef?.current) {
        return;
      }

      const rowIdx = gridRef.current.rows.findIndex(
        (r) => r[rowIdentifier]?.toString() === rowIndex.toString()
      );
      const idx = gridRef.current.columns.findIndex((c) => c.key === colName);

      if (rowIdx < 0 || idx < 0) {
        return;
      }

      gridRef.current.selectCell({ idx, rowIdx });
      setSelection(comment);
      setCreateSelection(undefined);
    },
    [gridRefs]
  );

  const onCreateComment = useCallback(
    (selection: CellSelection) => {
      setCreateSelection(selection);
      store.comments.showCommentsPanel();
    },
    [store.comments]
  );

  const handleClose = useCallback(() => {
    setSelection(undefined);
    setCreateSelection(undefined);
    store.comments.hideCommentsPanel();
  }, [store.comments]);

  const show =
    !!project && project.showComments && store.comments.commentsPanelShow;
  const showCreate = isEqual(createSelection, selection);

  const visibleSheets = Object.keys(gridRefs);

  return (
    <CommentContext.Provider
      value={{
        show,
        showCreate,
        selection,
        visibleSheets,
        onCellSelect: handleCellSelect,
        onCommentSelect: handleCommentSelect,
        onClose: handleClose,
        onCreateComment,
        hasComments,
        register,
        unregister,
      }}
    >
      {children}
    </CommentContext.Provider>
  );
};

export default observer(CommentProvider);
