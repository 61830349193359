import BigNumber from "bignumber.js";
import DataGrid from "components/DataGrid";
import { useCsvToGridDataAsync } from "components/DataGrid/hooks";
import { getNumberFormatter } from "components/DataGrid/NumberFormatter";
import {
  ColumnExtraOptions,
  DataGridHandleWithData,
} from "components/DataGrid/types";
import { getDataColumnsFromSchema } from "components/DataGrid/utils";
import { CsvValidationErrorContext } from "components/ValidationDashboard/contexts";
import { IRow } from "csv/types";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useStore } from "store";
import styled from "styled-components";
import { scaledRem } from "utils";

type Props = {};

const TotalsDataGrid = styled(DataGrid)`
  &.totals .rdg-container {
    min-height: calc(2rem + 9px + 16px + 2px);
  }
`;

const NumberFormatter = getNumberFormatter(2, 4, true);

const ONE_HUNDRED = new BigNumber("100");
const ZERO = new BigNumber("0");

const schemaTotals = (key: string): ColumnExtraOptions<IRow> => {
  switch (key) {
    case "id":
      return {
        editable: false,
        formatter: () => <></>,
        name: "",
        resizable: false,
        frozen: true,
      };
    case "rcode":
      return {
        cellClass: "cell-formulations-total-header",
        editable: false,
        formatter: () => <>Totals</>,
        name: "R Code",
        resizable: false,
        frozen: true,
        width: scaledRem(111),
      };
    case "description":
      return {
        cellClass: "cell-formulations-total-header",
        editable: false,
        formatter: () => <></>,
        name: "RM Desc",
        resizable: false,
        frozen: true,
        width: scaledRem(111),
      };
    case "crdStatus":
      return {
        cellClass: "cell-formulations-total-header",
        editable: false,
        formatter: () => <></>,
        name: "CRD Status",
        resizable: false,
        frozen: true,
        width: scaledRem(111),
      };
  }
  return {
    editable: false,
    formatter: NumberFormatter,
    cellClass: "cell-numeric",
    name: "\xa0", // Insert non-breaking space so column select keeps working.
    resizable: false,
    width: scaledRem(123),
  };
};

const FormulationsTabTotals: React.FC<Props> = () => {
  const store = useStore();
  const project = useProject();
  const validationSummary = useContext(CsvValidationErrorContext);
  const refTotals = useRef<DataGridHandleWithData>(null);

  // Base oils
  const csvStringBaseOils = project ? store.formulations.baseOilsAsCsv : "";
  const [columnsBaseOils, rowsBaseOils] = useCsvToGridDataAsync({
    csvString: csvStringBaseOils,
  });

  // Additives
  const csvStringAdditives = project ? store.formulations.additivesAsCsv : "";
  const [, rowsAdditives] = useCsvToGridDataAsync({
    csvString: csvStringAdditives,
  });

  const rawTotalColumns = useMemo(
    () => columnsBaseOils.map((col) => col.key),
    [columnsBaseOils]
  );
  const dataTotalColumns = useMemo(
    () => getDataColumnsFromSchema(rawTotalColumns, false, schemaTotals),
    [rawTotalColumns]
  );

  const totalRowPercent = useMemo(() => {
    const total: IRow = {
      id: 0,
      crdStatus: "",
      description: "",
      rcode: "TOTALS",
    };

    rowsBaseOils.forEach((row) => {
      Object.entries(row).forEach((entry) => {
        const [rowKey, rowValue] = entry;
        if (
          rowKey !== "id" &&
          rowKey !== "rcode" &&
          rowKey !== "description" &&
          rowKey !== "crdStatus"
        ) {
          const parsedValue = new BigNumber(`${rowValue}`);
          if (total[rowKey] === undefined) {
            total[rowKey] = ZERO;
          }
          if (!parsedValue.isNaN()) {
            total[rowKey] = (total[rowKey] as BigNumber).plus(parsedValue);
          }
        }
      });
    });

    rowsAdditives.forEach((row) => {
      Object.entries(row).forEach((entry) => {
        const [rowKey, rowValue] = entry;
        if (
          rowKey !== "id" &&
          rowKey !== "rcode" &&
          rowKey !== "description" &&
          rowKey !== "crdStatus"
        ) {
          const parsedValue = new BigNumber(`${rowValue}`);
          if (total[rowKey] === undefined) {
            total[rowKey] = ZERO;
          }
          if (!parsedValue.isNaN()) {
            total[rowKey] = (total[rowKey] as BigNumber).plus(parsedValue);
          }
        }
      });
    });

    return total;
  }, [rowsAdditives, rowsBaseOils]);

  const totalErrors = useMemo(() => {
    const errors: Record<string, string> = {};
    dataTotalColumns.forEach((columnItem, columnIndex) => {
      const rowTotal = totalRowPercent[columnItem.key] as BigNumber;
      if (
        columnItem.key !== "id" &&
        columnItem.key !== "rcode" &&
        columnItem.key !== "description" &&
        columnItem.key !== "crdStatus" &&
        (!rowTotal || !rowTotal.isEqualTo(ONE_HUNDRED))
      ) {
        const cellKey = `${columnItem.key}-0`;
        errors[cellKey] = "E:Rates should add up to 100%";
      }
    });
    return errors;
  }, [dataTotalColumns, totalRowPercent]);

  useEffect(() => {
    const newErrorKeys = new Set<string>();
    const newColumnNameMap: Record<string, string> = {};

    const totalsErrorSize = Object.keys(totalErrors).length;

    validationSummary.setters.setErrorCount((prev) => ({
      ...prev,
      totals: totalsErrorSize,
    }));

    if (totalsErrorSize > 0) {
      newErrorKeys.add("totals-E019");
      newColumnNameMap["totals"] = "Totals";
    }
    validationSummary.setters.setErrorKeys((prev) => ({
      ...prev,
      totals: newErrorKeys,
    }));
    validationSummary.setters.setColumnNameMaps((prev) => ({
      ...prev,
      totals: newColumnNameMap,
    }));
  }, [totalErrors, validationSummary.setters]);

  return (
    <TotalsDataGrid
      ref={refTotals}
      id="totals"
      multiple
      className="totals"
      collapsible={false}
      headerRowHeight={0}
      defaultColumnOptions={{ resizable: false }}
      rows={[totalRowPercent]}
      maxRowsShown={1}
      columns={dataTotalColumns}
      onRowsChange={() => {}}
      errors={totalErrors}
      rowIdentifier="id"
    />
  );
};

export default observer(FormulationsTabTotals);
