import { detach, Model, model, prop, rootRef } from "mobx-keystone";

export function makePlantId(id: number) {
  return `collab/Plant/${id}`;
}

@model("collab/Plant")
class Plant extends Model({
  id: prop<identifier>(),
  name: prop<string>(),
  code: prop<string>(),
}) {
  getRefId() {
    return makePlantId(this.id);
  }
}

export const plantRef = rootRef<Plant>("collab/PlantRef", {
  onResolvedValueChange(ref, newPlant, oldPlant) {
    if (oldPlant && !newPlant) {
      detach(ref);
    }
  },
});

export default Plant;
