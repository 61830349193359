import { CloudDownloadOutlined } from "@material-ui/icons";
import api from "api";
import clsx from "clsx";
import Button from "components/Button";
import DataGrid from "components/DataGrid";
import dropdownEditorFactory from "components/DataGrid/DropdownEditor";
import filterDisplayMapper from "components/DataGrid/filterDisplayMapper";
import { useCsvToGridDataAsync } from "components/DataGrid/hooks";
import valueMapMapper from "components/DataGrid/Mapper";
import NumberFormatter from "components/DataGrid/NumberFormatter";
import { numberParser } from "components/DataGrid/parsers";
import {
  ColumnExtraOptions,
  DataGridHandleWithData,
  RangeSelection,
  ValueParsers,
} from "components/DataGrid/types";
import Loading from "components/Loading";
import ProjectLockedLabel from "components/ProjectLockedLabel";
import UploadButton from "components/UploadButton";
import ValidationDashboard from "components/ValidationDashboard";
import { CsvValidationErrorContext } from "components/ValidationDashboard/contexts";
import { useValidationSummary } from "components/ValidationDashboard/hooks";
import { mappingRequirements } from "csv/mappings/define";
import { IRow } from "csv/types";
import { addCsvSepHeader, reparseMapHeadersFromCsvString } from "csv/utils";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RowsChangeData } from "react-data-grid";
import { ScrollSync } from "react-scroll-sync";
import { useAsync, useUnmount } from "react-use";
import { useStore } from "store";
import { scaledRem, Toast } from "utils";
import Toolbar from "../../components/Toolbar";
import { useProject, useSave } from "../Workspace/hooks";
import { CustomSheet } from "../Workspace/types";
import GenerateCostSheetButton from "./GenerateCostSheetButton";

type Props = {
  tab: CustomSheet;
};

const blankValidations = [
  "uniqueRef",
  "destinationCountry",
  "productSpecification",
  "packSize",
];

const valueParsers: ValueParsers = {
  volumeYear1: numberParser,
  volumeYear2: numberParser,
  volumeYear3: numberParser,
  volumeYear4: numberParser,
  volumeYear5: numberParser,
};

const RequirementsTab: React.FC<Props> = ({ tab }) => {
  const store = useStore();
  const project = useProject();
  const validationSummary = useValidationSummary();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const currentUser = store.auth.current;
  const hasEditPermission = !!(
    currentUser &&
    (currentUser.isAdmin ||
      currentUser.isAnalyst ||
      project?.adminUsers.includes(currentUser.id))
  );
  const editable = hasEditPermission && !project?.isLocked;

  useUnmount(() => {
    if (project && project.isDirty) {
      project.setNotDirty();
    }
  });

  const schema = useCallback(
    (key: string, sheetEditable?: boolean): ColumnExtraOptions<IRow> => {
      const defaults: ColumnExtraOptions<IRow> = {
        name: mappingRequirements[key] ? mappingRequirements[key][0] : key,
        width: mappingRequirements[key] ? mappingRequirements[key][1] : 50,
        editable: (row: IRow) => {
          if (!sheetEditable) {
            return sheetEditable ?? true;
          }

          const requirement = store.requirements.listItems.get(
            parseInt(row.id as string, 10)
          );
          if (requirement) {
            return !requirement.isCltLocked;
          }
          return false;
        },
        cellClass: (row: IRow) => {
          if (!sheetEditable) {
            return sheetEditable ?? true ? undefined : "cell-readonly";
          }

          const requirement = store.requirements.listItems.get(
            parseInt(row.id as string, 10)
          );
          if (requirement) {
            return requirement.isCltLocked ? "cell-readonly" : undefined;
          }
          return undefined;
        },
      };
      const options: Record<string, ColumnExtraOptions<IRow>> = {
        id: {
          ...defaults,
          editable: false,
          frozen: true,
          name: "",
          resizable: false,
        },
        project: {
          ...defaults,
          editable: false,
          frozen: false,
          formatter: () => <></>,
          maxWidth: 0,
          name: "",
          resizable: false,
        },
        uniqueRef: {
          ...defaults,
          frozen: true,
        },
        destinationCluster: {
          ...defaults,
          editable: false,
          cellClass: "cell-readonly",
        },
        destinationCountry: {
          ...defaults,
          editor: dropdownEditorFactory(
            "destinationCountry",
            store.assets.countrySelect
          ),
          editorOptions: {
            editOnClick: true,
          },
          formatter: valueMapMapper(
            "destinationCountry",
            store.assets.countryListItems
          ),
        },
        deliveryLocation: {
          ...defaults,
        },
        deliveryTerms: {
          ...defaults,
          editor: dropdownEditorFactory(
            "deliveryTerms",
            store.assets.termsSelect
          ),
          editorOptions: {
            editOnClick: true,
          },
          formatter: valueMapMapper(
            "deliveryTerms",
            store.assets.termsListItems
          ),
        },
        productSpecification: {
          ...defaults,
        },
        packSize: {
          ...defaults,
          editor: dropdownEditorFactory(
            "packSize",
            store.assets.packSizeSelect
          ),
          editorOptions: {
            editOnClick: true,
          },
          formatter: valueMapMapper("packSize", store.assets.packSizeListItems),
        },
        packageType: {
          ...defaults,
        },
        volumeYear1: {
          ...defaults,
          formatter: NumberFormatter,
          cellClass: (row: IRow) => {
            if (!sheetEditable) {
              return clsx("cell-numeric", {
                "cell-readonly": !sheetEditable,
              });
            }

            const requirement = store.requirements.listItems.get(
              parseInt(row.id as string, 10)
            );
            if (requirement) {
              return clsx("cell-numeric", {
                "cell-readonly": requirement.isCltLocked,
              });
            }
            return "cell-numeric";
          },
        },
        volumeYear2: {
          ...defaults,
          formatter: NumberFormatter,
          cellClass: (row: IRow) => {
            if (!sheetEditable) {
              return clsx("cell-numeric", {
                "cell-readonly": !sheetEditable,
              });
            }

            const requirement = store.requirements.listItems.get(
              parseInt(row.id as string, 10)
            );
            if (requirement) {
              return clsx("cell-numeric", {
                "cell-readonly": requirement.isCltLocked,
              });
            }
            return "cell-numeric";
          },
        },
        volumeYear3: {
          ...defaults,
          formatter: NumberFormatter,
          cellClass: (row: IRow) => {
            if (!sheetEditable) {
              return clsx("cell-numeric", {
                "cell-readonly": !sheetEditable,
              });
            }

            const requirement = store.requirements.listItems.get(
              parseInt(row.id as string, 10)
            );
            if (requirement) {
              return clsx("cell-numeric", {
                "cell-readonly": requirement.isCltLocked,
              });
            }
            return "cell-numeric";
          },
        },
        volumeYear4: {
          ...defaults,
          formatter: NumberFormatter,
          cellClass: (row: IRow) => {
            if (!sheetEditable) {
              return clsx("cell-numeric", {
                "cell-readonly": !sheetEditable,
              });
            }

            const requirement = store.requirements.listItems.get(
              parseInt(row.id as string, 10)
            );
            if (requirement) {
              return clsx("cell-numeric", {
                "cell-readonly": requirement.isCltLocked,
              });
            }
            return "cell-numeric";
          },
        },
        volumeYear5: {
          ...defaults,
          formatter: NumberFormatter,
          minWidth: 0,
          cellClass: (row: IRow) => {
            if (!sheetEditable) {
              return clsx("cell-numeric", {
                "cell-readonly": !sheetEditable,
              });
            }

            const requirement = store.requirements.listItems.get(
              parseInt(row.id as string, 10)
            );
            if (requirement) {
              return clsx("cell-numeric", {
                "cell-readonly": requirement.isCltLocked,
              });
            }
            return "cell-numeric";
          },
        },
        packSizeRfq: {
          ...defaults,
        },
        altScenario: {
          ...defaults,
        },
      };

      return options[key] || { ...defaults, width: scaledRem(100) };
    },
    [
      store.assets.countryListItems,
      store.assets.countrySelect,
      store.assets.packSizeListItems,
      store.assets.packSizeSelect,
      store.assets.termsListItems,
      store.assets.termsSelect,
      store.requirements.listItems,
    ]
  );

  const filterDisplayMappers = {
    destinationCountry: filterDisplayMapper(store.assets.countryListItems),
    deliveryTerms: filterDisplayMapper(store.assets.termsListItems),
    packSize: filterDisplayMapper(store.assets.packSizeListItems),
  };

  const csvString = project ? store.requirements.asCsv : "";
  const [columns, rows, loaded] = useCsvToGridDataAsync({
    csvString,
    editable,
    schema,
  });
  const ref = useRef<DataGridHandleWithData>(null);

  // (Re)load requirements when user opens this tab.
  const state = useAsync(async () => {
    if (!project) {
      return;
    }
    store.requirements.commitCsv(project.id);
    await store.requirements.list(project.id);
    store.requirements.commitCsv(project.id);
  }, [project]);

  // Save requirements when save button is pressed.
  useSave(
    async () => {
      if (!project) {
        return;
      }

      if (!editable) {
        throw new Error("Requirements not editable");
      }

      try {
        await store.requirements.saveAll(project.id);
      } catch (e) {
        throw new Error(
          "Requirements not saved. Please resolve all validation issues."
        );
      } finally {
        store.requirements.commitCsv(project.id);
      }
    },
    { saveOnUnmount: true }
  );

  useEffect(() => {
    const tempErrors: Record<string, string> = {};
    const blankValidationsMap: Record<string, string> = {};
    let tempErrorCount = 0;
    let tempErrorKeys: Set<string> = new Set();
    let tempColumnNameMap: Record<string, string> = {};

    columns.forEach((item) => {
      if (blankValidations.includes(item.key))
        blankValidationsMap[item.key] = item.key;
    });

    rows.forEach((row) => {
      columns.forEach((column) => {
        if (blankValidations.includes(column.key) && row[column.key] === "") {
          const cellKey = `${column.key}-${row.id}`;
          tempErrors[cellKey] = "E:This field cannot be left blank";
          tempErrorCount++;
          tempErrorKeys.add(`${column.key}-E002`);
          tempColumnNameMap[column.key] = `${column.name}`.replaceAll(
            "\n",
            " "
          );
        }
      });
    });
    validationSummary.setters.setErrorCount(() => ({ 0: tempErrorCount }));
    validationSummary.setters.setErrorKeys(() => ({ 0: tempErrorKeys }));
    validationSummary.setters.setColumnNameMaps(() => ({
      0: tempColumnNameMap,
    }));
    setErrors(tempErrors);
  }, [rows, columns, validationSummary.setters]);

  /**
   * Called by the underlying data grid, as well as the clipboard handler,
   * whenever one or more of the cells in the data grid changes.
   *
   * @param rows rows affected by changed cells
   * @param data metadata on the column affected by the changed cells
   */
  const handleRowsChange = useCallback(
    (rows: IRow[], data?: RowsChangeData<IRow, unknown>) => {
      if (!project) {
        return;
      }
      if (!data) {
        return;
      }
      const { key } = data.column; // key = field name
      const { indexes } = data;

      indexes.forEach((index) => {
        const { id } = rows[index]; // id = id of formulation
        const requirement = store.requirements.listItems.get(
          parseInt(id as string, 10)
        );
        requirement?.setValue(key, rows[index][key]);
      });

      store.requirements.commitCsv(project.id);

      project.setDirty();
    },
    [project, store.requirements]
  );

  const handleAddRows = useCallback(
    (numRows: number) => {
      if (!project) {
        return;
      }
      for (let i = 0; i < numRows; i++) {
        store.requirements.create(project.id);
      }
      store.requirements.commitCsv(project.id);
    },
    [store.requirements, project]
  );

  const handleDelete = useCallback(
    async (
      event: React.TouchEvent<HTMLDivElement>,
      data: { filteredRows: readonly IRow[]; range: RangeSelection }
    ) => {
      if (!project) {
        return;
      }

      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm("Are you sure you want to delete the selected requirements?")
      ) {
        return;
      }

      const {
        filteredRows,
        range: { start, end },
      } = data;

      project.setLoading("Deleting requirements...");

      const promises: Promise<void>[] = [];
      for (let i = start.rowIdx; i <= end.rowIdx; i++) {
        const requirementId = filteredRows[i].id;
        if (!requirementId) {
          return;
        }
        promises.push(store.requirements.delete(Number(requirementId)));
      }

      const results = await Promise.allSettled(promises);

      store.requirements.commitCsv(project.id);
      project?.setNotLoading();

      const rejected = results.filter((result) => result.status === "rejected");
      if (rejected.length > 0) {
        Toast.danger("Some requirements were not deleted.");
        return;
      }

      Toast.success("Selected requirements have been deleted.");
    },
    [project, store.requirements]
  );

  const handleDownloadClick = useCallback(async () => {
    if (!project) {
      return;
    }

    project.setLoading("Preparing download...");

    const csv = store.requirements.getCsv(project.id, true);

    let mappedCsvString = "";
    try {
      [mappedCsvString] = reparseMapHeadersFromCsvString(
        csv,
        mappingRequirements
      );
    } catch (e) {
      Toast.danger("Unable to generate the CSV file.");
      project.setNotLoading();
      return;
    }

    const zip = new JSZip().file(
      "requirements.csv",
      addCsvSepHeader(mappedCsvString)
    );

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `requirements-${project.id}.zip`);

    Toast.success("CSV file successfully loaded.");
    project.setNotLoading();
  }, [project, store.requirements]);

  const ready = useMemo(
    () => loaded && !state.loading,
    [loaded, state.loading]
  );

  const handleFileUpload = async (file: string) => {
    if (!project) {
      return;
    }

    project.setLoading("Preparing upload...");

    try {
      await api.projects.uploadRequirements(project.id, {
        file,
      });
      project.setCostSheetDirty();
    } catch (e) {
      Toast.danger("Unable to process the CSV file.");
      return;
    } finally {
      project.setNotLoading();
    }

    Toast.success("CSV file successfully uploaded.");
    await store.requirements.list(project.id);
    store.requirements.commitCsv(project.id);
  };

  if (!ready) {
    return <Loading full />;
  }

  return (
    <CsvValidationErrorContext.Provider value={validationSummary}>
      <Toolbar>
        <Button size="thin" onClick={handleDownloadClick}>
          <CloudDownloadOutlined /> Download CSV File
        </Button>
        <UploadButton onFileUpload={handleFileUpload} />
        {!project?.isLocked && editable && <GenerateCostSheetButton />}
        {project?.isLocked && <ProjectLockedLabel />}
        <ValidationDashboard validationChecks={[]} />
      </Toolbar>
      <ScrollSync enabled={false}>
        <DataGrid
          ref={ref}
          id="requirements"
          isActive
          errors={errors}
          rows={rows}
          headerRowHeight={scaledRem(56)}
          columns={columns}
          onAddRows={editable ? handleAddRows : undefined}
          onRowsChange={handleRowsChange}
          filterable
          filterDisplayMappers={filterDisplayMappers}
          valueParsers={valueParsers}
          rowIdentifier="id"
          extraContextMenu={(filteredRows, selected, range) => [
            {
              id: "deleteRows",
              label: "Delete selected requirements…",
              onClick: handleDelete,
              data: { filteredRows, range },
            },
          ]}
        />
      </ScrollSync>
    </CsvValidationErrorContext.Provider>
  );
};

export default observer(RequirementsTab);
