import React from "react";
import { Form, FormControlProps } from "react-bootstrap";
import styled from "styled-components";
import ErrorMessage from "../ErrorMessage";

interface FormFieldProps extends FormControlProps {
  children: JSX.Element | JSX.Element[];
  containerClassName?: string;
  error?: string | string[] | object;
  label?: string;
  position?: "top" | "bottom";
}

const InputContainer = styled.div`
  &.error {
    border: 2px solid #ff7591;

    label::before {
      color: #ff7591;
    }
  }
`;

const FormField: React.FC<FormFieldProps> = ({
  children,
  containerClassName,
  error,
  label,
  position = "bottom",
}) => {
  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <div className={containerClassName}>
        {error && position === "top" && <ErrorMessage error={error} />}
        <InputContainer className={error ? "error" : undefined}>
          {children}
        </InputContainer>
        {error && position === "bottom" && <ErrorMessage error={error} />}
      </div>
    </>
  );
};

export default FormField;
