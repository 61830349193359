import { HelpOutlineOutlined } from "@material-ui/icons";
import api from "api";
import { APITicketInput } from "api/tickets";
import ModalForm from "components/ModalForm";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Project from "store/models/Project";
import styled from "styled-components";
import { Toast } from "utils";
import Button from "../Button";
import Help from "./Help";

export const DEFAULT_TO_EMAILS = ["GCollabSupport@bp.com"];

const HelpModalContainer = styled(ModalForm)`
  .modal-dialog {
    font-size: 0.875rem;
    line-height: 1.625rem;
    min-width: 41.75rem;
  }
`;

type Props = {
  project: Project;
};

type Inputs = APITicketInput;

const HelpModal: React.FC<Props> = ({ project }) => {
  const methods = useForm<Inputs>();
  const [show, setShow] = useState(false);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleHide = useCallback(() => {
    setShow(false);
  }, []);

  const [toEmails, setToEmails] = useState<string[]>(DEFAULT_TO_EMAILS);
  const [ccEmails, setCcEmails] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [file, setFile] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [showCC, setShowCC] = useState<boolean>(false);

  const onSend = async (data: APITicketInput) => {
    data.project = project.id;
    data.recipients = toEmails.join(",");
    data.ccRecipients = ccEmails.join(",");
    data.subject = subject;

    // encode body to avoid being flagged as malicious
    data.body = await btoa(body);

    if (file) {
      data.file = file;
      data.fileName = fileName;
    }

    try {
      await api.tickets.create(data);
    } catch (e) {
      Toast.danger("Error");
      return;
    }
    handleDiscard();
    Toast.success("Help Ticket Sent!");
  };

  const handleDiscard = () => {
    setToEmails(DEFAULT_TO_EMAILS);
    setCcEmails([]);
    setSubject("");
    setBody("");
    setShowCC(false);
    setShow(false);
    setFile("");
    setFileName("");
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <Button disabled={project.loading} onClick={handleShow}>
        <HelpOutlineOutlined /> Help
      </Button>

      <HelpModalContainer centered show={show} onHide={handleHide}>
        <Help
          project={project}
          show={show}
          onSubmit={onSend}
          onHide={handleHide}
          toEmails={toEmails}
          setToEmails={setToEmails}
          ccEmails={ccEmails}
          setCcEmails={setCcEmails}
          showCC={showCC}
          setShowCC={setShowCC}
          subject={subject}
          setSubject={setSubject}
          body={body}
          setBody={setBody}
          file={file}
          setFile={setFile}
          fileName={fileName}
          setFileName={setFileName}
          onDiscard={handleDiscard}
        />
      </HelpModalContainer>
    </FormProvider>
  );
};

export default observer(HelpModal);
