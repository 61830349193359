import { MoveToInboxOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import api from "../../api";
import { APIProjectUpdateFolderInput } from "../../api/projects";
import Button from "../../components/Button";
import { Header, SubHeader } from "../../components/Header";
import ModalForm from "../../components/ModalForm";
import { useStore } from "../../store";
import ProjectMinimal from "../../store/models/ProjectMinimal";
import { Toast } from "../../utils";

const HeaderContainer = styled.div`
  min-width: 141px;
`;

interface Props {
  archived?: boolean;
  instance: ProjectMinimal;
  isOpen: boolean;
  setModal: (visible: boolean) => void;
  onProjectSubmit: () => Promise<void>;
}

const ProjectMoveForm: React.FC<Props> = ({
  archived,
  instance,
  isOpen,
  setModal,
  onProjectSubmit,
}) => {
  const store = useStore();
  const { projectFolders } = store;
  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm<APIProjectUpdateFolderInput>();
  const [loading, setLoading] = useState(false);

  const options = archived
    ? Array.from(projectFolders.listArchivedItems.values())
    : Array.from(projectFolders.listActiveItems.values());

  const renderOptions = options?.map((item) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });

  const handleValidate = async (data: APIProjectUpdateFolderInput) => {
    setLoading(true);

    try {
      await api.projects.updateFolder(instance?.id, data);
    } catch (e: any) {
      const { detail } = await api.handleError(e);
      if (detail) {
        Object.entries(detail!).forEach(([key, message]) => {
          setError(key as any, { message: (message as string[])[0] });
        });
        return;
      }
    } finally {
      setLoading(false);
    }

    await onProjectSubmit();
    setModal(false);
    Toast.success("Project Moved");
  };

  return (
    <ModalForm show={isOpen} onHide={() => setModal(false)} centered>
      <Form onSubmit={handleSubmit(handleValidate)}>
        <ModalForm.Header closeButton>
          <HeaderContainer>
            <SubHeader>Move Project</SubHeader>
            <Header>{instance.name}</Header>
          </HeaderContainer>
        </ModalForm.Header>
        <ModalForm.Body>
          <Form.Control
            as="select"
            {...register("folder")}
            disabled={loading}
            isInvalid={!!errors.folder}
            defaultValue={instance.folder}
          >
            <option value="">------</option>
            {renderOptions}
          </Form.Control>
          {errors.folder && (
            <Form.Control.Feedback type="invalid">
              {errors.folder.message}
            </Form.Control.Feedback>
          )}
        </ModalForm.Body>
        <ModalForm.Footer>
          <Button
            disabled={loading}
            onClick={() => setModal(false)}
            variant="cancel"
          >
            Cancel
          </Button>
          <Button disabled={loading} type="submit">
            <MoveToInboxOutlined /> Move
          </Button>
        </ModalForm.Footer>
      </Form>
    </ModalForm>
  );
};

export default ProjectMoveForm;
