import { Modal } from "react-bootstrap";
import styled from "styled-components";

const ModalForm = styled(Modal)`
  .modal-dialog {
    max-width: 25.25rem;
  }

  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-header {
    padding: 1.875rem 1.875rem 0.875rem;
  }

  .modal-body {
    padding: 0.875rem 1.875rem 0.938rem;

    .form-control {
      color: #000000;

      font-weight: 600;
      font-size: 0.875rem;
      line-height: 0.875rem;

      padding: 0.375rem 0.938rem;
      height: 2.25rem;
      border-radius: 0.125rem;
    }
  }

  nav {
    padding-left: 1.875rem;
    padding-right: 2.25rem;
  }

  .modal-footer {
    padding: 0.938rem 1.875rem 2.313rem;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding-right: 2.25rem;
  }
`;

export default ModalForm;
