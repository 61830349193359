import { computed } from "mobx";
import { Model, model, modelAction, prop_setArray, Ref } from "mobx-keystone";
import Plant, { makePlantId, plantRef } from "./Plant";

@model("collab/RMFormulationFilter")
class RMFormulationFilter extends Model({
  productSpecs: prop_setArray(() => new Set<string>()),
  formulationCodes: prop_setArray(() => new Set<string>()),
  plants: prop_setArray(() => new Set<Ref<Plant>>()),
  formulationOptions: prop_setArray(() => new Set<string>()),
  plantOptions: prop_setArray(() => new Set<string>()),
  altScenarios: prop_setArray(() => new Set<string>()),
}) {
  @computed
  get isFiltering() {
    return (
      this.productSpecs.size > 0 ||
      this.formulationCodes.size > 0 ||
      this.plants.size > 0 ||
      this.formulationOptions.size > 0 ||
      this.plantOptions.size > 0 ||
      this.altScenarios.size > 0
    );
  }

  @modelAction
  setProductSpecs(productSpecs: string[]) {
    this.productSpecs.clear();
    productSpecs.forEach((productSpec) => this.productSpecs.add(productSpec));
  }

  @modelAction
  setFormulationCodes(formulationCodes: string[]) {
    this.formulationCodes.clear();
    formulationCodes.forEach((formulationCode) =>
      this.formulationCodes.add(formulationCode)
    );
  }

  @modelAction
  setPlants(plantIds: identifier[]) {
    this.plants.clear();
    plantIds.forEach((id) => this.plants.add(plantRef(makePlantId(id))));
  }

  @modelAction
  setFormulationOptions(formulationOptions: string[]) {
    this.formulationOptions.clear();
    formulationOptions.forEach((formulationOption) =>
      this.formulationOptions.add(formulationOption)
    );
  }

  @modelAction
  setPlantOptions(plantOptions: string[]) {
    this.plantOptions.clear();
    plantOptions.forEach((plantOption) => this.plantOptions.add(plantOption));
  }

  @modelAction
  setAltScenarios(altScenarios: string[]) {
    this.altScenarios.clear();
    altScenarios.forEach((altScenario) => this.altScenarios.add(altScenario));
  }

  @modelAction
  reset() {
    this.productSpecs.clear();
    this.formulationCodes.clear();
    this.plants.clear();
    this.formulationOptions.clear();
    this.plantOptions.clear();
    this.altScenarios.clear();
  }

  @computed
  get selectedProductSpecsIds() {
    return Array.from(this.productSpecs).sort();
  }

  @computed
  get selectedFormulationCodesIds() {
    return Array.from(this.formulationCodes).sort();
  }

  @computed
  get selectedPlantIds() {
    return Array.from(this.plants).map((ref) => ref.current.id);
  }

  @computed
  get selectedFormulationOptionsIds() {
    return Array.from(this.formulationOptions).sort();
  }

  @computed
  get selectedPlantOptionsIds() {
    return Array.from(this.plantOptions).sort();
  }

  @computed
  get selectedAltScenariosIds() {
    return Array.from(this.altScenarios).sort();
  }

  @computed
  get selectedProductSpecs() {
    return Array.from(this.productSpecs)
      .sort()
      .map((productSpec) => ({
        value: productSpec,
        label: productSpec,
      }));
  }

  @computed
  get selectedFormulationCodes() {
    return Array.from(this.formulationCodes)
      .sort()
      .map((formulationCode) => ({
        value: formulationCode,
        label: formulationCode,
      }));
  }

  @computed
  get selectedPlants() {
    return Array.from(this.plants)
      .filter((ref) => ref.isValid)
      .map((ref) => ({
        value: ref.maybeCurrent?.id || 0,
        label: ref.maybeCurrent?.name || "?",
      }));
  }

  @computed
  get selectedFormulationOptions() {
    return Array.from(this.formulationOptions)
      .sort()
      .map((formulationOption) => ({
        value: formulationOption,
        label: formulationOption || "(Blank)",
      }));
  }

  @computed
  get selectedPlantOptions() {
    return Array.from(this.plantOptions)
      .sort()
      .map((plantOption) => ({
        value: plantOption,
        label: plantOption || "(Blank)",
      }));
  }

  @computed
  get selectedAltScenarios() {
    return Array.from(this.altScenarios)
      .sort()
      .map((altScenario) => ({
        value: altScenario,
        label: altScenario || "(Blank)",
      }));
  }
}

export default RMFormulationFilter;
