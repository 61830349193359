import { ValueParsers } from "../../components/DataGrid/types";
import { numberParser } from "../../components/DataGrid/parsers";

export const rmWorkingValueParsers: ValueParsers = {
  rm_purchase_price: numberParser, 
  supplier_freight: numberParser,
  drumming_differential_per_mt: numberParser,
  rebates_per_mt: numberParser,
  discounts_per_mt: numberParser,
  freight_cost: numberParser,
  cost_per_mt: numberParser,
  cost_per_mt_spec_curr: numberParser
};

export const ohPackMfgValueParsers: ValueParsers = {
  packaging_labelling_cost: numberParser, 
  specific_packaging_labelling_cost: numberParser,
  yield_loss: numberParser,
  bp_blending_fee: numberParser,
  "3p_toll_blending_cost": numberParser,
  other_bp_manufacturing_cost: numberParser,
  group_purchase: numberParser,
  "3p_purchase": numberParser,
  percent_cogs_efficiency_savings: numberParser, 
  bp_filling_fee: numberParser,
  depreciation: numberParser,
  packaging_labelling_cost_repacking_country: numberParser,
  specific_packaging_labelling_cost_repacking_country: numberParser,
  decanting_cost: numberParser,
  br_icms_tax: numberParser,
  my_sales_tax: numberParser
};

export const ohLogsValueParsers: ValueParsers = {
  extra_freight_cost_source_country: numberParser, 
  "3p_primary_transport_cost_source_country": numberParser,
  "3p_storage_handling_cost_source_country": numberParser,
  internal_storage_cost_source_country: numberParser,
  extra_freight_cost_repacking_country: numberParser,
  "3p_primary_transport_cost_repacking_country": numberParser,
  "3p_storage_handling_cost_repacking_country": numberParser,
  internal_storage_cost_repacking_country: numberParser,
  extra_freight_cost_destination_country: numberParser, 
  "3p_primary_transport_cost_destination_country": numberParser,
  "3p_storage_handling_cost_destination_country": numberParser,
  internal_storage_cost_destination_country: numberParser,
  logistics_specific_cost: numberParser,
  logistics_secondary_cost: numberParser,
  internal_primary_transport_cost: numberParser,
  cn_construction_tax: numberParser,
  th_import_sales_tax: numberParser
};