import localforage from "localforage";
import appConfig from "../config/appConfig";
import ky, { HTTPError, Options } from "ky";
import api from "../api";
import { AuthenticatedUser } from "../api/types";

/**
 * Stores the currently logged in user
 *
 * @param {user} AuthenticatedUser
 * @returns {void}
 */
export const saveAuthUser = async (user: AuthenticatedUser): Promise<void> => {
  await localforage.setItem(appConfig.authUserKey as string, user);
  api.config.token = user.accessToken;
};

/**
 * Clears the stored user
 *
 * @returns {void}
 */
export const flushAuthUser = async (): Promise<void> => {
  api.config.token = "";
  await localforage.setItem(appConfig.authUserKey as string, null);
};

/**
 * Get a new access token using the refresh token if current access token has expired
 *
 * @returns {string} new access token on success, or empty string on error
 */
export const refreshToken = async (): Promise<string> => {
  const baseUrl = appConfig.apiBaseUrl! as string;
  const timeout = appConfig.apiTimeout as number;
  const tokenEndpoint = "auth/token/refresh/";
  const authenticatedUser = await localforage.getItem<AuthenticatedUser>(
    appConfig.authUserKey as string
  );
  const refreshToken = authenticatedUser?.refreshToken;

  let response: Response;
  try {
    response = await ky.post(tokenEndpoint, {
      json: { refresh: refreshToken },
      prefixUrl: baseUrl,
      timeout,
    } as Options);
  } catch (error) {
    const httpError = error as HTTPError; // Appease TypeScript because `error` type is `unknown`.
    console.debug("[Debug][ky][Error]", await httpError.response.json());
    await flushAuthUser();
    window.location.href = "/login/";
    return "";
  } finally {
    api.config.refreshing = false;
  }

  const json = await response.json();
  const updatedUser: AuthenticatedUser = {
    ...json,
    permissions: "user",
  };
  await saveAuthUser(updatedUser);

  return json.accessToken;
};
