import { model, Model, prop } from "mobx-keystone";
import { computed } from "mobx";
import { APIClusterInputSheetsStatusInput } from "../../api/clusterInputSheetsStatus";

@model("collab/ClusterInputSheetsStatus")
class ClusterInputSheetsStatus extends Model({
  id: prop<identifier>(),
  project: prop<identifier>(),
  cluster: prop<identifier>(),
  clusterName: prop<string>(),
  issueDate: prop<string>(),
  reissueDate: prop<string>(),
  reissueVersion: prop<number>(),
  dueDate: prop<string>(),
  submitDate: prop<string>(),
  submitVersion: prop<number | null>(null),
  approvedDate: prop<string>(),
  notes: prop<string>(),
  reviewerNotes: prop<string>(),
  status: prop<string>(),
  hasQueries: prop<boolean>(),
  isLocked: prop<boolean>(),
}) {
  @computed
  get asAPI(): APIClusterInputSheetsStatusInput {
    return {
      project: this.project,
      cluster: this.cluster,
      issueDate: this.issueDate,
      dueDate: this.dueDate,
      submitDate: this.submitDate,
      approvedDate: this.approvedDate,
      notes: this.notes,
      reviewerNotes: this.reviewerNotes,
      status: this.status,
      hasQueries: this.hasQueries,
      isLocked: this.isLocked,
    };
  }
}

export default ClusterInputSheetsStatus;
