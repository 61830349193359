import base, { APIList, APISearchParams, config } from "./base";

export interface APIPlant {
  id: identifier;
  name: string;
  code: string;
}

export interface APIPlantList extends APIList<APIPlant> {}

export interface APIPlantListSearchParams extends APISearchParams {
  name?: string;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIPlantListSearchParams
) =>
  base.list<APIPlantList, APIPlantListSearchParams>(
    "plants/",
    page,
    limit,
    searchParams
  );
