import React from "react";
import { FormatterProps } from "react-data-grid";

type Props = FormatterProps;

export const getNumberFormatter = (
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 4,
  percentage: boolean = false
) => {
  const formatter = new Intl.NumberFormat("en-GB", {
    maximumFractionDigits,
    minimumFractionDigits,
  });

  const NumberFormatter: React.FC<Props> = ({ row, column }) => {
    const value = row[column.key];

    if (value === undefined || value === "") {
      return <></>;
    }

    return (
      <>
        {formatter.format(value)}
        {percentage ? "%" : ""}
      </>
    );
  };

  return React.memo(NumberFormatter);
};

export default getNumberFormatter();
