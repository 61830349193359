import base, { APIDetail, APIList, APISearchParams, config } from "./base";
import { APIAdditive, APIBaseOil } from "./formulations";

export interface APIRMFormulationBaseOilPercentage {
  id: identifier;
  formulation: identifier;
  rmVolume: number;
  treat: string;
  baseOil: APIBaseOil;
}

export interface APIRMFormulationAdditivePercentage {
  id: identifier;
  formulation: identifier;
  rmVolume: number;
  treat: string;
  additive: APIAdditive;
}

export interface APIRMFormulation {
  id: identifier;
  project: identifier;
  specs: string;
  code: string;
  plant: identifier | null;
  plantName: string;
  plantType: "master" | "plant" | null;
  formulationOption: string;
  plantOption: string;
  altScenario: string;
  fgVol: number;
  ordering: identifier;
  baseOilPercentages: APIRMFormulationBaseOilPercentage[];
  additivePercentages: APIRMFormulationAdditivePercentage[];
}

export interface APIRMFormulationList extends APIList<APIRMFormulation> {}

export interface APIRMFormulationListSearchParams extends APISearchParams {
  project: identifier;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIRMFormulationListSearchParams
) =>
  base.list<APIRMFormulationList>(
    "raw-material-report-formulations/",
    page,
    limit,
    searchParams
  );

export interface APIRMFormulationDetail extends APIDetail<APIRMFormulation> {}

export const detail = (rmFormulationId: identifier) =>
  base.get<APIRMFormulationDetail>(
    `raw-material-report-formulations/${rmFormulationId}/`
  );
