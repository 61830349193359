import { CSVFrozenHeaderMapping } from "csv/mappings";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { APICountryListSearchParams } from "../../api/countries";
import { APIInputSheetFileType } from "../../api/inputSheets";
import GenerateOutputsButton from "../../components/GenerateOutputsButton";
import Loading from "../../components/Loading";
import {
  getFrozenMappingOHLogs,
  getFrozenMappingOHMfg,
  getFrozenMappingRMWorking,
  mappingCapex,
  mappingMarginalCostPctCountry,
  mappingMarginalCostPctPlant,
  mappingOHLogs,
  mappingOHPackMfg,
  mappingRMWorking,
} from "../../csv/mappings/collect";
import { useStore } from "../../store";
import InputSheet from "../../store/models/InputSheet";
import WorkArea from "../WorkArea";
import { useProject } from "../Workspace/hooks";
import { CsvFile, TabList } from "../Workspace/types";
import CLTAssumptionsTab from "./CLTAssumptionsTab";
import { useProjectState } from "./hooks";
import {
  schemaCapex,
  schemaMarginalCostPctCountry,
  schemaMarginalCostPctPlant,
  schemaOHLogs,
  schemaOHPackMfg,
  schemaRMWorking,
} from "./schema";
import {
  marginalCostPctValidations,
  ohLogsValidations,
  ohPackMfgValidations,
  rmWorkingValidations,
} from "./validations";
import {
  ohLogsValueParsers,
  ohPackMfgValueParsers,
  rmWorkingValueParsers,
} from "./valueParsers";

type Props = {};

const CollectSection: React.FC<Props> = () => {
  const store = useStore();
  const project = useProject();
  const currentUser = store.auth.current;

  const hasEditPermission = !!(
    currentUser &&
    (currentUser.isAdmin ||
      currentUser.isAnalyst ||
      project?.adminUsers.includes(currentUser.id))
  );
  const editable = !project?.isLocked && hasEditPermission;

  useEffect(() => {
    (async () => {
      if (project) {
        const countryParams: APICountryListSearchParams = {
          project_id: project.id.toString(),
        };
        await store.assets.listCountries(undefined, undefined, countryParams);
      }
    })();
  }, [project, store.assets]);

  useProjectState(project, currentUser);

  const sheets = project
    ? store.inputSheets.ofProject(project.id)
    : new Map<APIInputSheetFileType, InputSheet[]>();

  const [frozenRMWorking, setFrozenRMWorking] =
    useState<CSVFrozenHeaderMapping>(getFrozenMappingRMWorking(true));
  const [frozenOHLogs, setFrozenOHLogs] = useState<CSVFrozenHeaderMapping>(
    getFrozenMappingOHLogs(true)
  );
  const [frozenOHMfg, setFrozenOHMfg] = useState<CSVFrozenHeaderMapping>(
    getFrozenMappingOHMfg(true)
  );

  const tabs: TabList = {
    rmWorking: {
      kind: "worksheet",
      id: "rmWorking",
      title: "RM Working",
      type: "sheet-collect",
      fileType: "rm_working",
      validationChecks: rmWorkingValidations,
      valueParsers: rmWorkingValueParsers,
      frozenColumns: frozenRMWorking,
      setFrozenColumns: setFrozenRMWorking,
      csvFiles:
        sheets.get("rm_working")?.map((sheet) => {
          const cltLocked = project?.lockedClusters.includes(sheet.cluster);
          const sheetEditable = !cltLocked;

          return [
            {
              id: `rmWorking-${sheet.id}`,
              sheet,
              downloadable: true,
              editable: editable && sheetEditable,
              updatable: editable && sheetEditable,
              title: sheet.clusterName,
              schema: (key, sheetEditable) =>
                schemaRMWorking(key, sheetEditable, frozenRMWorking),
              headerMapping: mappingRMWorking,
              rowIdentifier: "concatenate",
              extraHeaderRow: [
                {
                  id: "",
                  rm_purchase_price:
                    "Supplier Price List Information (RM Supplier Invoiced Costs Only) - Values in price/unit",
                  rebates_per_mt: "price/unit. use negative values",
                  cost_per_mt: "Landed Cost per MT",
                  freight_cost: "price/unit",
                } as any,
              ],
              hideColumns: () => ["raw_material_id", "concatenate"],
            },
          ];
        }) || [],
      marginalCsvFiles: [],

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.inputSheets.list(project.id, undefined, undefined, {
          file_type: "rm_working",
        });
      },
    },
    marginalCostPct: {
      kind: "worksheet",
      id: "marginalCostPct",
      title: "Marginal Cost %",
      type: "sheet-collect",
      fileType: "marginal_cost_percent_country",
      uploadable: false,
      validationChecks: marginalCostPctValidations,
      csvFiles: (() => {
        const marginalCostPctSheets = (
          sheets.get("marginal_cost_percent_country")?.map((sheet) => {
            const cltLocked = project?.lockedClusters.includes(sheet.cluster);
            const sheetEditable = !cltLocked;

            return {
              id: `marginalCostPctCountry-${sheet.id}`,
              sheet,
              downloadable: true,
              editable: editable && sheetEditable,
              updatable: editable && sheetEditable,
              title: sheet.clusterName,
              schema: schemaMarginalCostPctCountry,
              headerMapping: mappingMarginalCostPctCountry,
              rowIdentifier: "id",
            };
          }) || []
        )
          .concat(
            sheets.get("marginal_cost_percent_plants")?.map((sheet) => {
              const cltLocked = project?.lockedClusters.includes(sheet.cluster);
              const sheetEditable = !cltLocked;

              return {
                id: `marginalCostPctPlant-${sheet.id}`,
                sheet,
                downloadable: true,
                editable: editable && sheetEditable,
                updatable: editable && sheetEditable,
                title: sheet.clusterName,
                schema: schemaMarginalCostPctPlant,
                headerMapping: mappingMarginalCostPctPlant,
                rowIdentifier: "id",
              };
            }) || []
          )
          .reduce(
            (acc, curr) => ({
              ...acc,
              [curr.title]: [...(acc[curr.title] || []), curr],
            }),
            {} as Record<string, CsvFile[]>
          );
        return Object.values(marginalCostPctSheets);
      })(),
      marginalCsvFiles: [],

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.inputSheets.list(project.id, undefined, undefined, {
          file_type__in: [
            "marginal_cost_percent_country",
            "marginal_cost_percent_plants",
          ],
        });
      },
    },
    ohPackMfg: {
      kind: "worksheet",
      id: "ohPackMfg",
      title: "OH Pack Mfg",
      type: "sheet-collect",
      fileType: "oh_full_pack_mfg",
      validationChecks: ohPackMfgValidations,
      valueParsers: ohPackMfgValueParsers,
      frozenColumns: frozenOHMfg,
      setFrozenColumns: setFrozenOHMfg,
      comments:
        "Note: All inputs must be entered as cost per liter unless indicated as %",
      csvFiles:
        sheets.get("oh_full_pack_mfg")?.map((sheet) => {
          const cltLocked = project?.lockedClusters.includes(sheet.cluster);
          const sheetEditable = !cltLocked;

          return [
            {
              id: `ohPackMfg-${sheet.id}`,
              sheet,
              downloadable: true,
              editable: editable && sheetEditable,
              updatable: editable && sheetEditable,
              title: sheet.clusterName,
              rowIdentifier: "concatenate",
              schema: (key, sheetEditable) =>
                schemaOHPackMfg(
                  key,
                  sheetEditable,
                  sheet.clusterName,
                  frozenOHMfg
                ),
              headerMapping: mappingOHPackMfg,
              hideColumns: () => [
                "source_clt",
                "repackaging_clt",
                "concatenate",
              ],
            },
          ];
        }) || [],
      marginalCsvFiles:
        sheets.get("oh_marg_pack_mfg")?.map((sheet) => [
          {
            id: `ohMarginalPackMfg-${sheet.id}`,
            sheet,
            downloadable: true,
            editable: false,
            updatable: false,
            title: sheet.clusterName,
            schema: (key, sheetEditable) =>
              schemaOHPackMfg(
                key,
                sheetEditable,
                sheet.clusterName,
                frozenOHMfg
              ),
            rowIdentifier: "concatenate",
            headerMapping: mappingOHPackMfg,
            hideColumns: () => ["source_clt", "repackaging_clt", "concatenate"],
          },
        ]) || [],
      renderExtraToolbar: (worksheet) => (
        <GenerateOutputsButton
          text="Update marginal sheets"
          onClick={worksheet.csvLoader}
        />
      ),

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.inputSheets.list(project.id, undefined, undefined, {
          file_type__in: ["oh_full_pack_mfg", "oh_marg_pack_mfg"],
        });
      },
    },
    ohLogs: {
      kind: "worksheet",
      id: "ohLogs",
      title: "OH Logs",
      type: "sheet-collect",
      fileType: "oh_full_logs",
      validationChecks: ohLogsValidations,
      valueParsers: ohLogsValueParsers,
      frozenColumns: frozenOHLogs,
      setFrozenColumns: setFrozenOHLogs,
      comments:
        "Note: All inputs must be entered as cost per liter unless indicated as %",
      csvFiles:
        sheets.get("oh_full_logs")?.map((sheet) => {
          const cltLocked = project?.lockedClusters.includes(sheet.cluster);
          const sheetEditable = !cltLocked;

          return [
            {
              id: `ohLogs-${sheet.id}`,
              sheet,
              downloadable: true,
              editable: editable && sheetEditable,
              updatable: editable && sheetEditable,
              title: sheet.clusterName,
              schema: (key, sheetEditable) => {
                return schemaOHLogs(
                  key,
                  sheetEditable,
                  sheet.clusterName,
                  frozenOHLogs
                );
              },
              headerMapping: mappingOHLogs,
              rowIdentifier: "concatenate",
              hideColumns: () => [
                "source_clt",
                "repackaging_clt",
                "concatenate",
              ],
            },
          ];
        }) || [],
      marginalCsvFiles:
        sheets.get("oh_marg_logs")?.map((sheet) => [
          {
            id: `ohMarginalLogs-${sheet.id}`,
            sheet,
            downloadable: true,
            editable: false,
            updatable: true,
            title: sheet.clusterName,
            schema: (key, sheetEditable) => {
              return schemaOHLogs(
                key,
                sheetEditable,
                sheet.clusterName,
                frozenOHLogs
              );
            },
            headerMapping: mappingOHLogs,
            rowIdentifier: "concatenate",
            hideColumns: () => ["source_clt", "repackaging_clt", "concatenate"],
          },
        ]) || [],
      renderExtraToolbar: (worksheet) => (
        <GenerateOutputsButton
          text="Update marginal sheets"
          onClick={worksheet.csvLoader}
        />
      ),

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.inputSheets.list(project.id, undefined, undefined, {
          file_type__in: ["oh_full_logs", "oh_marg_logs"],
        });
      },
    },
    cltAssumptions: {
      kind: "custom",
      id: "cltAssumptions",
      title: "CLT Assumptions",
      type: "sheet-collect",
      render: CLTAssumptionsTab,
    },
    capex: {
      kind: "worksheet",
      id: "capex",
      title: "Capex Summary",
      type: "sheet-collect",
      fileType: "capex_summary",
      csvFiles:
        sheets.get("capex_summary")?.map((sheet) => {
          const cltLocked = project?.lockedClusters.includes(sheet.cluster);
          const sheetEditable = !cltLocked;

          return [
            {
              id: `capex-${sheet.id}`,
              sheet,
              downloadable: true,
              editable: editable && sheetEditable,
              insertable: editable && sheetEditable,
              updatable: editable && sheetEditable,
              title: sheet.clusterName,
              schema: schemaCapex,
              rowIdentifier: "id",
              headerMapping: mappingCapex,
            },
          ];
        }) || [],
      marginalCsvFiles: [],

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.inputSheets.list(project.id, undefined, undefined, {
          file_type: "capex_summary",
        });
      },
    },
  };

  if (!project) {
    return <Loading full />;
  }

  return <WorkArea tabs={tabs} />;
};

export default observer(CollectSection);
