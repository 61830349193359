import Loading from "components/Loading";
import { observer } from "mobx-react-lite";
import TenderOutcomeTable from "pages/StatusSection/TenderOutcomeTable";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { Toast } from "utils";
import { APISearchParams } from "../../api/base";
import { useStore } from "../../store";
import { Page } from "../Page";

const RootContainer = styled.div`
  margin: 1.875rem 3.75rem;
`;

interface ProjectsDriveProps {}

const TenderOutcomesDashboard: React.FC<ProjectsDriveProps> = () => {
  const store = useStore();
  const fetchTenderOutcomesList = async (params: APISearchParams) => {
    return store.tenderOutcomes.list(1, -1, params);
  };

  const [{ loading: projectsLoading, value: tenderOutcomes }, fetchProjects] =
    useAsyncFn(async () => {
      const params: APISearchParams = {};

      const { count } = await fetchTenderOutcomesList(params);
      return {
        count,
        results: Array.from(store.tenderOutcomes.listItems.values()),
      };
    });

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const methods = useForm();

  const handleSave = () =>
    Toast.danger("Tender Outcomes must be updated from within Projects.");

  if (projectsLoading || !tenderOutcomes) {
    return <Loading />;
  }

  return (
    <Page
      title="Projects"
      breadcrumb={[
        {
          title: "Tender Outcomes Dashboard",
          subtitle: "Tender Outcomes",
          link: "/tender-outcomes",
        },
      ]}
    >
      <RootContainer>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleSave)}>
            <TenderOutcomeTable hasEditPermissions={false} />
          </Form>
        </FormProvider>
      </RootContainer>
    </Page>
  );
};

export default observer(TenderOutcomesDashboard);
