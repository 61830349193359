import React from "react";
import { Modal as BSModal } from "react-bootstrap";
import { confirmable } from "react-confirm";
import styled from "styled-components";
import Button from "../Button";
import { SubHeader } from "../Header";

const Modal = styled(BSModal)`
  .modal-content {
    background: #ffffff;
    border: 0.063rem solid #000000;
    box-sizing: border-box;
    box-shadow: 0 0.25rem 0.875rem rgba(0, 0, 0, 0.14);
    border-radius: 0.25rem;
  }

  .modal-body {
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 1.875rem 0.25rem;
    padding: 1.875rem 1.875rem 2.438rem;

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 2.25rem;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ButtonDismiss = styled(Button)`
  &.btn-cancel {
    font-weight: bold;
    color: #000000;
  }
`;

type Props = {
  okLabel?: string | JSX.Element;
  subtitle?: string;
  dismission?: string;
  show?: boolean;
  proceed: (value: boolean) => void;
  enableEscape?: boolean;
};

const DismissDataModal = ({
  okLabel = "Dismiss",
  subtitle,
  dismission,
  show,
  proceed,
  enableEscape = true,
}: Props) => {
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      backdrop={enableEscape ? true : "static"}
      keyboard={enableEscape}
      centered
    >
      <Modal.Body>
        <div>
          <SubHeader>{subtitle}</SubHeader>
          <p>{dismission}</p>
        </div>
        <ButtonContainer>
          <ButtonDismiss variant="cancel" onClick={() => proceed(true)}>
            {okLabel}
          </ButtonDismiss>
        </ButtonContainer>
      </Modal.Body>
    </Modal>
  );
};

export default confirmable(DismissDataModal);
