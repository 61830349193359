import api from "api";
import { sheetErrorMap } from "csv/constants";
import { Cell, IRow } from "csv/types";
import { parseRows } from "csv/utils";
import { useCallback, useMemo, useState } from "react";
import { Column } from "react-data-grid";
import { CsvFileSet } from "../../pages/Workspace/types";
import { CsvValidationErrorContextProps } from "./contexts";

export function useValidationSummary(
  csvFileSets: CsvFileSet[] = [],
  selectedTabIndex: number = 0
) {
  const [errorCount, setErrorCount] = useState<Record<string, number>>({});
  const [warningCount, setWarningCount] = useState<Record<string, number>>({});
  const [errorKeys, setErrorKeys] = useState<Record<string, Set<string>>>({});
  const [columnNameMaps, setColumnNameMaps] = useState<
    Record<string, Record<string, string>>
  >({});

  const displayErrorCount = useMemo(
    () =>
      csvFileSets.length > 0
        ? csvFileSets[selectedTabIndex].reduce(
            (acc, curr) => acc + errorCount[curr.id],
            0
          )
        : Object.values(errorCount).reduce((acc, curr) => {
            return acc + curr;
          }, 0),
    [csvFileSets, errorCount, selectedTabIndex]
  );

  const displayWarningCount = useMemo(
    () =>
      csvFileSets.length > 0
        ? csvFileSets[selectedTabIndex].reduce(
            (acc, curr) => acc + warningCount[curr.id],
            0
          )
        : Object.values(warningCount).reduce((acc, curr) => {
            return acc + curr;
          }, 0),
    [csvFileSets, selectedTabIndex, warningCount]
  );

  const displayErrorKeys = useMemo(
    () =>
      csvFileSets.length > 0
        ? csvFileSets[selectedTabIndex].reduce(
            (acc, curr) =>
              new Set<string>([...acc, ...(errorKeys[curr.id] || [])]),
            new Set<string>()
          )
        : Object.values(errorKeys).reduce((acc, curr) => {
            Array.from(curr).map((e) => acc.add(e));
            return acc;
          }, new Set<string>()),
    [csvFileSets, errorKeys, selectedTabIndex]
  );

  const validationColumnNameMap: Record<string, string> = useMemo(
    () =>
      csvFileSets.length > 0
        ? csvFileSets[selectedTabIndex].reduce(
            (acc, curr) => ({ ...acc, ...columnNameMaps[curr.id] }),
            {}
          )
        : Object.values(columnNameMaps).reduce((acc, curr) => {
            return { ...acc, ...curr };
          }, {}),
    [columnNameMaps, csvFileSets, selectedTabIndex]
  );

  const state = useMemo(
    () => ({
      errorCount,
      warningCount,
      errorKeys,
      columnNameMaps,
    }),
    [columnNameMaps, errorCount, errorKeys, warningCount]
  );

  const setters = useMemo(
    () => ({
      setErrorCount,
      setWarningCount,
      setErrorKeys,
      setColumnNameMaps,
    }),
    []
  );

  const display = useMemo(
    () => ({
      errorCount: displayErrorCount,
      warningCount: displayWarningCount,
      errorKeys: displayErrorKeys,
      validationColumnNameMap,
    }),
    [
      displayErrorCount,
      displayErrorKeys,
      displayWarningCount,
      validationColumnNameMap,
    ]
  );

  return {
    state,
    setters,
    display,
  };
}

export function usePopulateValidationSummary(
  setters: CsvValidationErrorContextProps["setters"]
): [
  (
    id: string,
    rows: readonly IRow[],
    columns: readonly Column<IRow>[],
    error: string | ((row: IRow, column: Column<IRow>) => string)
  ) => Promise<void>,
  Record<string, string>
] {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const onPopulate = useCallback(
    async (
      id: string,
      rows: readonly IRow[],
      columns: readonly Column<IRow>[],
      error: string | ((row: IRow, column: Column<IRow>) => string)
    ) => {
      const {
        setErrorCount,
        setWarningCount,
        setErrorKeys,
        setColumnNameMaps,
      } = setters;

      let errorCount = 0;
      let warningCount = 0;
      let errorKeys: Set<string> = new Set();
      let columnNameMap: Record<string, string> = {};
      const tempErrors: Record<string, string> = {};

      let parsedRows: readonly IRow[];
      let errorEvaluator: (row: IRow, column: Column<IRow>) => Cell;

      if (typeof error === "string") {
        const response = await api.csv.get(error);
        const rawData = await response.text();

        parsedRows = parseRows<IRow>(rawData);
        errorEvaluator = (row: IRow, column: Column<IRow>) => row[column.key];
      } else {
        parsedRows = rows;
        errorEvaluator = error;
      }

      // remove header row
      parsedRows.forEach((row, index) => {
        columns.forEach((column) => {
          const { key } = column;

          if (!column) {
            return;
          }

          const value = errorEvaluator(row, column) as string;

          if (value !== "" && value in sheetErrorMap) {
            const cellKey = `${key}-${index + 1}`; // use row id for error map
            const errorType = value.charAt(0);
            tempErrors[cellKey] = `${errorType}:${sheetErrorMap[value]}`;
            columnNameMap[column.key] = `${column.name}`.replaceAll("\n", "");
            errorKeys.add(`${column.key}-${value}`);
            if (errorType === "W") {
              warningCount++;
            } else if (errorType === "E") {
              errorCount++;
            }
          }
        });
      });

      setErrors(tempErrors);
      setErrorCount((prev) => {
        const prevCopy = { ...prev };
        prevCopy[id] = errorCount;
        return prevCopy;
      });
      setWarningCount((prev) => {
        const prevCopy = { ...prev };
        prevCopy[id] = warningCount;
        return prevCopy;
      });
      setErrorKeys((prev) => {
        const prevCopy = { ...prev };
        prevCopy[id] = errorKeys;
        return prevCopy;
      });
      setColumnNameMaps((prev) => {
        const prevCopy = { ...prev };
        prevCopy[id] = columnNameMap;
        return prevCopy;
      });
    },
    [setters]
  );

  return [onPopulate, errors];
}
