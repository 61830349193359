import {
  Add,
  DeleteOutline,
  LockOpen,
  Public,
  ViewListOutlined,
} from "@material-ui/icons";
import { APIProjectInput } from "api/projects";
import React, { useEffect, useMemo, useState } from "react";
import { Form, FormControl } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { useAsync } from "react-use";
import styled from "styled-components";
import api from "../../api";
import { APICountry, APICountryInput } from "../../api/types";
import { APIUserListSearchParams } from "../../api/users";
import Button from "../../components/Button";
import FormLabel from "../../components/FormLabel";
import ReactSelect, { customStyles } from "../../components/ReactSelect";
import { useStore } from "../../store";
import Project from "../../store/models/Project";

const NameInput = styled(FormControl)`
  font-size: 0.875rem;
  line-height: 0.875rem;
  height: 2.375rem;

  &:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #74b468;
    outline: #74b468 auto 1px;
    box-shadow: none;
  }
`;

const CountriesLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddCountryButton = styled(Button)`
  &.btn {
    font-size: 0.625rem;
    line-height: 0.625rem;
    text-transform: uppercase;
    padding: 0;

    &.btn-add-country {
      border: 0;

      &:focus {
        outline: 0;
      }
    }
  }
`;

const DeleteCountryButton = styled(Button)`
  &.btn {
    &.btn-delete-country {
      border: 0;

      &:focus {
        outline: 0;
      }

      svg {
        width: 18px;
        height: 18px;
        margin-right: 0;
      }
    }
  }
`;

const CountriesDiv = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

const StyledDiv = styled.div`
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 100%;
`;

interface Props {
  instance: Project;
  editable?: boolean;
}

interface Inputs extends APIProjectInput {
  additionalCountries: APICountry[];
}

const SettingsForm: React.FC<Props> = ({ instance, editable }) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useFormContext<Inputs>();
  const [additionalCountries, setAdditionalCountries] = useState<
    APICountryInput[]
  >([]);
  const store = useStore();
  const clusters = [
    { value: undefined, label: "(Select)" },
    ...store.assets.clusterSelect,
  ];

  const choicesResults = useAsync(async () => {
    const params: APIUserListSearchParams = {
      is_active: true,
    };

    return api.users.list(undefined, undefined, params);
  });

  useEffect(() => {
    const addtlCountries = instance.additionalCountries.map((item) => ({
      id: item.id,
      name: item.name,
      cluster: item.cluster,
    }));
    setAdditionalCountries(addtlCountries);
    reset({
      name: instance.name,
      type: instance.type,
      opsInputUsers: instance.opsInputUsers,
      rmPriceUsers: instance.rmPriceUsers,
      projectOutputUsers: instance.projectOutputUsers,
      adminUsers: instance.adminUsers,
      additionalCountries: addtlCountries,
    });
  }, [instance, setAdditionalCountries, getValues, reset]);

  const handleAddCountries = () => {
    const defaultAdditionalCountry = {
      id: undefined,
      name: "",
      cluster: undefined,
    };
    setAdditionalCountries((prev) => [...prev, defaultAdditionalCountry]);
  };

  const options = useMemo(() => {
    const { loading, value: response } = choicesResults;
    if (!loading && response) {
      if (response.results.length > 0) {
        return response.results.map((item) => {
          return {
            value: item.id,
            label: item.name || `<${item.email}>`,
            avatar: item.photo || "",
          };
        });
      }
    }
    return [];
  }, [choicesResults]);

  const projectTypeOptions = [
    { value: "costing", label: "Costing Exercise" },
    { value: "project", label: "Project" },
    { value: "tender", label: "Tender" },
  ];

  return (
    <>
      <Form.Group>
        <FormLabel>
          <ViewListOutlined />
          Project Name
        </FormLabel>
        <Controller
          name="name"
          control={control}
          defaultValue={instance.name}
          rules={{ required: true }}
          render={({ field }) => (
            <NameInput
              type="text"
              disabled={!editable}
              {...field}
              isInvalid={errors.name}
              onChange={(name: string) => field.onChange(name)}
            />
          )}
        />
        {errors.name && (
          <Form.Control.Feedback type="invalid">
            {(errors.name.type as unknown as string) === "required"
              ? "This field may not be blank."
              : errors.name.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group>
        <FormLabel>
          <LockOpen />
          Project Type
        </FormLabel>
        <Controller
          name="type"
          control={control}
          defaultValue={instance.name}
          rules={{ required: true }}
          render={({ field }) => (
            <ReactSelect
              defaultValue={instance.type}
              disabled={!editable}
              name="type"
              options={projectTypeOptions}
              placeholder="Select Project Type"
              noOptionsMessage="-"
              hideSelectedOptions={false}
            />
          )}
        />
      </Form.Group>
      <Form.Group>
        <FormLabel>
          <LockOpen />
          ATP/GSC Ops Input
        </FormLabel>
        <ReactSelect
          defaultValue={instance.opsInputUsers}
          disabled={!editable}
          name="opsInputUsers"
          options={options}
          placeholder="Select users"
          noOptionsMessage="No Users"
          isMulti
        />
      </Form.Group>
      <Form.Group>
        <FormLabel>
          <LockOpen />
          Procurement RM Price Input
        </FormLabel>
        <ReactSelect
          defaultValue={instance.rmPriceUsers}
          disabled={!editable}
          name="rmPriceUsers"
          options={options}
          placeholder="Select users"
          noOptionsMessage="No Users"
          isMulti
        />
      </Form.Group>
      <Form.Group>
        <FormLabel>
          <LockOpen />
          Project Output Recipient
        </FormLabel>
        <ReactSelect
          defaultValue={instance.projectOutputUsers}
          disabled={!editable}
          name="projectOutputUsers"
          options={options}
          placeholder="Select users"
          noOptionsMessage="No Users"
          isMulti
        />
      </Form.Group>
      <Form.Group>
        <FormLabel>
          <LockOpen />
          Admin (Project Owner)
        </FormLabel>
        <ReactSelect
          defaultValue={instance.adminUsers}
          disabled={!editable}
          name="adminUsers"
          options={options}
          placeholder="Select users"
          noOptionsMessage="No Users"
          isMulti
        />
      </Form.Group>
      <Form.Group>
        <CountriesLabel>
          <FormLabel>
            <Public />
            Countries
          </FormLabel>
          {editable && (
            <AddCountryButton
              variant="add-country"
              onClick={handleAddCountries}
            >
              <Add /> Add Country
            </AddCountryButton>
          )}
        </CountriesLabel>
        {additionalCountries.length > 0 &&
          additionalCountries.map((additionalCountry, index, array) => {
            const { id, name, cluster } = additionalCountry;
            return (
              <CountriesDiv key={index}>
                <Controller
                  name={`additionalCountries.${index}.id`}
                  control={control}
                  defaultValue={id}
                  render={({ field }) => (
                    <NameInput
                      {...field}
                      type="hidden"
                      value={id}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newId = e.target.value;
                        setAdditionalCountries((prev) => {
                          prev[index].name = newId;
                          return [...prev];
                        });
                        setValue(`additionalCountries.${index}.name`, newId);
                      }}
                    />
                  )}
                />
                <StyledDiv>
                  <Controller
                    name={`additionalCountries.${index}.name`}
                    control={control}
                    defaultValue={name}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <NameInput
                        {...field}
                        type="text"
                        disabled={!editable}
                        value={name}
                        isInvalid={
                          errors.additionalCountries &&
                          errors.additionalCountries[index] &&
                          errors?.additionalCountries[index]?.name
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const newName = e.target.value;
                          setAdditionalCountries((prev) => {
                            prev[index].name = newName;
                            return [...prev];
                          });
                          setValue(
                            `additionalCountries.${index}.name`,
                            newName
                          );
                        }}
                        placeholder="Enter Country"
                      />
                    )}
                  />
                  {errors.additionalCountries &&
                    errors.additionalCountries[index] &&
                    errors?.additionalCountries[index]?.name && (
                      <Form.Control.Feedback type="invalid">
                        {(errors?.additionalCountries[index]?.name
                          ?.type as unknown as string) === "required"
                          ? "This field may not be blank."
                          : errors?.additionalCountries[index]?.name}
                      </Form.Control.Feedback>
                    )}
                </StyledDiv>
                <StyledDiv>
                  <Controller
                    name={`additionalCountries.${index}.cluster`}
                    control={control}
                    defaultValue={cluster}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={clusters}
                        value={clusters.find(
                          (option) => cluster === option.value
                        )}
                        className={
                          errors.additionalCountries &&
                          errors.additionalCountries[index] &&
                          errors?.additionalCountries[index]?.cluster
                            ? "is-invalid"
                            : undefined
                        }
                        isDisabled={!editable}
                        styles={{
                          ...customStyles,
                          control: (provided: any, state) => ({
                            ...customStyles.control(provided, state),
                            borderColor:
                              errors.additionalCountries &&
                              errors.additionalCountries[index] &&
                              errors?.additionalCountries[index]?.cluster
                                ? "#dc3545"
                                : state.isFocused
                                ? "#74b468"
                                : "#ced4da",
                          }),
                        }}
                        hideSelectedOptions
                        onChange={(value) => {
                          if (value) {
                            setAdditionalCountries((prev) => {
                              prev[index].cluster = value.value;
                              return [...prev];
                            });
                            setValue(
                              `additionalCountries.${index}.cluster`,
                              value.value as number
                            );
                          }
                        }}
                        placeholder="Select cluster"
                      />
                    )}
                  />
                  {errors.additionalCountries &&
                    errors.additionalCountries[index] &&
                    errors?.additionalCountries[index]?.cluster && (
                      <Form.Control.Feedback type="invalid">
                        {(errors?.additionalCountries[index]?.cluster
                          ?.type as unknown as string) === "required"
                          ? "This field may not be blank."
                          : errors?.additionalCountries[index]?.cluster}
                      </Form.Control.Feedback>
                    )}
                </StyledDiv>
                <div>
                  {editable && (
                    <DeleteCountryButton
                      variant="delete-country"
                      onClick={() => {
                        const addtlCountries = additionalCountries;
                        addtlCountries.splice(index, 1);
                        setAdditionalCountries([...addtlCountries]);
                        reset({
                          ...getValues(),
                          additionalCountries: additionalCountries,
                        });
                      }}
                    >
                      <DeleteOutline />
                    </DeleteCountryButton>
                  )}
                </div>
              </CountriesDiv>
            );
          })}
      </Form.Group>
    </>
  );
};

export default SettingsForm;
