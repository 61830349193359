import { useEffect } from "react";
import {
  dismissCLTApproved,
  dismissCLTRejected,
  dismissProjectLock,
  dismissProjectUnlock,
} from "../../utils/confirm";
import localforage from "localforage";
import Project from "../../store/models/Project";
import { APIUser } from "../../api/types";

export function useProjectState(
  project?: Project,
  currentUser?: APIUser | null
) {
  useEffect(() => {
    (async () => {
      if (project) {
        const projectNotifiedKey = `notifiedProjectLocked-${project.id}-${currentUser?.id}`;
        const notifiedProjectLocked =
          (await localforage.getItem(projectNotifiedKey)) ??
          (await localforage.setItem(projectNotifiedKey, false));
        const cltLocked =
          currentUser?.analystCluster &&
          project?.lockedClusters.includes(currentUser.analystCluster);

        if (project.isLocked || cltLocked) {
          if (!notifiedProjectLocked) {
            if (await dismissProjectLock()) {
              await localforage.setItem(
                projectNotifiedKey,
                project.isLocked || cltLocked
              );
            }
          }
        } else {
          if (notifiedProjectLocked) {
            if (await dismissProjectUnlock()) {
              await localforage.setItem(
                projectNotifiedKey,
                project.isLocked || cltLocked
              );
            }
          }
        }

        if (currentUser?.isAnalyst && currentUser?.analystCluster) {
          const { id, analystCluster } = currentUser;
          const cltNotifiedApprovedKey = `notifiedCltApproved-${analystCluster}-${id}`;
          const notifiedCltApproved =
            (await localforage.getItem(cltNotifiedApprovedKey)) ??
            (await localforage.setItem(cltNotifiedApprovedKey, false));
          const cltApproved = project.approvedClusters.includes(
            currentUser.analystCluster
          );

          if (cltApproved) {
            if (!notifiedCltApproved) {
              if (await dismissCLTApproved()) {
                await localforage.setItem(cltNotifiedApprovedKey, true);
              }
            }
          } else {
            if (notifiedCltApproved) {
              await localforage.setItem(cltNotifiedApprovedKey, false);
            }
          }

          const cltNotifiedRejectedKey = `notifiedCltRejected-${analystCluster}-${id}`;
          const notifiedCltRejected =
            (await localforage.getItem(cltNotifiedRejectedKey)) ??
            (await localforage.setItem(cltNotifiedRejectedKey, false));
          const cltRejected = project.rejectedClusters.includes(
            currentUser.analystCluster
          );

          if (cltRejected) {
            if (!notifiedCltRejected) {
              if (await dismissCLTRejected()) {
                await localforage.setItem(cltNotifiedRejectedKey, true);
              }
            }
          } else {
            if (notifiedCltRejected) {
              await localforage.setItem(cltNotifiedRejectedKey, false);
            }
          }
        }
      }
    })();
  }, [project, currentUser]);
}
