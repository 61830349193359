const FolderStar: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8021_454)">
        <path
          d="M15 4.5H9L7.5 3H3C2.175 3 1.5 3.675 1.5 4.5V13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V6C16.5 5.175 15.825 4.5 15 4.5ZM13.455 12.75L11.25 11.46L9.045 12.75L9.63 10.2525L7.6875 8.5725L10.245 8.355L11.25 6L12.255 8.355L14.8125 8.5725L12.87 10.2525L13.455 12.75Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_8021_454">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default FolderStar;
