import numeral from "numeral";
import { Cell } from "../../csv/types";

export const numberParser: (e: Cell) => Cell = (e) => {
  //Catch case where pasted cell is whitespace
  if (typeof e === "string" && e.length > 0 && e.trim() === "") {
    return "";
  }
  const parsedValue = numeral(`${e}`).value();
  if (parsedValue) return parsedValue;
  return e;
};
