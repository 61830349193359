import React from "react";
import { SvgIcon as MSvgIcon, SvgIconProps } from "@material-ui/core";
import styled from "styled-components";

const SvgIcon = styled(MSvgIcon)`
  color: transparent;
`;

function Money(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M1.6665 5.83268C1.6665 5.39065 1.8421 4.96673 2.15466 4.65417C2.46722 4.34161 2.89114 4.16602 3.33317 4.16602H16.6665C17.1085 4.16602 17.5325 4.34161 17.845 4.65417C18.1576 4.96673 18.3332 5.39065 18.3332 5.83268V14.166C18.3332 14.608 18.1576 15.032 17.845 15.3445C17.5325 15.6571 17.1085 15.8327 16.6665 15.8327H3.33317C2.89114 15.8327 2.46722 15.6571 2.15466 15.3445C1.8421 15.032 1.6665 14.608 1.6665 14.166V5.83268Z"
        stroke="#242424"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        stroke="#242424"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 7.49935C2.55056 7.49935 3.39841 7.14816 4.02353 6.52304C4.64865 5.89792 4.99984 5.05007 4.99984 4.16602"
        stroke="#242424"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15.8333C15 14.9493 15.3512 14.1014 15.9763 13.4763C16.6014 12.8512 17.4493 12.5 18.3333 12.5"
        stroke="#242424"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default React.memo(Money);
