import base, { APIList, APISearchParams, config } from "./base";
import { APICountry } from "./types";

export interface APICountryList extends APIList<APICountry> {}
export interface APICountryListSearchParams extends APISearchParams {
  name?: string;
}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APICountryListSearchParams
) =>
  base.list<APICountryList, APICountryListSearchParams>(
    "countries/",
    page,
    limit,
    searchParams
  );
