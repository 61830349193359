import {
  APITenderOutcomeBulkUpdateInput,
  APITenderOutcomeSearchParams,
} from "api/tenderOutcomes";
import { APITenderOutcome } from "api/types";
import { computed } from "mobx";
import {
  model,
  Model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import api from "../api";
import TenderOutcome from "./models/TenderOutcome";

@model("collab/TenderOutcomeStore")
class TenderOutcomeStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, TenderOutcome>()),
}) {
  @computed
  get ofProject() {
    return (projectId: identifier) =>
      Array.from(this.listItems.values()).filter(
        (tenderOutcome) => tenderOutcome.project === projectId
      );
  }

  @modelAction
  saveListItem(item: APITenderOutcome) {
    const listItem = new TenderOutcome(item);
    this.listItems.set(item.id, listItem);
    return listItem;
  }

  @modelFlow
  list = _async(function* (
    this: TenderOutcomeStore,
    page?: number,
    limit?: number,
    searchParams?: APITenderOutcomeSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(api.tenderOutcomes.list(page, limit, searchParams));
    this.listItems.clear();
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  bulkUpdate = _async(function* (
    this: TenderOutcomeStore,
    apiData: APITenderOutcomeBulkUpdateInput
  ) {
    try {
      const result = yield* _await(api.tenderOutcomes.bulk_update(apiData));
      const { instances } = result;
      const results = instances.map((item) => this.saveListItem(item));
      return results;
    } catch (e) {
      throw e;
    }
  });
}

export default TenderOutcomeStore;
