import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { EditorProps } from "react-data-grid";
import Select, { ActionMeta, OptionsType, OptionTypeBase } from "react-select";
import { useClickAway } from "react-use";
import { IRow } from "../../csv/types";
import { scaledRem } from "../../utils";

interface Choice {
  value: number | string;
  label: string;
  disabled?: boolean;
}

interface MultiEditorProps<IRow> extends EditorProps<IRow> {
  withSelectAll?: boolean;
}

export default function multipleDropdownEditorFactory(
  key: string,
  choices: Choice[],
  displayChoices?: Choice[],
  withSelectAll?: boolean
) {
  return observer(function MultipleDropDownEditor({
    row,
    onRowChange,
    editorPortalTarget,
  }: MultiEditorProps<IRow>) {
    const ref = useRef(null);
    const selectAllOption = {
      value: "<-all->",
      label: "Select All",
    };

    const value = (() => {
      if (!row[key]) {
        return [];
      }
      const rowValues = (row[key] as string).split(",");
      return rowValues.map((v) => ({
        value: Number(v) || v,
        label:
          choices.find((choice) => choice.value.toString() === v.toString())
            ?.label ||
          displayChoices?.find(
            (choice) => choice.value.toString() === v.toString()
          )?.label ||
          "Huh",
      }));
    })();

    const isAllSelected = () => value.length === choices.length;

    const handleChange = (
      value: OptionsType<Choice>,
      actionMeta: ActionMeta<OptionTypeBase>
    ) => {
      if (
        actionMeta.action === "select-option" &&
        actionMeta.option === selectAllOption
      ) {
        const newValue = withSelectAll
          ? choices.map((choice) => choice.value)
          : [];
        return onRowChange({ ...row, [key]: newValue.join(",") }, false);
      } else if (
        actionMeta.action === "deselect-option" &&
        actionMeta.option === selectAllOption
      ) {
        return onRowChange({ ...row, [key]: "" }, false);
      }
      return onRowChange(
        {
          ...row,
          [key]: value
            .filter((v) => v !== selectAllOption)
            .map((choice) => choice.value as string)
            .join(","),
        },
        false
      );
    };

    useClickAway(ref, () => {
      // Ensure state is committed if editor is not closed before clicking away (or clicking save)
      onRowChange(
        {
          ...row,
          [key]: value.map((v) => v.value as string).join(","),
        },
        true
      );
    });

    return (
      <div ref={ref}>
        <Select
          autoFocus
          menuIsOpen
          isMulti
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          backspaceRemovesValue={false}
          classNamePrefix="react-select"
          value={isAllSelected() ? [selectAllOption, ...value] : value}
          onChange={handleChange}
          options={
            withSelectAll && choices.length
              ? [selectAllOption, ...choices]
              : choices
          }
          menuPortalTarget={editorPortalTarget as HTMLElement}
          styles={{
            control: (provided) => ({
              ...provided,
              height: scaledRem(24) - 1,
              minHeight: scaledRem(24),
              lineHeight: "normal",
              border: 0,
              borderRadius: 0,
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              padding: 0,
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              height: scaledRem(24) - 1,
            }),
            clearIndicator: (provided) => ({
              ...provided,
              display: "none",
            }),
            option: (provided, state) => ({
              ...provided,
              minHeight: scaledRem(24),
              fontSize: scaledRem(10),
              paddingRight: 18,
              position: "relative",
              backgroundColor:
                state.isSelected && !state.isFocused
                  ? "#e9e9e9"
                  : provided.backgroundColor,
              color:
                state.isSelected && !state.isFocused ? "black" : provided.color,
              ":before": state.isSelected
                ? {
                    content: '"✔️"',
                    width: 12,
                    height: 12,
                    position: "absolute",
                    top: 6,
                    right: 6,
                  }
                : undefined,
            }),
            menu: (provided) => ({
              ...provided,
              borderRadius: 0,
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 1000,
            }),
            noOptionsMessage: (provided) => ({
              ...provided,
              fontSize: scaledRem(10),
              padding: scaledRem(4),
            }),
          }}
        />
      </div>
    );
  });
}
