import localforage from "localforage";
import React, { useCallback, useEffect } from "react";
import { Alert, Form } from "react-bootstrap";
import { Envelope } from "react-bootstrap-icons";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { APIProjectInput } from "../../api/projects";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import { useStore } from "../../store";
import { dismissProjectLock, dismissProjectUnlock } from "../../utils/confirm";
import { useProject, useSave } from "../Workspace/hooks";
import SettingsForm from "./SettingsForm";

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 0.875rem;
  height: 0;
  overflow: auto;
  padding: 5rem 0 1.875rem;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 37.5rem;
`;

interface Props {}

type Inputs = APIProjectInput;

const SettingsSection: React.FC<Props> = () => {
  const methods = useForm<Inputs>();
  const project = useProject();
  const store = useStore();
  const { current: currentUser } = store.auth;
  const hasEditPermissions = !!(
    currentUser &&
    (currentUser.isAdmin || project?.adminUsers.includes(currentUser.id))
  );
  const editable = hasEditPermissions && !project?.isLocked;

  useEffect(() => {
    (async () => {
      if (project && currentUser?.isGlobalUserOrAdmin) {
        const projectNotifiedKey = `notifiedProjectLocked-${project.id}-${currentUser?.id}`;
        const notifiedProjectLocked =
          (await localforage.getItem(projectNotifiedKey)) ??
          (await localforage.setItem(projectNotifiedKey, false));

        if (project.isLocked) {
          if (!notifiedProjectLocked) {
            if (await dismissProjectLock()) {
              await localforage.setItem(projectNotifiedKey, project.isLocked);
            }
          }
        } else {
          if (notifiedProjectLocked) {
            if (await dismissProjectUnlock()) {
              await localforage.setItem(projectNotifiedKey, project.isLocked);
            }
          }
        }
      }
    })();
  }, [project, currentUser]);

  const handleApply = useCallback(
    async (data: APIProjectInput) => {
      if (!project) {
        return;
      }

      try {
        await store.projects.patch(project.id, data);
      } catch (e) {
        throw new Error("Project not updated");
      }
    },
    [project, store]
  );

  useSave(async () => {
    if (!editable) {
      throw new Error("Project settings not editable");
    }

    await methods.handleSubmit(handleApply, () => {
      throw new Error("Project not saved.");
    })();
  });

  if (!project) {
    return <Loading full />;
  }

  return (
    <FormProvider {...methods}>
      <Toolbar></Toolbar>
      <Container>
        <Content>
          <Form onSubmit={methods.handleSubmit(handleApply)}>
            <SettingsForm instance={project} editable={editable} />
            <button type="submit" className="sr-only">
              Save
            </button>
          </Form>
          <Alert variant="info">
            <p>
              For new Collab user setup, please email Maria Jose Perez Alonso,
              Csaba Szunyog and Beata Feher-Dercsenyi.
            </p>
            <Alert.Link href="mailto:mariajose.perezalonso@ec1.bp.com,csaba.szunyog@bp.com,beata.feher-dercsenyi@bp.com?subject=New+Collab+user+setup">
              <Envelope /> Send Email
            </Alert.Link>
          </Alert>
        </Content>
      </Container>
    </FormProvider>
  );
};

export default SettingsSection;
