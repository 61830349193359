import { Column } from "react-data-grid";
import { IRow } from "../csv/types";

enum ROWS {
  PRODUCT_SPEC_ROW = 0, // Row indexes in formulation CPL (head) sheet
  VISCOSITY_ROW = 1,
  FORMULATION_CODE_ROW = 2,
  PLANT_ROW = 3,
}

export function getUniqueValuesFromRow(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[],
  row: ROWS
) {
  // Specs is the first row of `rows`.
  const specsRow = rows[row];

  if (!specsRow) {
    return [];
  }

  return Array.from(
    new Set(
      columns
        .filter(({ key }) => !!key && key !== "_")
        .map(({ key }) => specsRow[key]?.toString() || "")
        .sort()
    )
  );
}

export function getUniqueProductSpecs(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[]
) {
  return getUniqueValuesFromRow(columns.slice(2), rows, ROWS.PRODUCT_SPEC_ROW);
}

export function getUniqueViscosities(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[]
) {
  return getUniqueValuesFromRow(columns.slice(2), rows, ROWS.VISCOSITY_ROW);
}

export function getUniqueFormulationCodes(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[]
) {
  return getUniqueValuesFromRow(
    columns.slice(2),
    rows,
    ROWS.FORMULATION_CODE_ROW
  );
}

export function getUniquePlants(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[]
) {
  return getUniqueValuesFromRow(columns.slice(2), rows, ROWS.PLANT_ROW);
}

export function filterColumns(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[],
  filterRow: ROWS,
  filter: string[],
  alwaysIncludeKeys: string[]
) {
  if (filter.length === 0) {
    return columns;
  }
  console.log({ filter });
  return columns.filter(
    ({ key }) =>
      alwaysIncludeKeys.includes(key) ||
      filter.includes(rows[filterRow][key]?.toString() || "")
  );
}

export function filterByProductSpec(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[],
  filter: string[],
  alwaysIncludeKeys: string[] = [""]
) {
  return filterColumns(
    columns,
    rows,
    ROWS.PRODUCT_SPEC_ROW,
    filter,
    alwaysIncludeKeys
  );
}

export function filterByViscosity(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[],
  filter: string[],
  alwaysIncludeKeys: string[] = [""]
) {
  return filterColumns(
    columns,
    rows,
    ROWS.VISCOSITY_ROW,
    filter,
    alwaysIncludeKeys
  );
}

export function filterByFormulationCode(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[],
  filter: string[],
  alwaysIncludeKeys: string[] = [""]
) {
  return filterColumns(
    columns,
    rows,
    ROWS.FORMULATION_CODE_ROW,
    filter,
    alwaysIncludeKeys
  );
}

export function filterByPlant(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[],
  filter: string[],
  alwaysIncludeKeys: string[] = [""]
) {
  return filterColumns(
    columns,
    rows,
    ROWS.PLANT_ROW,
    filter,
    alwaysIncludeKeys
  );
}

export function filterByFormulationCplFilter(
  columns: readonly Column<IRow>[],
  rows: readonly IRow[],
  productSpecFilter: string[],
  viscosityFilter: string[],
  formulationCodeFilter: string[],
  plantFilter: string[],
  alwaysIncludeKeys: string[] = [""]
) {
  console.log({
    productSpecFilter,
    viscosityFilter,
    formulationCodeFilter,
    plantFilter,
  });

  const byProductSpec = filterByProductSpec(
    columns,
    rows,
    productSpecFilter,
    alwaysIncludeKeys
  );
  const byViscosity = filterByViscosity(
    byProductSpec,
    rows,
    viscosityFilter,
    alwaysIncludeKeys
  );
  const byFormulationCode = filterByFormulationCode(
    byViscosity,
    rows,
    formulationCodeFilter,
    alwaysIncludeKeys
  );
  const byPlant = filterByPlant(
    byFormulationCode,
    rows,
    plantFilter,
    alwaysIncludeKeys
  );
  return byPlant;
}

export function filterEmptyCplRows(rows: IRow[], columnNames: string[]) {
  return rows.filter(
    (row) =>
      Object.keys(row)
        .filter((key) => columnNames.includes(key))
        .map((key) => (row[key]?.toString() || "").trim())
        .filter((value) => !!value).length > 0
  );
}
