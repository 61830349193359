import { AddBoxOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import Button from "../Button";

type Props = {
  className?: string;
  onAddRows: (newRowCount: number) => void;
};

const RootContainer = styled.form`
  background-color: #ffffff;
  filter: drop-shadow(0.25rem 0.25rem 0.3125rem rgba(0, 0, 0, 0.02));
  font-size: 0.625rem;
  font-weight: bold;
  min-height: 3rem;
  line-height: 1.5rem;
  padding: 0.625rem;
  text-transform: uppercase;

  &.multiple {
    border: 1px solid #cecece;
    border-radius: 0 0 0.25rem 0.25rem;
    margin-top: calc(-0.5rem - 1px);
    margin-bottom: 1.25rem;
    width: fit-content;
  }

  &.collapsed {
    display: none;
  }

  input {
    border: 1px solid #cdcdcd;
    border-radius: 2px;
    display: inline-block;
    margin: 0 0.75rem;
    height: 1.75rem;
    width: 4.25rem;
  }

  .btn {
    padding-right: 0;
    margin-bottom: 0.3rem;
  }
`;

const AddRows: React.FC<Props> = ({ className, onAddRows }) => {
  const addRowsRef = useRef<HTMLInputElement>(null);

  const handleCustomAddRowsSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const toAdd = parseInt(addRowsRef.current?.value || "0", 10);
      onAddRows?.(toAdd);
    },
    [onAddRows]
  );

  return (
    <RootContainer onSubmit={handleCustomAddRowsSubmit} className={className}>
      Add{" "}
      <input type="number" min={1} defaultValue={1} ref={addRowsRef} required />{" "}
      Rows
      <Button type="submit" variant="borderless" size="thin">
        <AddBoxOutlined />
      </Button>
    </RootContainer>
  );
};

export default observer(AddRows);
