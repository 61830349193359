import { observer } from "mobx-react-lite";
import React from "react";
import { NavbarBrand } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BreadcrumbItem } from "./types";

type Props = BreadcrumbItem;

const StyledNavbarBrand = styled(NavbarBrand).attrs(() => ({ as: Link }))`
  display: block;
  line-height: 1;
  margin: 0 1.25rem;
  padding: 0;
  position: relative;
  opacity: 0.6;

  &:only-child,
  &:last-child {
    opacity: 1;
  }

  & + &::before {
    content: url("/chevron_right.svg");
    color: #000000;
    font-weight: 900;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -2rem;

    &:hover {
      text-decoration: none;
    }
  }
`;

const SubTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 0.625rem;
  user-select: none;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.3;
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  margin: 0;

  color: #000000;
`;

const NavbarItem: React.FC<Props> = ({ link, title, subtitle }) => (
  <StyledNavbarBrand to={link}>
    {subtitle ? <SubTitle>{subtitle}</SubTitle> : null}
    <Title>{title}</Title>
  </StyledNavbarBrand>
);

export default observer(NavbarItem);
