export const FILTER_DROPDOWN_OPTIONS = {
  equals: {
    key: "equals",
    title: "Equals",
  },
  does_not_equal: {
    key: "does_not_equal",
    title: "Does not equal",
  },
  begins_with: {
    key: "begins_with",
    title: "Begins with",
  },
  does_not_begin_with: {
    key: "does_not_begin_with",
    title: "Does not begin with",
  },
  ends_with: {
    key: "ends_with",
    title: "Ends with",
  },
  does_not_end_with: {
    key: "does_not_end_with",
    title: "Does not end with",
  },
  contains: {
    key: "contains",
    title: "Contains",
  },
  does_not_contain: {
    key: "does_not_contain",
    title: "Does not contain",
  },
};

export const NUMERIC_COLUMNS_FILTER_DROPDOWN_OPTIONS = {
  greater_than: {
    key: "greater_than",
    title: "Greater than",
  },
  less_than: {
    key: "less_than",
    title: "Less than",
  },
  between: {
    key: "between",
    title: "Between (inclusive)",
  },
};

export const SORT_DROPDOWN_OPTIONS = {
  ASC: {
    key: "ASC",
    title: "Ascending",
  },
  DESC: {
    key: "DESC",
    title: "Descending",
  },
  NONE: {
    key: "NONE",
    title: "None",
  },
};
