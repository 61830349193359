import base, { APIList, APISearchParams, config } from "./base";

export interface APIPackSize {
  id: identifier;
  name: string;
}

export interface APIPackSizeList extends APIList<APIPackSize> {}

export interface APIPackSizeListSearchParams extends APISearchParams {
  name?: string;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIPackSizeListSearchParams
) =>
  base.list<APIPackSizeList, APIPackSizeListSearchParams>(
    "pack-sizes/",
    page,
    limit,
    searchParams
  );
