import api from "api";
import { APIComment, APICommentDetail } from "api/comments";
import { computed } from "mobx";
import {
  ExtendedModel,
  model,
  modelFlow,
  prop,
  _async,
  _await,
} from "mobx-keystone";
import DirtyableWithId from "./DirtyableWithId";

@model("collab/Comment")
class Comment extends ExtendedModel(DirtyableWithId, {
  project: prop<identifier>(),
  user: prop<APIComment["user"]>(),
  datePosted: prop<string>(),
  sheetType: prop<string>(),
  sheetId: prop<identifier>(),
  rowIndex: prop<string>(),
  colName: prop<string>(),
  comment: prop<string>(""),
  isResolved: prop<boolean>(false),
}) {
  @computed
  get asAPI() {
    return {
      sheetType: this.sheetType,
      sheetId: this.sheetId,
      rowIndex: this.rowIndex,
      colName: this.colName,
      comment: this.comment,
    };
  }

  @modelFlow
  save = _async(function* (this: Comment) {
    if (!this.isDirty) {
      return;
    }

    let response: APICommentDetail;
    if (this.id < 0) {
      response = yield* _await(api.comments.create(this.project, this.asAPI));
    } else {
      response = yield* _await(api.comments.update(this.id, this.asAPI));
    }
    this.update(response);
  });
}

export default Comment;
