import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useUnmount } from "react-use";
import styled from "styled-components";
import { APIProjectInput } from "../../api/projects";
import Loading from "../../components/Loading";
import ProjectLockedLabel from "../../components/ProjectLockedLabel";
import Toolbar from "../../components/Toolbar";
import { useStore } from "../../store";
import { useProject, useSave } from "../Workspace/hooks";
import KeyAssumptionsForm from "./KeyAssumptionsForm";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.875rem;
  height: 0;
  overflow: auto;
  padding: 1.875rem;
`;

interface Props {}

const KeyAssumptionsTab: React.FC<Props> = () => {
  const project = useProject();
  const store = useStore();
  const methods = useForm();
  const { formState, reset } = methods;
  const currentUser = store.auth.current;
  const hasEditPermission = !!(
    currentUser &&
    (currentUser.isAdmin || project?.adminUsers.includes(currentUser.id))
  );
  const editable = hasEditPermission && !project?.isLocked;

  useEffect(() => {
    if (project) {
      if (formState.isDirty) {
        project.setDirty();
      } else {
        project.setNotDirty();
      }
    }
  }, [project, formState.isDirty]);

  useUnmount(() => {
    if (project && project.isDirty) {
      project.setNotDirty();
    }
  });

  const handleApply = useCallback(
    async (data: APIProjectInput) => {
      if (formState.isDirty && project && project.id) {
        try {
          await store.projects.patch(project.id, data);
        } catch (e) {
          throw e;
        }
        reset(data);
      }
    },
    [formState.isDirty, project, reset, store.projects]
  );

  useSave(
    async () => {
      if (!editable) {
        throw new Error("Key assumptions not editable");
      }

      await methods.handleSubmit(handleApply, () => {
        throw new Error("Project not saved.");
      })();
    },
    { saveOnUnmount: true }
  );

  if (!project) {
    return <Loading full />;
  }

  return (
    <FormProvider {...methods}>
      <Toolbar>{project.isLocked && <ProjectLockedLabel />}</Toolbar>
      <Container>
        <Form>
          <KeyAssumptionsForm instance={project} editable={editable} />
        </Form>
      </Container>
    </FormProvider>
  );
};

export default observer(KeyAssumptionsTab);
