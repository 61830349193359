import clsx from "clsx";
import Label from "components/Label";
import Loading from "components/Loading";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React from "react";
import { Image } from "react-bootstrap";
import { useAsync, useEffectOnce } from "react-use";
// import VersionFilter from "components/VersionFilter";
import { useStore } from "store";
import styled from "styled-components";
import RecentActivity from "./RecentActivity";
import VersionList from "./VersionList";

type Props = {};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 0;
  overflow: auto;
  padding: 1.875rem;
  align-items: flex-start;
  gap: 1.875rem;
`;

const StyledHeader1 = styled.h1`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: 0.031rem;
  margin-bottom: 1.25rem;
  margin-top: 0.625rem;
`;

const StyledHeader2 = styled.h2`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.031rem;
  text-transform: uppercase;
  margin-bottom: 0;
`;

const UpdatedAgoLabel = styled(Label)`
  letter-spacing: 0.063rem;
  line-height: 0.625rem;
  margin-bottom: 0;
  user-select: none;
`;

const StyledLabel = styled(UpdatedAgoLabel)`
  opacity: 1;
  color: #828282;
  letter-spacing: 0.063rem;
  line-height: 0.625rem;
  margin-top: 1.125rem;
`;

const EmptyText = styled(UpdatedAgoLabel)`
  font-size: 0.813rem;
  line-height: 0.813rem;
  margin-top: 1.125rem;
  text-align: center;
  text-transform: capitalize;
  user-select: none;
`;

const StyledDiv = styled.div`
  background: #fdfdfd;
  border: 0.063rem solid #c8c8c8;
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 1.25rem;
`;

const ProjectDetails = styled(StyledDiv)``;

const Avatar = styled(Image)`
  width: 1.5rem;
  height: 1.5rem;
  border: solid 0.125rem #000000;
  background-color: #c4c4c4;
  margin-right: 0.5rem;
  float: left;
  user-select: none;
`;

const UserDiv = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  color: #242424;
  margin-top: 0.25rem;
  clear: both;

  span {
    display: block;
    /* float: left; */
    margin-left: 2rem;
  }
`;

const UserListDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CreatedDiv = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.188rem;
  letter-spacing: 0.031rem;
  color: #242424;
`;

const ActivityContainer = styled(StyledDiv)`
  min-height: 100%;
`;

const Version = styled.div`
  padding: 0.938rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  gap: 1.25rem;
`;

const ActivityFeed: React.FC<Props> = () => {
  const store = useStore();
  const project = useProject();

  useEffectOnce(() => {
    store.activities.reset();
  });

  useAsync(async () => {
    await store.users.list();
  });

  if (!project) {
    return <Loading full />;
  }

  return (
    <Container>
      <ProjectDetails
        className={clsx("col-2", "d-sm-none", "d-md-none", "d-lg-block")}
      >
        <StyledHeader1>Project Details</StyledHeader1>
        <StyledLabel>Creator</StyledLabel>
        <UserDiv>
          <Avatar roundedCircle src={project.creatorPhotoSizes.medium} />
          <span>{project.creatorName}</span>
        </UserDiv>
        <StyledLabel>Created</StyledLabel>
        <CreatedDiv>
          {format(parseISO(project.created), "PPpp", { locale: enGB })}
        </CreatedDiv>
        <StyledLabel>Editors</StyledLabel>
        <UserListDiv>
          {project.editors.map((user) => (
            <UserDiv key={user.id}>
              <Avatar src={user.photoSizes.medium} roundedCircle />
              <span>{user.name}</span>
            </UserDiv>
          ))}
        </UserListDiv>
        <StyledLabel>Country Analyst</StyledLabel>
        <UserListDiv>
          {project.countryAnalysts.length > 0 ? (
            project.countryAnalysts.map((user) => (
              <div key={user.id}>
                <UserDiv>
                  <Avatar src={user.photoSizes.medium} roundedCircle />
                  <span>{user.name}</span>
                </UserDiv>
              </div>
            ))
          ) : (
            <EmptyText>No Country Analysts assigned</EmptyText>
          )}
        </UserListDiv>
      </ProjectDetails>
      <ActivityContainer className={clsx("col")}>
        <StyledHeader1>Recent Activity</StyledHeader1>
        <RecentActivity projectId={project.id} />
      </ActivityContainer>
      <Version className={clsx("col-3")}>
        <StyledHeader2>Versions</StyledHeader2>
        <UpdatedAgoLabel>
          Last updated {formatDistanceToNow(parseISO(project.modified))} ago
        </UpdatedAgoLabel>
        {/* TODO: Update filtering of versions. Make use of the params instead */}
        {/* <VersionFilter /> */}
        <VersionList
          projectId={project.id}
          currentVersionId={project.currentVersionId}
        />
      </Version>
    </Container>
  );
};

export default observer(ActivityFeed);
