import { useComments } from "components/Comments/hooks";
import DataGrid, { FrozenDataGrid } from "components/DataGrid";
import { useCsvToGridDataAsync } from "components/DataGrid/hooks";
import {
  DataGridHandleWithData,
  RangeSelection,
  ValueParsers,
} from "components/DataGrid/types";
import Loading from "components/Loading";
import { CsvValidationErrorContext } from "components/ValidationDashboard/contexts";
import { usePopulateValidationSummary } from "components/ValidationDashboard/hooks";
import appConfig from "config/appConfig";
import { IRow } from "csv/types";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Column, RowsChangeData } from "react-data-grid";
import { useAsync, useAsyncFn, useMount, useUnmount } from "react-use";
import { useStore } from "store";
import Sheet from "store/models/Sheet";
import { scaledRem } from "utils";
import { useProject, useSave } from "../Workspace/hooks";
import { CsvFile } from "../Workspace/types";

type Props = {
  collapsible?: boolean;
  isActive: boolean;
  file: CsvFile;
  multiple?: boolean;
  onActive: (sheetId: string) => void;
  valueParsers?: ValueParsers;
  onPreSave?: () => Promise<void>;
  onPostSave?: () => Promise<void>;
  onUpdateRows?: (
    sheet: Sheet,
    rows: IRow[],
    headers: string[]
  ) => Promise<void>;
};

const COMMENT_POLL_INTERVAL = 15000;

const CSVSheet: React.FC<Props> = ({
  collapsible = true,
  isActive,
  file,
  multiple,
  valueParsers,
  onActive,
  onPreSave,
  onPostSave,
  onUpdateRows,
}) => {
  const store = useStore();
  const project = useProject();

  const editable = file.editable ?? true;
  const [columns, rows, csvLoaded, setRows] = useCsvToGridDataAsync({
    sheet: file.sheet,
    editable,
    schema: file.schema,
  });
  const ready = csvLoaded && !!columns && !!rows;

  const { onCellSelect, onCreateComment, hasComments, register, unregister } =
    useComments();

  const { setters } = useContext(CsvValidationErrorContext);
  const [onPopulate, errors] = usePopulateValidationSummary(setters);

  const ref = useRef<DataGridHandleWithData>(null);

  // Load comments when sheet changes.
  const [, fetchComments] = useAsyncFn(async () => {
    if (!project) {
      return;
    }
    await store.comments.list(project.id, file.sheet.sheetType, file.sheet.id);
  }, [project, file.sheet]);

  // Fetch new comments every `COMMENT_POLL_INTERVAL` or so seconds.
  // Adds randomness to diffuse multiple requests hitting the server at the same time.
  useEffect(() => {
    let c: number;

    (async function poll() {
      await fetchComments();
      c = window.setTimeout(
        poll,
        COMMENT_POLL_INTERVAL * 0.7 +
          Math.floor(COMMENT_POLL_INTERVAL * 0.3 * Math.random())
      );
    })();

    return () => {
      if (c) {
        clearInterval(c);
      }
    };
  }, [fetchComments]);

  useMount(() => {
    register(
      file.sheet.sheetType,
      file.sheet.id,
      ref,
      file.rowIdentifier ?? "id"
    );
  });

  useUnmount(() => {
    unregister(file.sheet.sheetType, file.sheet.id);

    if (project && project.isDirty) {
      project.setNotDirty();
    }
  });

  // Template for blank rows, not including the index cell, which will be auto-populated.
  const rowTemplate = useMemo(() => {
    const templ: Omit<IRow, "id"> = {};

    if (!columns) {
      return templ;
    }

    columns
      .filter((column) => column.key !== "id")
      .forEach((column) => {
        templ[column.key] = "";
      });
    return templ;
  }, [columns]);

  const columnsWithoutHidden = useMemo(() => {
    const { hideColumns } = file;
    if (!hideColumns) {
      return columns;
    }
    return columns.filter((col) => hideColumns().indexOf(col.key) < 0);
  }, [columns, file]);

  const commentIdentifiers = project
    ? store.comments.ofSheetIdentifiers(
        project.id,
        file.sheet.sheetType,
        file.sheet.id
      )
    : {};

  useAsync(async () => {
    if (!ready) {
      return;
    }

    const { id, sheet, errorFn } = file;

    if (!sheet.errorFile && !errorFn) {
      return;
    }

    await onPopulate(id, rows, columns, errorFn ?? sheet.errorFile);
  }, [file.sheet.errorFile, rows, columns, ready]);

  const update = useCallback(
    (rows: IRow[]) => {
      const { updatable, sheet } = file;
      if (updatable) {
        const rowCopy = rows.map(({ id, ...rest }) => rest) as IRow[];

        // Include hidden columns, but not the "id".
        const headers = columns
          .filter(({ key }) => key !== "id")
          .map(({ key }) => key);

        if (!onUpdateRows) {
          sheet.setCsvString(rowCopy, headers);
        } else {
          onUpdateRows(sheet, rowCopy, headers);
        }
      }
    },
    [columns, file, onUpdateRows]
  );

  const handleAddRows = useCallback(
    (numRows: number) => {
      if (!rows) {
        return;
      }

      const blankRows = Array.from(Array(numRows).keys()).map((i) => ({
        id: rows.length + i + 1,
        ...rowTemplate,
      }));

      const newRows = [...rows, ...blankRows];
      setRows(newRows);
      update(newRows);

      file.sheet.setDirty();
    },
    [file.sheet, rowTemplate, rows, setRows, update]
  );

  const handleRowsChange = useCallback(
    (rows: IRow[], data?: RowsChangeData<IRow>) => {
      setRows(rows, data);
      update(rows);
      if (project) {
        project.setDirty();
      }
    },
    [project, setRows, update]
  );

  const handleDelete = useCallback(
    (
      event: React.TouchEvent<HTMLDivElement>,
      data: { filteredRows: readonly IRow[]; range: RangeSelection }
    ) => {
      const {
        filteredRows,
        range: { start, end },
      } = data;

      // eslint-disable-next-line no-restricted-globals
      if (!confirm("Are you sure you want to delete the selected rows?")) {
        return;
      }

      const offset = file.extraHeaderRow?.length || 0;

      const rowIdsToDelete = filteredRows
        .slice(offset + start.rowIdx, offset + end.rowIdx + 1)
        .map((row) => row.id)
        .filter((id) => !!id);

      const newRows = rows.filter((row) => !rowIdsToDelete.includes(row.id));

      setRows(newRows);
      update(newRows);
    },
    [file.extraHeaderRow?.length, rows, setRows, update]
  );

  const handleCellSelect = useCallback(
    (row: IRow, column: Column<IRow>) => {
      onCellSelect(file, row, column);
    },
    [file, onCellSelect]
  );

  // Save CSV when save button is pressed.
  useSave(
    async (manual: boolean) => {
      const { sheet, updatable } = file;

      if (!editable) {
        throw new Error("Worksheet not editable");
      }

      if (!updatable) {
        throw new Error("Worksheet not updatable");
      }

      try {
        await sheet.save(manual);
      } catch (e) {
        throw new Error("Worksheet not saved.");
      }
    },
    {
      saveOnUnmount: true,
      preSaveFn: onPreSave,
      postSaveFn: onPostSave,
    }
  );

  const TheDataGrid = file.frozen ? FrozenDataGrid : DataGrid;

  return ready ? (
    <TheDataGrid
      ref={ref}
      id={file.id}
      collapsible={collapsible}
      columns={columnsWithoutHidden}
      commentIdentifiers={commentIdentifiers}
      errors={errors}
      extraHeaderRows={file.extraHeaderRow || []}
      filterable={file.filterable !== false}
      headerRowHeight={file.showHeader !== false ? scaledRem(56) : 0}
      isActive={isActive}
      maxRowsShown={file.maxRowsShown}
      multiple={multiple}
      onActive={onActive}
      onCellSelect={handleCellSelect}
      onAddRows={file.insertable ? handleAddRows : undefined}
      onRowsChange={handleRowsChange}
      rows={rows}
      rowIdentifier={file.rowIdentifier ?? "id"}
      title={file.title}
      valueParsers={valueParsers}
      extraContextMenu={(filteredRows, selected, range) => {
        if (!selected) {
          return [];
        }

        const isView = hasComments(
          file,
          filteredRows[selected.rowIdx],
          columnsWithoutHidden[selected.idx]
        );

        const menu = [];

        // View/add comments
        if (
          appConfig.enableComments &&
          selected.rowIdx! >= (file.extraHeaderRow?.length || 0)
        ) {
          menu.push({
            id: "comments",
            label: `${isView ? "View" : "Add"} comment${isView ? "s" : "…"}`,
            onClick: () =>
              onCreateComment({
                sheetType: file.sheet.sheetType,
                sheetId: file.sheet.id,
                rowIndex: `${
                  filteredRows[selected.rowIdx][file.rowIdentifier ?? "id"]
                }`,
                colName: columnsWithoutHidden[selected.idx].key,
              }),
          });
        }

        if (file.insertable === true) {
          menu.push({
            id: "deleteRows",
            label: "Delete selected rows…",
            onClick: handleDelete,
            data: { filteredRows, range },
            disabled:
              !file.insertable ||
              selected.rowIdx! < (file.extraHeaderRow?.length || 0), // Ensure that extra header rows cannot be removed.
          });
        }

        return menu;
      }}
    />
  ) : (
    <Loading full />
  );
};

export default observer(CSVSheet);
