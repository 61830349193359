import api from "../api";
import { AuthenticatedUser } from "../api/types";
import { Model, model, modelFlow, prop, _async, _await } from "mobx-keystone";
import { flushAuthUser, saveAuthUser } from "../utils";
import { computed } from "mobx";

@model("collab/AuthStore")
export default class AuthStore extends Model({
  user: prop<AuthenticatedUser | null>(null),
}) {
  @modelFlow
  checkAuth = _async(function* (this: AuthStore) {
    const authenticatedUser = yield* _await(api.auth.checkAuth());
    this.user = authenticatedUser as AuthenticatedUser;

    api.config.token = this.user?.accessToken || "";

    return {
      user: authenticatedUser,
      isAuthenticated: !!authenticatedUser,
    };
  });

  @modelFlow
  login = _async(function* (this: AuthStore, code: string | undefined) {
    if (code) {
      try {
        const response = yield* _await(api.auth.login(code));
        const user = response as AuthenticatedUser;
        this.user = user;
        _await(saveAuthUser(user));

        return response;
      } catch (error) {
        throw error;
      }
    }
  });

  @modelFlow
  logout = _async(function* (this: AuthStore) {
    yield* _await(api.auth.logout());
    yield* _await(flushAuthUser());
    this.user = null;
  });

  @computed
  get current() {
    return this.user ? this.user.user : undefined;
  }
}
