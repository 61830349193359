import { computed } from "mobx";
import { Model, model, modelAction, prop_setArray, Ref } from "mobx-keystone";
import Cluster, { clusterRef, makeClusterId } from "./Cluster";
import Country, { countryRef, makeCountryId } from "./Country";
import Plant, { makePlantId, plantRef } from "./Plant";

@model("collab/FormulationFilter")
class FormulationFilter extends Model({
  productSpecs: prop_setArray(() => new Set<string>()),
  formulationCodes: prop_setArray(() => new Set<string>()),
  plants: prop_setArray(() => new Set<Ref<Plant>>()),
  clusters: prop_setArray(() => new Set<Ref<Cluster>>()),
  countries: prop_setArray(() => new Set<Ref<Country>>()),
  approved: prop_setArray(() => new Set<boolean>()),
}) {
  @computed
  get isFiltering() {
    return (
      this.productSpecs.size > 0 ||
      this.formulationCodes.size > 0 ||
      this.plants.size > 0 ||
      this.clusters.size > 0 ||
      this.countries.size > 0 ||
      this.approved.size > 0
    );
  }

  @modelAction
  setProductSpecs(productSpecs: string[]) {
    this.productSpecs.clear();
    productSpecs.forEach((productSpec) => this.productSpecs.add(productSpec));
  }

  @modelAction
  setFormulationCodes(formulationCodes: string[]) {
    this.formulationCodes.clear();
    formulationCodes.forEach((formulationCode) =>
      this.formulationCodes.add(formulationCode)
    );
  }

  @modelAction
  setPlants(plantIds: identifier[]) {
    this.plants.clear();
    plantIds.forEach((id) => this.plants.add(plantRef(makePlantId(id))));
  }

  @modelAction
  setClusters(clusterIds: identifier[]) {
    this.clusters.clear();
    clusterIds.forEach((id) =>
      this.clusters.add(clusterRef(makeClusterId(id)))
    );
  }

  @modelAction
  setCountries(countryIds: identifier[]) {
    this.countries.clear();
    countryIds.forEach((id) =>
      this.countries.add(countryRef(makeCountryId(id)))
    );
  }

  @modelAction
  setApproved(approved: boolean[]) {
    this.approved.clear();
    approved.forEach((state) => this.approved.add(state));
  }

  @modelAction
  reset() {
    this.productSpecs.clear();
    this.formulationCodes.clear();
    this.plants.clear();
    this.clusters.clear();
    this.countries.clear();
    this.approved.clear();
  }

  @computed
  get selectedProductSpecsIds() {
    return Array.from(this.productSpecs).sort();
  }

  @computed
  get selectedFormulationCodesIds() {
    return Array.from(this.formulationCodes).sort();
  }

  @computed
  get selectedPlantIds() {
    return Array.from(this.plants).map((ref) => ref.current.id);
  }

  @computed
  get selectedClusterIds() {
    return Array.from(this.clusters).map((ref) => ref.current.id);
  }

  @computed
  get selectedCountryIds() {
    return Array.from(this.countries).map((ref) => ref.current.id);
  }

  @computed
  get selectedApprovedStates() {
    return Array.from(this.approved).map((state) => state);
  }

  @computed
  get selectedProductSpecs() {
    return Array.from(this.productSpecs)
      .sort()
      .map((productSpec) => ({
        value: productSpec,
        label: productSpec,
      }));
  }

  @computed
  get selectedFormulationCodes() {
    return Array.from(this.formulationCodes)
      .sort()
      .map((formulationCode) => ({
        value: formulationCode,
        label: formulationCode,
      }));
  }

  @computed
  get selectedPlants() {
    return Array.from(this.plants)
      .filter((ref) => ref.isValid)
      .map((ref) => ({
        value: ref.maybeCurrent?.id || 0,
        label: ref.maybeCurrent?.name || "?",
      }));
  }

  @computed
  get selectedClusters() {
    return Array.from(this.clusters)
      .filter((ref) => ref.isValid)
      .map((ref) => ({
        value: ref.maybeCurrent?.id || 0,
        label: ref.maybeCurrent?.name || "?",
      }));
  }

  @computed
  get selectedCountries() {
    return Array.from(this.countries)
      .filter((ref) => ref.isValid)
      .map((ref) => ({
        value: ref.maybeCurrent?.id || 0,
        label: ref.maybeCurrent?.name || "?",
      }));
  }

  @computed
  get selectedApproved() {
    return Array.from(this.approved).map((state) => ({
      value: state,
      label: state ? "Yes" : "No",
    }));
  }
}

export default FormulationFilter;
