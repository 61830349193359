import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useMemo } from "react";
import { HeaderRendererProps } from "react-data-grid";
import styled from "styled-components";
import { ArrowDownSquare } from "react-bootstrap-icons";
import { isEmpty } from "lodash";
import { RangeSelectionContext, FilterContext } from "./contexts";
import { Position } from "./types";

const StyledHeader = styled.div`
  align-items: center;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  left: 0.625rem;
  line-height: 1.5;
  white-space: pre-line;
  position: absolute;
  right: 0.625rem;
  top: 0;
`;

const StyledHeaderText = styled.div`
  flex: auto;
`;

const StyledArrowDownSquare = styled(ArrowDownSquare)`
  text-align: right;
  font-size: 14px;
  min-width: 20px;
  width: 20px;

  &:hover {
    color: rgba(17, 17, 17, 1);
    cursor: pointer;
  }

  &.activeFilter {
    color: #007f00;
  }
`;

const SELECTION_TYPE = "column";

function HeaderCell<R, SR>(props: HeaderRendererProps<R, SR>) {
  const { column } = props;
  const { setRange, rows, selectionType, setSelectionType, setSelected } =
    useContext(RangeSelectionContext);
  const {
    filterable,
    checkboxFilters,
    selectionFilters,
    sortDirection,
    sortColumn,
    setColumnKey,
    setShowDropdown,
    setColumnBoundingLeft,
    setBoundingTop,
  } = useContext(FilterContext);

  const activeFilter =
    (sortColumn === column.key && sortDirection !== "NONE") ||
    checkboxFilters.hasOwnProperty(column.key) ||
    (selectionFilters.hasOwnProperty(column.key) &&
      !isEmpty(selectionFilters[column.key].option));

  // <-- Column selection //

  const start: Position = useMemo(
    () => ({ idx: column.idx, rowIdx: 0 }),
    [column.idx]
  );

  const end: Position = useMemo(
    () => ({ idx: column.idx, rowIdx: rows.length - 1 }),
    [column.idx, rows.length]
  );

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!setRange) {
        return;
      }
      if (e.buttons === 1 && selectionType === SELECTION_TYPE) {
        setRange((prevRange) => {
          if (prevRange) {
            return {
              ...prevRange,
              end,
            };
          }
        });
      }
    },
    [end, selectionType, setRange]
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setSelectionType?.(SELECTION_TYPE);
      setSelected?.(start);
      setRange?.({ start, end });
    },
    [end, setRange, setSelected, setSelectionType, start]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setRange?.({ start, end });
    },
    [end, setRange, start]
  );

  const handleMouseUp = useCallback(() => {
    setSelectionType?.("");
  }, [setSelectionType]);

  // Column selection --> //

  const toggleDropdownFilter = useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      const boundingClientRect = e?.currentTarget?.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;

      const columnBoundingLeft = boundingClientRect
        ? boundingClientRect.left
        : 0;
      const columnBoundingTop = boundingClientRect ? boundingClientRect.top : 0;
      setColumnKey(column.key);
      setShowDropdown(true);
      setColumnBoundingLeft(columnBoundingLeft + scrollLeft);
      setBoundingTop(columnBoundingTop + scrollTop);
      e.stopPropagation();
    },
    [
      setColumnKey,
      setShowDropdown,
      setColumnBoundingLeft,
      setBoundingTop,
      column.key,
    ]
  );

  return (
    <StyledHeader
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
    >
      <StyledHeaderText>{column.name}</StyledHeaderText>
      {column.idx !== 0 && filterable && (
        <StyledArrowDownSquare
          size={14}
          onClick={(e) => toggleDropdownFilter(e)}
          onMouseDown={(e) => e.stopPropagation()}
          className={clsx({
            activeFilter,
          })}
        />
      )}
    </StyledHeader>
  );
}

export default observer(HeaderCell);
