import {
  Archive,
  BorderColor,
  InsertDriveFileOutlined,
  Launch,
  MoreVert,
  MoveToInboxOutlined,
  UnarchiveOutlined,
} from "@material-ui/icons";
import api from "api";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import enGB from "date-fns/locale/en-GB";
import Duplicate from "icons/Duplicate";
import DuplicateProject from "pages/Projects/DuplicateProject";
import ProjectMoveForm from "pages/Projects/ProjectMoveForm";
import RenameProject from "pages/Projects/RenameProject";
import { darken } from "polished";
import React, { useState } from "react";
import history from "services/history";
import { useStore } from "store";
import ProjectMinimal from "store/models/ProjectMinimal";
import styled from "styled-components";
import { Toast } from "utils";
import Button from "../Button";
import { Option, OptionButton } from "../OptionButton";

const ProjectDiv = styled.div`
  width: 260px;
  min-height: 115px;
  padding: 0.625rem 0.625rem 0.938rem 0.938rem;

  border: 1px solid #cecece;
  box-sizing: border-box;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  p {
    margin-bottom: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    margin-top: 0.625rem;
    display: flex;
    font-size: 0.75rem;
    line-height: 0.75rem;
    text-transform: none;
    opacity: 0.4;
    user-select: none;
  }

  &:hover,
  &:focus {
    border-color: #000000;
  }

  &:not(:disabled):active,
  &:not(:disabled):active .btn {
    background-color: ${darken(0.1, "#ffffff")};
  }
`;

const ProjectIcons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;

  .project-icon {
    width: 44px;
    height: 44px;
  }
`;

const ProjectActions = styled.div`
  display: flex;
  align-items: baseline;
`;

const ProjectLaunchButton = styled(Button)`
  &.btn {
    padding: 0;
    min-width: 1.5rem;
    min-height: 1.625rem;
    margin-right: 0.938rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0;
    }

    &:focus {
      outline: none;
    }
  }

  &.btn-primary {
    border-color: transparent;

    &:focus,
    &:hover,
    &:not(:disabled):active {
      color: #000000;
      background-color: transparent;
      border-color: transparent;
    }
  }
`;

type Props = {
  project: ProjectMinimal;
  urlPrefix: string;
  archived?: boolean;
  onProjectSubmit: () => Promise<void>;
  onArchiveRestore: () => Promise<void>;
};

const ProjectCard: React.FC<Props> = ({
  project,
  urlPrefix,
  archived,
  onProjectSubmit,
  onArchiveRestore,
}) => {
  const store = useStore();
  const { current: currentUser } = store.auth;
  const [renameProjectModalShow, setRenameProjectModalShow] =
    useState<boolean>(false);
  const [moveProjectModalShow, setMoveProjectModalShow] =
    useState<boolean>(false);
  const [duplicateProjectModalShow, setDuplicateProjectModalShow] =
    useState<boolean>(false);

  const handleProjectClick = () => {
    history.push(`${urlPrefix}${project.id}`);
  };

  const handleProjectClickNewTab = (event: any) => {
    event.stopPropagation();
    window.open(`${urlPrefix}${project.id}`, "_blank");
  };

  const handleRestore = async () => {
    try {
      await api.projects.restoreProject(project.id);
    } catch (e) {
      Toast.danger("Project not restored");
      return;
    }
    onArchiveRestore();
    Toast.success("Project restored");
  };

  const handleArchive = async () => {
    try {
      await api.projects.archiveProject(project.id);
    } catch (e) {
      Toast.danger("Project not archived");
      return;
    }
    onArchiveRestore();
    Toast.success("Project archived");
  };

  const projectOptions = archived ? (
    <>
      {currentUser?.isAdmin && (
        <Option onClick={handleRestore}>
          <UnarchiveOutlined />
          Restore Project
        </Option>
      )}
      <Option
        onClick={() => {
          setMoveProjectModalShow(true);
        }}
      >
        <MoveToInboxOutlined />
        Move Project
      </Option>
    </>
  ) : (
    <>
      <Option
        onClick={() => {
          setDuplicateProjectModalShow(true);
        }}
      >
        <Duplicate />
        Duplicate Project
      </Option>
      {currentUser?.isAdmin && (
        <Option onClick={handleArchive}>
          <Archive />
          Archive Project
        </Option>
      )}
      <Option
        onClick={() => {
          setRenameProjectModalShow(true);
        }}
      >
        <BorderColor />
        Rename Project
      </Option>
      <Option
        onClick={() => {
          setMoveProjectModalShow(true);
        }}
      >
        <MoveToInboxOutlined />
        Move Project
      </Option>
    </>
  );

  return (
    <>
      <ProjectDiv onClick={handleProjectClick}>
        <ProjectIcons>
          <InsertDriveFileOutlined className="project-icon" />
          <ProjectActions>
            <ProjectLaunchButton onClick={handleProjectClickNewTab}>
              <Launch />
            </ProjectLaunchButton>
            <OptionButton
              id="project-option-dropdown"
              title={<MoreVert />}
              menuAlign="right"
              onClick={(event: any) => {
                event.stopPropagation();
              }}
            >
              {projectOptions}
            </OptionButton>
          </ProjectActions>
        </ProjectIcons>
        <p>{project.name}</p>
        <span
          title={format(parseISO(project.modified), "Pp", { locale: enGB })}
        >
          Last updated {formatDistanceToNow(parseISO(project.modified))} ago
        </span>
        <span
          title={format(parseISO(project.modified), "Pp", { locale: enGB })}
        >
          Created {formatDistanceToNow(parseISO(project.created))} ago
        </span>
      </ProjectDiv>

      <RenameProject
        instance={project}
        isOpen={renameProjectModalShow}
        setModal={(visible: boolean) => setRenameProjectModalShow(visible)}
        onProjectSubmit={onProjectSubmit}
      />

      <ProjectMoveForm
        archived={archived}
        instance={project}
        isOpen={moveProjectModalShow}
        setModal={(visible: boolean) => setMoveProjectModalShow(visible)}
        onProjectSubmit={onProjectSubmit}
      />
      <DuplicateProject
        instance={project}
        isOpen={duplicateProjectModalShow}
        setModal={(visible: boolean) => setDuplicateProjectModalShow(visible)}
      />
    </>
  );
};

export default ProjectCard;
