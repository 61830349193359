import { FormLabel } from "react-bootstrap";
import styled from "styled-components";

const Label = styled(FormLabel).attrs(() => ({}))`
  font-size: 0.625rem;
  font-weight: bold;
  line-height: 1.375rem;
  opacity: 0.3;
  padding-bottom: ${({ column }) =>
    column === "sm" ? "0.1875rem" : undefined};
  padding-top: ${({ column }) => (column === "sm" ? "0.1875rem" : undefined)};
  text-transform: uppercase;
`;

export default Label;
