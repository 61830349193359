import base, {
  APIBulkUpdateResult,
  APIDetail,
  APIList,
  APISearchParams,
  config,
} from "./base";

export type CostingType = "full_cogs" | "ex_plant" | "formulation";

export interface APIRequirement {
  id: identifier;
  project: identifier;
  uniqueRef: string;
  destinationCountry: identifier;
  deliveryTerms: identifier;
  deliveryLocation: string;
  productSpecification: string;
  packSize: identifier;
  packageType: string;
  volumeYear1: number | null;
  volumeYear2: number | null;
  volumeYear3: number | null;
  volumeYear4: number | null;
  volumeYear5: number | null;
  packSizeRfq: string;
  altScenario: string;
  isCltLocked: boolean;
}

export type APIRequirementInput = Partial<Omit<APIRequirement, "isCltLocked">>;

export interface APIRequirementList extends APIList<APIRequirement> {}

export interface APIRequirementListSearchParams extends APISearchParams {
  project?: identifier;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIRequirementListSearchParams
) =>
  base.list<APIRequirementList, APIRequirementListSearchParams>(
    "requirements/",
    page,
    limit,
    searchParams
  );

export interface APIRequirementDetail extends APIDetail<APIRequirement> {}

export const detail = (requirementId: identifier) =>
  base.get<APIRequirementDetail>(`requirements/${requirementId}/`);

export const create = (projectId: identifier, data: APIRequirementInput) =>
  base.post<APIRequirementDetail>(`requirements/`, {
    json: { project: projectId, ...data },
  });

export const patch = (requirementId: identifier, data: APIRequirementInput) =>
  base.patch<APIRequirementDetail>(`requirements/${requirementId}/`, {
    json: data,
  });

export const del = (requirementId: identifier) =>
  base.deleteEmpty(`requirements/${requirementId}/`);

export const bulkUpdate = (
  projectId: identifier,
  data: APIRequirementInput[]
) =>
  base.patch<APIBulkUpdateResult<APIRequirementDetail>>(
    `requirements/bulk-update/`,
    {
      json: {
        instances: data.map((item) => ({ project: projectId, ...item })),
      },
    }
  );
