import {
  CheckCircleOutline,
  Refresh,
  ReportProblemOutlined,
} from "@material-ui/icons";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { useCallback, useEffect, useState } from "react";
import { useStore } from "store";
import styled from "styled-components";
import { Toast } from "utils";

type Props = {
  onPostUpdate?: () => Promise<void>;
};

const UnsavedText = styled.p`
  text-transform: uppercase;
  margin-bottom: 0;
  span {
    font-weight: 600;
    font-size: 0.75rem;
    color: #f2994a;
  }

  svg {
    margin-right: 0.625rem;
    fill: #f2994a;
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const SavedText = styled.p`
  text-transform: uppercase;
  margin-bottom: 0;
  span {
    font-weight: 600;
    font-size: 0.75rem;
    color: #74b469;
  }

  svg {
    margin-right: 0.625rem;
    fill: #74b469;
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const GenerateCostSheetButton: React.FC<Props> = ({ onPostUpdate }) => {
  const store = useStore();
  const project = useProject();

  const [costSheetGenerated, setCostSheetGenerated] = useState(false);

  const handleGenerateCostSheetClick = useCallback(async () => {
    if (!project) {
      return;
    }

    project.setLoading();

    try {
      await Promise.all([
        store.requirements.saveAll(project.id),
        store.formulations.saveAll(project.id),
      ]);
      await project.generateCostSheet();
      setCostSheetGenerated(true);
      await store.projects.detail(project.id);
    } catch (e) {
      Toast.danger("Cost sheet (frame) could not be generated.");
      return;
    } finally {
      project.setNotLoading();
    }

    await onPostUpdate?.();

    Toast.success("Cost sheet (frame) updated.");
  }, [
    onPostUpdate,
    project,
    store.formulations,
    store.projects,
    store.requirements,
  ]);

  useEffect(() => {
    if (project?.isCostSheetDirty) {
      setCostSheetGenerated(false);
    }
  }, [project?.isCostSheetDirty]);

  return (
    <>
      <Button
        variant={project?.isCostSheetDirty ? "cost-sheet-pending" : "primary"}
        size="thin"
        onClick={handleGenerateCostSheetClick}
        disabled={project?.loading}
      >
        <Refresh /> Update Cost Sheet (Frame)
      </Button>
      {project?.isCostSheetDirty && !costSheetGenerated ? (
        <UnsavedText>
          <ReportProblemOutlined />
          <span>This project requires a cost sheet (frame) update.</span>
        </UnsavedText>
      ) : (
        costSheetGenerated && (
          <SavedText>
            <CheckCircleOutline />
            <span>All changes have been saved.</span>
          </SavedText>
        )
      )}
    </>
  );
};

export default observer(GenerateCostSheetButton);
