import { CSVFrozenHeaderMapping } from "csv/mappings";
import dropdownEditorFactory from "../../../components/DataGrid/DropdownEditor";
import { ColumnExtraOptions } from "../../../components/DataGrid/types";
import { mappingOHLogs } from "../../../csv/mappings/collect";
import { IRow } from "../../../csv/types";
import {
  clusterRestrictedCellGenerator,
  editableCellGenerator,
  getActiveColumn,
  getGivenCell,
  getGivenNumberCell,
  getInputCell,
  getNumberCell,
  idCell,
} from "./utils";

const EUROPE = "Europe";
// const EUROPE = "Europe, Russia & Belarus";

const schemaOHLogs = (
  key: string,
  sheetEditable?: boolean,
  clusterName?: string,
  frozenIdentifiers?: CSVFrozenHeaderMapping
) => {
  const defaultCell = {
    name: mappingOHLogs[key] ? mappingOHLogs[key][0] : key,
    width: mappingOHLogs[key] ? mappingOHLogs[key][1] : 50,
  };
  const givenCellOHLogs = {
    ...defaultCell,
    ...getGivenCell(true),
  };
  const unfrozenGivenCellOHLogs = {
    ...defaultCell,
    ...getGivenCell(false),
  };
  const givenNumberCellOHLogs = {
    ...defaultCell,
    ...getGivenNumberCell(1, 1, false, true),
  };
  const unfrozenGivenNumberCellOHLogs = {
    ...defaultCell,
    ...getGivenNumberCell(1, 1, false),
  };
  const inputCell = {
    ...defaultCell,
    ...getInputCell(sheetEditable ?? true),
  };
  const numberCellDefault = {
    ...defaultCell,
    ...getNumberCell(sheetEditable ?? true, 3, 3),
  };

  const sourceRestrictedNumberCellDefault = clusterRestrictedCellGenerator(
    numberCellDefault,
    "source_clt",
    clusterName,
    sheetEditable ?? true
  );

  const repackagingRestrictedNumberCellDefault = clusterRestrictedCellGenerator(
    numberCellDefault,
    "repackaging_clt",
    clusterName,
    sheetEditable ?? true
  );

  const destinationRestrictedNumberCellDefault = clusterRestrictedCellGenerator(
    numberCellDefault,
    "destination_clt",
    clusterName,
    sheetEditable ?? true
  );

  const destinationRestrictedNumberCellPct = clusterRestrictedCellGenerator(
    {
      ...defaultCell,
      ...getNumberCell(sheetEditable ?? true, 2, 2, true),
    },
    "destination_clt",
    clusterName,
    sheetEditable
  );

  const options: Record<string, ColumnExtraOptions<IRow>> = {
    id: idCell,
    concatenate: givenCellOHLogs,
    active: getActiveColumn(frozenIdentifiers?.active),
    delivery_location: !frozenIdentifiers?.delivery_location
      ? unfrozenGivenCellOHLogs
      : givenCellOHLogs,
    dest_country: !frozenIdentifiers?.dest_country
      ? unfrozenGivenCellOHLogs
      : givenCellOHLogs,
    destination_clt: !frozenIdentifiers?.destination_clt
      ? unfrozenGivenCellOHLogs
      : givenCellOHLogs,
    source_plant: !frozenIdentifiers?.source_plant
      ? unfrozenGivenCellOHLogs
      : givenCellOHLogs,
    source_clt: givenCellOHLogs,
    repackaging_plant: !frozenIdentifiers?.repackaging_plant
      ? unfrozenGivenCellOHLogs
      : givenCellOHLogs,
    repackaging_clt: givenCellOHLogs,
    delivery_terms: !frozenIdentifiers?.delivery_terms
      ? unfrozenGivenCellOHLogs
      : givenCellOHLogs,
    pack_size_from_source: !frozenIdentifiers?.pack_size_from_source
      ? unfrozenGivenCellOHLogs
      : givenCellOHLogs,
    pack_size: !frozenIdentifiers?.pack_size
      ? unfrozenGivenCellOHLogs
      : givenCellOHLogs,
    volume_yr1: !frozenIdentifiers?.volume_yr1
      ? unfrozenGivenNumberCellOHLogs
      : givenNumberCellOHLogs,
    alt_scenario: !frozenIdentifiers?.alt_scenario
      ? unfrozenGivenCellOHLogs
      : givenCellOHLogs,
    warehouse_code: inputCell,
    alt_sku_logistics: inputCell,
    rtm: clusterRestrictedCellGenerator(
      {
        ...inputCell,
        editor: (props) => {
          const RtmDropdown = dropdownEditorFactory(key, [
            {
              value: "Direct - Del to Cust",
              label: "Direct - Del to Cust",
            },
            {
              value: "Direct - Cust Collection",
              label: "Direct - Cust Collection",
            },
            {
              value: "Distributor - Del to WH",
              label: "Distributor - Del to WH",
            },
            {
              value: "Distributor - Del to Port",
              label: "Distributor - Del to Port",
            },
            {
              value: "Distributor - Dist Collects",
              label: "Distributor - Dist Collects",
            },
            {
              value: "Distributor - DFF (US Only)",
              label: "Distributor - DFF (US Only)",
            },
            {
              value: "Distributor - Other",
              label: "Distributor - Other",
            },
          ]);
          return <RtmDropdown {...props} />;
        },
        editorOptions: {
          editOnClick: true,
        },
      },
      "destination_clt",
      clusterName,
      sheetEditable
    ),
    extra_freight_cost_source_country: sourceRestrictedNumberCellDefault,
    "3p_primary_transport_cost_source_country":
      sourceRestrictedNumberCellDefault,
    "3p_storage_handling_cost_source_country":
      sourceRestrictedNumberCellDefault,
    internal_storage_cost_source_country: sourceRestrictedNumberCellDefault,
    extra_freight_cost_repacking_country:
      repackagingRestrictedNumberCellDefault,
    "3p_primary_transport_cost_repacking_country":
      repackagingRestrictedNumberCellDefault,
    "3p_storage_handling_cost_repacking_country":
      repackagingRestrictedNumberCellDefault,
    internal_storage_cost_repacking_country:
      repackagingRestrictedNumberCellDefault,
    percent_formulation_cost: destinationRestrictedNumberCellPct,
    percent_ex_plant_cost: destinationRestrictedNumberCellPct,
    percent_ex_plant_import_cost: destinationRestrictedNumberCellPct,
    extra_freight_cost_destination_country:
      destinationRestrictedNumberCellDefault,
    "3p_primary_transport_cost_destination_country":
      destinationRestrictedNumberCellDefault,
    "3p_storage_handling_cost_destination_country":
      destinationRestrictedNumberCellDefault,
    internal_storage_cost_destination_country:
      destinationRestrictedNumberCellDefault,
    logistics_specific_cost: destinationRestrictedNumberCellDefault,
    logistics_secondary_cost: destinationRestrictedNumberCellDefault,
    internal_primary_transport_cost: editableCellGenerator(
      sourceRestrictedNumberCellDefault,
      EUROPE === clusterName,
      sheetEditable
    ),
    cn_construction_tax: editableCellGenerator(
      numberCellDefault,
      "China" === clusterName,
      sheetEditable
    ),
    th_import_sales_tax: editableCellGenerator(
      numberCellDefault,
      "Thailand" === clusterName,
      sheetEditable
    ),
  };
  return options[key] || numberCellDefault;
};

export default schemaOHLogs;
