import api from "api";
import { APISubActivity } from "api/activities";
import { computed, observable } from "mobx";
import {
  model,
  Model,
  modelFlow,
  prop,
  prop_setArray,
  _async,
  _await,
} from "mobx-keystone";

@model("collab/Activity")
class Activity extends Model({
  id: prop<identifier>(),
  created: prop<string>(),
  project: prop<identifier>(),
  projectName: prop<string>(),
  projectVersion: prop<identifier>(),
  projectVersionNumber: prop<number>(),
  user: prop<identifier>(),
  userName: prop<string>(),
  userPhotoSizes: prop<{ big?: string; medium?: string }>(() => ({})),
  clusters: prop<identifier[]>(() => []),
  clusterNames: prop<string[]>(() => []),
  activityType: prop<string>(),
  notes: prop<string>(),
  subactivityCount: prop<number>(0),
  subactivities: prop_setArray(() => new Set<APISubActivity>()),
}) {
  @observable private count: number = 0;
  @observable private next: string | null = null;

  @modelFlow
  listSubActivities = _async(function* (
    this: Activity,
    page?: number,
    limit?: number
  ) {
    if (this.count > 0 && !this.next) {
      return { count: this.count, next: false, previous: true, results: [] };
    }

    const { count, next, previous, results } = yield* _await(
      api.activities.listSubActivities(this.id, this.next, page, limit)
    );

    this.count = count;
    this.next = next;

    results.forEach((subactivity) => this.subactivities.add(subactivity));

    return { count, next: !!next, previous: !!previous, results };
  });

  @computed
  get hasMoreSubActivities() {
    return !!this.next;
  }
}

export default Activity;
