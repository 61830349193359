import { Mutex } from "async-mutex";
import baseKy, { HTTPError, Options as KyOptions } from "ky";
import { omit } from "lodash";
import { refreshToken } from "utils";

export interface APIConfig {
  baseUrl: string;
  defaultLimit: number;
  timeout: number;
  token: string;
  refreshing: boolean;
}

export const config: APIConfig = {
  baseUrl: "",
  defaultLimit: 99999,
  timeout: 5 * 60000, // 5 minutes
  token: "",
  // Define a semaphore to prevent multiple requests from attempting to refresh the access token.
  refreshing: false,
};

export interface APISearchParams {
  search?: string;
  sortColumn?: string;
  sortDirection?: "asc" | "desc";
  [k: string]: string | string[] | number | boolean | undefined;
}

interface Options extends KyOptions {
  public?: boolean;
}

function getActualOptions(options: Options = {}) {
  const actualOptions: KyOptions = omit(options, ["public"]);

  if (!options.public && config.token) {
    actualOptions.headers = {
      Authorization: `Bearer ${config.token}`,
      ...actualOptions.headers,
    };
  }

  return actualOptions;
}

const refreshMutex = new Mutex();
let refreshRequired = false;

const ky = baseKy.extend({
  hooks: {
    beforeRequest: [
      async (request) => {
        console.debug("[Debug][ky]", request.method, request.url, request);
      },
    ],
    beforeRetry: [
      async ({ request, error }) => {
        if ((error as HTTPError).response.status === 401) {
          // An access token refresh is required.
          refreshRequired = true;

          // Just let the request wait if another is already trying to refresh the access token.
          const release = await refreshMutex.acquire();

          try {
            // At this point, some other request might have already set `refreshRequired` to `false`.
            if (refreshRequired) {
              const token = await refreshToken();
              if (token) {
                config.token = token;
                request.headers.set("Authorization", `Bearer ${token}`);
                refreshRequired = false;
                return;
              }
            } else {
              request.headers.set("Authorization", `Bearer ${config.token}`);
              return;
            }
          } finally {
            release();
          }

          return baseKy.stop;
        }
      },
    ],
  },
  retry: {
    limit: 5,
    methods: [
      "get",
      "put",
      "post",
      "head",
      "patch",
      "delete",
      "options",
      "trace",
    ],
    statusCodes: [401, 408, 413, 429, 502, 503, 504],
  },
});

// Base methods

async function destroyEmpty(url: string, options?: Options) {
  return ky.delete(url, {
    prefixUrl: config.baseUrl,
    timeout: config.timeout,
    ...getActualOptions(options),
  });
}

async function getEmpty(url: string, options?: Options) {
  return ky.get(url, {
    prefixUrl: config.baseUrl,
    timeout: config.timeout,
    ...getActualOptions(options),
  });
}

async function head(url: string, options?: Options) {
  return ky.head(url, {
    prefixUrl: config.baseUrl,
    timeout: config.timeout,
    ...getActualOptions(options),
  });
}

async function patchEmpty(url: string, options?: Options) {
  return ky.patch(url, {
    prefixUrl: config.baseUrl,
    timeout: config.timeout,
    ...getActualOptions(options),
  });
}

async function postEmpty(url: string, options?: Options) {
  return ky.post(url, {
    prefixUrl: config.baseUrl,
    timeout: config.timeout,
    ...getActualOptions(options),
  });
}

async function putEmpty(url: string, options?: Options) {
  return ky.put(url, {
    prefixUrl: config.baseUrl,
    timeout: config.timeout,
    ...getActualOptions(options),
  });
}

async function destroy<T>(url: string, options?: Options): Promise<T> {
  const response = await destroyEmpty(url, options);

  return response.json();
}

async function get<T>(url: string, options?: Options): Promise<T> {
  const response = await getEmpty(url, options);

  return response.json();
}

async function patch<T>(url: string, options?: Options): Promise<T> {
  const response = await patchEmpty(url, options);

  return response.json();
}

async function post<T>(url: string, options?: Options): Promise<T> {
  const response = await postEmpty(url, options);

  return response.json();
}

async function put<T>(url: string, options?: Options): Promise<T> {
  const response = await putEmpty(url, options);

  return response.json();
}

// Convenience methods

export type APIDetail<T> = T;

export interface APIList<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface APIBulkUpdateResult<T> {
  instances: T[];
}

function buildSortString(sortColumn: string, sortDirection: "asc" | "desc") {
  return `${sortDirection === "desc" ? "-" : ""}${sortColumn}`;
}

async function list<T, S extends APISearchParams = APISearchParams>(
  url: string,
  page = 1,
  limit = 0,
  searchParams?: S,
  options?: Options
): Promise<T> {
  const actualLimit =
    limit === -1 ? Number.MAX_SAFE_INTEGER : limit || config.defaultLimit;
  const offset = (page - 1) * actualLimit;

  let params: {
    [k: string]: string | string[] | number | boolean | undefined;
  } = {
    limit: actualLimit,
    offset,
    ...searchParams,
  };

  const sortColumn = searchParams?.sortColumn;
  const sortDirection = searchParams?.sortDirection;
  const order =
    sortColumn && sortDirection
      ? buildSortString(sortColumn, sortDirection)
      : undefined;

  try {
    delete params.sortColumn;
    delete params.sortDirection;
  } catch (e) {
    // pass
  }

  if (order) {
    params = { ...params, order };
  }

  return get<T>(url, {
    ...options,
    // Better than `any`...
    searchParams: params as { [k: string]: string | number | boolean },
  });
}

export interface APIIDList {
  ids: (string | number)[];
}
export function destroyBulk<T>(url: string, data: T) {
  return destroyEmpty(url, { json: data });
}

const _export = {
  ky,
  deleteBulk: destroyBulk,
  delete: destroy,
  deleteEmpty: destroyEmpty,
  get,
  getEmpty,
  head,
  list,
  patch,
  patchEmpty,
  post,
  postEmpty,
  put,
  putEmpty,
};

export default _export;
