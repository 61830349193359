import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useEffectOnce } from "react-use";
import Loading from "../../components/Loading";
import { useStore } from "../../store";

type Props = RouteProps & {
  redirectTo?: string;
};

const UnauthenticatedRoute: React.FC<Props> = ({
  children,
  redirectTo = "/login",
  ...rest
}) => {
  const store = useStore();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffectOnce(() => {
    (async () => {
      const { isAuthenticated: isUserAuthenticated } =
        await store.auth.checkAuth();

      if (isUserAuthenticated) {
        await store.init();
      }

      setIsAuthenticated(isUserAuthenticated);
      setIsInitialized(true);
    })();
  });

  if (!isInitialized) {
    return <Loading full />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default observer(UnauthenticatedRoute);
