import { CloudDownloadOutlined } from "@material-ui/icons";
import api from "api";
import Button from "components/Button";
import FormulationFilter from "components/FormulationFilter";
import Loading from "components/Loading";
import ProjectLockedLabel from "components/ProjectLockedLabel";
import SearchFormulations from "components/SearchFormulations";
import UploadButton from "components/UploadButton";
import ValidationDashboard from "components/ValidationDashboard";
import { CsvValidationErrorContext } from "components/ValidationDashboard/contexts";
import { useValidationSummary } from "components/ValidationDashboard/hooks";
import { addCsvSepHeader, reparseMapHeadersFromCsvString } from "csv/utils";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { ScrollSync } from "react-scroll-sync";
import { useAsync, useUnmount } from "react-use";
import { useStore } from "store";
import styled from "styled-components";
import { Toast } from "utils";
import Toolbar from "../../../components/Toolbar";
import { useProject, useSave } from "../../Workspace/hooks";
import { CustomSheet } from "../../Workspace/types";
import GenerateCostSheetButton from "../GenerateCostSheetButton";
import FormulationsTabAdditives from "./FormulationsTabAdditives";
import FormulationsTabBaseOils from "./FormulationsTabBaseOils";
import FormulationsTabHead from "./FormulationsTabHead";
import FormulationsTabTotals from "./FormulationsTabTotals";
import FormulationsTabTreatRates from "./FormulationsTabTreatRates";

type Props = {
  tab: CustomSheet;
};

const SheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  overflow: auto;
  /* padding: 1.25rem; */
`;

const FormulationsTab: React.FC<Props> = ({ tab }) => {
  const store = useStore();
  const project = useProject();
  const currentUser = store.auth.current;
  const hasEditPermission = !!(
    currentUser &&
    (currentUser.isAdmin ||
      project?.opsInputUsers.includes(currentUser.id) ||
      project?.adminUsers.includes(currentUser.id))
  );
  const editable = hasEditPermission && !project?.isLocked;

  const [activeSheet, setActiveSheet] = useState<string>();

  const validationSummary = useValidationSummary();

  useUnmount(() => {
    if (project && project.isDirty) {
      project.setNotDirty();
    }
  });

  // (Re)load formulations when user opens this tab.
  const state = useAsync(async () => {
    if (!project) {
      return;
    }
    store.formulations.commitCsv(project.id);
    await store.formulations.list(project.id);
    await store.requirements.list(project.id);
    store.formulations.commitCsv(project.id);
  }, [project]);

  // Save formulations when save button is pressed.
  useSave(
    async () => {
      if (!project) {
        return;
      }

      if (!editable) {
        throw new Error("Formulations not editable");
      }

      try {
        await store.formulations.saveAll(project.id);
      } catch (e) {
        throw new Error(
          "Formulations not saved. Please ensure all input validations are resolved."
        );
      } finally {
        store.formulations.commitCsv(project.id);
      }
    },
    { saveOnUnmount: true }
  );

  const handleDownloadClick = useCallback(async () => {
    if (!project) {
      return;
    }

    project.setLoading("Preparing download...");

    const [mappedCsvHeadString, , colLength] = reparseMapHeadersFromCsvString(
      store.formulations.getHeadCsv(project.id, true) || ""
    );
    const mainHeader = [
      "",
      "",
      "",
      "",
      ...mappedCsvHeadString.split("\n")[0].split(",").slice(4),
    ].join(",");
    const oilsHeader = ["", "R Code", "RM Desc", "CRD Status"]
      .concat(new Array(colLength - 4))
      .join(",");
    const [mappedCsvBaseOilString] = reparseMapHeadersFromCsvString(
      store.formulations.getBaseOilsCsv(project.id, true) || ""
    );
    const [mappedCsvAdditiveString] = reparseMapHeadersFromCsvString(
      store.formulations.getAdditivesCsv(project.id, true) || ""
    );

    const mappedCsvString = addCsvSepHeader(
      `${mainHeader}\r\n${mappedCsvHeadString
        .split("\r\n")
        .slice(1)
        .join("\r\n")}\r\n${oilsHeader}\r\n${mappedCsvBaseOilString
        .split("\r\n")
        .slice(1)
        .join("\r\n")}\r\n${mappedCsvAdditiveString
        .split("\r\n")
        .slice(1)
        .join("\r\n")}`
    );

    const zip = new JSZip().file(`formulations.csv`, mappedCsvString);

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `formulations-${project.id}.zip`);

    project.setNotLoading();
  }, [project, store.formulations]);

  const handleFileUpload = async (file: string) => {
    if (!project) {
      return;
    }

    project?.setLoading("Preparing upload...");

    try {
      await api.projects.uploadFormulations(project.id, {
        file,
      });
      project.setCostSheetDirty();
    } catch (e) {
      Toast.danger("Unable to process the CSV file.");
      return;
    } finally {
      project.setNotLoading();
    }

    Toast.success("CSV file successfully uploaded.");
    await store.requirements.list(project.id);
    await store.formulations.list(project.id);
    store.formulations.commitCsv(project.id);
  };

  const ready = !state.loading;

  if (!ready) {
    return <Loading full />;
  }

  return (
    <CsvValidationErrorContext.Provider value={validationSummary}>
      <Toolbar>
        <FormulationFilter />
        <Button size="thin" onClick={handleDownloadClick}>
          <CloudDownloadOutlined /> Download CSV File
        </Button>
        <UploadButton onFileUpload={handleFileUpload} />
        {editable && <SearchFormulations />}
        {editable && <GenerateCostSheetButton />}
        {project?.isLocked && <ProjectLockedLabel />}
        <ValidationDashboard validationChecks={[]} />
      </Toolbar>
      <ScrollSync vertical={false}>
        <SheetContainer>
          <FormulationsTabHead
            editable={editable}
            isSheetActive={activeSheet === "formulations"}
            onSheetActive={setActiveSheet}
          />
          <FormulationsTabBaseOils
            editable={editable}
            isSheetActive={activeSheet === "baseOils"}
            onSheetActive={setActiveSheet}
          />
          <FormulationsTabAdditives
            editable={editable}
            isSheetActive={activeSheet === "additives"}
            onSheetActive={setActiveSheet}
          />
          <FormulationsTabTreatRates
            editable={editable}
            isSheetActive={activeSheet === "treatRates"}
            onSheetActive={setActiveSheet}
          />
          <FormulationsTabTotals />
        </SheetContainer>
      </ScrollSync>
    </CsvValidationErrorContext.Provider>
  );
};

export default observer(FormulationsTab);
