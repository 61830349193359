import { LockOpenOutlined, LockOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useAsyncFn, useEffectOnce } from "react-use";
import styled from "styled-components";
import {
  APIClusterInputSheetsStatusBulkUpdateInput,
  APIClusterInputSheetsStatustSearchParams,
} from "../../api/clusterInputSheetsStatus";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { useStore } from "../../store";
import { Toast } from "../../utils";
import { confirmProjectLock } from "../../utils/confirm";
import { useProject, useSave } from "../Workspace/hooks";
import StatusDashboardTable from "./StatusDashboardTable";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  overflow: auto;
  padding: 1.25rem;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  &.btn {
    min-width: 22rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

const Header = styled.h1`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
`;

type Props = {};

const StatusDashboard: React.FC<Props> = () => {
  const store = useStore();
  const methods = useForm<APIClusterInputSheetsStatusBulkUpdateInput>();
  const project = useProject();
  const { current: currentUser } = store.auth;
  const hasEditPermissions = !!(
    currentUser &&
    (currentUser.isAdmin || project?.adminUsers.includes(currentUser.id))
  );
  const editable = hasEditPermissions && !project?.isLocked;

  const [{ loading }, initFn] = useAsyncFn(async () => {
    if (project) {
      const params: APIClusterInputSheetsStatustSearchParams = {
        project: project.id,
      };
      await store.clusterInputSheetsStatus.list(undefined, undefined, params);
    }
    return Array.from(store.clusterInputSheetsStatus.listItems.values());
  }, [project]);

  useEffectOnce(() => {
    initFn();
  });

  const handleClick = useCallback(async () => {
    if (!project) {
      return;
    }

    const { id, latestIssueDate, versionNumber } = project;
    if (!project.isLocked) {
      if (await confirmProjectLock(latestIssueDate, versionNumber)) {
        try {
          await store.projects.lock(id);
        } catch (e) {
          Toast.danger(`Project lock failed`);
          return;
        }
        Toast.success(`Project locked`);
      }
    } else {
      try {
        await store.projects.unlock(id);
      } catch (e) {
        Toast.danger(`Project unlock failed`);
        return;
      }
      Toast.success(`Project unlocked`);
    }

    await initFn();

    try {
      await store.projects.detail(id);
    } catch (e) {
      Toast.danger("Project could not be updated.");
    }
  }, [initFn, project, store.projects]);

  const handleSave = useCallback(
    async (data: APIClusterInputSheetsStatusBulkUpdateInput) => {
      try {
        await store.clusterInputSheetsStatus.bulkUpdate(data);
      } catch (e) {
        throw new Error("Clusters not updated");
      }
    },
    [store]
  );

  useSave(async () => {
    if (!editable) {
      throw new Error("Status Dashboard not editable");
    }
    await methods.handleSubmit(handleSave)();
  });

  if (!project || loading) {
    return <Loading full />;
  }

  return (
    <FormProvider {...methods}>
      <Container>
        <Form onSubmit={methods.handleSubmit(handleSave)}>
          <StyledDiv>
            <Header>Status Dashboard</Header>
            {hasEditPermissions && (
              <StyledButton onClick={handleClick}>
                {project.isLocked ? (
                  <>
                    <LockOpenOutlined /> Unlock
                  </>
                ) : (
                  <>
                    <LockOutlined /> Lock
                  </>
                )}{" "}
                Project
              </StyledButton>
            )}
          </StyledDiv>
          {loading ? (
            <Loading />
          ) : (
            <StatusDashboardTable hasEditPermissions={hasEditPermissions} />
          )}
        </Form>
      </Container>
    </FormProvider>
  );
};

export default observer(StatusDashboard);
