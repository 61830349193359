import base, { APIList, APISearchParams, config } from "./base";

export type APIProjectVersion = {
  id: identifier;
  project: identifier;
  clusters: identifier[];
  creator: identifier;
  creatorName: string;
  versionNumber: number;
  revisionInstance: identifier;
  latestRevisionInstance: identifier;
  notes: string;
};

export interface APIProjectVersionList extends APIList<APIProjectVersion> {}
export interface APIProjectVersionListSearchParams extends APISearchParams {}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIProjectVersionListSearchParams
) =>
  base.list<APIProjectVersionList, APIProjectVersionListSearchParams>(
    "project-versions/",
    page,
    limit,
    searchParams
  );
