import React, { useState } from "react";
import styled from "styled-components";
import { Option, OptionButton } from "../OptionButton";
import {
  BorderColor,
  DeleteOutlined,
  FolderOpen,
  MoreVert,
} from "@material-ui/icons";
import history from "../../services/history";
import FolderForm from "../../pages/Projects/FolderForm";
import ProjectFolder from "../../store/models/ProjectFolders";
import { darken } from "polished";
import api from "../../api";
import { Toast } from "../../utils";
import { useStore } from "../../store";
import { confirmDeleteFolder } from "../../utils/confirm";

const FolderDiv = styled.div`
  min-width: 184px;
  height: 48px;
  padding: 8px 15px;

  border: 1px solid #cecece;
  box-sizing: border-box;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: #000000;
  }

  &:not(:disabled):active {
    background-color: ${darken(0.1, "#ffffff")};
  }
`;

const FolderLabel = styled.span`
  color: #000000;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: uppercase;

  span {
    display: flex;
    align-items: center;
    font-size: 0.625rem;
    opacity: 0.3;
    user-select: none;

    svg {
      width: 12px;
      height: 12px;
      margin-right: 0.375rem;
    }
  }
`;

type Props = {
  folder: ProjectFolder;
  archived?: boolean;
  onFolderSubmit?: () => Promise<void>;
};

const FolderCard: React.FC<Props> = ({ folder, archived, onFolderSubmit }) => {
  const store = useStore();
  const { current: currentUser } = store.auth;
  const urlPrefix = "/folders/";
  const [renameFolderModalShow, setRenameFolderModalShow] =
    useState<boolean>(false);

  const handleFolderClick = () => {
    history.push(`${urlPrefix}${folder.slug}`);
  };

  const handleFolderDelete = async () => {
    if (await confirmDeleteFolder(folder.name)) {
      try {
        await api.projectFolders.destroy(folder.slug);
      } catch (e) {
        Toast.danger("Folder not deleted");
        return;
      }
      await onFolderSubmit?.();
      Toast.success("Folder deleted");
    }
  };

  return (
    <>
      <FolderDiv onClick={handleFolderClick}>
        <FolderLabel>
          <span>
            <FolderOpen />
            Folder
          </span>
          {folder.name}
        </FolderLabel>
        {currentUser?.isAdmin && (
          <OptionButton
            id="folder-option-dropdown"
            title={<MoreVert />}
            menuAlign="right"
            onClick={(event: any) => {
              event.stopPropagation();
            }}
          >
            <Option
              onClick={() => {
                setRenameFolderModalShow(true);
              }}
            >
              <BorderColor />
              Rename Folder
            </Option>
            <Option onClick={handleFolderDelete}>
              <DeleteOutlined />
              Delete Folder
            </Option>
          </OptionButton>
        )}
      </FolderDiv>
      <FolderForm
        instance={folder}
        archived={archived}
        isOpen={renameFolderModalShow}
        setModal={(visible: boolean) => setRenameFolderModalShow(visible)}
        onFolderSubmit={onFolderSubmit}
      />
    </>
  );
};

export default FolderCard;
