import { DataGridHandleWithData } from "components/DataGrid/types";
import { IRow } from "csv/types";
import { CellSelectEvent, CsvFile } from "pages/Workspace/types";
import React from "react";
import { Column } from "react-data-grid";
import Comment from "store/models/Comment";
import { CellSelection } from "./types";

type CommentContextProps = {
  show: boolean;
  showCreate: boolean;
  selection?: CellSelection;
  visibleSheets: string[];
  onCellSelect: CellSelectEvent;
  onCommentSelect: (comment: Comment) => void;
  onClose: () => void;
  onCreateComment: (selection: CellSelection) => void;
  hasComments: (csvFile: CsvFile, row?: IRow, column?: Column<IRow>) => boolean;
  register: (
    sheetType: string,
    sheetId: identifier,
    ref: React.RefObject<DataGridHandleWithData>,
    rowIdentifier: string
  ) => void;
  unregister: (sheetType: string, sheetId: identifier) => void;
};

const CommentContext = React.createContext<CommentContextProps>({
  show: false,
  showCreate: false,
  visibleSheets: [],
  onCellSelect: () => {},
  onCommentSelect: () => {},
  onClose: () => {},
  onCreateComment: () => {},
  hasComments: () => false,
  register: () => {},
  unregister: () => {},
});

export default CommentContext;
