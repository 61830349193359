import {
  AssessmentOutlined,
  DashboardOutlined,
  Public,
  ReportProblemOutlined,
  Settings,
} from "@material-ui/icons";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { observer } from "mobx-react-lite";
import React from "react";
import { NavbarBrand } from "react-bootstrap";
import { Route, Switch, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import styled from "styled-components";
import Loading from "../../components/Loading";
import NavbarButtons from "../../components/NavbarButtons";
import Collect from "../../icons/Collect";
import { useStore } from "../../store";
import CollectSection from "../CollectSection";
import DefineSection from "../DefineSection";
import OutputSection from "../OutputSection";
import { Page } from "../Page";
import SettingsSection from "../SettingsSection";
import StatusSection from "../StatusSection";
import { ProjectContext } from "./contexts";
import Sidebar from "./Sidebar";

type Props = {};

const RootContainer = styled.div`
  background-color: #f1f1f1;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: stretch;
  width: 100%;
  overflow: hidden;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  position: relative;
  width: calc(100% - 5rem);
`;

const StyledNavbarBrand = styled(NavbarBrand)`
  display: block;
  line-height: 1;
  margin: 0 1.25rem;
  padding: 0;
  position: relative;

  text-align: right;
`;

const SubTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 0.625rem;
  user-select: none;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.3;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0;

  color: #000000;
`;

const UnsavedText = styled.p`
  margin-bottom: 0;
  span {
    font-weight: 600;
    font-size: 0.875rem;
    color: #f2994a;
  }

  svg {
    margin-right: 0.625rem;
    fill: #f2994a;
    width: 1.125rem;
    height: 1.125rem;
  }
`;

type MenuItem = {
  icon: any;
  text: string;
  link: string;
};

const menuItems: MenuItem[] = [
  {
    icon: Public,
    text: "Define",
    link: "",
  },
  {
    icon: Collect,
    text: "Collect",
    link: "/collect",
  },
  {
    icon: AssessmentOutlined,
    text: "Output",
    link: "/analyze",
  },
  {
    icon: DashboardOutlined,
    text: "Status",
    link: "/status",
  },
  {
    icon: Settings,
    text: "Settings",
    link: "/settings/details",
  },
];

const Workspace: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const urlPrefix = `/projects/${id}`;
  const pathPrefix = "/projects/:id";
  const store = useStore();
  const project = store.projects.listItems.get(parseInt(id, 10));

  const { name } = project || { name: "Loading" };

  useAsync(async () => {
    await store.projects.detail(parseInt(id, 10));
  }, [id]);

  return (
    <Page
      title={name}
      breadcrumb={[
        { title: "Projects Drive", subtitle: "Projects", link: "/projects" },
        { title: name, subtitle: "Active Projects", link: urlPrefix },
      ]}
      renderHeaderRight={() => {
        if (!project) {
          return <></>;
        }
        return (
          <>
            {project.isDirty && (
              <StyledNavbarBrand>
                <UnsavedText>
                  <ReportProblemOutlined />
                  <span>This file has unsaved changes</span>
                </UnsavedText>
              </StyledNavbarBrand>
            )}
            <StyledNavbarBrand>
              <SubTitle>Version {project.versionNumber}</SubTitle>
              <Title
                title={
                  project.modified
                    ? format(parseISO(project.modified), "Pp", {
                        locale: enGB,
                      })
                    : "Loading"
                }
              >
                {project.modified
                  ? `As of ${formatDistanceToNow(
                      parseISO(project.modified)
                    )} ago`
                  : "Loading"}
              </Title>
            </StyledNavbarBrand>
            <NavbarButtons project={project} urlPrefix={urlPrefix} />
          </>
        );
      }}
    >
      <RootContainer>
        <Sidebar items={menuItems} urlPrefix={urlPrefix} />
        <ProjectContext.Provider value={project}>
          <Main>
            {project && project.loading && <Loading full overlay />}
            <Switch>
              <Route exact path={pathPrefix}>
                <DefineSection />
              </Route>
              <Route exact path={`${pathPrefix}/collect`}>
                <CollectSection />
              </Route>
              <Route exact path={`${pathPrefix}/analyze`}>
                <OutputSection />
              </Route>
              <Route exact path={`${pathPrefix}/status`}>
                <StatusSection />
              </Route>
              <Route exact path={`${pathPrefix}/settings/details`}>
                <SettingsSection />
              </Route>
            </Switch>
          </Main>
        </ProjectContext.Provider>
      </RootContainer>
    </Page>
  );
};

export default observer(Workspace);
