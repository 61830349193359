import { FormCheck } from "react-bootstrap";
import styled from "styled-components";

const Switch = styled(FormCheck).attrs(() => ({ type: "switch" }))`
  .custom-control-label::before {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
  }

  .custom-control-label::after {
    background-color: #ffffff;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #74b469;
    border-color: #74b469;
  }

  .custom-control-label {
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 1.5rem;
    text-transform: uppercase;
    vertical-align: middle;
  }
`;

export default Switch;
