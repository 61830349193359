import api from "api";
import { format, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useStore } from "store";
import Comment from "store/models/Comment";
import styled from "styled-components";
import { Toast } from "utils";
import { confirmDeleteComment } from "utils/confirm";
import AtMentionDiv from "./AtMentionDiv";
import CommentForm from "./CommentForm";
import { CellSelection } from "./types";

type Props = {
  comment: Comment;
  first?: boolean;
  onClick?: (comment: Comment) => void;
  selection: CellSelection;
};

const ToggleButton = styled(Dropdown.Toggle)`
  display: none;

  &::after {
    display: none;
  }
`;

const Options = styled(Dropdown)`
  position: absolute;
  top: -0.5rem;
  right: 0;

  &:focus,
  &:focus-within {
    display: block;
  }
`;

const Container = styled.div`
  position: relative;

  &:hover,
  &:focus-within {
    ${ToggleButton}:not(:disabled) {
      display: block;
    }
  }
`;

const User = styled.div`
  clear: both;
  padding-right: 1rem;

  img {
    border-radius: 50%;
    border: 2px solid #8fac42;
    display: block;
    float: left;
    height: 1.75rem;
    margin-right: 1rem;
    width: 1.75rem;
  }

  .name {
    font-weight: bold;
    margin-left: 2.75rem;
  }

  .date {
    font-weight: 600;
    margin-left: 2.75rem;
  }
`;

const Post: React.FC<Props> = ({
  comment,
  first = false,
  onClick,
  selection,
}) => {
  const store = useStore();
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEditCancelClick = () => {
    setIsEditing(false);
  };

  const handleDeleteConfirm = async () => {
    if (await confirmDeleteComment()) {
      store.comments.delete(comment.id);
    }
  };

  const handleResolve = async () => {
    try {
      await store.comments.resolve(comment.id);
    } catch (e) {
      const { detail } = await api.handleError(e as Error);
      Toast.danger(`Could not resolve thread: ${detail!.detail}`);
    }
  };

  const handleUnresolve = async () => {
    try {
      await store.comments.unresolve(comment.id);
    } catch (e) {
      const { detail } = await api.handleError(e as Error);
      Toast.danger(`Could not resolve thread: ${detail!.detail}`);
    }
  };

  const renderView = () => <AtMentionDiv message={comment.comment} />;

  const renderEdit = () => (
    <CommentForm
      key={comment.comment}
      cancelable
      comment={comment}
      isActive
      onSetInactive={handleEditCancelClick}
      selection={selection}
    />
  );

  return (
    <Container>
      <Options alignRight>
        <ToggleButton
          disabled={!first && comment.isResolved}
          variant="borderless"
          size="sm"
          id={`comment_${comment.id}-options`}
        >
          <ThreeDotsVertical />
        </ToggleButton>
        <Dropdown.Menu>
          <Dropdown.Item
            disabled={comment.isResolved}
            onClick={handleEditClick}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDeleteConfirm}>
            Delete&hellip;
          </Dropdown.Item>
          {first ? (
            <>
              <Dropdown.Divider />
              {comment.isResolved ? (
                <Dropdown.Item onClick={handleUnresolve}>
                  Unresolve thread
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={handleResolve}>
                  Resolve thread
                </Dropdown.Item>
              )}
            </>
          ) : null}
        </Dropdown.Menu>
      </Options>
      <User>
        <img src={comment.user.photoSizes.big} alt={comment.user.name} />
        <div className="name">{comment.user.name}</div>
        <div className="date">
          {format(parseISO(comment.datePosted), "P h:mm a", {
            locale: enGB,
          })}
        </div>
      </User>
      {isEditing ? renderEdit() : renderView()}
    </Container>
  );
};

export default observer(Post);
