import base, { APIList, APISearchParams, config } from "./base";

type TreatRate = {
  percentage: number;
  description: string;
  marketSector?: "additive" | "base oil" | "purchased product";
  crdStatus?:
    | "core"
    | "restricted"
    | "core restricted"
    | "to be updated"
    | "not determined"
    | "delete";
};

export interface APIFormulationDatahub {
  id: identifier;
  plantFormulationCode: string;
  masterFormulationCode: string;
  productVariantCode: string;
  fusionPlantCode: string;
  componentRCode: string;
  alternativeRCode: string;
  version: string;
  mass: number;
  formulationStatus: string;
  fusionPlantId: string;
  treatRates: { [key: string]: TreatRate };
  plantFormulationPlant: number;
}
export interface APIFormulationDatahubList
  extends APIList<APIFormulationDatahub> {}

export interface APIFormulationDatahubListSearchParams extends APISearchParams {
  plant_formulation_code?: string;
  master_formulation_code?: string;
  plant?: number | undefined;
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIFormulationDatahubListSearchParams
) =>
  base.list<APIFormulationDatahubList, APIFormulationDatahubListSearchParams>(
    "formulations-datahub/",
    page,
    limit,
    searchParams
  );
