import styled from "styled-components";

export const SubHeader = styled.h2`
  font-weight: bold;
  letter-spacing: 0.063rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  user-select: none;
  text-transform: uppercase;
  color: #000000};
  opacity: 0.3;
`;

export const Header = styled.h1`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  color: #000000;
`;
