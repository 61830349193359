import { computed, observable } from "mobx";
import {
  Model,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import { computedFn } from "mobx-utils";
import api from "../api";
import {
  APIRequirement,
  APIRequirementDetail,
  APIRequirementListSearchParams,
} from "../api/requirements";
import { arrayToCsv } from "../csv/utils";
import Requirement from "./models/Requirement";

@model("collab/RequirementStore")
class RequirementStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, Requirement>()),
}) {
  @observable
  private _csv: string = "";

  @modelAction
  saveListItem(item: APIRequirement) {
    const listItem = new Requirement(item);
    const existing = this.listItems.get(item.id);

    if (!existing) {
      this.listItems.set(item.id, listItem);
      return listItem;
    }

    if (!existing.isDirty) {
      existing.update(item);
    }
    return existing;
  }

  @modelFlow
  list = _async(function* (
    this: RequirementStore,
    projectId: identifier,
    page?: number,
    limit?: number,
    searchParams?: APIRequirementListSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(
      api.requirements.list(page, limit, {
        project: projectId,
        ...searchParams,
      })
    );

    // Clear list except those that have not been saved or are dirty
    [...this.ofProject(projectId)].forEach((requirement) => {
      if (requirement.id > 0 && !requirement.isDirty) {
        this.listItems.delete(requirement.id);
      }
    });

    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelAction
  create(projectId: identifier) {
    // Use a negative `id` to indicate that the requirement has never been saved.
    const id = Math.min(0, ...this.listItems.keys()) - 1;

    const listItem = new Requirement({
      id,
      project: projectId,
      volumeYear1: null,
      volumeYear2: null,
      volumeYear3: null,
      volumeYear4: null,
      volumeYear5: null,
    });
    this.listItems.set(listItem.id, listItem);
  }

  @modelFlow
  saveAll = _async(function* (this: RequirementStore, projectId: identifier) {
    const requirementsToSave = this.ofProject(projectId)
      .filter((item) => item.project === projectId)
      .filter((item) => item.isDirty && item.uniqueRef.trim() !== "");

    if (requirementsToSave.length === 0) {
      return;
    }

    const data = requirementsToSave.map((item) => item.asAPIChanged);

    let instances: APIRequirementDetail[];
    try {
      ({ instances } = yield* _await(
        api.requirements.bulkUpdate(projectId, data)
      ));
    } catch (e) {
      throw e;
    }

    // Delete temporary objects included in the posted data after bulk update.
    // This should be safe because bulk update will either save everything,
    // or save nothing (and throw an exception).
    // Mark saved objects as not dirty.
    requirementsToSave.forEach((item) => {
      if (item.id <= 0) {
        this.listItems.delete(item.id);
      } else {
        item.setNotDirty();
      }
    });

    instances.forEach((instance) => this.saveListItem(instance));
  });

  @modelFlow
  delete = _async(function* (
    this: RequirementStore,
    requirementId: identifier
  ) {
    if (requirementId > 0) {
      try {
        yield* _await(api.requirements.del(requirementId));
      } catch (e) {
        throw e;
      }
    }
    this.listItems.delete(requirementId);
  });

  ofProject = computedFn(function (
    this: RequirementStore,
    projectId: identifier
  ) {
    return Array.from(this.listItems.values())
      .filter((item) => item.project === projectId)
      .sort(({ uniqueRef: a, id: aid }, { uniqueRef: b }) => {
        if (aid < 0) {
          return 1;
        }
        return a.localeCompare(b);
      });
  });

  @modelAction
  commitCsv(projectId: identifier) {
    this._csv = this.getCsv(projectId, false);
  }

  getCsv(projectId: identifier, forExport: boolean = false) {
    return arrayToCsv(
      this.ofProject(projectId).map((item) =>
        forExport ? item.asCSV : item.asRow
      )
    );
  }

  @computed
  get asCsv() {
    return this._csv;
  }
}

export default RequirementStore;
