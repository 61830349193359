import { BorderColor, Create } from "@material-ui/icons";
import React from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import api from "../../api";
import {
  APIProjectFolderInput,
  APIProjectFolderUpdateInput,
} from "../../api/projectFolders";
import Button from "../../components/Button";
import { Header, SubHeader } from "../../components/Header";
import ModalForm from "../../components/ModalForm";
import { useStore } from "../../store";
import ProjectFolder from "../../store/models/ProjectFolders";
import { Toast } from "../../utils";

const HeaderContainer = styled.div`
  min-width: 141px;
`;

interface Props {
  archived?: boolean;
  instance?: ProjectFolder;
  isOpen: boolean;
  isInsideFolder?: boolean;
  setModal: (visible: boolean) => void;
  onFolderSubmit?: () => Promise<void>;
}

const FolderForm: React.FC<Props> = ({
  archived,
  instance,
  isOpen,
  isInsideFolder = false,
  setModal,
  onFolderSubmit,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const store = useStore();
  const headerText = instance ? "Rename" : "Create New";
  const submitLogo = instance ? <BorderColor /> : <Create />;
  const submitText = instance ? "Rename" : "Create";

  const handleValidate = async (
    data: APIProjectFolderInput | APIProjectFolderUpdateInput
  ) => {
    try {
      await (instance
        ? isInsideFolder
          ? store.projectFolders.patch(instance?.slug, data)
          : api.projectFolders.update(instance?.slug, data)
        : api.projectFolders.create(data));
    } catch (errorResponse: any) {
      if (errorResponse.description !== "Unauthorized") {
        const { detail } = await api.handleError(errorResponse);
        if (detail) {
          Object.entries(detail!).forEach(([key, message]) => {
            setError(key as any, { message: (message as string[])[0] });
          });
          return;
        }
      }
    }
    await onFolderSubmit?.();
    setModal(false);
    Toast.success(`Folder ${instance ? "Renamed" : "Added"}`);
  };

  return (
    <ModalForm show={isOpen} onHide={() => setModal(false)} centered>
      <Form onSubmit={handleSubmit(handleValidate)}>
        <ModalForm.Header closeButton>
          <HeaderContainer>
            <SubHeader>{archived ? "Archived" : "Active"} Folder</SubHeader>
            <Header>{headerText} Folder</Header>
          </HeaderContainer>
        </ModalForm.Header>
        <ModalForm.Body>
          <Form.Control
            type="text"
            placeholder="Folder Name"
            {...register("name")}
            defaultValue={instance && instance.name}
            isInvalid={!!errors.name}
          />
          {errors.name && (
            <Form.Control.Feedback type="invalid">
              {errors.name.message}
            </Form.Control.Feedback>
          )}
          {!instance && (
            <Form.Control
              type="hidden"
              {...register("isActive")}
              defaultValue={`${!archived}`}
            />
          )}
        </ModalForm.Body>
        <ModalForm.Footer>
          <Button onClick={() => setModal(false)} variant="cancel">
            Cancel
          </Button>
          <Button type="submit">
            {submitLogo} {submitText}
          </Button>
        </ModalForm.Footer>
      </Form>
    </ModalForm>
  );
};

export default FolderForm;
