import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";
import Button from "../../components/Button";
import { TabList } from "../Workspace/types";

type Props = Pick<React.HTMLAttributes<HTMLDivElement>, "className"> & {
  activeTab: string;
  tabs: TabList;
  tabsBefore?: React.ReactNode;
  tabsAfter?: React.ReactNode;
  onChangeTab: (tab: string) => void;
};

const RootContainer = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #d8d8d8;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
`;

const TabContainer = styled(ScrollContainer)`
  padding: 0.375rem;
  white-space: nowrap;

  button.btn {
    margin-right: 0.375rem;
  }
`;

const WorksheetTabs: React.FC<Props> = ({
  activeTab,
  className,
  tabs,
  onChangeTab,
}) => {
  const tabRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: WheelEvent) => {
    if (tabRef.current) {
      tabRef.current.scrollLeft += e.deltaY;
    }
  };

  useEffect(() => {
    document.addEventListener("wheel", handleScroll);
    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  });

  return (
    <RootContainer ref={tabRef} className={className}>
      <TabContainer vertical={false} hideScrollbars>
        {Object.entries(tabs).map(([id, tab]) => (
          <Button
            key={id}
            active={id === activeTab}
            onClick={() => onChangeTab(id)}
            variant={tab.type}
            size="sheet-tab"
          >
            {tab.title}
          </Button>
        ))}
      </TabContainer>
    </RootContainer>
  );
};

export default observer(WorksheetTabs);
