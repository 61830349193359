import { Close, FolderSpecial, PersonOutlined } from "@material-ui/icons";
import Logo from "components/Logo";
import { observer } from "mobx-react-lite";
import { darken } from "polished";
import React from "react";
import { Button, Nav } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

type Props = {
  show?: boolean;
  onClose: () => void;
};

const Container = styled(Nav)`
  width: 13.75rem;
  padding: 1.25rem;
  background-color: white;
  height: 100%;
  position: fixed;
  left: -13.75rem;
  top: 0;
  z-index: 2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  transition-property: left;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  && {
    display: flex;
    flex-direction: column;
  }

  &&.side-nav-enter-done {
    left: 0;
  }
`;

const SideNavButton = styled(Button)`
  background-color: transparent;
  color: #292929;
  border: 0;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  display: block;

  &:active,
  &:hover,
  &:focus {
    background-color: transparent !important;
    color: #292929 !important;
    box-shadow: none !important;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const SideNavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 0;
  color: #000000;
  transition: background-color 0.15s;

  &:first-of-type {
    margin-top: 1rem;
  }

  &:hover {
    background-color: ${darken(0.05, "#ffffff")};
    color: #000000;
    text-decoration: none;
  }
`;

const SideNavText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  color: #000000;
`;

const Spacer = styled.div`
  flex: 1;
`;

const SideNavLogo = styled(Logo)`
  opacity: 0.3;
  user-select: none;
  pointer-events: none;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
`;

const SideNav: React.FC<Props> = ({ show, onClose }) => {
  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <CSSTransition in={show} timeout={200} classNames="side-nav">
        <Container>
          <SideNavButton onClick={onClose}>
            <Close />
          </SideNavButton>
          <SideNavItem to="/projects">
            <FolderSpecial />
            <SideNavText>Projects Drive</SideNavText>
          </SideNavItem>
          <SideNavItem to="/tender-outcomes">
            <FolderSpecial />
            <SideNavText>Tender Outcomes</SideNavText>
          </SideNavItem>
          <SideNavItem to="/permissions">
            <PersonOutlined />
            <SideNavText>Permissions Settings</SideNavText>
          </SideNavItem>
          <Spacer />
          <SideNavLogo />
        </Container>
      </CSSTransition>
    </OutsideClickHandler>
  );
};

export default observer(SideNav);
