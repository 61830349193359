import React from "react";
import { CheckCircle } from "@material-ui/icons";
import styled from "styled-components";

type Props = {};
const ProjectApprovedText = styled.div`
  span {
    color: #484848;
    font-weight: 600;
    font-size: 0.875rem;
  }

  svg {
    fill: #9bbe41;
    margin-right: 0.313rem;
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const ProjectApprovedLabel: React.FC<Props> = () => {
  return (
    <ProjectApprovedText>
      <CheckCircle />{" "}
      <span>This sheet has been approved by the CLT Reviewer.</span>
    </ProjectApprovedText>
  );
};

export default ProjectApprovedLabel;
