import { AttachFileOutlined, DeleteOutlined } from "@material-ui/icons";
import api from "api";
import Button from "components/Button";
import Loading from "components/Loading";
import { formatDistanceToNow, parseISO } from "date-fns";
import { saveAs } from "file-saver";
import FileIcon from "icons/FileIcons/FileIcon";
import PDFIcon from "icons/FileIcons/PDFIcon";
import XLSXIcon from "icons/FileIcons/XLSXIcon";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { SyntheticEvent, useMemo, useRef, useState } from "react";
import { useAsync } from "react-use";
import { useStore } from "store";
import CLTFile from "store/models/CLTFile";
import styled, { css } from "styled-components";
import { convertFileToBase64, Toast } from "utils";

type Props = {
  clusterId?: identifier | undefined;
  sheetsStatus?: identifier;
  define?: boolean;
  floating?: boolean;
  inputSheet?: identifier;
};

const Container = styled.div<{
  floating?: boolean;
}>`
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 23.125rem;
  height: 34rem;
  border: 1px solid #989898;
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: #fafafa;

  ${({ floating }) =>
    floating
      ? css`
          position: absolute;
          top: 3rem;
          right: 1rem;
        `
      : css`
          position: relative;
          @media only screen and (max-width: 1024px) {
            max-width: 100%;
          }
        `};
`;

const FileBoxHeader = styled.div`
  font-weight: bold;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CLTFileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CLTFileLink = styled.button`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  color: #000000;
  background: none;
  color: inherit;
  border: none;
  text-align: left;
  align-self: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const CLTFileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 10rem;
`;

const TimeAgoContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
`;

const TimeAgoText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.625rem;
  padding-right: 0.25rem;
  text-align: right;
  min-width: 4rem;
`;

const HeaderTextContainer = styled.div`
  padding-top: 0.375rem;
`;

const AttachFileIcon = styled(AttachFileOutlined)`
  transform: rotate(90deg);
`;

const DeleteIcon = styled(DeleteOutlined)`
  transform: scale(0.875);
  &:hover {
    transform: scale(1);
    color: #b66436;
  }
`;
const CLTFiles: React.FC<Props> = ({
  clusterId,
  sheetsStatus,
  define,
  floating,
  inputSheet,
}) => {
  const store = useStore();
  const project = useProject();
  const [loading, setLoading] = useState(false);

  // (Re)load clt files when user opens this tab.
  const reloadFiles = async () => {
    if (!project) {
      return;
    }
    await store.cltFiles.list(project.id);
    setLoading(false);
  };
  const state = useAsync(reloadFiles, [project]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileUploadButtonClick = (e: SyntheticEvent) => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleChange = async (e: any) => {
    setLoading(true);
    const file = e.target.files[0];
    const b64File = await convertFileToBase64(file);
    const payload = {
      project: project?.id,
      name: file.name,
      file: b64File,
      ...(sheetsStatus
        ? { sheetsStatus: sheetsStatus }
        : { cluster: clusterId }),
    };
    try {
      await api.cltFiles.create(payload);
    } catch (e) {
      Toast.danger("Failed to upload file.");
      return;
    }
    Toast.success("File Uploaded Successfully!");
    reloadFiles();
  };

  const CLTFileDisplay: React.FC<{ cltFile: CLTFile }> = ({ cltFile }) => {
    const { name, created } = cltFile;

    const Icon = useMemo(() => {
      const extension = name.split(".")?.[1];

      switch (extension) {
        case "pdf":
          return <PDFIcon />;
        case "csv":
          return <XLSXIcon />;
        case "xlsx":
          return <XLSXIcon />;
        default:
          return <FileIcon />;
      }
    }, [name]);

    const handleDownload = (e: SyntheticEvent) => {
      saveAs(cltFile.file, cltFile.name);
    };

    const handleDelete = async (e: SyntheticEvent) => {
      if (
        !window.confirm("Are you sure you want to delete this file?") ||
        !cltFile.id
      ) {
        return;
      }
      setLoading(true);
      try {
        await api.cltFiles.destroy(cltFile.id);
      } catch {
        Toast.danger("Failed to delete file.");
        return;
      }
      Toast.success("File Deleted Successfully!");
      reloadFiles();
    };
    return (
      <CLTFileContainer>
        <CLTFileLink onClick={handleDownload}>
          {Icon}
          <CLTFileName>{name}</CLTFileName>
        </CLTFileLink>
        <TimeAgoContainer>
          <TimeAgoText>
            {formatDistanceToNow(parseISO(created))} ago
          </TimeAgoText>
        </TimeAgoContainer>
        <DeleteIcon onClick={handleDelete} />
      </CLTFileContainer>
    );
  };

  const ready = useMemo(
    () => !state.loading && !loading,
    [state.loading, loading]
  );

  const cltFiles = useMemo(() => {
    if (!project || !ready) {
      return [];
    }
    return inputSheet
      ? store.cltFiles.ofInputSheet(inputSheet)
      : define
      ? store.cltFiles.ofCluster(project.id, clusterId)
      : sheetsStatus
      ? store.cltFiles.ofSheetsStatus(sheetsStatus)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project,
    ready,
    inputSheet,
    store.cltFiles,
    store.cltFiles.listItems.size,
    define,
    clusterId,
    sheetsStatus,
  ]);

  if (!ready || !project || !clusterId) {
    return <Loading full overlay />;
  }

  return (
    <Container floating={floating}>
      <FileBoxHeader>
        <HeaderTextContainer>FILES </HeaderTextContainer>
        {!inputSheet && (
          <Button variant="file-box" onClick={handleFileUploadButtonClick}>
            <input
              type="file"
              hidden
              ref={fileInputRef}
              onChange={handleChange}
            />
            Upload File <AttachFileIcon />
          </Button>
        )}
      </FileBoxHeader>
      {cltFiles?.length ? (
        cltFiles.map((cltFile, idx) => (
          <CLTFileDisplay key={idx} cltFile={cltFile} />
        ))
      ) : (
        <div>No Files</div>
      )}
    </Container>
  );
};

export default observer(CLTFiles);
