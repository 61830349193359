import { Form } from "react-bootstrap";
import styled from "styled-components";

const Toolbar = styled(Form)`
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  gap: 1.25rem;
  padding: 0 1.25rem;
  height: 3rem;
  width: 100%;

  .dropdown-item {
    font-size: 0.875rem;
    margin-right: 1rem;

    svg {
      height: 1.25rem;
      margin-right: 1rem;
      vertical-align: top;
    }
  }
`;

export default Toolbar;
