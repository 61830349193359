const XLSXIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4675 5.25625C17.4466 5.20594 17.416 5.1602 17.3775 5.12168L12.3775 0.12168C12.2993 0.0440234 12.1936 0.0003125 12.0833 0H2.91668C2.68656 0 2.5 0.186563 2.5 0.41668V19.5834C2.5 19.8134 2.68656 20 2.91668 20H17.0834C17.3135 20 17.5 19.8134 17.5 19.5833V5.41668C17.4999 5.36156 17.4888 5.30703 17.4675 5.25625ZM12.5 1.4225L16.0775 5H12.5V1.4225ZM16.6667 19.1667H3.33332V0.83332H11.6666V5.41664C11.6666 5.64676 11.8532 5.83332 12.0833 5.83332H16.6666V19.1667H16.6667Z"
      fill="black"
    />
    <path
      d="M5 7.08318V17.0832C5 17.3133 5.18656 17.4998 5.41668 17.4998H14.5834C14.8135 17.4998 15 17.3133 15 17.0832V7.08318C15 6.85307 14.8135 6.6665 14.5834 6.6665H5.41668C5.18656 6.66654 5 6.85307 5 7.08318ZM12.5 7.49986H14.1667V9.16654H12.5V7.49986ZM12.5 9.99986H14.1667V11.6665H12.5V9.99986ZM12.5 12.4999H14.1667V14.1665H12.5V12.4999ZM12.5 14.9999H14.1667V16.6665H12.5V14.9999ZM8.33332 7.49986H11.6666V9.16654H8.33332V7.49986ZM8.33332 9.99986H11.6666V11.6665H8.33332V9.99986ZM8.33332 12.4999H11.6666V14.1665H8.33332V12.4999ZM8.33332 14.9999H11.6666V16.6665H8.33332V14.9999ZM5.83332 7.49986H7.5V9.16654H5.83332V7.49986ZM5.83332 9.99986H7.5V11.6665H5.83332V9.99986ZM5.83332 12.4999H7.5V14.1665H5.83332V12.4999ZM5.83332 14.9999H7.5V16.6665H5.83332V14.9999Z"
      fill="black"
    />
  </svg>
);
export default XLSXIcon;
