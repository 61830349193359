import api from "api";
import { APIAdditive, APIAdditiveInput } from "api/formulations";
import { computed } from "mobx";
import {
  ExtendedModel,
  model,
  modelFlow,
  prop,
  _async,
  _await,
} from "mobx-keystone";
import DirtyableWithId from "./DirtyableWithId";

@model("collab/Additive")
class Additive extends ExtendedModel(DirtyableWithId, {
  rcode: prop<string>(""),
  description: prop<string>(""),
  crdStatus: prop<string>(""),
}) {
  _prevId: number = 0;

  onInit() {
    this.updatePrevId();
  }

  updatePrevId() {
    this._prevId = this.id;
  }

  @computed
  get asAPI(): APIAdditiveInput {
    return {
      rcode: this.rcode,
      description: this.description,
      crdStatus: this.crdStatus,
    };
  }

  @modelFlow
  save = _async(function* (this: Additive) {
    if (!this.isDirty) {
      return;
    }

    let response: APIAdditive;
    try {
      response = yield* _await(api.formulations.additiveCreate(this.asAPI));
    } catch (e) {
      console.error(e);
      throw e;
    }

    this.update(response);
  });
}

export default Additive;
