import { SearchOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { FormControl, Modal } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useStore } from "store";
import styled from "styled-components";
import { Toast } from "utils";
import Button from "../Button";
import FormLabel from "../FormLabel";
import Loading from "../Loading";
import ReactSelect from "../ReactSelect";

const TextInput = styled(FormControl)`
  font-size: 0.875rem;
  line-height: 0.875rem;
  height: 2.375rem;
  margin-top: 0.125rem;
  margin-bottom: 0.75rem;

  &:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #74b468;
    outline: #74b468 auto 1px;
    box-shadow: none;
  }
`;

const BooleanInput = styled.label`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.031rem;
  margin-top: 1.125rem;

  input {
    display: none;
  }
  span {
    display: inline-block;
    height: 0.9rem;
    width: 0.9rem;
    border: 2px solid #000000;
    border-radius: 3px;
    padding: 2px;
    margin-right: 0.375rem;
    background-clip: content-box;
  }
  [type="checkbox"]:checked + span {
    background-color: rgb(0, 81, 148, 1);
  }
`;

type Inputs = {
  formulationCode: string;
  plant?: number;
  isMaster: boolean;
};

type SearchFormulationsFormProps = {
  onCancel: () => void;
};

const SearchFormulationsForm: React.FC<SearchFormulationsFormProps> = ({
  onCancel,
}) => {
  const store = useStore();
  const methods = useForm<Inputs>({
    defaultValues: {
      formulationCode: "",
      plant: undefined,
      isMaster: true,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: Inputs) => {
      setIsLoading(true);

      const { isMaster, plant, formulationCode } = data;

      try {
        await store.formulations.listDatahub(
          undefined,
          undefined,
          isMaster && plant
            ? {
                master_formulation_code: formulationCode,
                plant: plant,
              }
            : isMaster
            ? {
                master_formulation_code: formulationCode,
              }
            : {
                plant_formulation_code: formulationCode,
              }
        );
      } catch (e) {
        Toast.danger("Unable to fetch formulations data.");
        return;
      } finally {
        setIsLoading(false);
        if (store.formulations.datahubListItems.size < 1) {
          Toast.danger("No formulations data found.");
        }
      }
    },
    [store.formulations]
  );

  const plantOptions = store.assets.plantSelect;

  return !isLoading ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body>
          <FormLabel>Formulation Code</FormLabel>
          <TextInput
            {...methods.register("formulationCode", { required: true })}
            placeholder="Search"
          />
          <FormLabel>Plant Selected for Costing</FormLabel>
          <Controller
            name="plant"
            control={methods.control}
            rules={{ required: false }}
            render={({ field }) => (
              <ReactSelect
                name="plant"
                options={plantOptions}
                placeholder="Select Plant (Optional)"
                noOptionsMessage="-"
                hideSelectedOptions={false}
              />
            )}
          />
          <BooleanInput>
            <input {...methods.register("isMaster")} type="checkbox" />
            <span></span>
            Filter by Master Code
          </BooleanInput>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="borderless" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            <SearchOutlined /> Search
          </Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  ) : (
    <Loading />
  );
};

export default observer(SearchFormulationsForm);
