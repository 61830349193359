import base, { APIList, APISearchParams, config } from "./base";
import { APICluster } from "./types";

export interface APIClusterList extends APIList<APICluster> {}
export interface APIClusterListSearchParams extends APISearchParams {}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIClusterListSearchParams
) =>
  base.list<APIClusterList, APIClusterListSearchParams>(
    "clusters/",
    page,
    limit,
    searchParams
  );
