import {
  Model,
  model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import api from "../api";
import {
  APIInputSheet,
  APIInputSheetFileType,
  APIInputSheetSearchParams,
} from "../api/inputSheets";
import InputSheet from "./models/InputSheet";

@model("collab/InputSheetStore")
class InputSheetStore extends Model({
  listItems: prop_mapObject(() => new Map<identifier, InputSheet>()),
}) {
  @modelAction
  saveListItem(item: APIInputSheet) {
    const listItem = new InputSheet(item);
    const existing = this.listItems.get(item.id);

    if (!existing) {
      this.listItems.set(item.id, listItem);
      return listItem;
    }

    if (!existing.isDirty) {
      existing.update(listItem);
    }
    return existing;
  }

  @modelFlow
  list = _async(function* (
    this: InputSheetStore,
    projectId: identifier,
    page?: number,
    limit?: number,
    searchParams?: APIInputSheetSearchParams
  ) {
    const {
      count,
      next,
      previous,
      results: resultsRaw,
    } = yield* _await(
      api.inputSheets.list(page, limit, {
        ...searchParams,
        sheets_status__project: projectId,
      })
    );

    // Clear cached items matching the search params
    Array.from(this.listItems.entries())
      .filter(([id, item]) => item.project === projectId)
      .forEach(([id]) => {
        this.listItems.delete(id);
      });

    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  ofProject(projectId: identifier) {
    const perType = new Map<APIInputSheetFileType, InputSheet[]>();
    Array.from(this.listItems.values())
      .filter((sheet) => sheet.project === projectId)
      .sort((a, b) => a.clusterName.localeCompare(b.clusterName))
      .forEach((sheet) => {
        if (!perType.has(sheet.fileType)) {
          perType.set(sheet.fileType, []);
        }
        perType.get(sheet.fileType)!.push(sheet);
      });
    return perType;
  }

  @modelFlow
  saveAll = _async(function* (
    this: InputSheetStore,
    projectId: identifier,
    manual: boolean
  ) {
    const sheetsGrouped = this.ofProject(projectId);
    const sheets = Array.from(sheetsGrouped.values()).reduce(
      (acc, cur) => [...acc, ...cur],
      []
    );
    yield* _await(Promise.all(sheets.map((sheet) => sheet.save(manual))));
    yield* _await((async () => {})());
  });
}

export default InputSheetStore;
