import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { APITenderOutcome } from "api/types";
import clsx from "clsx";
import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Editor from "components/Editor";
import { isBefore, parseISO } from "date-fns";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import React, { useMemo } from "react";
import BootstrapTable, {
  ColumnDescription,
  SortOrder,
} from "react-bootstrap-table-next";
import { Controller, useFormContext } from "react-hook-form";
import { useStore } from "store";
import styled, { css } from "styled-components";

const BootstrapTableWrapper = styled.div`
  .sd-table {
    border-collapse: separate;
    border-spacing: 0 0.625rem;
    font-size: 0.75rem;

    color: #000000;

    .sd-table-header {
      background: #eef1f3;
      font-weight: bold;
      line-height: 1rem;
      text-transform: uppercase;

      th {
        border-bottom: 0.063rem solid #b5c0c8;
        border-top: 0.063rem solid #b5c0c8;

        &:first-of-type {
          border-left: 0.063rem solid #b5c0c8;
        }
        &:last-of-type {
          border-right: 0.063rem solid #b5c0c8;
        }
        &:first-of-type,
        &:last-of-type {
          border-radius: 0.25rem;
        }

        :focus {
          outline: none;
        }
      }
    }

    .sd-table-row {
      line-height: 0.75rem;
      background: #ffffff;
      box-shadow: 0.25rem 0.25rem 0.313rem rgba(0, 0, 0, 0.02);

      td {
        border-bottom: 0.063rem solid #bec5ca;
        border-top: 0.063rem solid #bec5ca;
        text-align: center;

        &:first-of-type {
          border-left: 0.063rem solid #bec5ca;
          text-align: left;
        }
        &:last-of-type {
          border-right: 0.063rem solid #bec5ca;
        }
        &:first-of-type,
        &:last-of-type {
          border-radius: 0.125rem;
        }
      }
    }

    td.react-bs-table-no-data {
      border-top: none;
      color: #808080;
      text-align: center;
      user-select: none;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 600;
    }

    th,
    td {
      vertical-align: middle;
    }

    td {
      padding: 0.625rem 0.75rem;
    }
  }
`;

const HeaderWrapper = styled.div<{ leftAlign?: boolean }>`
  display: flex;

  ${({ leftAlign }) =>
    leftAlign
      ? css`
          align-items: left;
          justify-content: start;
        `
      : css`
          align-items: center;
          justify-content: center;
        `}
  user-select: none;
  cursor: pointer;
  gap: 1.125rem;
`;

const CaretWrapper = styled.div`
  svg {
    width: 0.875rem;
    height: 0.875rem;
    stroke: #4e4e4e;
    border: 0.063rem solid #4e4e4e;
    box-sizing: border-box;
    border-radius: 0.125rem;
  }
`;

const ProjectNameDiv = styled.div`
  font-weight: 700;
`;

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
`;

const StyledDatePicker = styled(DatePicker)`
  padding-left: auto;
  padding-right: auto;

  &.input-group {
    .form-control {
      height: 2.375rem;
    }

    .btn-calendar,
    .form-control {
      background-color: #fdfdfd;
      border-color: #c8c8c8;
      color: #000000;
    }

    &.date-on-time {
      .btn-calendar,
      .form-control {
        background-color: #c3da87;
        border-color: #8fac42;
      }
    }

    &.date-not-on-time {
      .btn-calendar,
      .form-control {
        background: #f89964;
        border-color: #b66436;
      }
    }
  }
`;

type Props = {
  hasEditPermissions: boolean;
};

const TenderOutcomeTable: React.FC<Props> = ({ hasEditPermissions }) => {
  const { control, setValue } = useFormContext();
  const store = useStore();
  const project = useProject();

  const data = useMemo(
    () =>
      Array.from(
        project
          ? store.tenderOutcomes.ofProject(project.id)
          : store.tenderOutcomes.listItems.values()
      ),
    [project, store.tenderOutcomes]
  );

  const tenderOutcomeHeaderFormatter = (
    column: ColumnDescription,
    colIndex: number,
    { sortElement }: { sortElement: JSX.Element }
  ) => (
    <>
      <HeaderWrapper leftAlign={colIndex === 0}>
        <div>{column.text}</div>
        {sortElement}
      </HeaderWrapper>
    </>
  );

  const tenderOutcomeSortCaret = (order?: SortOrder) => {
    const caret = order === "asc" ? <ArrowDropUp /> : <ArrowDropDown />;
    return <CaretWrapper>{caret}</CaretWrapper>;
  };

  const datePickerFormatter = (
    cell: string,
    row: APITenderOutcome,
    rowIndex: number
  ) => (
    <Controller
      control={control}
      name={`instances[${rowIndex}].date`}
      defaultValue={!!cell ? parseISO(cell) : undefined}
      render={({ field }) => {
        const isOnTime = isBefore(new Date(), field.value);
        return (
          <StyledDatePicker
            disabled={!hasEditPermissions}
            className={clsx({
              "date-on-time": isOnTime,
              "date-not-on-time": !isOnTime,
            })}
            {...field}
          />
        );
      }}
    />
  );

  const statusSelectFormatter = (
    cell: string,
    row: APITenderOutcome,
    rowIndex: number
  ) => (
    <CellWrapper>
      <Controller
        control={control}
        name={`instances[${rowIndex}].status`}
        defaultValue={cell}
        render={({ field }) => (
          <>
            <Button
              disabled={!hasEditPermissions}
              variant="tender-won"
              active={field.value === "won"}
              onClick={() => setValue(`instances[${rowIndex}].status`, "won")}
            >
              Won
            </Button>
            <Button
              disabled={!hasEditPermissions}
              variant="tender-lost"
              active={field.value === "lost"}
              onClick={() => setValue(`instances[${rowIndex}].status`, "lost")}
            >
              Lost
            </Button>
          </>
        )}
      />
    </CellWrapper>
  );

  const columns = [
    {
      dataField: "projectName",
      sort: true,
      text: "Project Name",
      headerFormatter: tenderOutcomeHeaderFormatter,
      sortCaret: tenderOutcomeSortCaret,
      formatter: (cell: string, row: APITenderOutcome, rowIndex: number) => (
        <>
          <ProjectNameDiv>{cell}</ProjectNameDiv>
          <Controller
            control={control}
            name={`instances[${rowIndex}].id`}
            render={({ field }) => <input type="hidden" {...field} />}
            defaultValue={row.id}
          />
        </>
      ),
    },
    {
      dataField: "date",
      text: "Date Published",
      sort: true,
      headerFormatter: tenderOutcomeHeaderFormatter,
      sortCaret: tenderOutcomeSortCaret,
      formatter: datePickerFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerFormatter: tenderOutcomeHeaderFormatter,
      sortCaret: tenderOutcomeSortCaret,
      formatter: statusSelectFormatter,
    },
  ];

  return (
    <>
      <BootstrapTableWrapper>
        <BootstrapTable
          classes="sd-table"
          headerClasses="sd-table-header"
          rowClasses="sd-table-row"
          keyField="id"
          bordered={false}
          data={data}
          columns={columns}
          defaultSorted={[{ dataField: "projectName", order: "asc" }]}
          noDataIndication={() => "No Tender Outcomes"}
        />
      </BootstrapTableWrapper>
      {project && data.length === 1 ? (
        <Editor
          fullWidth
          defaultValue={data[0].notes}
          name="instances[0].notes"
          size="md"
          readOnly={project.loading}
        />
      ) : null}
    </>
  );
};

export default observer(TenderOutcomeTable);
